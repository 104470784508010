.disabled-row {
  background-color: red;
  pointer-events: none;
}

.rowColor {
  background-color: none !important;
}

.ant-table-thead thead tr th {
  background-color: none !important;
}

.ant-table-wrapper.ToDo.ant-table-row {
  margin: 30px 0 0 0 !important;
  background: none !important;
}

.ant-table-tbody {
  margin: 20px 0 20px 0 !important;
}

.ant-table-thead > tr > th {
  background-color: none !important;
  white-space: nowrap !important;
}

.ant-table-thead tr {
  border-bottom: 3px solid #ccc !important;
}

.ant-table-cell::before {
  height: 0px !important;
}

.ant-table-wrapper.ToDo .ant-table .ant-table-thead > tr > th {
  background: none !important;
  border-bottom: none !important;
}

.ant-table-wrapper.ToDo .ant-table .ant-table-thead > tr > th:nth-child(5) {
  background: none !important;
  border-bottom: none !important;
  text-align: center !important;
}

.ant-table-wrapper.ToDo .ant-table .ant-table-thead > tr > th:nth-child(6) {
  background: none !important;
  border-bottom: none !important;
  text-align: center !important;
}

.ant-table-wrapper.Resolution_table .ant-table .ant-table-thead > tr > th {
  background: none !important;
  border-bottom: none !important;
}

.ant-table-wrapper.Resolution_table .ant-table .ant-table-tbody > tr > td {
  position: initial !important;
}

.ant-table-wrapper.Resolution_table
  .ant-table
  .ant-table-thead
  > tr
  > th:nth-child(6) {
  background: none !important;
  border-bottom: none !important;
  text-align: center !important;
}

.ant-table-wrapper.Resolution_table
  .ant-table
  .ant-table-thead
  > tr
  > th:nth-child(6) {
  background: none !important;
  border-bottom: none !important;
  text-align: center !important;
}

.ant-table-wrapper.ToDo
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ApprovalSending-wrapper {
  clear: both;
  max-width: 100%;
}

.ant-table-wrapper.PendingApprovalsTable .ant-table .ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.ant-table-wrapper.ApprovalsTable .ant-table .ant-table-tbody > tr > td {
  padding: 12px 0px !important;
}

.ant-table-wrapper.ToDo .ant-table {
  background: none !important;
}

.ToDo .ant-table table {
  border-spacing: 0 10px !important;
  border-radius: 50px !important;
  table-layout: fixed !important;
  margin-top: -19px !important;
}
.ToDo::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.ToDo::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.ant-table-wrapper.ToDo.ant-table .ant-table-thead > tr > th {
  padding: 2px 16px !important;
}
.ant-table-wrapper.ToDo
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}