.LeaveMeetinButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.LeaveMeetinButton:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.JoinMeetingButton {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.JoinMeetingButton:is(:active, :hover, :focus, :focus-visible) {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.CopyLinkButton {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.CopyLinkButton:is(:active, :hover, :focus, :focus-visible) {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.CopyLinkButton_Disabled {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.CopyLinkButton_Disabled:is(:active, :hover, :focus, :focus-visible) {
  padding: 8px 24px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ScrollerMeeting_Active {
  height: 48vh !important;
  overflow-x: hidden !important;
}

.ScrollerMeeting_Active::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.ScrollerMeeting_Active::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Heading_Gray_meeting {
  color: #5a5a5a !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.MeetingTitle_Heading {
  font-weight: 600 !important;
  font-size: 28px !important;
  color: #6172d6 !important;
}

.ParaGraph_SavedMeeting {
  color: #505050 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  overflow: hidden;
  display: block;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.NOtes_heading {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #6172d6 !important;
}

.LinkClass {
  font-size: 20px !important;
  color: #6172d6 !important;
  text-decoration: underline !important;
}

.RspvClassDetails {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
}

.Cancel_Meeting_SaveMeeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Meeting_SaveMeeting:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Meeting_SaveMeeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Meeting_SaveMeeting:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Meeting_Details {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Scedule_OnHeading {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

.SceduledDateTime {
  color: #505050 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

img.clipboard-icon {
  cursor: pointer;
}
