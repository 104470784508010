.todolist-title-col {
  cursor: pointer;
  color: #505050;
  margin: 0px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px !important;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600 !important;
  color: #2197d7 !important;
}

body[dir="rtl"] .todolist-title-col {
  font-weight: 600 !important;
  color: #2197d7 !important;
}

.blue {
  color: #6172d6;
  font-weight: 600 !important;
}

.orange {
  color: #d8a709;
  font-weight: 600 !important;
}

.yellow {
  color: yellow;
  font-weight: 600 !important;
}

.gray {
  color: gray;
  font-weight: 600 !important;
}

.green {
  color: green;
  font-weight: 600 !important;
}

.todolist-create-btn {
  width: 145px !important;
}

body[dir="rtl"] .todolist-create-btn {
  width: 160px !important;
}

.todolist-create-btn button {
  width: 131px;
  height: 31px;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
  position: relative;
}

body[dir="rtl"] .todolist-create-btn button {
  font-weight: 400 !important;
}

.todolist-create-btn button:focus-visible {
  box-shadow: none !important;
}

body[dir="rtl"] .todolist-create-btn button {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.ToDo {
  overflow-y: auto;
}

.ToDo tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 3px 3px !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #505050 !important;
}

.ToDo tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(1) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

body[dir="rtl"]
  .ToDo
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border-left: 0px !important;
}

.ToDo tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(6) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  text-align: center;
}

body[dir="rtl"]
  .ToDo
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(6) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px !important;
  border-right: 0px !important;
  text-align: center;
}

/* for todo pagination focus color */

.ToDo .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e9e9e9 !important;
  font-size: 14px;
  font-weight: bold;
}

.Status-Todo .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  width: 100px !important;
  min-width: 100px !important;
}

/* .ant-tooltip.ant-tooltip-placement-top {
  display: none !important;
} */

.ant-table-filter-column {
  display: inline-flex !important;
}

.ant-radio-input {
  opacity: 1 !important;
}

.filter-chevron-icon-todolist {
  color: #1890ff !important;
  float: left !important;
}

.ant-table-column-title {
  z-index: 0 !important;
}

.todolistContainer_Committee {
  height: auto;
  width: 100% !important;
}

.ant-table-wrapper.ToDo table thead tr th:nth-child(4) {
  text-align: center !important;
}

.ant-table-wrapper.ToDo table tbody tr td:nth-child(4) {
  text-align: center !important;
}

.ant-table-wrapper.ToDo table tbody tr td:nth-child(5) {
  text-align: center !important;
}

.todolist-heading-size {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  position: relative;
  top: 3px;
}

body[dir="rtl"] .todolist-heading-size {
  font-weight: 600 !important;
}

/* for todolist table */
.NoTaskTodo .ant-result-title {
  color: #e4e4e4;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .row-scroll-todolist {
    position: relative !important;
    overflow: auto !important;
    padding: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .todolist-heading-size {
    font-size: 15px !important;
    color: #6172d6 !important;
    font-weight: 600 !important;
    margin-top: 2px !important;
  }

  .todolist-create-btn button {
    font-size: calc(14px - 20%) !important;
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    color: #fff !important;
    border-radius: 4px !important;
    font-weight: 600 !important;
    border: none !important;
  }
}

.ant-result.ant-result-info.NoTaskTodo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded:has(
    .ant-result.ant-result-info.NoTaskTodo
  ) {
  background: none;
}

.iconForDatePicker.margin-right-20 svg.DatePickerIcon {
  height: 36px !important;
  width: 33px !important;
  margin-top: 1px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: 5px !important;
}

button.btn.btn-primary.meeting.search.me-3.btn.btn-Primary svg {
  margin-top: -1px;
}

body[dir="rtl"]
  .ant-table-wrapper.ToDo
  .ant-table
  .ant-table-thead
  > tr
  > th.deadLineTodo {
  text-align: center !important;
  padding-right: 0 !important;
}

body[dir="rtl"] .ArabicStatus {
  font-weight: 600 !important;
}

.InProgress {
  color: #d8a709;
}

body[dir="rtl"] .InProgress {
  font-weight: 600 !important;
}

.Pending {
  color: #5f78d6;
}

body[dir="rtl"] .Pending {
  font-weight: 600 !important;
}

.Upcoming {
  color: #f68732;
}

body[dir="rtl"] .Upcoming {
  font-weight: 600 !important;
}

.Cancelled {
  color: #f16b6b;
}

body[dir="rtl"] .Cancelled {
  font-weight: 600 !important;
}

.Completed {
  color: #81db86;
}

body[dir="rtl"] .Completed {
  font-weight: 600 !important;
}

.filterValues-dropdown
  div
  .rc-virtual-list
  .rc-virtual-list-holder
  div
  .rc-virtual-list-holder-inner {
  display: block !important;
  width: auto;
}

.PaginationStyle-TodoList {
  background-color: #fff !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
  padding: 12px 40px !important;
  height: 54px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
  align-items: center;
}

body[dir="rtl"] .PaginationStyle-TodoList {
  width: auto !important;
  padding: 8px 10px !important;
}
.Status-Todo
  div
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner {
  display: block !important;
}
td.ant-table-column-sort {
  background: transparent;
}
.ToDo .ant-table .ant-table-body {
  padding-right: 10px;
}

.FilterResetBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
