.Forgot_Password_Verification_Tagline {
  font-size: 12px !important;
  margin-top: 5px;
  margin-left: 17px !important;
  font-weight: 400 !important;
}

.Forgot_Password_Verification_ResendCode {
  font-size: 10px !important;
  margin-left: 4px;
  color: #5f78d6 !important;
  text-decoration: underline;
}

.ForgotPassword_Verification_EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px !important;
  padding: 0 20px;
}

.Update_Password_successfull_Next_button_EmailVerify {
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: calc(18px - 20%) !important;
  padding: 10px 15px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Update_Password_successfull_Next_button_EmailVerify {
  font-weight: 600 !important;
}

.Update_Password_successfull_Next_button_EmailVerify:focus-visible {
  width: 50%;
  border-radius: 4px;
  font-size: calc(18px - 20%) !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.Update_Password_successfull_Next_button_EmailVerify:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6;
}

.Update_password_successfully_Auth_Icon {
  position: absolute;
  right: -115px;
  bottom: -135px;
  transform: rotate(63deg);
  z-index: 99999;
}

.Login_page_text {
  display: flex;
  margin-top: 12%;
  flex-direction: column;
  margin-left: 40px;
  justify-content: flex-start;
  align-items: flex-start;
}

.Login_page_text h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 114%;
}

.Update_password_loginbox_auth_paper {
  margin-left: 60px;
  padding: 60px 0;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

body[dir="rtl"] .Update_password_loginbox_auth_paper {
  margin-right: 60px;
  padding: 30px 0;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.heading-1 {
  color: #6172d6;
}

body[dir="rtl"] .heading-1 {
  font-weight: 600 !important;
  font-size: 70px !important;
}

.heading-2 {
  color: #4adede;
}

body[dir="rtl"] .heading-2 {
  font-weight: 600 !important;
  font-size: 60px !important;
}

.Update_Password_Successfull_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Update_Password_Successfull_select-language-signin:focus,
.Update_Password_Successfull_select-language-signin:focus-within,
.Update_Password_Successfull_select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.Update_Password_Successfull_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

body[dir="rtl"] .Update_Password_Successfull_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 80%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

.Update_password_successful_language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.Update_password_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .Update_password_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 80.5%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.Update_Password_Heading {
  font-size: calc(32px - 20%) !important;
  user-select: none;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Update_Password_Heading {
  font-weight: 600 !important;
}

.update_password_second_heading {
  margin-top: -15px !important;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}
