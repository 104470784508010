.notes-margin {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  top: 20px !important;
}

.notes-heading-size {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .notes-heading-size {
  font-weight: 600 !important;
}

.create-note-btn {
  width: 150px;
  height: 31px;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
}

body[dir="rtl"] .create-note-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.create-note-btn:hover,
.create-note-btn:active,
.create-note-btn:focus,
.create-note-btn:focus-visible {
  width: 150pxv !important;
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
}

body[dir="rtl"] .create-note-btn {
  font-weight: 600 !important;
}

.notescontainer {
  height: 91vh;
  width: 98% !important;
  margin: 0 auto;
}

.collapse-border {
  border-radius: 4px !important;
  box-shadow: 1px solid #5a5a5a !important;
}

.header-of-collapse {
  width: 60% !important;
  height: auto;
  --max-lines: 1;
  display: -webkit-box !important;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.ant-collapse {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent !important;
}

.Notes-text-In-header {
  width: 100% !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background: linear-gradient(to left, #49dbdb 30%, #6172d6 70%) !important;
}

@media screen and (max-width: 1200px) {
  .attachIcon-In-Collapse {
    right: 390px !important;
  }

  .header-of-collapse {
    width: 55% !important;
  }

  .starIcon-In-Collapse {
    right: 535px !important;
  }

  .collapse-text-attached {
    margin-right: 20px !important;
  }

  .editIcon-In-Collapse {
    left: 35px !important;
  }
}

@media screen and (max-width: 800px) {
  .attachIcon-In-Collapse {
    right: 390px !important;
  }

  .starIcon-In-Collapse {
    right: 495px !important;
  }

  .collapse-text-attached {
    margin-left: 190px !important;
  }

  .editIcon-In-Collapse {
    left: 90px !important;
  }
}

.collapse-text-attached {
  color: #5a5a5a !important;
  font-size: 11px;
  width: 130px;
}

/* material Ui CSS */

.MuiAccordion-rounded {
  color: "darkslategray";
  background-color: "#E4FAF4";
  border-radius: "0.6rem";
  box-shadow: "none";
}

.header-of-collapse-material {
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 100% !important;
  color: #2197d7;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

body[dir="rtl"] .header-of-collapse-material {
  font-weight: 600 !important;
}

.collapse-text-attached-material {
  color: #5a5a5a !important;
  font-size: 12px;
}

body[dir="rtl"] .collapse-text-attached-material {
  font-weight: 400 !important;
}

.MinusIcon {
  color: #6172d6 !important;
  cursor: pointer;
}

.PlusIcon {
  color: #6172d6 !important;
  cursor: pointer;
}

.editIcon-In-Collapse-material {
  cursor: pointer !important;
}

.editIconBox {
  position: absolute !important;
  top: 15px !important;
  right: 10px !important;
}

body[dir="rtl"] .editIconBox {
  right: 74.7% !important;
}

.notesViewContainer {
  min-height: 68vh !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  height: 100px !important;
}

.notesViewContainer::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.notesViewContainer::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.notes-create-attachment {
  width: 500px !important;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  padding: 0 !important;
  height: 120px !important;
}

.NotesAttachments {
  width: 100% !important;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  padding: 0 !important;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 85px !important;
}

.NotesAttachments::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.NotesAttachments::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.notes-attachment-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  width: 90px;
  position: relative;
}

.notes-attachment-text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

body[dir="rtl"] .notes-attachment-text {
  font-weight: 400 !important;
}

.emptyNotesState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 65vh !important;
}

.emptystatetext {
  margin-top: 20px !important;
  color: #e1e1e1 !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  width: 350px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
}

.PaginationStyle-Notes {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-Notes:is(:focus-visible) {
  outline: none !important;
}

body[dir="rtl"] .PaginationStyle-Notes {
  width: auto !important;
  padding: 12px 10px !important;
}
.MinusIcon_span {
  width: 20px;
  height: 20px;
}

/* Searching Notes Styles Same as polls as resused polls Styles  */

.Search_Button_polls_SearchModal {
  padding: 12px 30px !important;
  background: #6172d6;
  color: #fff;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: none !important;
}

.Search_Button_polls_SearchModal:hover,
.Search_Button_polls_SearchModal:focus-visible,
.Search_Button_polls_SearchModal:focus,
.Search_Button_polls_SearchModal:active {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: none !important;
}

.Reset_Button_polls_SearchModal {
  padding: 12px 30px !important;
  background: #fff;
  color: #5a5a5a;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
}

.Reset_Button_polls_SearchModal:hover,
.Reset_Button_polls_SearchModal:active,
.Reset_Button_polls_SearchModal:focus,
.Reset_Button_polls_SearchModal:focus-visible {
  padding: 12px 30px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
}

.Cross_Icon_Styling {
  cursor: pointer;
}

.SearhBar_Polls {
  width: 100% !important;
  height: auto !important;
  background: #ffffff !important;
  position: absolute;
  right: 0;
  z-index: 1 !important;
  top: 39px !important;
  border: 1px solid #e1e1e1 !important;
  padding: 20px 10px;
}

.polling_searchinput {
  display: inline-block;
  position: absolute !important;
  right: 0 !important;
  width: auto;
  top: 5px;
  margin-right: 25px;
}

body[dir="rtl"] .polling_searchinput {
  position: absolute !important;
  right: 88% !important;
  cursor: pointer !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
}
