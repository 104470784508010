.guest-video-ended {
  padding: 60px 90px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.main-heading {
  font-size: 30px;
  font-weight: 600;
  color: #6172d6 !important;
  margin-bottom: 10px;
}

.sub-Heading-main {
  font-size: 26px;
  font-weight: 600;
  color: #5a5a5a !important;
  margin-bottom: 0px !important;
}
