.NewMeeting_container {
  width: 98% !important;
  margin: 0 auto !important;
}

.NewMeetinHeading {
  color: #6172d6 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.Newmeeting_Scehedule_meet {
  width: auto;
  white-space: nowrap;
  height: 39px;
  border-radius: 4px;
  font-size: 12px !important;
  color: #fff;
  background: #6172d6 !important;
  border: none !important;
  font-weight: 600;
  padding: 12px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Newmeeting_Scehedule_meet:hover,
.Newmeeting_Scehedule_meet:active,
.Newmeeting_Scehedule_meet:focus,
.Newmeeting_Scehedule_meet:focus-visible {
  width: auto !important;
  white-space: nowrap !important;
  height: 39px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  font-weight: 600 !important;
  padding: 12px 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.Search_Bar_icon_class {
  cursor: pointer;
}

.polling_searchinput {
  display: inline-block;
  position: absolute !important;
  right: 0 !important;
  width: auto;
  top: 5px;
  margin-right: 7px;
}

.Search-Box_meeting {
  width: 502px !important;
  height: 242px !important;
  background: #fff;
  border: 1px solid #e1e1e1;
  position: absolute !important;
  right: 0;
  z-index: 1 !important;
  top: 39px !important;
}

.Cross_Icon_Styling {
  cursor: pointer;
}

body[dir="rtl"] .polling_searchinput {
  position: absolute !important;
  right: 88% !important;
  cursor: pointer !important;
}

.ResetButtonMeeting {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1;
  color: #5a5a5a;
}

.ResetButtonMeeting:hover,
.ResetButtonMeeting:hover,
.ResetButtonMeeting:hover,
.ResetButtonMeeting:hover {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.SearchButtonMeetings {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.SearchButtonMeetings:hover,
.SearchButtonMeetings:active,
.SearchButtonMeetings:focus-visible,
.SearchButtonMeetings:focus {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  font-weight: 600 !important;
  border: none !important;
  color: #fff !important;
}

.joining-Meeting {
  height: 28px;
  font-size: 12px;
  color: #fff;
  background: #6172d6;
  border: none;
  border-radius: 4px !important;
}

.joining-Meeting:active,
.joining-Meeting:hover,
.joining-Meeting:focus,
.joining-Meeting:focus-visible {
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

.Start-Meeting {
  height: 28px;
  font-size: 12px;
  color: #fff;
  background: #81db86;
  border: none;
  border-radius: 4px !important;
  white-space: nowrap;
  font-weight: 500;
}

.Start-Meeting:is(:hover, :focus, :focus-visible:active) {
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #81db86 !important;
  border: none !important;
  border-radius: 4px !important;
}

.End-Meeting {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #f16b6b !important;
  border: none !important;
  border-radius: 4px !important;
}

.End-Meeting:is(:hover, :focus, :focus-visible:active) {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #f16b6b !important;
  border: none !important;
  border-radius: 4px !important;
}

.Title_desc {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px !important;
}

.PaginationStyle-Committee {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .PaginationStyle-Committee {
  width: 100% !important;
  padding: 12px 10px !important;
}

body[dir="rtl"] .PaginationStyle-Committee {
  width: auto !important;
  padding: 8px 10px !important;
}

.Empty_State_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center !important;
  flex-direction: column;
}

.PaperStylesMeetingTwoPage {
  width: 100% !important;
  height: auto !important;
  position: relative;
  padding: 20px 20px !important;
  border-radius: 8px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.UnpublishedMeetingButton {
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.publishedMeetingButton {
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.publishedMeetingButton-active {
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

.UnpublishedMeetingButton-active {
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}

.UnpublishedMeetingButton:is(:hover, :active, :focus, :focus-visible) {
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.meetingTitle {
  font-weight: 600 !important;
  color: #6172d6;
  font-size: 14px !important;
  cursor: pointer;
  margin: 0 5px;
}

.width-10 {
  width: 10px !important;
}

.DefineTableFixedheight {
  height: 60vh !important;
}

.meeting-expanded-row {
  margin-left: 35px !important;
}
.orgaizer_value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.Board-Deck {
  height: 28px;
  font-size: 12px;
  color: #fff;
  background: #6172d6;
  border: none;
  border-radius: 4px !important;
}

.Board-Deck:active,
.Board-Deck:hover,
.Board-Deck:focus,
.Board-Deck:focus-visible {
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.isemptySpace {
  width: 15px;
  height: 15px;
}

.ResetOkBtn {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

body[dir="rtl"] .ResetOkBtn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
