.DataRoom_container {
  max-width: 98%;
  margin: 0 auto;
  max-height: calc(100vh - 60px) !important;
}

.Data_room_heading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6;
  white-space: nowrap;
}

.Data_room_new_btn {
  width: 137px !important;
  height: 39px !important;
  background: #5a5a5a;
  font-size: 12px !important;
  border: none;
}

.Search_Icon {
  position: absolute !important;
  right: 213px !important;
  cursor: pointer !important;
  top: 26px !important;
  z-index: 1 !important;
}

.Data_room_paper {
  height: 100% !important;
  width: 100% !important;
  padding: 30px 30px !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.allDocuments_btn {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff;
  border: none !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a;
}

.allDocuments_btn_active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
}

body[dir="rtl"] .allDocuments_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.allDocuments_btn:hover,
.allDocuments_btn:focus,
.allDocuments_btn:active,
.allDocuments_btn:focus-visible {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: none !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.myDocument_btn_active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
}

body[dir="rtl"] .myDocument_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.myDocument_btn {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff;
  border: none !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a;
}

.myDocument_btn:hover,
.myDocument_btn:focus,
.myDocument_btn:active,
.myDocument_btn:focus-visible {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: none !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.Shared_with_me_btn_active,
.Shared_with_me_btn_active:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 12px !important;
  font-size: 12px !important;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
}

body[dir="rtl"] .Shared_with_me_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Shared_with_me_btn {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: 600;
  background: #fff;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a;
}

.Shared_with_me_btn:is(:active, :focus, :focus-visible, :hover) {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}
.Send_for_approval_btn {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: 600;
  background: #fff;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a;
}

.Send_for_approval_btn:is(:active, :focus, :focus-visible, :hover) {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  border: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}

.Send_for_approval_btn_active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
}

body[dir="rtl"] .Send_for_approval_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.profile_picture {
  border-radius: 30px !important;
}

.Name_particiapnt {
  font-size: 12px !important;
  font-weight: 600;
  color: #a2a2a2;
}

.Lastmodified_date {
  font-size: 12px !important;
  font-weight: 600;
  color: #a2a2a2;
}

.File_size_Style {
  font-size: 12px !important;
  font-weight: 600;
  color: #a2a2a2;
}

.name_of_life {
  font-size: 12px !important;
  font-weight: 600;
  color: #a2a2a2;
}

.DropDown {
  width: 137px !important;
  height: 107px !important;
  background: #fff;
  border-radius: 4px !important;
  z-index: 1;
  position: absolute;
  top: 56px !important;
  border: 1px solid #e1e1e1;
  left: 270px !important;
}

.New_folder {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Folder_Upload {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
  margin-top: 2px !important;
  margin-left: 4px !important;
}

.Messege_nofiles {
  font-size: 30px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Tag_line_nofiles {
  font-size: 20px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Messege_nofiles_shared {
  font-size: 20px !important;
  font-weight: 600;
  color: #bbbbbb;
}

.Folder_Icon {
  z-index: 1;
}

.Search_icon {
  position: absolute !important;
  z-index: 1;
  bottom: 100px !important;
  right: 630px;
}

.paper_Icon {
  position: absolute !important;
  top: 150px !important;
  right: 640px;
}

.spark_icon {
  position: absolute;
  left: 660px;
}

.Drop_Down_searchBar {
  width: 100% !important;
  height: auto;
  padding: 10px 10px !important;
  background: #fff;
  border: 1px solid #e1e1e1;
  z-index: 2;
  position: absolute;
  top: 39px !important;
  overflow-x: auto;
  right: 0 !important;
  overflow-y: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.Drop_Down_searchBar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Drop_Down_searchBar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.DropDown_name {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Show_more_options {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Search_option_text_span {
  font-size: 12px;
  font-weight: 600;
  color: #6172d6;
  cursor: pointer;
}

.Search_result_Heading {
  font-size: 16px !important;
  font-weight: 600;
  color: #6172d6;
}

.Clear_All_btn {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
  cursor: pointer;
  position: relative;
}

.Drop_Down_searchBar_Options {
  width: 611px !important;
  height: auto;
  padding: 10px 10px !important;
  background: #fff;
  border: 1px solid #e1e1e1;
  z-index: 3;
  position: absolute;
  top: 39px;
  right: 0;
}

body[dir="rtl"] .Drop_Down_searchBar_Options {
  right: 0 !important;
}

.CrossButton {
  background: none;
  border: none;
  cursor: pointer !important;
}

body[dir="rtl"] .CrossButton {
  position: absolute;
  left: 0;
}

.CrossButton:hover,
.CrossButton:active,
.CrossButton:focus,
.CrossButton:focus-visible {
  background: none !important;
  border: none !important;
}

.cancell_Search_button_Dataroom {
  background: #fff;
  border: 1px solid #e1e1e1;
  padding: 12px 30px !important;
  font-size: 12px;
  font-weight: 600;
  color: #5a5a5a;
}

.cancell_Search_button_Dataroom:hover,
.cancell_Search_button_Dataroom:active,
.cancell_Search_button_Dataroom:focus,
.cancell_Search_button_Dataroom:focus-visible {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a !important;
}

.Search_Search_button_Dataroom {
  background: #6172d6;
  border: none;
  padding: 12px 30px !important;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.Search_Search_button_Dataroom:hover,
.Search_Search_button_Dataroom:active,
.Search_Search_button_Dataroom:focus,
.Search_Search_button_Dataroom:focus-visible {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #fff !important;
  cursor: pointer;
}

body[dir="rtl"] .Search_Icon {
  position: absolute !important;
  cursor: pointer !important;
  right: 1370px !important;
}

.grid_view_btn {
  height: 100% !important;
  width: 39px !important;
  background: none !important;
  outline: none !important;
  border: none !important;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid_view_btn:is(:hover, :visited, :active, :focus, :focus-visible, :active) {
  height: 100% !important;
  width: 39px !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid_view_btn_active {
  height: 100% !important;
  width: 39px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

body[dir="rtl"] .grid_view_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.List_view_btn {
  height: 100% !important;
  width: 39px !important;
  background: none !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px !important;
}

.List_view_btn:is(:hover, :visited, :active, :focus, :focus-visible, :active) {
  height: 100% !important;
  width: 39px !important;
  background: none !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.List_view_btn_active {
  height: 100% !important;
  width: 39px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

body[dir="rtl"] .List_view_btn_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.new_folder {
  font-size: 10px !important;
  margin-left: 3px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.Folder_style {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.HoverStyle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
}

.Deletehover {
  width: 25px !important;
  height: 25px !important;
  background: #f1f1f1 !important;
  border-radius: 20px !important;
  padding: 5px 5px !important;
  box-shadow: 0px 0px 0px 5px solid #f1f1f1;
}

.Back_ground_For_uploader {
  width: 425px !important;
  max-height: 320px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 1 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: 90px !important;
  border: 1px solid #e1e1e1 !important;
  height: 130px;
}
.Back_ground_For_uploader_folder {
  width: 425px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 999999 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: 90px !important;
  border: 1px solid #e1e1e1 !important;
  height: auto !important;
}
body[dir="rtl"] .Back_ground_For_uploader_folder {
  width: 425px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 1 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: auto !important;
  border: 1px solid #e1e1e1 !important;
  height: auto !important;
  left: 90px;
}

body[dir="rtl"] .Back_ground_For_uploader_active {
  width: 425px !important;
  height: 44px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 1 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: auto !important;
  border: 1px solid #e1e1e1 !important;
  left: 90px;
}

.Back_ground_For_uploader_active {
  width: 425px !important;
  height: 44px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 1 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: 90px !important;
  border: 1px solid #e1e1e1 !important;
}
.Back_ground_For_uploader_active_folder {
  width: 425px !important;
  height: 44px !important;
  background: #fff;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border: #e1e1e1;
  position: fixed;
  z-index: 1 !important;
  bottom: 0px !important;
  border-radius: 4px !important;
  right: 90px !important;
  overflow-y: auto;
  border: 1px solid #e1e1e1 !important;
}
.Blue_Strip {
  width: 425px !important;
  height: 43px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background: #6172d6;
  z-index: 1 !important;
}

.name_of_life_in_Bar {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}
.Progress_bar {
  color: #fff !important;
}
.progress_bar {
  font-size: 0px !important;
  display: flex;
  justify-content: flex-end;
}
.Scroller_bar_of_BarUploder_folder {
  max-height: 16.8vh !important;
  overflow-x: hidden !important;
  background-color: #fff !important;
  padding: 10px 0px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.3s;
  overflow-y: auto;
  display: block;
}

.Scroller_bar_of_BarUploder {
  height: 13vh !important;
  overflow-x: hidden !important;
  padding-bottom: 10px;
}

.Scroller_bar_of_BarUploder::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_bar_of_BarUploder::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Uploading {
  font-size: 12px !important;
  color: #fff;
  font-weight: 600;
}

.Drop_Down_container {
  width: 145px !important;
  height: auto;
  padding: 7px 15px !important;
  background: #ffffff !important;
  border: 1px solid #e1e1e1;
  z-index: 1;
  border-radius: 4px !important;
  position: absolute;
  right: 9%;
  top: 57%;
  line-height: 7px !important;
}

.hr-Line_tag {
  width: 105px !important;
  border: 1px solid #e1e1e1;
  background: #fff !important;
  margin-left: 20px !important;
  margin-top: 7px !important;
}

.Options_name_threedotdropdown {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-left: 7px !important;
}

.backgeound_Rename_notification {
  width: 480px !important;
  height: 45px !important;
  background: #5a5a5a;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 5%;
}

.Option_Document_button {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a !important;
}

.Custome_range_options {
  width: 191px !important;
  height: 237px;
  border: 1px solid #e1e1e1;
  background: #fff;
  position: absolute;
  z-index: 1;
}

.DropDownSelct {
  width: 100px;
  height: 39px !important;
}

.Clear_button {
  width: 62px !important;
  height: 20px !important;
  font-size: 8px !important;
  border: 1px solid #e1e1e1;
  font-weight: 600;
  border-radius: 4px !important;
  background: #fff;
  color: #5a5a5a;
}

.Clear_button:hover,
.Clear_button:active,
.Clear_button:focus,
.Clear_button:focus-visible {
  width: 62px !important;
  height: 20px !important;
  font-size: 8px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.DragDropIconDataRoom {
  background: none !important;
  border: none !important;
}

.dataFolderRow {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
  height: 22px !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dataroom_location {
  white-space: nowrap !important;
}

.Data_room_btn {
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 100%;
}

.font_size {
  font-size: 12px;
  font-weight: 600;
}

body[dir="rtl"] .font_size {
  margin-left: 20px !important;
}

.dataroom_searchinput {
  display: inline-block;
  position: absolute !important;
  right: 0 !important;
  width: auto;
  top: 7px;
  margin-right: 10px;
}

body[dir="rtl"] .dataroom_searchinput {
  display: inline-block;
  position: absolute !important;
  right: 90% !important;
  width: auto;
  left: 0;
  top: 7px;
  margin-right: 10px;
}
.dataroom_searchinput2 {
  display: inline-block;
  position: absolute !important;
  right: 0 !important;
  width: auto;
  top: 7px;
  margin-right: 10px;
}
body[dir="rtl"] .dataroom_searchinput2 {
  display: inline-block;
  position: absolute !important;
  right: 93% !important;
  width: auto;
  left: 0;
  top: 7px;
  margin-right: 10px;
}
.delete__Icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete__Icon:hover {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete__Icon_img_hover {
  display: none;
}
.delete__Icon_img {
  cursor: pointer;
}
.delete__Icon:hover .delete__Icon_img {
  display: none;
}
.delete__Icon:hover .delete__Icon_img_hover {
  display: block;
}
.share__Icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share__Icon:hover {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share__Icon_img {
  cursor: pointer;
}

.share__Icon_img:hover path {
  stroke: #6172d6 !important;
}

.download__Icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download__Icon path {
  filter: #f00009;
}

.download__Icon:hover {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download__Icon_img {
  cursor: pointer;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start__Icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start__Icon:hover {
  width: 25px;
  height: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start__Icon_img {
  cursor: pointer;
}

.threeDot__Icon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  position: relative !important;
}

.lsit_grid_buttons {
  border: 1px solid #e1e1e1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px !important;
  border-radius: 4px !important;
  overflow: hidden;
}

.dataroom_table_heading {
  font-weight: 600;
  color: #5a5a5a !important;
  font-size: 12px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 300px !important;
  user-select: none !important;
  cursor: pointer !important;
  display: block !important;
}
.fileRow {
  display: flex;
  align-items: center;
  width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 300px !important;
  gap: 5px;
}

.Dataroom__mydocument_location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-weight: 600;
  color: #5a5a5a !important;
  font-size: 12px !important;
}

.NavLink__filter_Folder {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
  display: flex;
}

.NavLink__filter_Folder:is(:hover) {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter_Folder:last-child {
  border: none !important;
}

.NavLink__filter__file {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
  display: flex;
}

.NavLink__filter__file:is(:hover) {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter__file:last-child {
  border: none !important;
}

.FilterDropDown_TableView {
  padding: 5px 10px;
  width: 120px !important;
  height: auto !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  z-index: 99999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 22px;
  left: 0;
}

.Option_Document_button_selected {
  font-size: 12px !important;
  font-weight: 600;
  color: #fff !important;
}

.dropdown__Document_Value {
  width: 100%;
  height: 39px;
  padding: 0 7px !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  gap: 5px;
}

.overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.placeholder-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.options_dropdown button {
  border: none !important;
  padding: 5px 0px !important;
}

.options_dropdown button:is(:hover, :active, :focus, :focus-visible, :visited) {
  border: none !important;
}

.options_dropdown_toggle {
  padding: 5px 0px !important;
}

.options_menu {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}

.ownerName {
  font-weight: 600;
  color: #5a5a5a !important;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
.crossIcon {
  display: none !important;
  cursor: pointer !important;
}

.folderProgress {
  width: 20% !important;
}

.showUploadBar:hover .crossIcon {
  display: block !important;
}
.showUploadBar:hover .folderProgress {
  display: none !important;
}

.folderProgress .ant-progress-inner {
  font-size: 0px !important;
}
.folderProgress .ant-progress-inner .ant-progress-text {
  display: none !important;
}
.empty-search-state {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  height: "100vh";
}

.datepicker-in-search {
  border: 1px solid #e1e1e1 !important;
  width: 50% !important;
  margin-top: 2px !important;
  background: white !important;
}

.datepicker_input {
  height: 39px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  outline: none !important;
  padding: 0 10px !important;
}
.datePickerTodoCreate2 .rmdp-container {
  width: 100% !important;
}
.showUploadBar {
  height: 45px !important;
}
.showUploadBarUploaded {
  height: 35px !important;
}
.GreentickIcon {
  display: block !important;
}
.GreentickIcon_forfile {
  width: 20px;
  height: 20px;
  display: block;
}
.crossIcon-file {
  display: none !important;
  cursor: pointer !important;
}
.showUploadBarUploaded-file {
  height: 35px !important;
}
.showUploadBarUploaded-file:hover .crossIcon-file {
  display: block !important;
}
.datePicker_Container {
  width: 100% !important;
}
.DataRoom_DropDown .dropdown-tggle::before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
  background-color: #fff !important;
}
.DataRoom_DropDown .dropdown-tggle:hover {
  border: none !important;
}

.DataRoom_DropDown button {
  width: 137px !important;
  height: 39px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

body[dir="rtl"] .DataRoom_DropDown button {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}
body[dir="rtl"] .dropdown_menu_dataroom {
  top: 0 !important;
}

.DataRoom_DropDown button:hover {
  border: none !important;
}

.dropdown_menu_dataroom {
  min-width: auto;
}
.dropdown_menu_dataroom .dropdown-menu a.dropdown-item.dropdown-item {
  line-height: 36px;
}
.DataRoom_DropDown .dropdown-menu.show {
  padding: 0 !important;
  min-width: 100% !important;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translate(0px, 45px);
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}
.DataRoom_DropDown .dropdown-menu {
  padding: 0 !important;
  min-width: 100% !important;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translate(0px, 45px);
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .DataRoom_DropDown .dropdown-menu.show {
  padding: 0 !important;
  width: 137px;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translateY(0px, 20px) !important;
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item {
  padding: 10px 10px;
  font-weight: 600;
  text-align: center;
  font-size: 12px !important;
  border-bottom: 2px solid #a2a2a2 !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item_folder {
  border-bottom: none !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item:hover {
  background: none !important;
}

.DataRoom_DropDown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dataroom_dropdown_item {
  line-height: 30px;
}
.Recently_Added {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 30px;
}
.Recently_Added_tagLine {
  color: #5a5a5a;
  font-weight: 500;
  font-size: 12px;
}

.FilterResetBtn {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :focus, :active, :focus-visible) {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn:is(:hover, :focus, :active, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
.datemodifiedfilter {
  max-width: 80% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}


.breadCrumbsThreeDotsDiv_Row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 10px;
  position: relative;
  padding-bottom: 10px;
  color: #5a5a5a;
  font-weight: 600;
  cursor: pointer;
}
.breadCrumbsThreeDotsDiv_Row::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 0;
  background-color: #E1E1E1;
  height: 1px;
  width: 100%;
}
