.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
  color: #0000ff;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  display: none;
  background: #fff;
  border-color: #1890ff;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
  display: none;
}

.ant-picker-calendar-header {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  line-height: 20px;
  padding: 50px;
}

.calendar {
  height: auto !important;
  min-height: 68.5vh;
  margin: 21px;
  border-radius: 4px;
}

.calendar-header {
  margin: 2px;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  background: #0000ff;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: #ffffff;
}

.ant-picker-calendar-full .ant-picker-panel {
  text-align: center;
}

.calendar .rbc-month-view .rbc-month-row .rbc-row .rbc-date-cell {
  text-align: center !important;
  margin-top: 10px;
  font-weight: 600;
}

.calendar .rbc-month-view .rbc-month-row .rbc-row .rbc-current {
  color: #fff !important;
}

.calendar .rbc-month-view {
  border: none !important;
}

/* change color blue to this */
.calendar .rbc-toolbar .rbc-toolbar-label {
  color: #2097d6;
  font-size: 1.5rem;
}

/* change color from blue to this */
.calendar .rbc-month-view .rbc-month-header .rbc-header {
  padding: 20px 0 !important;
  border: none !important;
  color: #6172d6;
  text-transform: uppercase;
  font-weight: normal;
}

.calendar .rbc-month-view .rbc-month-header .rbc-header {
  padding: 20px 0 !important;
  border: none !important;
}

.calendar .rbc-month-view .rbc-month-row {
  border: 1px solid #ccc !important;
  display: inline-table !important;
}

.calendar .rbc-month-view .rbc-month-row .rbc-row-bg {
  background-color: #fff;
  color: #fff !important;
}

.calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-day-bg:hover {
  background: aliceblue !important;
  color: #000 !important;
}

body[dir="rtl"]
  .calendar
  .rbc-month-view
  .rbc-month-row
  .rbc-row-bg
  .rbc-day-bg:hover {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.calendar
  .rbc-month-view
  .rbc-month-row
  .rbc-row.content
  .rbc-row
  .rbc-date-cell
  button:hover {
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
  color: #fff !important;
}

body[dir="rtl"]
  .calendar
  .rbc-month-view
  .rbc-month-row
  .rbc-row.content
  .rbc-row
  .rbc-date-cell
  button:hover {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.rbc-today {
  background-color: #036ab2 !important;
}

.calendar
  .rbc-month-view
  .rbc-month-row
  .rbc-row-content
  .rbc-row
  .rbc-row-segment
  .rbc-event {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 100px !important;
  font-weight: 700;
  padding: 0 5px !important;
  text-align: center !important;
  max-width: 125px !important;
}

body[dir="rtl"] .rbc-row-segment {
  font-weight: 400;
}

.add-event {
  color: #fff !important;
  border-radius: 0.375rem !important;
}

.add-event .btn::after {
  content: "" !important;
  display: none !important;
}

.button-text {
  font-size: 0.8rem;
}

.add-event .dropdown-item {
  width: 190px;
  padding: 10px 5px;
}

.add-event .dropdown-item {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 !important;
  font-weight: 600;
}

.add-event .dropdown-menu {
  --bs-dropdown-padding-x: 0 !important;
  --bs-dropdown-padding-y: 0 !important;
}

.rbc-header span {
  font-size: 16px;
  color: #6172d6;
  font-weight: 600;
}

body[dir="rtl"] .rbc-header span {
  font-weight: 600;
}

.bg-primary.text-white.add-event.calendar-dropdown.dropdown
  button.dropdown-toggle.btn.btn-primary {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
}

.bg-primary.text-white.add-event.calendar-dropdown.dropdown:hover
  button.dropdown-toggle.btn.btn-primary {
  background-color: #0a58ca !important;
  border-color: #0a58ca !important;
}

.text-white.add-event {
  position: relative;
}

.text-white.add-event.calendar-dropdown button {
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
  border: none !important;
  font-size: 14px;
  color: #fff !important;
  width: 192px;
  padding: 10px 15px !important;
  margin-top: 7px;
  position: relative !important;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  margin-left: 20px;
}

body[dir="rtl"] .text-white.add-event.calendar-dropdown button {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.calendar-dropdown {
  position: relative;
}

.calendar-dropdown::after {
  content: "";
  position: absolute !important;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 170px;
  top: 12px;
}

.dropdownchevron {
  position: absolute;
  top: -20px;
}

.rbc-today {
  background: aliceblue !important;
  color: #000 !important;
}

body[dir="rtl"] .rbc-today {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.calendar-heading {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

button.btn.calendar-today.btn.btn-primary {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  font-size: 14px !important;
  padding: 6px 30px !important;
  color: #fff !important;
  font-weight: 600;

  border: none !important;
}

body[dir="rtl"] button.btn.calendar-today.btn.btn-primary {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.rbc-row-segment {
  padding: 0px 10px !important;
  font-size: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: 1200px) {
  .calendar-heading {
    margin-left: 20px !important;
    font-size: 26px !important;
    color: #6172d6 !important;
  }

  .text-white.add-event.calendar-dropdown button {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    font-size: 14px;
    margin-left: 20px;
    color: #fff !important;
    width: 170px;
    padding: 7px 0px !important;
    margin-top: 7px;
  }

  body[dir="rtl"] .text-white.add-event.calendar-dropdown button {
    background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  }

  .add-event .dropdown-item {
    width: 170px;
    padding: 10px 5px;
  }

  .Calendar_calendar-add-event-label__kw10R {
    margin-bottom: 0.2em !important;
    cursor: pointer;
    color: #6172d6;
    font-size: 24px !important;
    text-transform: uppercase;
  }
}

.Calendar_CreateBtn .dropdown-tggle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
  background-color: #fff !important;
}

.Calendar_CreateBtn .dropdown-toggle::after {
  display: none !important;
}

.Calendar_CreateBtn button {
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex;
  justify-content: center;
  width: 100% !important;
}

body[dir="rtl"] .Calendar_CreateBtn button {
  font-weight: 600;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Calendar_CreateBtn .dropdown-menu.show {
  padding: 0 !important;
  width: 140px;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translate(0px, 45px);
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .Calendar_CreateBtn .dropdown-menu.show {
  padding: 0 !important;
  width: 100% !important;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translateY(0px, 45px) !important;
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  top: 0 !important;
}

.Calendar_CreateBtn .dropdown-menu.show .dropdown-item {
  padding: 10px 10px;
  font-weight: 600;

  text-align: center;
  font-size: 11px !important;
}

body[dir="rtl"] .Calendar_CreateBtn .dropdown-menu.show .dropdown-item {
  font-weight: 600;
}

.Calendar_CreateBtn .dropdown-menu.show .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.Calendar_CreateBtn .dropdown-menu.show .dropdown-item:hover {
  background: none !important;
}

.Calendar_CreateBtn .dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.Calender-heading {
  font-size: 40px !important;
  font-weight: 600;

  color: #5f78d6 !important;
}

body[dir="rtl"] .Calender-heading {
  font-weight: 600;
}

.rbc-row {
  margin: 2px !important;
}

.rbc-event-content {
  margin-top: 3px !important;
}

.rbc-event.rbc-event-allday.rbc-event-continues-after {
  border-radius: 4px !important;
}

.rbc-overlay-header {
  font-size: 15px !important;
  font-weight: 600;

  color: #5f78d6 !important;
}

.Create_title {
  margin-right: 90px !important;
}

.heading_button {
  margin-left: 20px !important;
  font-size: calc(14px - 20%);
}

body[dir="rtl"] .heading_button {
  margin-right: 17px !important;
}

.Calendar_CreateBtn {
  margin-top: 10px !important;
}

.calendar_container {
  width: 98% !important;
  margin: 0 auto !important;
  height: 91vh;
}

/* Change text color of .rbc-show-more when .rbc-day-bg is hovered */
.rbc-show-more {
  color: #5a5a5a !important; /* Use !important to ensure the style is applied */
  font-weight: 600;
}
.rbc-button-link {
  color: #5a5a5a !important; /* Use !important to ensure the style is applied */
}
.rbc-overlay .rbc-event .rbc-event-content {
  color: #fff !important;
}
