.chatBox-video {
  position: fixed;
  bottom: 0;
  right: 85px;
  width: 325px;
  height: 87.3vh;
  background: #fff;
  border: 3px solid #4adede;
  overflow: hidden;
  border-radius: 4px;
  top: 80px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in !important;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
}

body[dir="rtl"] .chatBox-video {
  position: fixed;
  bottom: 0;
  right: auto;
  width: 325px;
  height: 88.5vh;
  background: #fff;
  border: 1px solid #4cd7de;
  overflow: hidden auto;
  border-radius: 4px;
  top: 73px;
  left: 85px;
}

svg.pointer-video-icon {
  position: fixed;
  bottom: 135px;
  right: 74px;
  transform: rotate(90deg);
  background: #fff;
  color: #6172d6;
  border-left: none !important;
}

span.triangle-overlay-video {
  position: fixed;
  height: 13px;
  width: 2px;
  bottom: 134px;
  right: 85px;
  background: #fff;
  z-index: 1;
}

body[dir="rtl"] span.triangle-overlay-video {
  position: fixed !important;
  height: 13px !important;
  width: 2px !important;
  bottom: 134px !important;
  background: #fff !important;
  z-index: 1 !important;
  left: 84px !important;
  right: auto !important;
}

body[dir="rtl"] svg.pointer-video-icon {
  position: fixed !important;
  bottom: 135px !important;
  left: 74px !important;
  transform: rotate(270deg) !important;
  background: #fff !important;
  color: #6172d6 !important;
  border-left: none !important;
  right: auto !important;
}

body[dir="rtl"] .group-btn-gray {
  position: absolute;
  height: 40px !important;
  width: 150px;
  bottom: 3%;
  right: auto;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  filter: grayscale(1);
  padding-left: 0 !important;
  padding-right: 0 !important;
  left: 3%;
}

body[dir="rtl"] .group-btn-gray span img {
  transform: rotate(180deg);
}

body[dir="rtl"] .group-btn {
  position: absolute;
  height: 40px !important;
  width: 150px;
  bottom: 3%;
  right: auto;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  left: 3%;
}

body[dir="rtl"] .group-btn span img {
  transform: rotate(180deg);
}

.videocall-header-panel {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 10px 5px;
  width: 100%;
}

.videocall-normal-panel {
  height: 74.3vh;
  overflow: hidden auto !important;
}

.videocall-footer-panel {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: rgba(33, 35, 38, 0.1) 0px -10px 10px -10px;
  display: flex;
  align-items: center;
}

.videocall-normal-recent .infinite-scroll-component {
  overflow: hidden auto !important;
}