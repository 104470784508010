.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.rbc-btn-group {
  display: none !important;
}

.icon {
  font-size: 1.3rem !important;
  cursor: pointer;
  color: #707070 !important;
}

/* change color blue to this */
.month-title {
  font-size: 1.4rem;
  color: #2097d6;
  font-weight: 600;
}

.date-picker-style .date-picker-calendar .ant-picker-input {
  display: none !important;
}

.calendar-add-event-label {
  margin-bottom: 0.2em;
  cursor: pointer;
  color: #6172d6;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}

body[dir="rtl"] .calendar-add-event-label {
  font-weight: 600;
}

.rbc-row-segment {
  padding: 0px 10px !important;
  font-size: 10px !important;
}

.custom-footer {
  text-align: center;
}

.cirle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.cirleheading {
  font-weight: 600;
}
.calendarsowMore {
  font-size: 8px !important;
}
