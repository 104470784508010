.Share_folder_modal_Heading {
  font-size: 20px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Scroller_particiapnt_shared_folder {
  height: 28vh !important;
  overflow-x: hidden !important;
}

.Scroller_particiapnt_shared_folder::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_particiapnt_shared_folder::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Notify_people_styles {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-top: 3px !important;
}

.Copy_Link_btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}

.Copy_Link_btn:is(:active, :hover, :focus, :focus-visible, :target, :visited) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}
.send_btn {
  padding: 12px 30px !important;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background: #6172d6;
  border: none;
}

.send_btn:is(:active, :hover, :focus, :focus-visible, :target, :visited) {
  padding: 12px 30px !important;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background: #6172d6;
  border: none;
}

.Request_Access_btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff;
  background: #6172d6;
  border: none;
  border-radius: 4px !important;
}

.Access_request_modal_heading {
  font-size: 20px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Sub_line_access_request_modal {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Request_send_heading {
  font-size: 20px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.description_request_send {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Editor_btn {
  padding: 7px 30px !important;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  border: 1px solid #e1e1e1;
  border-radius: 3px !important;
}

.general_Access_btn {
  padding: 7px 30px !important;
  font-size: 12px !important;
  background: #5a5a5a;
  color: #fff;
  border: none;
  border-radius: 3px !important;
}

.select-timezone {
  width: 100px !important;
  height: 10px !important;
}

.Background_notification {
  width: auto;
  padding: 6px 20px;
  background: #5a5a5a;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px !important;
  position: absolute;
  right: 50%;
  bottom: 0px;
}

.Back_ground_editNotification {
  width: 254px !important;
  height: 31px !important;
  background: #5a5a5a;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px !important;
  position: absolute !important;
  right: 160px !important;
  bottom: 0px !important;
}

.Back_ground_accessupdate {
  width: 116px !important;
  height: 31px !important;
  background: #5a5a5a;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px !important;
  position: absolute !important;
  right: 230px !important;
  bottom: 0px !important;
}

.Access_updated {
  font-size: 10px !important;
  color: #f6f6f6;
  font-weight: 600;
}

.Link_copied {
  font-size: 12px !important;
  color: #f6f6f6;
  font-weight: 600;
}

.Expiration_header_background {
  background: rgba(97, 114, 214, 0.14);
  padding: 8px 8px !important;
  margin: 0 !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  color: #6172d6 !important;
}

.ModalRequestHeader {
  margin: 0 !important;
  padding: 5px 12px !important;
  width: 100%;
}

.ModalHeader {
  width: 100% !important;
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  height: auto;
  margin-top: -5px !important;
  border-radius: 20px !important;
}

.Text_for_header_expiration {
  color: #6172d6 !important;
  font-size: 12px !important;
  font-weight: 600;
}

.margin {
  margin-top: 2.5px !important;
}

.Shared_Document_Heading {
  font-size: 20px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.InvitetoEdit_Heading {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Edit_options {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-left: 3px !important;
}

.Line-height {
  line-height: 15px !important;
}

.Box_for_attachments {
  width: 492px !important;
  height: 39px !important;
  border: 1px solid #d4d4d4;
  background: #fff;
  border-radius: 4px !important;
  margin-left: 12px !important;
}

.Open_button {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  border: none;
  font-size: 12px !important;
  border-radius: 4px !important;
}

.File_name {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Edit_notification {
  font-size: 10px !important;
  color: #f6f6f6;
  font-weight: 600;
}

.shareFolderAddMemberBtn {
  padding: 4px 30px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #f6f6f6 !important;
  width: 100%;
  height: 100%;
  border: none !important;
  outline: none !important;
}

.shareFolderAddMemberBtn:is(:hover, :active, :focus, :focus-visible, :visited) {
  padding: 4px 30px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #f6f6f6 !important;
  width: 100%;
  height: 100%;
  border: none !important;
  outline: none !important;
}

.Editor_select {
  height: 31px !important;
}

.dropdown__Document_Value {
  border: 1px solid #e1e1e1;
  height: 38px;
  border-radius: 4px;
  display: flex;
  color: #fff;
  margin: auto 0;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
}
.generalAccess-value {
  font-size: 0.5rem;
}
.cross_icon_modal {
  position: absolute;
  top: 0;
  right: 0;
}
