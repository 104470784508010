.form-control2 {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px !important;
  height: 30px !important;
}

.form-control2::placeholder {
  color: #e1e1e1 !important;
}

.updateNotes_titleInput {
  border-radius: 4px !important;
  font-weight: 500 !important;
  padding: 12px 16px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
  height: 39px !important;
}

body[dir="rtl"] .updateNotes_titleInput {
  font-weight: 500 !important;
}

.form-control2:focus {
  box-shadow: none !important;
}

.relativePosition {
  position: relative !important;
}

.PasswordIcon {
  display: block !important;
  width: 1% !important;
  /* margin-top: 0.25rem; */
  font-size: 20px !important;
  color: #198754 !important;
  position: absolute !important;
  right: 20px !important;
  top: 23px !important;
}

.form-control3 {
  border-radius: 4px !important;
  border-color: #ccc !important;
  font-size: 13px !important;
}

.form-control3:focus {
  box-shadow: none !important;
}

.todoviewmodalcomments {
  border: 1px solid #e1e1e1 !important;
  overflow-y: auto !important;
  height: 40px;
}

.todoviewmodalcomments:focus,
.todoviewmodalcomments:focus-visible {
  box-shadow: none !important;
  border: 1px solid #e1e1e1 !important;
}

.createtodo-description {
  border: 1px solid #e1e1e1 !important;
  height: 180px !important;
  resize: none;
  border-radius: 4px;
  overflow-y: auto;
}

.Polls_meeting {
  height: 110.96px !important;
  resize: none !important;
}

.createtodo-description::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.createtodo-description::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.createtodo-description:focus,
.createtodo-description:focus-visible {
  box-shadow: none !important;
}

.createtodo-title {
  border: 1px solid #e1e1e1 !important;
}

.assigneeFindInCreateToDo {
  border: 1px solid #e1e1e1 !important;
}

.taskassignee {
  border: 1px solid #e1e1e1 !important;
}

.MeetingViewText {
  border: none !important;
}

.modalAddNoteTitleInput {
  margin: 0;
  height: 39px !important;
  padding: 12px 16px !important;
  border: 1px solid #e1e1e1 !important;
}

.modalAddNoteTitleInput::placeholder {
  color: #bbbbbb !important;
  font-weight: 600;
  font-size: 12px !important;
}

.modalAddNoteTitleInput:focus,
.modalAddNoteTitleInput:focus-visible,
.modalAddNoteTitleInput:hover,
.modalAddNoteTitleInput:active {
  border: 1px solid #e1e1e1 !important;
}

.text-area-close-resolution {
  resize: none !important;
  height: 130.99px !important;
}

.NewMeetingFileds {
  height: 39px !important;
}

.NewMeetingFileds_withIcon {
  height: 39px !important;
  position: relative !important;
}

.text-area-close-New_meeting {
  resize: none !important;
  height: 69px !important;
}

.text-area-close-New_meeting_error {
  resize: none !important;
  height: 69px !important;
  border: 1px solid #f16b6b !important;
}

.sharefoldersearchInput {
  height: 38px !important;
}

.dataRoomSearchInput {
  width: 611px;
  position: relative !important;
}

.PollingSearchInput {
  width: 100% !important;
  position: relative !important;
  height: 39px !important;
  padding-right: 50px;
}

.meetingSearch {
  width: 502px;
  position: relative !important;
  height: 39px !important;
  padding-right: 50px !important;
}

.meetinInnerSearch {
  position: relative !important;
  height: 39px !important;
  font-size: 0.8rem;
}

.Search_Modal_Fields {
  height: 39px !important;
}

.PollingCreateModal {
  margin: 0;
  height: 39px !important;
  padding: 12px 16px !important;
  border: 1px solid #e1e1e1 !important;
  position: relative;
  font-size: 12px !important;
  font-weight: 400;
  color: #5a5a5a !important;
}

.PollingCreateModal2 {
  height: 39px !important;
  padding: 12px 16px !important;
  border: 1px solid #e1e1e1 !important;
  position: relative;
}

.pollingCreateTitle {
  font-size: 12px !important;
  font-weight: 500;
  color: #5a5a5a !important;
  height: 39px !important;
}

.search_voterInput {
  margin: 0 !important;
}

.text-area-create-resolution {
  height: 110px !important;
  resize: none;
}

.text-area-create-meeting {
  height: 49px !important;
  resize: none;
}

.text-area-sharefolder {
  height: 110px !important;
  resize: none;
}

.Organizer_table {
  height: 39px !important;
}

.text-area-create-Notify-organizors {
  height: 136px !important;
  resize: none !important;
}

.addOraganizer {
  height: 39px !important;
}

.datePickerTodoCreate {
  width: 100%;
  height: 39px !important;
  margin-left: 15px !important;
  font-size: 12px;
}

.PollingCViewText {
  margin: 0;
  height: 39px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  position: relative;
}

.text-area-create-group {
  font-size: 12px !important;
  font-weight: 500;
  color: #5a5a5a !important;
}

.text-area-create-group::placeholder {
  color: #e1e1e1 !important;
}

.createCommittee_searchMember::placeholder {
  color: #e1e1e1 !important;
}

.AgendaTextField {
  height: 39px !important;
}

.resolution-search-input {
  position: relative !important;
}

.text-area-dataroom {
  resize: none;
  overflow-y: auto;
}

.searchFilterAgendaContributor {
  padding: 12px 30px !important;
}

.TextAreaProposedMeetingDetails {
  font-size: 1rem !important;
}

.user-login-history-searchbar {
  width: 100%;
  position: relative;
}

.user-login-history-searchbar .valid-feedback {
  display: block;
}
.signatureflow_input {
  width: 100% !important;
  height: 39px;
  font-weight: 500;
  color: #5a5a5a;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.signatureflow_input::placeholder {
  color: #a2a2a2 !important;
}

.usermanagementTextField {
  height: 39px !important;
}

.PakageDetails {
  height: 40px !important;
  width: 60px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.PakageDetails_UMUpgrade {
  height: 40px !important;
  width: 45px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.textField-RejectComment {
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
}

.textField-RejectComment::placeholder {
  color: #a2a2a2 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.DeclineReasonInput {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}
.DeclineReasonInput::placeholder {
  color: #a2a2a2 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: "Montserrat" !important;
}
/* .waitingParticipantsSearchField::placeholder {
  letter-spacing: 1px !important;
  color: rgba(162, 162, 162, 1) !important;
} */

/* Password Verify when meeting is delete */
.passwordCheckField_parent {
  position: relative !important;
}
.passwordCheckField {
  height: 35px !important;
  width: 100%;
  font-size: 13px !important;
}