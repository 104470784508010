.PersonName {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #545454;
}

.Designation {
  color: #6172d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Email {
  color: #505050 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.PresentBackgroundSection {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background: #81db86 !important;
  color: #fff !important;
  font-weight: 600 !important;
  display: flex;
  gap: 8px !important;
  align-items: center !important;
}

.AbsentBackgroundSection {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background: #f16b6b !important;
  color: #fff !important;
  font-weight: 600 !important;
  display: flex;
  gap: 8px !important;
  align-items: center !important;
}

.WorkFromHomeSection {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background: #d8a709 !important;
  color: #fff !important;
  font-weight: 600 !important;
  display: flex;
  gap: 8px !important;
  align-items: center !important;
}

.CloneMeetingStyles {
  padding: 12px 30px !important;
  border: 1px solid #e1e1ee !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.CloneMeetingStyles:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  border: 1px solid #e1e1ee !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.fixedHeight {
  height: 50vh !important;
}

.Cancel_Meeting_Details {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}
