p.FileModalTitle {
  margin: 0;
  font-weight: 500 !important;
  font-weight: 600;
  font-size: 17px;
  color: #6172d6;
}

img.image-close {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}

body[dir="rtl"] img.image-close {
  position: absolute;
  right: auto;
  top: 0px;
  cursor: pointer;
  left: 10px;
}

.Cancel_button_Notify {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-weight: 600 !important;
  background: #fff;
  color: #5a5a5a;
}

.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Send_Notify {
  height: 40px;
  padding: 0px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background: #5f78d6;
  color: #fff;
}

.Send_Notify:hover,
.Send_Notify:active,
.Send_Notify:focus,
.Send_Notify:focus-visible {
  height: 40px;
  padding: 0px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-weight: 600 !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.tags-input-container {
  padding: 0.5em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.tags-input-containerr {
  padding: 0.5em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 80px;
  overflow: hidden auto;
}

.tag-item {
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 50px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 85%;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
}

.tag-item img {
  position: relative;
  top: 1px;
}

.tag-item .close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
  background: none !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: 50% !important;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
  height: 23px;
}

span.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
}

p.organizationUsers {
  font-weight: 500 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #6172d6;
  margin-top: -15px !important;
  margin-bottom: 7px !important;
}

p.NonOrganizationUsers {
  font-weight: 500 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #6172d6;
  margin-top: 1.5em !important;
}

.text-area-organizer {
  margin-top: 0.5em;
}

.text-area-organizer label {
  display: none !important;
}
