.CustomerInfo_div {
  max-height: 500px !important;
}

.papercolor {
  margin-top: 20px !important;
  margin-left: 90px;
  padding-bottom: 40px;
  color: #fff;
  border: #ccc 1px solid !important;
}

.btnUpdate {
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  background-color: #6172d6;
  color: #fff;
}

.btnUpdate:hover {
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px;
  border: none !important;
}

.btnRevert {
  padding: 5px 40px;
  border-radius: 4px;
  margin-left: 18px;
  background: #fff;
  color: #5a5a5a;
  font-size: 12px;
  border: 1px solid #e1e1e1;
}

.btnRevert:hover,
.btnRevert:target,
.btnRevert:visited,
.btnRevert:focus,
.btnRevert:focus-visible {
  background: #fff !important;
  color: rgb(8, 8, 8) !important ;
  border: 1px solid #e1e1e1 !important;
}

.textFieldBorder {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #ffffff;
}

.CompanyTitle {
  font-size: 14px !important;
  font-weight: 600;
  margin-top: 26px;
  color: #5a5a5a;
}

.customerheading {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
}

.lineOnBottom {
  border-bottom: 1px solid rgb(242, 242, 242) !important;
  border-spacing: 5px !important;
}

.Customer-Information-Scroller-container {
  height: 50vh !important;
}

.editLink {
  color: #6172d6;
  margin-left: 20px;
  cursor: pointer;
}

.edit-Number-Link {
  color: #6172d6;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 8px;
}

.select-country {
  color: #b1aeae !important;
}

.modalHeaderUpdate {
  display: none !important;
}

.modalUpdatemodal {
  margin: 20px 60px !important;
  max-height: 75% !important;
  height: 75% !important;
  min-height: 75% !important;
}

.modalUpdateted {
  border-bottom: none;
  border-top: none;
  padding: 10px 20px;
  height: 5%;
  max-height: 5%;
  min-height: 5%;
  margin-top: 15px !important;
}

.modalUpdateted-2 {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
  padding: 0px 5px;
}

.modalCancelBtn {
  padding: 10px 30px;
  border: #e1e1e1 1px solid;
  color: #000000;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  margin-bottom: 30px !important;
}

.modalCancelBtn:hover {
  padding: 10px 30px;
  border: #e1e1e1 1px solid;
  color: #171d2d;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 30px !important;
  font-size: 12px;
}

.modalProceedBtn {
  padding: 10px 30px;
  border: #e1e1e1 1px solid;
  border: none;
  border-radius: 4px;
  background-color: #6172d6;
  margin-bottom: 30px !important;
  font-size: 12px;
}

.modalProceedBtn:hover {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  background-color: #6172d6;
  margin-bottom: 30px !important;
  font-size: 12px;
}

.modalUpdateText {
  text-align: center !important;
  font-size: 17px !important;
  color: #000 !important;
  font-weight: 600;
}

.formcontrol-Name-field {
  width: 100% !important;
  height: 30px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 13px;
}

.formcontrol-Name-field:focus {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  height: 30px !important;
  background-color: white !important;
  box-shadow: none !important;
}

.formcontrol-SelectCountry-field {
  width: 100% !important;
  height: 32px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: white !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
}

.formcontrol-SelectCountry-field-disabled {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
  background-image: none !important;
}

.formcontrol-SelectCountry-field:focus {
  border: 1px solid #e1e1e1 !important;
  height: 32px !important;
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: none !important;
  background-repeat: no-repeat;
}

select:disabled {
  opacity: 0.7 !important;
}

.formcontrol-Address1-field {
  width: 100% !important;
  height: 30px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 13px;
}

.formcontrol-Address1-field:focus {
  border: 1px solid #e1e1e1 !important;
  height: 30px !important;
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: none !important;
}

.formcontrol-Addressone-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-Addressone-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-Addresstwo-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-Addresstwo-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-State-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-State-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-City-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-City-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-ZipCode-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-ZipCode-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-ContactName-field {
  width: 100% !important;
  height: 30px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 13px;
}

.formcontrol-ContactName-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-Number-field {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #e1e1e1 !important;
  background-color: white !important;
  font-size: 13px;
}

.formcontrol-Number-field_disabled {
  background-color: transparent !important;
  border: none !important;
}

.formcontrol-PhoneNumber-Field {
  width: 140% !important;
  height: 30px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: transparent !important;
}

.formcontrol-PhoneNumber-Field:focus {
  border: 1px solid #e1e1e1 !important;
  height: 30px !important;
  border-radius: 4px !important;
  background-color: white !important;
  box-shadow: none !important;
}

.customerINformation {
  min-height: 53vh;
  overflow-y: auto;
}

.customerINformation::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-block: 25px;
}

.customerINformation::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.react-flag-Info {
  height: 30px !important;
  padding: auto !important;
  margin-top: 4px !important;
}

.react-flag-Info button {
  cursor: pointer !important;
  max-width: 100% !important;

  min-height: 39px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 3px 2px !important;
  font-size: 9px !important;
  color: #4d4d4d !important;
  border: thin solid rgba(77, 77, 77, 0.3);
  border-radius: 2px !important;
  background: transparent;
}

.react-flag-Info input {
  cursor: pointer !important;
  padding: 5px 8px !important;
  margin: 4px 5px !important;
  font-size: 10px !important;
  white-space: nowrap !important;
}

.react-flag-Info button:after {
  border-top: 3px solid #4d4d4d;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 0;
  margin-left: -3px;
}

.react-flag-Info button[aria-expanded="true"]:after {
  border-top: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #4d4d4d;
  margin-left: -3px;
}

.react-flag-Info button:focus {
  cursor: pointer !important;
  max-width: 100% !important;
  max-height: 120px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 3px 2px !important;
  font-size: 9px !important;
  color: #000000 !important;
  border: thin solid #000000 !important;
  border-radius: 2px !important;
  background: transparent;
}

.customerInfoModal {
  display: block !important;
}

.span-on-number {
  margin-left: 9px !important;
}
.address_bottom_line {
  font-size: 1em !important;
}

.react-Country-Dropdown {
  height: 39px !important;
  padding: auto !important;
}

.react-Country-Dropdown button {
  cursor: pointer !important;
  max-width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  min-height: 39px !important;
  padding: 0px 12px !important;
  font-size: 12px !important;
  color: #4d4d4d !important;
  border: thin solid #e1e1e1 !important ;
  border-radius: 4px !important;
  background: white !important;
}

.react-Country-Dropdown input {
  cursor: pointer !important;
  padding: 5px 8px !important;
  margin: 4px 5px !important;
  font-size: 10px !important;
  white-space: nowrap !important;
}

.react-Country-Dropdown button:after {
  border-top: 3px solid #4d4d4d;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 0;
  margin-left: -3px;
}

.react-Country-Dropdown button[aria-expanded="true"]:after {
  border-top: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #4d4d4d;
  margin-left: -3px;
}

.react-Country-Dropdown button:focus {
  cursor: pointer !important;
  max-width: 100% !important;
  max-height: 120px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 1px 12px !important;
  font-size: 12px !important;
  color: #000000 !important;
  border: thin solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: white !important;
}

.react-Country-Dropdown-span {
  position: relative;
  left: 12px;
  top: 4px;
}

body[dir="rtl"] .react-Country-Dropdown-span {
  left: 0px !important;
  right: 12px !important;
  top: 0px;
}
