.pendingApprovalContainer {
  width: 98% !important;
  height: 91vh !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

.pendingApprovalHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #6172d6;
}

.Accept-all {
  width: 123px;
  height: 39px;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

.Accept-all:is(:active, :hover, :focus, :focus-visible) {
  width: 123px;
  height: 39px;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

span.No-of-reviews {
  width: 292px;
  height: 40px;
  background: #fff8e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ddb227;
  margin-right: 15px;
}

body[dir="rtl"] span.No-of-reviews {
  width: 292px;
  height: 40px;
  background: #fff8e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ddb227;
  margin-right: auto !important;
  margin-left: 15px !important;
}

.pendingApprovalPaper {
  border: 1px solid #e1e1e1;
  height: auto !important;
  min-height: 78vh !important;
  width: 100% !important;
}

p.Parent-title-heading {
  font-weight: 600;
  font-size: 30px;
  color: #6172d6;
}

p.minutes-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #5a5a5a;
  margin: 0 !important;
  overflow-wrap: break-word !important;
  max-width: 100% !important;
}

span.bar-line {
  border: 1px solid #e1e1e1;
  display: block;
  height: 100%;
  border-top: none;
  border-right: none;
  border-bottom: none;
  position: absolute;
}

img.Image {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

p.agendaCreater {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #5a5a5a;
}

.uploaded-details {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
  margin-left: 50px;
  margin-right: 50px;
  margin-left: 63px;
}

.uploaded-details-rejected {
  border: 1px solid #f16b6b;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative; /* Added position relative for positioning the ::after element */
  margin-left: 63px;
}

.uploaded-details-accepted {
  border: 1px solid #81db86;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative; /* Added position relative for positioning the ::after element */
  margin-left: 63px;
}

.version-control-wrapper-with-more {
  width: 35px;
  height: 100%;
  background: #fff;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: 21px;
  border: none !important;
}

body[dir="rtl"] .version-control-wrapper-with-more {
  width: 35px;
  height: 100%;
  background: #fff;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: auto;
  right: 21px;
  border: none !important;
}

.version-control-wrapper-with-more span.with-text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #6172d6;
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1 !important;
  border-radius: 9px !important;
}

.version-control-wrapper {
  width: 18px;
  height: 18px;
  background: #f16b6b;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: 30px;
}

body[dir="rtl"] .version-control-wrapper {
  width: 18px;
  height: 18px;
  background: #f16b6b;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: auto;
  right: 30px;
}

.version-control-wrapper-last {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: 30px;
}

.version-control-wrapper-with-more-last {
  width: 35px;
  height: 100%;
  background: #fff;
  border-radius: 9px;
  display: block;
  position: absolute;
  top: 60px;
  left: 21px;
  border: none !important;
}

.version-control-wrapper-with-more-last span.with-text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #6172d6;
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1 !important;
  border-radius: 9px !important;
}

p.uploadedbyuser {
  font-size: 12px;
  font-weight: 700;
  color: #5f78d6;
  margin: 0 !important;
  margin-left: 20px !important;
}

body[dir="rtl"] p.uploadedbyuser {
  font-size: 12px;
  font-weight: 700;
  color: #5f78d6;
  margin: 0 !important;
  margin-left: auto !important;
  margin-right: 20px !important;
}

.gap-ti {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px !important;
}

body[dir="rtl"] .gap-ti {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto !important;
  margin-right: 20px !important;
}

.Accept-comment {
  width: 104px;
  background: #6172d6 !important;
  border-color: #6172d6 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  line-height: 5px;
  margin-bottom: 10px;
}

.Accept-comment:is(:active, :hover, :focus, :focus-visible) {
  width: 104px;
  background: #6172d6 !important;
  border-color: #6172d6 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  line-height: 5px;
  margin-bottom: 10px;
}

.Reject-comment {
  width: 104px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
  margin-left: 10px;
  line-height: 5px;
  padding: 0;
}

.Reject-comment:is(:active, :hover, :focus, :focus-visible) {
  width: 104px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  margin-left: 10px;
  margin-bottom: 10px;
  line-height: 5px;
  padding: 0;
}

.Rejected-comment {
  width: 104px;
  background: #f16b6b !important;
  border-color: #f16b6b !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
  line-height: 5px;
  padding: 0;
}

.Rejected-comment:is(:active, :hover, :focus, :focus-visible) {
  width: 104px;
  background: #f16b6b !important;
  border-color: #f16b6b !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
  line-height: 5px;
  padding: 0;
}

.Accepted-comment {
  width: 104px;
  background: #81db86 !important;
  border-color: #81db86 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
  line-height: 5px;
  padding: 0;
}

.Accepted-comment:is(:active, :hover, :focus, :focus-visible) {
  width: 104px;
  background: #81db86 !important;
  border-color: #81db86 !important;
  height: 24px;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
  line-height: 5px;
  padding: 0;
}

p.time-uploader {
  margin: 0;
  bottom: 20px;
  margin-left: 10px;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

body[dir="rtl"] p.time-uploader {
  margin: 0;
  bottom: 20px;
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

p.date-uploader {
  margin: 0;
  position: absolute;
  bottom: 5px;
  margin-left: 20px;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

body[dir="rtl"] p.date-uploader {
  margin: 0;
  position: absolute;
  bottom: 5px;
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

.inherit-height {
  min-height: inherit !important;
}

.review-minutes-wrapper-scroll {
  height: 57vh;
  overflow: hidden auto;
  padding-right: 15px;
  margin-bottom: 15px;
}

body[dir="rtl"] .review-minutes-wrapper-scroll {
  height: 57vh;
  overflow: hidden auto;
  padding-right: 0 !important;
  padding-left: 15px !important;
  margin-bottom: 15px;
}

.review-minutes-wrapper-scroll::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.review-minutes-wrapper-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.review-minutes-wrapper {
  height: 57vh;
  overflow: hidden auto;
  margin-bottom: 15px;
}

.review-minutes-wrapper::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.review-minutes-wrapper::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.Cancel {
  width: 103px;
  height: 39px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  margin-right: 15px;
  margin-bottom: 15px;
}

.Cancel:is(:active, :hover, :focus, :focus-visible) {
  width: 103px;
  height: 39px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  margin-right: 15px;
  margin-bottom: 15px;
}

body[dir="rtl"] .Cancel {
  width: 103px;
  height: 39px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  margin-right: auto !important;
  margin-left: 15px !important;
  margin-bottom: 15px;
}

body[dir="rtl"] .Cancel:is(:active, :hover, :focus, :focus-visible) {
  width: 103px;
  height: 39px;
  background: #fff !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  margin-right: auto !important;
  margin-left: 15px !important;
  margin-bottom: 15px;
}

.Submit-review {
  width: 154px;
  height: 39px;
  background: #5f78d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.Submit-review:is(:active, :hover, :focus, :focus-visible) {
  width: 154px;
  height: 39px;
  background: #5f78d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: 600;
}

.positioning-tb {
  margin-top: 10px !important;
}
