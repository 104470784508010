.DeleteOrganizationheading {
  font-size: 30px !important;
  white-space: nowrap !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .DeleteOrganizationheading {
  font-weight: 700 !important;
}

.DeleteOrganizationCardStyles {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 30px 40px !important;
  border-radius: 4px !important;
}

.DeleteOrgniazationMessege {
  font-size: 16px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .DeleteOrgniazationMessege {
  font-weight: 600 !important;
}

.deleteOrganization_btn {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600 !important;
  background: #f16b6b !important;
  color: #fff !important;
  font-size: 12px !important;
}

.deleteOrganization_btn:is(:focus, :active, :focus-visible, :hover) {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600 !important;
  background: #f16b6b !important;
  color: #fff !important;
  font-size: 12px !important;
}

body[dir="rtl"] .deleteOrganization_btn {
  font-weight: 600 !important;
}
