.Poll_details_Heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

.Box_For_Title_toShow {
  width: 100% !important;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.ViewTitleTOShowOnProgress {
  width: 100%;
  font-size: 14px !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  margin: 4px !important;
}

.no-Of-Yes {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.No-of-Yes-Answers {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  width: 100% !important;
}

.no-Of-No {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  margin-left: 30% !important;
}

.border_bottom {
  border-bottom: 1px solid #e1e1e1;
  width: 100% !important;
}

.Participants_polls_Details {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #6172d6;
}

.Scroller_participants {
  height: 32vh !important;
  overflow-x: hidden !important;
  width: 100%;
}

.Scroller_participants::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.Scroller_participants::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Scroller_Options {
  height: auto !important;
  max-height: 30vh;
  overflow-x: hidden !important;
  width: 100%;
}

.Scroller_Options::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_Options::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Card_border2 {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  margin-left: 0px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

body[dir="rtl"] .Card_border2 {
  margin-right: 0px !important;
}

.Name_cards {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
  margin-left: 5px !important;
}

.Image {
  margin-left: 8px !important;
}

.Yes_voters {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.Class_Close {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;

}

.Class_Close:hover,
.Class_Close:active,
.Class_Close:focus,
.Class_Close:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.OVerall_Padding {
  padding: 0px 45px !important;
  margin-top: -25px !important;
}

.Cross_Icon_poll_Details {
  cursor: pointer !important;
  z-index: 1 !important;
}

.BOx_for_yes {
  width: 100%;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding-left: 10px;
  margin: 0 0 10px 0;
}
.scrollable-title {
  max-height: 60px;
  padding-right: 5px;
  overflow: hidden;
  overflow-y: auto;
  word-wrap: break-word;
}
.scrollable-title::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.scrollable-title::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.scrollable-title2 {
  min-height: 39px !important;
}
