.ErrorMessageBox {
  background: #ffd5d5;
  height: auto;
  padding: 10px 20px;
  color: #f16b6b;
}
.warning_heading span {
  color: #f16b6b !important;
}
.warning_heading {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 16px;
}

.warning_heading span {
  color: #f16b6b;
}
