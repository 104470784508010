.search-Icon {
  margin: 0 0 0 5px;
  transition: all 0.5s;
}

.bell-Icon {
  margin: 15px 10px 0 20px;
  transition: all 0.5s;
  position: relative;
}

.mx-2 {
  margin-left: 10px;
}

.dropdown-menu-list-item {
  padding: 0px 15px;
}

.user-dropdown {
  height: 45px;
}

.user-dropdown .dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  padding: 16px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  gap: 10px;
  cursor: pointer;
}

.dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  padding: 16px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.show .dropdown {
  border: none !important;
}

.dropdown-toggle:hover {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
}

.user-dropdown .dropdown-toggle .header-icon {
  margin: 0 4px 0 0;
}

.user-dropdown .dropdown-menu.active {
  display: block;
}

.user-dropdown .dropdown-menu.show {
  width: 100%;
  margin-top: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.user-dropdown .dropdown-menu-list-item {
  margin-bottom: 20px;
  position: relative;
}

.user-dropdown .dropdown-menu-list-item a {
  padding: 5px 0 3px 0;
  display: block;
}

.user-dropdown .dropdown-menu-list-item:after {
  position: relative !important;
  bottom: -10px;
  width: 85%;
  height: 1px;
  background: #e1e1e1;
  content: "";
  display: block;
}

.nav-item {
  text-align: none;
  list-style: none;
}

.user-dropdown .dropdown-toggle .user-name {
  margin: 0 2px;
}

.dropright .dropdown-menu.show {
  right: 0px;
  left: inherit;
  display: block;
}

.dropmidle .dropdown-menu {
  transform: translate(-55%, 30px) !important;
  display: none;
}

.dropmidle .dropdown-menu.show {
  display: block;
  margin-top: 5px;
}

.dropdown-item {
  white-space: nowrap !important;
  font-size: 0.8rem;
}

.dropdown-item:hover {
  background: none;
}

.dropmidle .notification-dropdown-menu {
  transform: translate(-43%, 24px);
}

.notification-dropdown .notification-dropdown-menu {
  min-width: 320px;
  max-width: 320px;
  max-height: 70vh;
  overflow: auto;
}

.notification-dropdown > [data-bs-toggle="dropdown"].show:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #e1e1e1;
  content: "";
  display: block;
  position: absolute;
  top: 53px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.icon-list-item {
  width: 20px;
  height: 20px;
  background: #e1e1e1;
  border-radius: 50%;
}

.desc-notification-user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.desc-item-time {
  text-align: right;
  color: #9a9a9a;
  margin: 0 10px 0 0;
}

.icon-notification-list-item {
  width: 50px;
  height: 50px;
  background: #cccc;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.desc-notification-list-item {
  padding: 0 10px;
  width: 100% !important;
  height: auto;
}

.notification-dropdown-menu-item {
  color: #505050;
}

.notification-dropdowntoggle {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px !important;
  color: #000;
}

.notification-dropdowntoggle:hover {
  border: none !important;
}

.notification-dropdowntoggle::after {
  border: none;
}

.user-name {
  margin: 0 10px 0 0;
  padding: 0;
}

.dropdown-toggle {
  margin: 0;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  color: #000;
}

.dropdown-toggle:focus {
  border: none;
  --bs-btn-focus-box-shadow: none;
}

.dropdown_menu {
  width: 100% !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  color: #000 !important;
}

.notification-dropdown_menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #f6f9ff;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 320px;
  max-width: 320px;
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;

  background-clip: padding-box;

  inset: 0px auto auto 0px;
  transform: translate(-106px, 55px) !important;
  overflow-x: hidden;
  max-height: 70vh;
}

.notification-day {
  margin: 0;
  font-weight: 700;
  font-size: 0.9rem;
  color: #000;
}

.heading {
  font-size: 1.1rem;
  width: 280px;
}

.header-navbar-show {
  padding: 0 !important;
  margin: 0 !important;
}

.sidebar-for-onboard {
  z-index: 99999999 !important;
  display: flex !important;
  align-items: left !important;
  justify-content: start !important;
  justify-items: start !important;
}

.header-schedule-meeting-btn {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.header-schedule-meeting-btn-show {
  display: flex !important;
  margin: 0px !important;
  align-items: center !important;
}
