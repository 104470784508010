.accordion-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 200px;
  background: #fff;
  transition: max-height 0.2s ease-in-out;
}
.accordion-custom-extended {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 10px;
  overflow: hidden;
  background: #fff;
  transition: height 0.2s ease-in-out;
}
.FirstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title-cont {
  width: 33.33%;
  justify-content: flex-start;
  display: flex;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px !important;
  color: #2197d7;
  cursor: pointer !important;
}
.dateTime {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  gap: 20px;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 33.33%;
  gap: 20px;
}
.SecondRow {
  width: 100%;
  height: auto;
  padding: 12px 10px;
}
