.diskus-sidebar {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #fff !important;
  position: fixed;
  height: 100vh !important;
  max-width: max-content !important;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 101 !important;
}

.new_sidebar {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100% !important;
  border-right: 1px solid #e1e1e1;
}
body[dir="rtl"] .new_sidebar {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100% !important;
  border-left: 1px solid #e1e1e1;
}

.admin_nav {
  min-height: 0vh !important;
}

/* .iconSidebar {
  margin: 30px;
} */

.sidebar-row {
  margin: 0 !important;
  -bs-gutter-x: 0 !important;
}

.iconSidebar-meeting {
  color: #fff !important;
  margin: 20px 0 0 0;
  font-size: 30px !important;
}

.iconSidebar-check-list {
  color: #fff !important;
  margin: 110px 0 0 0;
  font-size: 30px !important;
}

.iconSidebar-note2 {
  color: #fff !important;
  font-size: 30px !important;
  margin: 20px 0 0 0;
}

.iconSidebar-chat {
  /* color: #fff !important; */
  font-size: 18px !important;
  /* margin: 30px 0 0 0; */
}

.iconSidebar-calendar {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

/* .iconSidebar-calender {
  font-size: 30px !important;
  color: #fff;
  margin: 50px 0 0 0;
} */
.iconSidebar-user {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

/* a.faqMenu { */
/* margin-top: 220px !important; */
/* } */

.iconSidebar-help {
  font-size: 30px !important;
  color: #fff;
  margin: 0 !important;
}

.iconSidebar-setting {
  font-size: 30px !important;
  color: #fff;
  margin: 20px 0 0 0;
}

.iconSidebar-attachment {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

.popper_attachment {
  background-color: #fff;
}

a.iconSidebar:hover svg path {
  background: linear-gradient(to top, #49dbdb 0%, #6172d6 100%) !important;
}

body[dir="rtl"] a.iconSidebar:hover svg path {
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
}

.div_hover:hover {
  color: #fff !important;
}

.Hover_class:hover {
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
}

a.iconSidebar {
  height: 45px;
}

a.iconSidebar {
  /* background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important; */
  width: 100%;
  height: 55px;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.iconSidebar svg {
  height: 26px !important;
}
body[dir="rtl"] a.iconSidebar div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
a.iconSidebar div {
  margin-top: 5px !important;
}

.iconSidebar svg path {
  fill: #6172d6;
}

.iconSidebar-active-sidebar {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  border-radius: 4px !important;
  align-items: center;
}

body[dir="rtl"] .iconSidebar-active-sidebar {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  height: 55px;
}

.iconSidebar-active-sidebar svg path {
  fill: #fff !important;
}

.iconSidebar-active-sidebar svg rect#Rectangle_830 {
  fill: #fff !important;
}

.iconSidebar-active-sidebar svg rect#Rectangle_832 {
  fill: #fff !important;
}

.iconSidebar-active-sidebar svg rect#Rectangle_831 {
  fill: #fff !important;
}

a:hover .iconSidebar-active-sidebar svg rect#Rectangle_830 {
  fill: #fff !important;
}

a:hover .iconSidebar-active-sidebar svg rect#Rectangle_832 {
  fill: #fff !important;
}

a:hover .iconSidebar-active-sidebar svg rect#Rectangle_831 {
  fill: #fff !important;
}

a:hover .iconSidebar-active-sidebar.nav-link.active rect#Rectangle_830 {
  fill: #fff !important;
}

a.m-0.p-0.iconSidebar-active-sidebar.nav-link.active rect#Rectangle_831 {
  fill: #fff !important;
}

/* Aun Work */

.admin-width {
  width: 100% !important;
  margin: 0;
  padding: 0;
}

/* svg {
  margin: 0 auto;
} */
.sidebar-tool {
  fill: white !important;
  font-size: 12px !important;
  background-color: black !important;
  padding: 4px !important;
  border-radius: 4px !important;
  color: white !important;
}

.Meeting_Side_bar_Tag_todo {
  font-size: 9px !important;
  color: #5a5a5a !important;
  font-weight: 600;
  white-space: nowrap !important;
}

body[dir="rtl"] .Meeting_Side_bar_Tag_todo {
  display: flex !important;
}

.Meeting_Side_bar_Tag_active_active_todo {
  font-size: 8px !important;
  color: #fff !important;
  font-weight: 600;
  white-space: nowrap !important;
}

.expanded_menu {
  position: absolute !important;
  height: auto !important;
  left: 65px !important;
  background-color: #fff !important;
  z-index: -1 !important;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  bottom: 4px !important;
}

body[dir="rtl"] .expanded_menu {
  left: auto !important;
  right: 63px !important;
}

/* css */

body[dir="rtl"] a.iconSidebar:hover svg path {
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
}

a.iconSidebar:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  width: 100%;
  height: 55px;
  color: #fff;
  border-radius: 4px !important;
}

body[dir="rtl"] a.iconSidebar:hover {
  height: 55px !important;
}

body[dir="rtl"] a.iconSidebar:hover span {
  color: #fff !important;
}

a.iconSidebar:hover span {
  color: #fff !important;
}

a.iconSidebar span {
  color: #505050 !important;
  font-size: 10px !important;
  white-space: nowrap;
  font-weight: 600;
}

body[dir="rtl"] a.iconSidebar span {
  color: #505050 !important;
  font-size: 10px !important;
  white-space: nowrap;
  font-weight: 600;
}

body[dir="rtl"] a.iconSidebar:hover {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
}

a.iconSidebar:hover svg path {
  fill: #fff !important;
}

/* css for inner screen */
a.iconSidebar:hover a.icon_expand.nav-link svg path {
  fill: #6172d6 !important;
}

a.iconSidebar:hover a.icon_expand.nav-link:hover svg path {
  fill: #fff !important;
}

a.iconSidebar:active a.icon_expand.nav-link svg path {
  fill: #6172d6 !important;
}

/* important for outer icons outer icons   */

a.icon_expand.nav-link svg {
  margin-top: 10px !important;
  width: 100% !important;
  height: 26px;
}

a.iconSidebar-active-sidebar.nav-link:active svg path {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  fill: #6172d6 !important;
}

a.iconSidebar-active-sidebar.nav-link svg {
  margin-top: 5px !important;
  width: 100% !important;
  height: 26px !important;
}

body[dir="rtl"] a.iconSidebar-active-sidebar.nav-link svg {
  margin-top: 5px !important;
  width: 100% !important;
  height: 26px !important;
}

a.icon-active-sidebar_expand.nav-link svg {
  margin-top: 10px !important;
  width: 100% !important;
  height: 26px !important;
}

a.icon-active-sidebar_expand.nav-link {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  height: 55px !important;
  width: 98% !important;
  border-radius: 4px;
}

a.m-0.p-0.icon_expand.nav-link:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
  fill: #fff !important;
  height: 55px !important;
  width: 99% !important;
}

a.m-0.p-0.icon_expand.nav-link:is(:hover, :focus) svg path {
  fill: #fff !important;
}

a.m-0.p-0.icon_expand.nav-link:is(:hover, :focus) span {
  color: #fff !important;
}

a.m-0.p-0.icon_expand.nav-link svg path {
  fill: #6172d6 !important;
}

a.icon-active-sidebar_expand.nav-link svg path {
  fill: #fff !important;
}
/* iconSidebar */
.iconSidebar:hover .noteIcon svg rect#Rectangle_830 {
  fill: #fff !important;
}
.iconSidebar:hover .noteIcon svg rect#Rectangle_831 {
  fill: #fff !important;
}
.iconSidebar:hover .noteIcon svg rect#Rectangle_832 {
  fill: #fff !important;
}

/* Moved Css */
.iconItem_activeSideBarMain {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
  color: #fff;
  min-width: 63px !important;
}

body[dir="rtl"] .iconItem_activeSideBarMain {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.iconItem_activeSideBarMain img {
  height: 25px;
  margin-top: 5px;
  filter: brightness(0) invert(1);
}

.iconItem_activeSideBarMain span {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff !important;
  white-space: nowrap;
}

/* For Show More Starts */
.iconItem_activeSideBarMainShowMore {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
  color: #fff;
  min-width: 63px !important;
}

body[dir="rtl"] .iconItem_activeSideBarMainShowMore {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.iconItem_activeSideBarMainShowMore img {
  height: 25px;
  margin-top: 8px !important;
}

.iconItem_activeSideBarMainShowMore span {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff !important;
  white-space: nowrap;
}

.ForActive {
  filter: brightness(0) invert(1);
}
/* For Show More Ends */

.iconItemSideBarMain span {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.iconItemSideBarMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  text-decoration: none;
  min-width: 63px !important;
}

.iconItemSideBarMain:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px !important;
  color: #fff !important;
  min-width: 63px !important;
}

body[dir="rtl"] .iconItemSideBarMain:hover {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.iconItemSideBarMain:hover img {
  height: 25px !important;
  margin-top: 5px !important;
  filter: brightness(0) invert(1);
}

.iconItemSideBarMain img {
  height: 25px !important;
  margin-top: 5px !important;
}
