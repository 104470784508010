.MontserratExtraBold {
  font-weight: 700 !important;
}

body[dir="rtl"] .MontserratExtraBold {
  font-weight: 700 !important;
}

.MontserratMedium {
  font-weight: 500 !important;
}
body[dir="rtl"] .MontserratMedium {
  font-weight: 500 !important;
}
body[dir="rtl"] .paymentActivityTitle {
  font-weight: 600 !important;
}
.paymentActivityTitle {
  font-size: calc(33px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  padding: 0;

  margin-right: 10px;
}

.PaymentActivitySubtitle {
  font-weight: 700 !important;
  color: #5a5a5a;
  font-size: 20px;
}
body[dir="rtl"] .PaymentActivitySubtitle {
  font-weight: 700 !important;
}
.number-color {
  color: #5a5a5a;
  font-weight: 700 !important;
  margin-left: 10px;
}

body[dir="rtl"] .number-color {
  font-weight: 700 !important;
}
