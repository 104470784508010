.upgrade_planBox {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.upgrade_planBox ul {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  color: #5a5a5a;
}

.upgrade_planBox ul li::before {
  content: url(../../../../assets/images/newElements/check.png);
  margin-right: 10px;
  width: 2px;
}

body[dir="rtl"] .upgrade_planBox ul li::before {
  content: url(../../../../assets/images/newElements/check.png);
  margin-right: 0px !important;
  margin-left: 10px !important;
  width: 2px;
}

.upgrade_planBox h4 {
  color: #5a5a5a !important;
  font-size: 27px;
}

.upgrade_planBox ul li {
  margin: 15px 0;
  font-size: 15px;
  font-weight: 500 !important;
}

body[dir="rtl"] .upgrade_planBox ul li {
  font-weight: 500 !important;
}

.upgrade_button {
  margin: 160px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  text-align: center;
  width: 330px;
  border-radius: 4px;
  background: #6172d6;
  border: transparent;
  font-weight: 500 !important;
  font-size: 12px !important;
  height: 40px;
}

body[dir="rtl"] .upgrade_button {
  font-weight: 500 !important;
}

.upgrade_button:hover,
.upgrade_button:active,
.upgrade_button:visited,
.upgrade_button:target,
.upgrade_button:focus {
  margin: 160px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  text-align: center;
  width: 330px;
  border-radius: 4px;
  background: #6172d6 !important;
  border: transparent;
  font-weight: 500 !important;
  font-size: 12px !important;
  height: 40px;
}

body[dir="rtl"] .upgrade_button {
  float: left !important;
}

body[dir="rtl"] .upgrade_button:hover,
body[dir="rtl"] .upgrade_button:active,
body[dir="rtl"] .upgrade_button:visited,
body[dir="rtl"] .upgrade_button:target,
body[dir="rtl"] .upgrade_button:focus {
  float: left !important;
}

.packagecard {
  border-radius: 4px;
  background-color: #fff !important;
  padding: 0 35px;
  position: relative;
  height: 560px;
  border: 1px solid #e1e1e1 !important;
}

.Package_title h2 {
  text-align: center;
  margin: 28px 0;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Package_title h2 {
  font-weight: 600 !important;
}

.packagecard h4 {
  color: #076db7;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 40px;
}

.packagecard_pricebox {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px 0px;
}

body[dir="rtl"] .packagecard_pricebox {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.packagecard_priceBox_container {
  width: 180px;
  margin: 0 auto;
}

.packagecard_priceBox_container h4 {
  text-transform: lowercase;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 700 !important;
}

.packagecard_priceBox_container p {
  font-size: 10px;
  margin: 5px 0 0 0 !important;
  padding: 0 !important;
}

.packagecard_priceBox_container span {
  text-align: center;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.packagecard_priceBox_container span:hover {
  color: #fff;
  transition: 0.3s ease-in-out;
  background-color: #6172d6;
}

.packagecard_one {
  border: 1px solid #ccc;
}

.selectedPackage_priceDetails {
  padding: 10px 0;
  color: #5a5a5a;
  font-weight: 700 !important;
  margin-bottom: 20px;
}

.selectedPackage_priceDetails h4 {
  color: #5a5a5a;
  font-size: 2.3rem;
  font-weight: 700 !important;
}

body[dir="rtl"] .selectedPackage_priceDetails h4 {
  font-weight: 700 !important;
}

.packagecard_two {
  border: 2px solid #6172d6;
  visibility: visible;
  margin-bottom: 50px;
}

.packagecard_two h3 {
  color: #076db7;
  font-weight: 700 !important;
}

.packagecard_two_visible {
  border: 2px solid #6172d6;
  visibility: hidden;
  margin-bottom: 50px;
}

.packagecard_disoucntprice_para {
  text-align: center;
  margin: 0 !important;
}

.selectedpackagecard_disoucntprice_para {
  text-align: center;
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}

body[dir="rtl"] .selectedpackagecard_disoucntprice_para {
  font-weight: 400 !important;
}

.packagecard_usersallows {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.packagecard_usersallows + p {
  font-size: 12px;
}

body[dir="rtl"] .packagecard_usersallows {
  font-weight: 400 !important;
}

.packagecard_usersallows_heading {
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #000;
}

.packagecard_usersallows_members {
  padding: 8px 0;
  font-size: 11px;
  width: 120px;
  text-align: center;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  color: #fff;
  text-transform: uppercase;
}

body[dir="rtl"] .packagecard_usersallows_members {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.subcriptionkey_1 {
  background: linear-gradient(to left, #6172d6 5%, #6172d6 95%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  border-top-left-radius: 4px;
}

body[dir="rtl"] .subcriptionkey_1 {
  background: linear-gradient(to right, #6172d6 5%, #6172d6 95%);
  border-top-left-radius: 0px;
  border-top-right-radius: 4px;
  font-weight: 400 !important;
}

.subcriptionkey_2 {
  background: linear-gradient(to right, #6172d6 1%, #49dbdb 99%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  border-top-right-radius: 4px;
}

body[dir="rtl"] .subcriptionkey_2 {
  background: linear-gradient(to left, #6172d6 1%, #49dbdb 99%);
  border-top-right-radius: 0px !important;
  border-top-left-radius: 4px !important;
  font-weight: 400 !important;
}

.subcriptionkey p {
  margin: 0;
}

.packagecard_usersallows_members p {
  margin: 0;
}

.packagecard_usersallows_members_value {
  border: 1px solid #ccc;
}

.packagecard_btn {
  width: 100%;
  background-color: #6172d6 !important;
  border-radius: 1px !important;
  border: 1px solid #6172d6 !important;
  visibility: visible;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.selected-package-text {
  padding: 0;
  height: auto;
  overflow: hidden;
  margin-bottom: 15px;
}

body[dir="rtl"] .selected-package-text {
  font-weight: 400 !important;
}

.ExecutiveMembersRange {
  width: 100%;
  border-radius: 20px !important;
  border: 0.5px solid #949494 !important;
  background-color: #fff !important;
}

.BoardMembersRange {
  width: 100%;
  border-radius: 20px !important;
  border: 0.5px solid #949494 !important;
  background-color: #fff !important;
}

.crossicon {
  color: #707070 !important;
}

.packageCard_title {
  text-transform: uppercase;
  color: #000;
  font-size: 2.5rem;
  text-align: center;
  color: #5a5a5a;
  font-weight: 700 !important;
  margin: 40px 0 0 0 !important;
}

body[dir="rtl"] .packageCard_title {
  font-weight: 700 !important;
}

.text-color {
  color: #5a5a5a !important;
}

.MontserratSemiBold {
  font-weight: 600 !important;
}

.upgradeplandetail_line {
  font-weight: 500 !important;
  color: #5a5a5a;
  font-size: 15px;
}

body[dir="rtl"] .upgradeplandetail_line {
  font-weight: 500 !important;
}

.subcriptionvalue_1 {
  border-bottom-left-radius: 4px;
  border: 1px solid #ccc;
  padding: 3px 0px;
}

body[dir="rtl"] .subcriptionvalue_1 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4px;
  font-weight: 400 !important;
}

.subcriptionvalue_2 {
  border-bottom-right-radius: 4px;
  border: 1px solid #ccc;
  padding: 3px 0px;
}

body[dir="rtl"] .subcriptionvalue_2 {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 4px;
  font-weight: 400 !important;
}

img.package-icon {
  position: absolute;
  top: -9px;
  left: 20px;
  width: 67px;
}

body[dir="rtl"] img.package-icon {
  position: absolute;
  top: -9px;
  left: auto;
  right: 20px;
  width: 67px;
}

.progressbar-text {
  text-align: left;
  margin-bottom: 5px;
  color: #949494;
  font-weight: 500;
}

body[dir="rtl"] .progressbar-text {
  font-weight: 500 !important;
}
