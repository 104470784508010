.Advance_setting_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #5f78d6 !important;
}

.Show_Heading_Advance_persmission {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.Bottom_line {
  width: 100% !important;
  height: 1px !important;
  display: block;
  margin-top: 10px;
  background-color: #ccc;
}

.Bottom_line_names {
  width: 104% !important;
  height: 1px !important;
  display: block;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #ccc;
}

/* sidebar styling  */
.sidebar-row {
  margin: 0% !important;
  padding: 0% !important;
}

.sidebar-text {
  cursor: pointer !important;
  color: #004990 !important;
  font-size: 17px;
  font-weight: 500;
}

.btm-icons {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.sidebar-icons-color {
  color: #004990 !important;
  padding: 0px 6px;
  font-size: 18px !important;
}

.menu-background {
  background-color: #e6e8ff;
  text-align: left !important;
}

.Menu-sidebar-class {
  margin-left: -10px !important;
  font-weight: 600;
  font-size: 15px;
  color: #004990 !important;
  width: 100% !important;
  height: 100%;
}

.menu-icons {
  font-size: 18px !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: #e6e8ff;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-layout .ant-layout-sider {
  background: #ffffff !important;
}

.SecurityMenu > ant-menu-submenu-arrow,
.ant-menu-submenu-arrow {
  /* right: 10px !important; */
  transform: rotate(270deg) translateX(2.5px) !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu
  .ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  transform: rotate(135deg) translateX(-2.5px) translateY(0px);
}

section.ant-layout.ant-layout-has-sider.css-dev-only-do-not-override-1e3x2xa {
  height: 83.5vh !important;
  border-top-right-radius: 10px;
  overflow: hidden !important;
  border-top-right-radius: 20px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  border-top-right-radius: 10px !important;
}

/* :where(.css-dev-only-do-not-override-1e3x2xa).ant-layout
  .ant-layout-sider-children {
  width: 250px !important;
} */

:where(
    .css-dev-only-do-not-override-1e3x2xa
  ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-1e3x2xa
  ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-inline .ant-menu-submenu,
:where(.css-dev-only-do-not-override-1e3x2xa).ant-menu-vertical
  .ant-menu-submenu {
  margin: 0 !important;
}

.Agenda_heading_Advance {
  color: #5f78d6 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-right: 20% !important;
}

.All_Heading_Advance_permission {
  color: #5f78d6 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.All_Heading_Advance_permission_View {
  color: #5a5a5a !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.ParticipantName {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Profile {
  border-radius: 50% !important;
}

.Names_advance_permission {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ant-layout {
  background: none !important;
}

.OverAll_padding {
  padding: 0px 26px !important;
}

.Cancel_Button {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Cancel_Button:hover,
.Cancel_Button:active,
.Cancel_Button:focus,
.Cancel_Button:focus-visible {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Save_Button {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.Save_Button:hover,
.Save_Button:active,
.Save_Button:focus,
.Save_Button:focus-visible {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.AdvancePermission_switches {
  margin-left: 20px !important;
}
.AdvancePermission_switches_View {
  margin-left: 11px !important;
}

.Heading_introductions {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.Heading_introductions_active {
  color: #6172d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.Heading_introductions:is(:hover, :active, :focus, :focus-visible) {
  color: #6172d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.Heading_introductions_Of_SubAgenda {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  display: inline-block !important;
  overflow: hidden;
  width: 280px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.UpperLineSubAgenda {
  height: 1px !important;
  color: #e1e1e1 !important;
  width: 100% !important;
}

.Plus_icon-Class {
  cursor: pointer !important;
  transition: ease-in-out 0.3s;
}

.AgendaPermissionModal .modal-dialog .modal-content {
  padding: 12px !important;
}

.AgendaPermissionModalHide {
  display: none !important;
}

.SidebarSection {
  display: flex !important;
  justify-content: space-between;
  height: auto;
  align-items: flex-start !important;
  padding-bottom: 0px !important;
}

.SidebarSectionSubAgenda {
  display: flex !important;
  justify-content: space-between;
  height: auto;
  align-items: flex-start !important;
  padding-bottom: 0px !important;
  gap: 10px !important;
}

.SidebarSectionSubAgendaActive {
  display: flex !important;
  justify-content: space-between;
  height: auto;
  align-items: flex-start !important;
  padding-bottom: 0px !important;
  gap: 10px !important;
  background: #f6f6f6 !important;
}
.SubAgendaBackground {
  /* background: #f6f6f6 !important; */
  display: flex !important;
}
.SubAgendaBox {
}

.SidebarSection_expanded {
  display: flex !important;
  justify-content: space-between;
  height: auto;
  align-items: flex-start !important;
  background: #f6f6f6 !important;
  padding-bottom: 0px !important;
}
.Background {
  padding-top: 1px !important;
  background: #f6f6f6 !important;
}

.LineUpper {
  width: 1px !important;
  height: 60vh !important;
  display: inline-block;
  background: #e1e1e1 !important;
}
.footerClassAgendaPermission {
  display: block !important;
  margin-right: 10px !important;
}

.expandmenuIntroduction {
  cursor: pointer !important;
}
.modal_header {
  display: block !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.modal_body {
  padding-top: 0 !important;
}

.Times_styles {
  color: #5a5a5a !important;
  font-size: 10px !important;
  font-weight: 600 !important;
}
.minus_sign {
  width: 10px;
  height: 1px;
  background: #5a5a5a;
  display: inline-block !important;
}

.Bottom_Line {
  margin-top: 8px !important;
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  display: flex !important;
}
.tranisitionStyle {
  transition: ease-in-out 0.1s;
}

.Scroller_Advanced_Permission_Modal {
  height: 38vh !important;
  overflow-x: hidden !important;
  margin-left: -5px !important;
}

.Scroller_Advanced_Permission_Modal::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_Advanced_Permission_Modal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
