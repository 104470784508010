.changePasswordTitle {
  color: #6172d6;
  margin: 10px 0 10px 7px;
  font-size: calc(38px - 50%) !important;
  font-weight: 600 !important;
}

.IconStyle.valid-feedback {
  display: block !important;
  width: 1% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: absolute !important;
  right: 39px !important;
  top: 20px !important;
  cursor: pointer;
}

.Revert {
  padding: 10px 40px;
  border-radius: 2px;
  margin-left: 18px;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 12px;
  border: 1px solid #e1e1e1;
}

.changePasswordButtons {
  margin-top: 80px;
}

.Revert:hover {
  padding: 10px 40px;
  border-radius: 2px;
  margin-left: 18px;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 12px;
  border: 1px solid #e1e1e1;
}

.Revert:active,
.Revert:focus,
.Revert:target,
.Revert:visited,
.Revert:focus-visible,
.Revert:focus-within {
  padding: 10px 40px;
  border-radius: 2px;
  margin-left: 18px;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 12px;
  border: 1px solid #e1e1e1 !important;
}

.Update {
  padding: 10px 40px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  background-color: #6172d6;
  color: #fff;
}

.Update:active,
.Update:hover,
.Update:focus,
.Update:target,
.Update:visited,
.Update:focus-visible,
.Update:focus-within {
  padding: 10px 40px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  background-color: #6172d6 !important;
  color: #fff !important;
}

.Update[disabled] {
  filter: grayscale(0.5) !important;
}

.passwordTextHandler {
  background-color: #e6eaf7;
  padding: 2px;
  color: #fff;
  border: #707070;
  border-radius: 4px !important;
}

.passwordCheckBox {
  background-color: #e6eaf7;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 12px !important ;
}

.password-must {
  color: #5a5a5a !important;
}

.modalUpdatemodal {
  margin: 0 80px !important;
  margin-top: 25px !important;
  max-height: 75% !important;
  height: 75% !important;
  min-height: 75% !important;
}

.modalUpdateText {
  text-align: center !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-top: 10px;
}

.modalUpdateted-2 {
  align-items: center !important;
  margin-top: 30px !important;
}

.modalCancelBtn {
  padding: 5px 30px;
  border: #171d2d 1px solid;
  color: #171d2d;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 30px !important;
}

.modalCancelBtn:hover {
  padding: 5px 30px;
  border: #171d2d 1px solid;
  color: #171d2d;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 30px !important;
}

.modalProceedBtn {
  padding: 10px 50px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #6172d6;
  margin-bottom: 30px !important;
}

.modalProceedBtn:hover {
  padding: 10px 50px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #6172d6;
  margin-bottom: 30px !important;
}

.modalHeaderUpdatePassword {
  display: none !important;
  margin-top: 20px;
}
