.Offline {
  color: #f16b6b;
  text-align: center;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.Messege {
  color: #5a5a5a;
  text-align: center;
  font-size: 12px !important;
  font-weight: 400 !important;
}
