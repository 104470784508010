.Shared_Document_Heading {
    font-size: 20px !important;
    color: #6172D6;
    font-weight: 600;
}

.InvitetoEdit_Heading {
    font-size: 16px !important;
    color: #6172D6;
    font-weight: 600;
}

.Line-height {
    line-height: 15px !important;
}

.Edit_options {
    font-size: 16px !important;
    color: #6172D6;
    font-weight: 600;
    margin-left: 3px !important;
}

.InvitetoEdit_Heading {
    font-size: 16px !important;
    color: #5a5a5a;
    font-weight: 600;
}

.Box_for_attachments {
    width: 492px !important;
    height: 39px !important;
    border: 1px solid #d4d4d4;
    background: #fff;
    border-radius: 4px !important;
    margin-left: 12px !important;
}

.File_name {
    font-size: 12px !important;
    color: #5a5a5a;
    font-weight: 600;
}

.Open_button {
    padding: 12px 30px !important;
    background: #6172D6;
    border: none;
    font-size: 12px !important;
    border-radius: 4px !important;
}

.Open_button:is(:hover, :active, :visited, :focus, :focus-visible) {
    padding: 12px 30px !important;
    background: #6172D6;
    border: none;
    font-size: 12px !important;
    border-radius: 4px !important;
}

.shareDocument_bodyClass {
    padding: 0 35px;
}

.shareDocument_footerClass {
    padding: 20px 35px;
}