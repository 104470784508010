.BillingDetailsHeading {
  font-weight: 600 !important;
  color: #6172d6 !important;
  font-size: 30px !important;
}

body[dir="rtl"] .BillingDetailsHeading {
  font-weight: 600 !important;
}

.NextbuttonBillingMethod {
  background: #6172d6 !important;
  color: #fff !important;
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .NextbuttonBillingMethod {
  font-weight: 600 !important;
}

.NextbuttonBillingMethod:is(:hover, :focus, :focus-visible, :active) {
  background: #6172d6 !important;
  color: #fff !important;
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.BackbuttonBillingMethod {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .BackbuttonBillingMethod {
  font-weight: 600 !important;
}

.BackbuttonBillingMethod:is(:hover, :focus, :focus-visible, :active) {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.sectionStyling {
  padding: 10px 40px !important;
}

.languageSelector {
  position: absolute;
  right: 0;
  z-index: 99999 !important;
  margin-top: 20px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: 0;
  z-index: 99999 !important;
  margin-top: 20px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

.signUp_goBack {
  color: #000;
  text-decoration: underline;
  cursor: pointer !important;
  margin: -10px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .signUp_goBack {
  font-weight: 400 !important;
}
