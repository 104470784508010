.CancelSubscriptionHeading {
  font-size: 30px !important;
  white-space: nowrap !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .CancelSubscriptionHeading {
  font-weight: 700 !important;
}

.CardCancelSubscription {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 10px 40px !important;
  border-radius: 4px !important;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.CardCancelSubscriptionLeft {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  padding: 22px 55px !important;
  border-radius: 4px !important;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.subheadingCancelsubscription {
  font-size: 16px !important;
  white-space: nowrap !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .subheadingCancelsubscription {
  font-weight: 600 !important;
}

.TableheadingTotal {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .TableheadingTotal {
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center !important;
}

.ChargesPerLicesensetotal {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .ChargesPerLicesensetotal {
  font-weight: 700 !important;
  display: flex !important;
  justify-content: center !important;
}

.ChargesPerLicesense {
  font-size: 20px !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .ChargesPerLicesense {
  font-weight: 700 !important;
  display: flex !important;
  justify-content: center !important;
}

.Tableheading {
  font-size: 16px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Tableheading {
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center !important;
}

.subcriptionkey_1 {
  background: #fff !important;
  margin: 0;
  padding: 13px;
  color: #6172d6;
  height: 50px;
  display: flex;
  font-weight: 600 !important;
  justify-content: center;
  font-size: 16px !important;
  text-align: center;
  font-weight: 500;
  border-top-left-radius: 4px;
  border: 1px solid #6172d6 !important;
  border-top-right-radius: 4px !important;
}

body[dir="rtl"] .subcriptionkey_1 {
  background: #fff !important;
  border-top-left-radius: 0px;
  color: #6172d6;
  border-top-right-radius: 4px;
  font-weight: 600 !important;
}

.subcriptionvalue_1 {
  border-bottom-left-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 600 !important;
  color: #5a5a5a;
  font-size: 16px !important;
  padding: 15px 0px;
  border: 1px solid #6172d6 !important;
  border-bottom-right-radius: 4px !important;
}

body[dir="rtl"] .subcriptionvalue_1 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4px;
  font-weight: 600 !important;
}

.subcriptionkey_2 {
  background: #6172d6 !important;
  margin: 0;
  padding: 13px;
  color: #fff;
  height: 50px;
  display: flex;
  font-weight: 600 !important;
  font-size: 16px !important;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  border-top-right-radius: 4px;
}

body[dir="rtl"] .subcriptionkey_2 {
  background: #6172d6 !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 4px !important;
  font-weight: 600 !important;
}

.subcriptionvalue_2 {
  border-bottom-right-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 600 !important;
  color: #5a5a5a;
  font-size: 16px !important;
  padding: 15px 0px;
}

body[dir="rtl"] .subcriptionvalue_2 {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 4px;
  font-weight: 600 !important;
}

.CancelNowClassstyles {
  padding: 12px 30px !important;
  width: 100% !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #f16b6b !important;
  background: #fff !important;
  color: #f16b6b !important;
  font-weight: 600 !important;
}

.CancelNowClassstyles:is(:active, :focus, :focus-visible, :hover) {
  padding: 12px 30px !important;
  width: 100% !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #f16b6b !important;
  background: #fff !important;
  color: #f16b6b !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .CancelNowClassstyles {
  font-weight: 600 !important;
}

.ConfirmSubsStyles {
  padding: 12px 30px !important;
  width: 100% !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: none !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.ConfirmSubsStyles:is(:active, :focus, :focus-visible, :hover) {
  padding: 12px 30px !important;
  width: 100% !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  border: none !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .ConfirmSubsStyles {
  font-weight: 600 !important;
}

.single-cell-table {
  border-collapse: separate;
  width: 100%;
  border: 1px solid #6172d6;

  border-spacing: 0;
  border-radius: 4px;
}

.single-cell-table th,
.single-cell-table td {
  border: 1px solid #6172d6;
  padding: 8px;
  text-align: center;
  border-radius: 4px !important ;
}
.single-cell-table th {
  background-color: #fff;
  color: #6172d6;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.single-cell-table td {
  background-color: #fff;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
