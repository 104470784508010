.View_attachment_heading {
  font-size: 20pt !important;
  color: #6172d6 !important;
  font-weight: 600;
}

body[dir="rtl"] .View_attachment_heading {
  font-weight: 600;
}

.ViewAttachment_paper {
  height: 82vh;
  width: 100%;
  padding: 40px 40px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Scroller_ViewAttachments {
  max-height: 50vh !important;
  overflow-x: hidden !important;
  min-height: 50vh;
}

.Scroller_ViewAttachments::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_ViewAttachments::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.notes-attachment-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center !important;
  white-space: nowrap !important;
}

body[dir="rtl"] .notes-attachment-text {
  font-weight: 400;
}

.viewAttachmentCloseBtn {
  padding: 12px 30px;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a;
  background-color: #fff !important;
}

body[dir="rtl"] .viewAttachmentCloseBtn {
  font-weight: 400;
}

.viewAttachmentCloseBtn:hover,
.viewAttachmentCloseBtn:active,
.viewAttachmentCloseBtn:focus,
.viewAttachmentCloseBtn:focus-visible {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  background-color: #fff !important;
}

.notes-attachment-icon {
  text-align: center;
}
