.todo-employee-container {
  display: inline-block;
  width: 50%;
}
.todo-employee-name-image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}
.todo-employee-name-card {
  color: #086ab2;
  font-size: 13px !important;
  text-overflow: ellipsis;
  text-align: left;
}

.todo-employee-label-card {
  font-size: 11px;
  color: #505050 !important;
}
.cardTextIconStyle {
  white-space: pre-wrap;
}
