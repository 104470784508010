.outerBoxForBilling {
  width: 100% !important;
  min-height: 55vh !important;
  padding: 30px 40px !important;
  height: auto !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.nameStyles {
  font-size: 10px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] span.billing-address {
  font-weight: 600 !important;
}

.stericClass {
  color: #f16b6b !important;
}

.errorMessage {
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 400 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 400 !important;
}
