.paymentbox {
  border: 1px solid #e1e1e1 !important;
}

.paymentform_heading {
  text-align: center;
  font-size: calc(36px - 20%);
  margin: 0 auto;
  color: #6172d6 !important;
  letter-spacing: 1px;
}

body[dir="rtl"] .paymentform_heading {
  font-weight: 600 !important;
}

.Payment_Accordion {
  width: 100%;
}

.paymentform_Container {
  min-height: 85vh !important;
  max-height: 85vh !important;
  overflow-y: auto;
}

.paymentform_input {
  border-radius: 1px !important;
}

.Payment_Paynow_btn {
  width: 100%;
  background-color: #076db7;
  border-radius: 1px;
}

.Paymentpage_Back_btn {
  font-size: 1.2rem;
  color: #000;
  text-decoration: underline;
}

.Paymentpage_Back_btn:hover {
  color: #000;
  text-decoration: underline;
}

.packagecard_priceBox_container {
  width: 210px;
  margin: 20px auto;
}

.packagecard_pricebox {
  padding: 10px 0;
  border-radius: 4px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
}

body[dir="rtl"] .packagecard_pricebox {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
  font-weight: 700 !important;
}

.packagecard_priceBox_container h4 {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: calc(38px - 20%);
}

body[dir="rtl"] .packagecard_priceBox_container h4 {
  font-weight: 700 !important;
}

.packagecard_priceBox_container p {
  font-size: 10px;
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
}

.PackagesButtons {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.packagecard_pricebox_Active {
  text-align: center;
  transition: 0.3s ease-in-out;
  user-select: none;
  color: #6172d6 !important;
  background: rgba(255, 255, 255, 1) !important;
  border-radius: 4px;
  border: 1px solid #6172d6;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.monthlyAnuallyspan {
  width: 100%;
  font-weight: 600 !important;
  text-align: center;
  transition: 0.3s ease-in-out;
  user-select: none;
  color: #fff;
  border-radius: 4px;
  background: rgba(97, 114, 214, 0.9) !important;
  text-transform: capitalize;
}

.packagecard_two {
  border: 2px solid #6172d6;
  visibility: visible;
  margin-bottom: 50px;
}

.packagecard_two h4 {
  color: #076db7;
  font-weight: 700;
}

.packagecard_two_visible {
  border: 2px solid #6172d6;
  visibility: hidden;
  margin-bottom: 50px;
}

.pricesuffle {
  height: 70px;
  text-align: center;
  margin: 40px 0 20px 0;
}

body[dir="rtl"] .pricesuffle {
  font-weight: 700 !important;
}

.pricesuffle span:nth-child(2) {
  opacity: 50% !important;
}

body[dir="rtl"] .pricesuffle span:nth-child(2) {
  font-weight: 700 !important;
}

body[dir="rtl"] .Arabicstyles {
  font-weight: 700 !important;
}

.paymentpricecardBox {
  border-right: 2px solid #ccc;
}

.paymentdetailbox {
  margin: 15px auto 10px auto;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px;
  color: #fff;
  border-radius: 4px;
}

body[dir="rtl"] .paymentdetailbox {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.Ordersummaryheading {
  font-size: calc(22px - 20%);
  color: #6172d6;
  text-align: center;
}

body[dir="rtl"] .Ordersummaryheading {
  font-weight: 500 !important;
}

.link_text {
  text-align: center;
  font-size: 11px;
  text-decoration: underline;
}

.link_text a {
  color: #000 !important;
}

body[dir="rtl"] .link_text a {
  font-weight: 500 !important;
}

.paymentoptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.paymentoptions div {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 13px;
}

.paymentoptions div figure {
  margin: 0;
}

.paymentoptions div:nth-child(1) figure img:nth-child(1) {
  width: 30px !important;
}

.paymentoptions div:nth-child(1) figure img:nth-child(3) {
  width: 20px !important;
}

.paymentoptions div:nth-child(2) figure img {
  width: 110px;
}

.paymentoptions div:nth-child(3) figure img {
  width: 110px;
}

.disount_per {
  background: #6172d6;
  color: #fff;
  border-radius: 4px;
}

.select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 112px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

body[dir="rtl"] .select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 78.5%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin:focus,
.select-language-signin:focus-within,
.select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 262px;
  top: 37px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 79%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.span-formontly {
  width: 100%;
  background-color: none;
  text-align: center;
  cursor: pointer;
  color: #6172d6 !important;
  opacity: 1;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-weight: 500 !important;
  height: 25px;
  margin-top: 3px;
  padding-top: 3px;
}

body[dir="rtl"] .span-formontly {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-right: 1px solid #e1e1e1;
  font-weight: 500 !important;
}

.span-forAnnually {
  width: 100%;
  background-color: none;
  text-align: center;
  cursor: pointer;
  color: #6172d6 !important;
  opacity: 1;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 500 !important;
  height: 25px;
  margin-top: 3px;
  padding-top: 3px;
}

body[dir="rtl"] .span-forAnnually {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-left: 1px solid #e1e1e1;
  font-weight: 500 !important;
}

.spanActive {
  background: #6172d6;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: 500 !important;
  height: 30px;
  padding-top: 5px;
  text-transform: uppercase;
}

body[dir="rtl"] .spanActive {
  font-weight: 500 !important;
}

.goBackChevRon {
  font-weight: 700 !important;
  color: #5a5a5a !important;
  font-size: calc(25px - 20%) !important;
}

body[dir="rtl"] .goBackChevRon {
  font-weight: 700 !important;
}

.goBackChevRon:hover {
  color: #5a5a5a !important;
}

.descriptionline {
  text-align: center;
}

.descriptiontext {
  background: #e1e1e1 !important;
  border-radius: 4px;
  max-width: 380px;
  margin: 40px auto;
  padding: 5px;
  color: #5a5a5a !important;
  font-size: calc(16px -20%);
  font-weight: 600 !important;
}

body[dir="rtl"] .descriptiontext {
  font-weight: 600 !important;
}

body[dir="rtl"] .Arabicstyles {
  font-weight: 600 !important;
  color: red !important;
}

.Arabic_Styles_Percent {
  font-weight: 500 !important;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.PaymentFormSubmitPayment {
  width: auto;
  margin: 30px 0;
  padding: 8px 30px !important;
  font-size: calc(16px -20%);
  font-weight: 600 !important;
  background-color: #6172d6 !important;
}
