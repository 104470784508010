.Groups-heading-size {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Groups-heading-size {
  font-weight: 600 !important;
}

.create-Group-btn {
  width: 219px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
}

body[dir="rtl"] .create-Group-btn {
  font-weight: 600 !important;
}

body[dir="rtl"] .create-Group-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.create-Group-btn:hover,
.create-Group-btn:active,
.create-Group-btn:focus,
.create-Group-btn:focus-visible {
  width: 219px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
}

.Archived-Group-btn {
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
  width: 219px !important;
  height: 31px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px !important;
  align-items: center;
}

body[dir="rtl"] .Archived-Group-btn {
  font-weight: 600 !important;
}

body[dir="rtl"] .Archived-Group-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Archived-Group-btn:hover,
.Archived-Group-btn:focus-visible,
.Archived-Group-btn:focus,
.Archived-Group-btn:active {
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
  width: 219px !important;
  height: 31px !important;
  justify-content: center !important;
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
}

.update-Group-btn {
  width: 100%;
  height: 36px;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.update-Group-btn:hover,
.update-Group-btn:focus-visible,
.update-Group-btn:focus,
.update-Group-btn:active {
  width: 100% !important;
  height: 36px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Active-group-style {
  background-color: #ebf8ef !important;
  width: 100%;
  overflow: hidden;
  margin: 0 !important;
  padding: 5px 0 !important;
}

.Groupscontainer {
  height: 91vh;
  width: 98% !important;
  margin: 0 auto !important;
}

.Groups {
  padding-bottom: 12px !important;
  border: 1px solid#81DB86 !important;
  border-radius: 4px !important;
  overflow: hidden;
  background: #fff !important;
  height: auto !important;
  width: 100% !important;
}

.card-heading {
  font-size: 20px !important;
  margin: 10px 0 !important;
  padding: 0 !important;
}

.card-heading::first-letter {
  text-transform: uppercase;
}

.Sub-Heading {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 8px !important;
}

.namesCards {
  font-size: 10px !important;
  --max-lines: 1;
  display: -webkit-box !important;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.roundnumber {
  background-color: #6172d6;
  height: 30px !important;
  width: 30px !important;
  border-radius: 20px !important;
  color: #ffffff;
  font-size: 12px !important;
  padding: 6px !important;
}

.cover {
  padding: 10px !important;
}

.hyperlink {
  color: blue !important;
}

.MainIcon {
  background-color: lightgray;
  border-radius: 30px !important;
  height: 50px !important;
  width: 50px !important;
  padding: 8px !important;
}

.Tagline {
  line-height: 1rem !important;
}

.cvgicon {
  fill: white;
}

.group-icon {
  display: flex;
  height: 50px;
  width: 50px;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 30px;
  justify-content: center;
  font-size: 20px !important;
  padding: 10px !important;
  align-items: center;
}

.Active-status {
  color: #81db86 !important;
}

.Groups_scroll_bar {
  height: 80vh !important;
  overflow-x: hidden !important;
}

.Groups_scroll_bar::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Groups_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px !important;
}

.PaginationStyle-Committee {
  background-color: #fff !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
  padding: 12px 40px !important;
  height: 54px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
  margin-top: 10px !important;
}

body[dir="rtl"] .PaginationStyle-Committee {
  width: auto !important;
  padding: 8px 10px !important;
}

.HR-line-Committee-group {
  color: #5a5a5a !important;
  margin: 1px !important;
}

.heading-modal-active-contfirmation {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

body[dir="rtl"] .heading-modal-active-contfirmation {
  font-weight: 600 !important;
}

.Confirm-activegroup-modal {
  font-size: 12px;
  background: #6172d6;
  color: #fff;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 12px 30px 12px 30px !important;
  border: none !important;
}

body[dir="rtl"] .Confirm-activegroup-modal {
  font-weight: 600 !important;
}

.Confirm-activegroup-modal:is(
    :hover,
    :active,
    :focus,
    :focus-visible,
    :visited
  ) {
  font-size: 12px;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 12px 30px 12px 30px !important;
  border: none !important;
}

.Cancel-activegroup-modal {
  padding: 12px 30px !important;
  font-size: 12px;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .Cancel-activegroup-modal {
  font-weight: 600 !important;
}

.Cancel-activegroup-modal:is(
    :hover,
    :active,
    :focus,
    :focus-visible,
    :visited
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.NoGroupsData {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 65vh !important;
}

.NoGroupsDataFoundText {
  font-weight: 600 !important;
  color: #bbbbbb !important;
  font-size: 20px !important;
}

.groups_box {
  max-height: 65vh !important;
  min-height: 65vh !important;
  overflow: hidden auto;
}

.groups_box::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.groups_box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px !important;
}
