.normal-videoParticipant-panel {
  position: fixed;
  height: 50vh;
  bottom: 10px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 99 !important;
  padding: 5px 20px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.normal-videoParticipant-Icons-state {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.normal-videoParticipant-tag-name {
  background: #5a5a5a;
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.video-normal-videoParticipant {
  width: 100%;
  object-fit: cover !important;
  max-height: 40vh;
  padding: 10px 0px;
}

.normal-videoParticipant-component {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  height: 96%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.normal-videoParticipant-ready-to-join {
  font-size: 30px;
  font-weight: 600;
  color: #5a5a5a;
}

.normal-videoParticipant-Join-Now-Btn {
  width: 118px;
  height: 39px;
  background-color: #55ce5c;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
}

.normal-videoParticipant-waiting-room-class {
  font-weight: 600;
  font-size: 30px;
  color: #6172d6;
  margin-bottom: 1px; /* Adjust the bottom margin to reduce spacing */
}

.normal-videoParticipant-organizer-allow-class {
  font-weight: 600;
  font-size: 20px;
  color: #5a5a5a;
  margin-top: 0; /* Remove any top margin */
}

.normal-videoParticipant-left-meeting-text {
  color: #6172d6 !important;
  font-size: 30px;
  font-weight: 600;
  text-align: center !important;
  margin-bottom: 0px;
}

.normal-videoParticipant-left-meeting-rejoin-text {
  color: #5a5a5a !important;
  font-size: 20px;
  font-weight: 600;
}

.normal-participant-title {
  color: #6172d6 !important;
  font-size: 20px;
  font-weight: 600;
}
