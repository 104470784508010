.ViewDetails_paper {
  height: 100% !important;
  width: 100% !important;
  padding: 30px 30px !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Title-file {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ViewDetials_Buttons {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.ViewDetials_Buttons:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
}

body[dir="rtl"] .ViewDetials_Buttons {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.ViewDetials_Buttons_active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}

body[dir="rtl"] .ViewDetials_Buttons_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Access_heading {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

.Vertical_Seperator {
  height: 100% !important;
  border: 1px solid #5a5a5a !important;
  width: 2px !important;
  display: block;
}

.Activity_Buttons_active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}
.Activity_Buttons_active:is(:active, :hover, :focus, :focus-visible, :target) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}

body[dir="rtl"] .Activity_Buttons_active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.profileClass {
  border-radius: 50% !important;
}

.profileClass:hover::after {
  content: attr(title);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1; /* Ensure tooltip appears on top of other elements */
}
.Activity_Buttons {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.Activity_Buttons:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.owned_heading {
  font-size: 12px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

.Manage_access_button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Manage_access_button:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Horizontal_seperator {
  width: 100% !important;
  display: flex !important;
  border: 0.5px solid #e1e1e1 !important;
  margin-top: 15px !important;
}

.File_Detials_Heading {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 600;
}
.DetialsHeading {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 700;
}

.DetialsHeading_subHeading {
  color: #a5a5a5 !important;
  font-weight: 600;
  font-size: 12px !important;
}

.Scroller {
  height: 47vh !important;
  overflow-x: hidden !important;
}

.Scroller::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Today_heading {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin: 10px 0;
}

.activity_heading {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

.date_heading {
  font-size: 10px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

.Filename_heading {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Removed_particpant_styles {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
  text-decoration: line-through !important;
}
.icon_outer_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip-onHover {
  position: absolute;
  background-color: #5a5a5a;
  width: 100%;
  top: -20px;
}
