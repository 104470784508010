.Stats_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #5a5a5a;
}
.ProgressBarContains {
  position: relative;
  padding: 0 30px;
  justify-content: center;
}
.ProgressBarContains::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #e1e1e1;
  left: 0;
  border-radius: 10px;
}
body[dir="rtl"] .ProgressBarContains::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: #e1e1e1;
  right: 0;
  border-radius: 10px;
}
/* Upcoming Meeting this week */
.dashboard_progress_upcomingmeeting {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.dashboard_progress_upcomingmeeting div {
  background-color: #6172d6 !important;
  border-radius: 25px !important;
  text-align: right;
  padding: 0 10px;
}
body[dir="rtl"] .dashboard_progress_upcomingmeeting div {
  background-color: #6172d6 !important;
  border-radius: 25px !important;
  text-align: left !important;
  padding: 0 10px;
}
.dashboard_progress_upcomingmeeting_R {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.dashboard_progress_upcomingmeeting_R div {
  background-color: transparent !important;
  border-radius: 25px !important;
  text-align: right;
  margin-left: 7px;
  padding-right: 30px;
  width: auto !important;
}
body[dir="rtl"] .dashboard_progress_upcomingmeeting_R div {
  background-color: transparent !important;
  border-radius: 25px !important;
  text-align: left;
  margin-left: 7px;
  padding-right: 30px;
  width: auto !important;
}
/* Upcoming Task this week */

.dashboard_progress_upcomingTask {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.dashboard_progress_upcomingTask div {
  background-color: #ffc300 !important;
  border-radius: 25px !important;
  text-align: right;
  padding: 0 10px;
  
}
body[dir="rtl"] .dashboard_progress_upcomingTask div {
  background-color: #ffc300 !important;
  border-radius: 25px !important;
  text-align: left !important;
  padding: 0 10px;
}
.dashboard_progress_upcomingTask_R {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.dashboard_progress_upcomingTask_R div {
  background-color: transparent !important;
  border-radius: 25px !important;
  text-align: right;
  margin-left: 7px;
  padding-right: 30px;
  width: auto !important;
}
/* Upcoming Pending Approval this week */

.dashboard_progress_upcomingApproval {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.dashboard_progress_upcomingApproval div {
  background-color: #55ce5c !important;
  border-radius: 25px !important;
  text-align: right !important;
  padding: 0 10px;
}
body[dir="rtl"] .dashboard_progress_upcomingApproval div {
  background-color: #55ce5c !important;
  border-radius: 25px !important;
  text-align: left !important;
  padding: 0 10px;
}
.dashboard_progress_upcomingApproval_R {
  height: 25px !important;
  border-radius: 25px !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.dashboard_progress_upcomingApproval_R div {
  background-color: transparent !important;
  border-radius: 25px !important;
  text-align: right;
  margin-left: 7px;
  padding-right: 30px;
  width: auto !important;
}

body[dir="rtl"] .dashboard_progress_upcomingApproval_R div {
  background-color: transparent !important;
  border-radius: 25px !important;
  text-align: left !important;
  margin-left: 7px;
  padding-right: 30px;
  width: auto !important;
}
