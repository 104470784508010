.progressWrapper {
  margin-top: 15px;
  width: 100%;
  height: 89px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 25px;
  margin-bottom: 25px;
}
span.line {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  display: block;
  height: 70px;
  width: 3px;
  margin-top: -15px;
}

.progress-value-wrapper-signed {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-pending {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-decline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-signed span {
  color: #55ce5c;
}

.progress-value-wrapper-pending span {
  color: #d8a709;
}

.progress-value-wrapper-decline span {
  color: #f16b6b;
}

span.numeric-value {
  /* font-family: "MontserratSemiBold"; */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
}

span.value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

p.pendingStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase !important;
  color: #d8a709;
}

p.signedStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #55ce5c;
}

p.declineStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #f16b6b;
}

p.draftStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #a2a2a2;
}

.FilterResetBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
.ReviewMinutes_emptyTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  color: #5a5a5a;
  margin: 10px 0;
}

.ReviewMinutes_emptyTitle_tag {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5A5A5A;
  line-height: 15px;
}



