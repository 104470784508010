.Poll_Container {
  width: 98% !important;
  height: 90vh !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

.Poll_Container__heading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6;
  margin: 0 !important;
  padding: 0 !important;
}

.new_Poll_Button {
  height: 31px;
  padding: 7px 36px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
}
body[dir="rtl"] .new_Poll_Button {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.polling_searchinput {
  display: inline-block;
  position: absolute !important;
  right: 0 !important;
  width: auto;
  top: 5px;
  margin-right: 25px;
}

.SearhBar_Polls {
  width: 100% !important;
  height: auto !important;
  background: #ffffff !important;
  position: absolute;
  right: 0;
  z-index: 1 !important;
  top: 39px !important;
  border: 1px solid #e1e1e1 !important;
  padding: 20px 10px;
}

.Cross_Icon_Styling {
  cursor: pointer;
}

.Search_Button_polls_SearchModal {
  padding: 12px 30px !important;
  background: #6172d6;
  color: #fff;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: none !important;
}

.Search_Button_polls_SearchModal:hover,
.Search_Button_polls_SearchModal:focus-visible,
.Search_Button_polls_SearchModal:focus,
.Search_Button_polls_SearchModal:active {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: none !important;
}

.Reset_Button_polls_SearchModal {
  padding: 12px 30px !important;
  background: #fff;
  color: #5a5a5a;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
}

.Reset_Button_polls_SearchModal:hover,
.Reset_Button_polls_SearchModal:active,
.Reset_Button_polls_SearchModal:focus,
.Reset_Button_polls_SearchModal:focus-visible {
  padding: 12px 30px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
}

.Table_title {
  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: 600;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.GlobalSearchFieldICon {
  cursor: pointer !important;
}

body[dir="rtl"] .polling_searchinput {
  position: absolute !important;
  right: 88% !important;
  cursor: pointer !important;
}

.Vote_button_poll {
  padding: 6px 12px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #81db86 !important;
  color: #fff !important;
  font-weight: 600;
  border: none !important;
}

.Vote_button_poll:hover,
.Vote_button_poll:active,
.Vote_button_poll:focus,
.Vote_button_poll:focus-visible {
  padding: 6px 12px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #81db86 !important;
  color: #fff !important;
  font-weight: 600;
  border: none !important;
}

.Voted_after {
  padding: 6px 12px !important;
  background: #ecefff !important;
  font-size: 12px !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Voted_after:focus-visible,
.Voted_after:focus,
.Voted_after:active,
.Voted_after:hover {
  padding: 6px 12px !important;
  background: #ecefff !important;
  font-size: 12px !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Search_Bar_icon_class {
  cursor: pointer;
}

.voteBtn {
  background-color: #81db86 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 80px !important;
  font-weight: 600;
}
.voteBtn:is(:hover, :active, :focus, :focus-visible, :visited) {
  background-color: #81db86 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 80px !important;
  font-weight: 600;
}

.votedBtn {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 30px !important;
  font-weight: 600;
}
.votedBtn:is(:hover, :active, :focus, :focus-visible, :visited) {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 80px !important;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  height: 30px !important;
  font-weight: 600;
}

.Not-voted {
  font-size: 12px !important;
  margin-top: 12px !important;
}

.Background-nonvoted-Button {
  max-width: 109px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  height: 42px !important;
  color: #6172d6 !important;
  background-color: #ecefff !important;
}

.PaginationStyle-polling {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-polling:is(:focus-visible) {
  outline: none !important;
}

body[dir="rtl"] .PaginationStyle-polling {
  width: auto !important;
  padding: 12px 10px !important;
}

.Ellipses_Class {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  cursor: pointer !important;
  color: #2197d7 !important;
  width: 100%;
  display: inline-block;
  font-weight: 600 !important;
}

.PaginationStyle-Meeting {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-Meeting:is(:focus-visible) {
  outline: none !important;
}

body[dir="rtl"] .PaginationStyle-Meeting {
  width: auto !important;
  padding: 12px 10px !important;
}

.PaginationStyle-Committee {
  background-color: #fff !important;
  width: auto !important;
  display: flex !important;
  justify-content: center !important;
  padding: 12px 40px !important;
  height: 54px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .PaginationStyle-Committee {
  width: auto !important;
  padding: 8px 10px !important;
  margin-top: 30px !important;
}

.Poll_emptyState {
  min-height: 75vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px !important;
  flex-direction: column !important;
  margin-bottom: 10px;
  width: 100% !important;
  border-radius: 8px !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}

.No_Poll_Heading {
  margin-top: 50px !important;
  font-weight: 600;
  font-size: 30px !important;
  color: #5a5a5a !important;
}

.No_Poll_Text {
  font-weight: 600;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.FilterResetBtn {
  padding: 2px 14px !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 10px !important;
  border: 1px solid #5a5a5a !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 2px 14px !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 10px !important;
  border: 1px solid #5a5a5a !important;
}

.ResetOkBtn {
  padding: 3px 14px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 3px 14px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ViewVotesButtonStyles {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 95px !important;
  font-weight: 600 !important;
}

.ViewVotesButtonStyles:is(:hover, :focus, :focus-visible, :active) {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 95px !important;
  font-weight: 600 !important;
}
