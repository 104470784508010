.Heading_For_Updated_successfully {
  font-size: 20pt;
  font-weight: 600 !important;
  color: #5a5a5a;
}

body[dir="rtl"] .Heading_For_Updated_successfully {
  font-weight: 600 !important;
}

.Heading_Success_updateResolution {
  font-size: 20pt;
  font-weight: 600 !important;
  color: #5a5a5a;
}

body[dir="rtl"] .Heading_Success_updateResolution {
  font-weight: 600 !important;
}

.Tik_arrow {
  position: absolute;
  top: 40px;
}
