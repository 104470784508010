.videoIncoming-max-call {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 380px;
  width: 350px;
  z-index: 9;
  padding-top: 25px;
  border-radius: 10px;
  background: #707070 !important;
  backdrop-filter: blur(15px);
}

.outgoing-call-text-max-call {
  font-size: 19px !important;
  color: #232323 !important;
  font-weight: 600;
  margin-top: 20px;
}

.calling-text-max-call {
  font-size: 14px !important;
  color: #232323 !important;
  font-weight: 600;
}

.avatar-column-max-call {
  text-align: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.someone-calling-title-max-call {
  text-align: center;
}

.calling-title-max-call {
  text-align: center;
}

.button-img {
  background-color: transparent !important;
  border: none !important;
}

.button-img-max-call {
  background-color: transparent !important;
  border: none !important;
}

.incoming-action {
  display: grid !important;
}

span.incoming-text {
  text-align: center;
  color: #232323;
  font-size: 13px;
  font-weight: 500;
}

.videoIncoming-active-call {
  position: fixed;
  bottom: 15px;
  right: 71px;
  height: 190px;
  width: 260px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 9999999999 !important;
  padding-top: 25px;
  border-radius: 4px;
}

.avatar-column-active-call {
  text-align: center;
}

.avatar-column-active-call img {
  width: 90px;
}

.outgoing-call-text-active-call {
  font-size: 15px !important;
  color: #232323 !important;
  font-weight: 600;
  margin: 0;
}

.button-active-img {
  background-color: transparent !important;
  border: none !important;
}

button.button-active-img.btn.btn-primary img {
  width: 40px;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex;
}

.incoming-active-text {
  text-align: center !important;
  color: #232323;
  font-size: 11px;
  font-weight: 500;
}

button.button-active-img.btn.btn-primary span {
  text-align: center !important;
  display: inline-block;
}

body[dir="rtl"] .videoIncoming-active-call {
  position: fixed;
  bottom: 15px;
  right: auto;
  left: 71px;
  height: 190px;
  width: 260px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 999 !important;
  padding-top: 25px;
  border-radius: 4px;
}

.video-incoming-icon {
  padding: 10px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative !important;
  margin-left: 5px;
  background-size: cover;
  background-repeat: no-repeat;
}
