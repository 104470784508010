.Scedule_newMeeting_Heading {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #5f78d6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  display: block;
}

.Scedule_meeting_paper {
  width: 100% !important;
  height: auto !important;
  min-height: 78vh !important;
  padding: 10px 40px !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Schedule_meetings_options,
.Schedule_meetings_options:disabled {
  font-size: 16px !important;
  border: 1px solid #808080;
  border-radius: 4px !important;
  font-weight: 600 !important;
  background: #fff !important;
  color: #545454;
}

.Schedule_meetings_options_active,
.Schedule_meetings_options_active:disabled {
  font-size: 16px !important;
  border: 1px solid #5f78d6 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.Schedule_meetings_options:hover,
.Schedule_meetings_options:active,
.Schedule_meetings_options:focus,
.Schedule_meetings_options:focus-visible {
  font-size: 16px !important;
  border: 1px solid #5f78d6 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  font-weight: 600 !important;
  color: #fff !important;
}
.LeavemeetingBtn {
  padding: 12px 30px !important;
  width: 100%;
  background: #f16b6b !important;
  color: #fff !important;
  border: none !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}