.box {
  height: 100%;
}
.DashboardTask-thead tr th {
  font-weight: 600;
  font-size: 12px;
  color: #6127d6;
}
.DashboardTask-body {
  padding-right: 10px;
}
.DashboardTask-row {
  height: 39px;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
  border-radius: 4px;
}
.DashboardTask-body table {
  border-spacing: 10px !important;
  table-layout: inherit !important;
  border-collapse: separate;
}
.DashboardTask-body table tr {
  border: 1px solid #e1e1e1;
}
.newDashboardTask .ant-table table {
  border-spacing: 0 10px !important;
}
.allow-limit-modal-p {
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 600;
  margin-top: 10px;
}
.allowModalIcon {
  color: #f16b6b;
  border: none !important;
  border-radius: 0px !important;
  margin-top: 25px !important;
}
.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-wrapper.rmdp-shadow {
  width: 100% !important;
}

.rmdp-top-class {
  justify-content: center;
}

.rmdp-calendar {
  width: 100%;
}

.rmdp-day-picker {
  display: block !important;
}

.rmdp-week {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.rmdp-week .rmdp-week-day {
  font-size: 9px !important;
}
.Dashboard-Calendar .rmdp-header-values {
  font-size: calc(26px - 20%) !important;
  font-weight: 600;
}

body[dir="rtl"] .Dashboard-Calendar .rmdp-header-values {
  font-weight: 600;
}

span.rmdp-arrow-container.rmdp-left {
  margin-top: -6px !important;
}

span.rmdp-arrow-container.rmdp-right {
  margin-top: -6px !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #e1e1e1 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.rmdp-day.rmdp-deactive.rmdp-selected span {
  background: #e9e9e9;
}

.rmdp-day.rmdp-today span {
  background: none;
  color: #000;
}
.rmdp-arrow {
  border: none !important;
  padding: 0px !important;
}

.rmdp-left .rmdp-arrow {
  border-top: 8px solid transparent !important;
  border-left: 14px solid #6172d6 !important;
  border-bottom: 8px solid transparent !important;
  z-index: 1;
}

.rmdp-right .rmdp-arrow {
  border-top: 8px solid transparent !important;
  border-left: 14px solid #6172d6 !important;
  border-bottom: 8px solid transparent !important;
  z-index: 1;
}

.rmdp-right i {
  transform: rotate(0deg) !important;
}

.rmdp-left i {
  transform: rotate(180deg) !important;
}

.rmdp-arrow-container:hover {
  background-color: #fff !important;
  box-shadow: none !important;
}
