.Result_Heading_resolution {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Result_Heading_resolution {
  font-weight: 600;
}

.Result_page_paper {
  height: auto;
  width: 100%;
  padding: 55px 80px !important;
  margin: 0 0 30px 0;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.results_paper_heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
  word-wrap: break-word;
  max-height: 120px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  overflow-x: hidden;
  word-break: break-word;
  overflow-y: auto;
}

.results_paper_heading::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.results_paper_heading::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

body[dir="rtl"] .results_paper_heading {
  font-weight: 600;
}

.Total_voters {
  font-size: 16pt !important;
  font-weight: 600;
  color: #5a5a5a !important;
}

body[dir="rtl"] .Total_voters {
  font-weight: 600;
}

.No_of_Votes {
  color: #6172d6 !important;
  margin-left: 3px !important;
}

body[dir="rtl"] .No_of_Votes {
  font-weight: 600;
}

.pending_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #ddb227;
  color: #ddb227 !important;
  border-radius: 4px !important;
}
.approved_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #6de595;
  border-radius: 4px !important;
  color: #6de595 !important;
}
.notApproved_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #f16b6b !important;
  color: #f16b6b !important;
  border-radius: 4px !important;
}
.Tie_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #5a5a5a;
  border-radius: 4px !important;
  color: #5a5a5a !important;
}

.status_vote_resolution {
  font-size: 30px !important;
  font-weight: 600;
}

body[dir="rtl"] .status_vote_resolution {
  font-weight: 600;
}

.voters_heading_result {
  font-size: 16pt !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .voters_heading_result {
  font-weight: 600;
}

.name_participant {
  font-size: 12pt !important;
  font-weight: 600;
  color: #5a5a5a !important;
}

.email_participant {
  font-size: 10pt !important;
  color: #6172d6 !important;
  font-weight: 600;
  width: 100% !important;
  text-decoration: underline;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Lineheight {
  line-height: 15px !important;
  margin-top: 9px !important;
  margin-left: 10px !important;
}

.Result-Screen_scroller {
  height: 180px !important;
  overflow-x: hidden !important;
}

.Result-Screen_scroller::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Result-Screen_scroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Close_resolution_Btn_Resultresolution {
  padding: 12px 80px !important;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Close_resolution_Btn_Resultresolution {
  font-weight: 600;
}

.Close_resolution_Btn_Resultresolution:hover,
.Close_resolution_Btn_Resultresolution:focus,
.Close_resolution_Btn_Resultresolution:active,
.Close_resolution_Btn_Resultresolution:focus-visible {
  padding: 12px 80px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

.Close_resolution_Btn_Resultresolution:disabled {
  padding: 12px 80px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
  opacity: 50%;
}

.Close_Btn_Resultresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Close_Btn_Resultresolution {
  font-weight: 600;
}

.Close_Btn_Resultresolution:hover,
.Close_Btn_Resultresolution:focus,
.Close_Btn_Resultresolution:active,
.Close_Btn_Resultresolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.line_Resultesolution {
  width: 1px;
  height: 100%;
  background-color: #5a5a5a !important;
}

.voting_method_heading {
  color: #5a5a5a !important;
  font-weight: 600;
  font-size: 16px !important;
}

.voting_methong_value {
  color: #6172d6 !important;
  font-weight: 600;
  font-size: 16px !important;
  margin-left: 3px !important;
}
