.Previous_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Previous_Button:is(:active, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.RedCrossClass {
  position: absolute !important;
  right: -8px;
  top: -9px;
  cursor: pointer !important;
}

.Name {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
}

.Uploaded_heading {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5f78d6 !important;
}

.Line_heigh {
  margin-top: 7px !important;
  line-height: 15px !important;
}

.Profile_minutes {
  border-radius: 50% !important;
}

.Date_Minutes_And_time {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5f78d6 !important;
}

.Box_Minutes {
  width: 100% !important;
  height: auto !important;
  min-height: 92px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  position: relative !important;
}

.ScrollerMinutes {
  height: 25vh !important;
  overflow-x: hidden !important;
}

.ScrollerMinutes::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.ScrollerMinutes::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Sizing_Saved_Minutes {
  padding: 0px 10px !important;
}

.Title_File {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5a5a5a !important;
  display: flex !important;
  gap: 10px !important;
}

.Add-note-QuillRow {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  z-index: 0 !important;
}

.modal-Note-Title-Input :focus {
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .modal-Note-Title-Input {
  font-weight: 500 !important;
}

.quill-height-addNote {
  border-top: 1px solid lightgrey;
  height: 18vh !important;
  border-radius: 3px !important;
  position: relative;
}

.quill-height-addNote .ql-toolbar.ql-snow {
  color: #a2a2a2 !important;
}

.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  margin-left: 3px !important;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600 !important;
}

.Button_General {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Button_General:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Previous_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Previous_Button:is(:active, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.filesStyles {
  width: 100% !important;
  display: flex !important;
  gap: 35px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}

.Outer_Border-Line {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  margin-top: 8px !important;
}

.Leftpolygon {
  background: none;
  border: none;
  display: flex !important;
  align-items: center !important;
}

body[dir="rtl"] .Leftpolygon {
  transform: rotate(180deg) !important;
}

.Leftpolygon:hover,
.Leftpolygon:active,
.Leftpolygon:focus,
.Leftpolygon:focus-visible {
  background: none !important;
  border: none !important;
}

.Show_more {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.AgendaTitleClass {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #6172d6 !important;
}

.Show_more_Styles {
  font-weight: 600 !important;
  font-size: 10px !important;
}

.errorNotesMessage {
  height: 0px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden {
  font-weight: 600 !important;
}

.errorNotesMessage_description {
  margin: 7% 4px 4px 0px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage_description {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden_description {
  visibility: hidden;
  margin: 7% 4px 4px 4px;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden_description {
  font-weight: 600 !important;
}

.header-of-collapse-material {
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 100% !important;
  color: #2197d7;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

body[dir="rtl"] .header-of-collapse-material {
  font-weight: 600 !important;
}

.PlusIcon {
  margin-right: 30px !important;
  color: #6172d6 !important;
}

.MinusIcon {
  margin-right: 40px !important;
  color: #6172d6 !important;
  /* margin-bottom: 16px !important; */
}

body[dir="rtl"] .MinusIcon {
  margin-right: 60px !important;
}
.viewAgendaWiseAttachments {
  max-height: 110px;
  min-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.notes_accordion {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.notes_accordion .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

