.overlay {
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  backdrop-filter: blur(7px);
  left: 0;
  z-index: 99999;
}
.overlay-content {
  margin: 0 !important;
  padding: 20px !important;
  font-weight: bold;
  position: relative;
  z-index: 99999;
  max-width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.overlay-content h3 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}
.overlay-content img {
  max-width: 500px;
  width: 350px !important;
}
/* change color blue to this */
.overlay-content p {
  margin-top: 20px;
  color: #5f78d6 !important;
  font-size: 1rem;
  letter-spacing: 0.08rem;
  font-weight: 800;
}
