p.pendingStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase !important;
  color: #d8a709;
}

p.signedStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #55ce5c;
}

p.declineStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #f16b6b;
}

p.draftStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #a2a2a2;
}

.signatories_vale {
  color: #6172d6;
  font-weight: 600;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
}
.date_vale {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 600;
}
.status_draft_signatoriesList {
  height: 1px;
  background-color: #000;
  width: 10px;
  display: inline-block;
  text-align: center;
}
.Sort_icon_descend {
  user-select: none;
}
.Sort_icon_ascend {
  user-select: none;
}
.fileName_Approvals {
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ApprovalSend_emptyContainer {
  display: flex;
  justify-content: center;
  max-height: 55vh;
  height: 55vh;
  align-items: center;
  flex-direction: column;
}
.emptyState_title {
  font-size: 30px;
  font-weight: 600;
  color: #5a5a5a;
  text-align: center;
  margin-top: 25px;
}
.emptyState_tagline {
  font-size: 12px;
  font-weight: 400;
  color: #5a5a5a;
  text-align: center;
}
.FilterResetBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
.ResetOkBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}