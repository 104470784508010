.selected_package_details {
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
}

.selected_package_Container {
  background-color: #fff;
  margin: 0;
}

.PayInvoiceButton {
  width: 100%;
  border-radius: 4px;
  margin: 0;
  padding: 0 auto;
  background: #6172d6;
  border: none;
  font-weight: 500 !important;
  font-size: 12px !important;
}

body[dir="rtl"] .PayInvoiceButton {
  font-weight: 500 !important;
}
.PayInvoiceButton:hover,
.PayInvoiceButton:active,
.PayInvoiceButton:focus,
.PayInvoiceButton:focus-visible,
.PayInvoiceButton:visited,
.PayInvoiceButton:target {
  width: 100% !important;
  border-radius: 4px !important;
  margin: 0 !important;
  padding: 0 auto !important;
  background: #6172d6 !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.viewInvocieButton {
  background: none;
  border: 1px solid #e1e1e1;
  color: #5a5a5a !important;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.PayInvoiceButton_disabled {
  filter: grayscale(0.5) invert(1) !important;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  padding: 5px 10px;
  font-weight: 500 !important;
  background: none;
  text-align: center;
  font-size: 12px !important;
}
.viewInvocieButton_disabled {
  background: none;
  border: 1px solid #e1e1e1;
  color: #5a5a5a !important;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  padding: 5px 10px;
  font-weight: 500 !important;
  font-size: 12px !important;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

body[dir="rtl"] .viewInvocieButton {
  font-weight: 500 !important;
}
.viewInvocieButton:hover,
.viewInvocieButton:active,
.viewInvocieButton:focus,
.viewInvocieButton:focus-visible,
.viewInvocieButton:visited,
.viewInvocieButton:target {
  background: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  width: 100% !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.5s !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px 10px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.pdfIconstyle {
  width: 25px;
  height: 25px;
}

.DownloadInvoiceButton {
  background: none;
  border: 1px solid #e1e1e1;
  color: #5a5a5a !important;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  justify-content: space-between;
  font-weight: 500 !important;
  font-size: 12px !important;
}

body[dir="rtl"] .DownloadInvoiceButton {
  font-weight: 500 !important;
}

.DownloadInvoiceButton:hover,
.DownloadInvoiceButton:active,
.DownloadInvoiceButton:focus,
.DownloadInvoiceButton:focus-visible,
.DownloadInvoiceButton:visited,
.DownloadInvoiceButton:target {
  background: none !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  width: 100% !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.2s !important;
  display: flex !important;
  justify-content: space-between !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  /* padding: 12px 30px; */
}

.PayOutstanding_Heading {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}
body[dir="rtl"] .PayOutstanding_Heading {
  font-weight: 600 !important;
}
.PackageHeadingClassName {
  font-weight: 700 !important;
  margin: 10px 0 30px 0;
  font-size: 16px;
}
body[dir="rtl"] .PackageHeadingClassName {
  font-weight: 700 !important;
}
.selected_package_details_p1 {
  font-weight: 500 !important;
  font-size: 20px;
  color: #5a5a5a !important;
}
body[dir="rtl"] .selected_package_details_p1 {
  font-weight: 500 !important;
}
.selected_package_details_p2 {
  font-weight: 400 !important;
  font-size: 15px !important;
}

body[dir="rtl"] .selected_package_details_p2 {
  font-weight: 400 !important;
}

.OutStandingHeading {
  font-size: 25px !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .OutStandingHeading {
  font-weight: 700 !important;
}

.PayoutStandingCard {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Assistance {
  background: #f2f2f2 !important;
  width: 100% !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  margin-top: 70px !important;
  align-items: center;
  height: auto !important;
  max-height: 261px !important;
}

.CallAssistantSubHeading {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .CallAssistantSubHeading {
  font-weight: 700 !important;
}

.BussniessmailStyles {
  font-size: 16px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
  word-break: break-word; /* Ensure long text breaks properly */
  overflow-wrap: break-word; /* Ensure long text wraps properly */
}

body[dir="rtl"] .BussniessmailStyles {
  font-weight: 700 !important;
}

@media (max-width: 1024px) {
  .Assistance {
    padding: 10px 20px !important;
    margin-top: 20px !important;
    max-height: 331px !important;
  }

  .CallAssistantSubHeading {
    font-size: 14px !important;
  }

  .BussniessmailStyles {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .Assistance {
    padding: 10px 15px !important;
    margin-top: 20px !important;
    max-height: 351px !important;
  }

  .CallAssistantSubHeading {
    font-size: 12px !important;
  }

  .BussniessmailStyles {
    font-size: 12px !important;
  }
}

@media (max-width: 480px) {
  .Assistance {
    padding: 5px 10px !important;
    margin-top: 10px !important;
    max-height: 371px !important;
  }

  .CallAssistantSubHeading {
    font-size: 10px !important;
  }

  .BussniessmailStyles {
    font-size: 10px !important;
  }
}
