.AddUserheading {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .AddUserheading {
  font-weight: 600 !important;
}

.PageAlignment {
  padding: 10px 40px !important;
}

.NameCreateAddtional {
  font-style: normal;
  color: #6172d6 !important;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}

body[dir="rtl"] .NameCreateAddtional {
  font-weight: 600 !important;
}

.Steric {
  color: #f16b6b !important;
}

.NameClass {
  font-size: 16px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .NameClass {
  font-weight: 600 !important;
}

.AdminAlsoClass {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  margin-top: 3px !important;
}

body[dir="rtl"] .AdminAlsoClass {
  font-weight: 500 !important;
}

.AddUserCancelButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.AddUserCancelButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .AddUserCancelButton {
  font-weight: 600 !important;
}

.AddUserCreateButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.AddUserCreateButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

body[dir="rtl"] .AddUserCreateButton {
  font-weight: 600 !important;
}

.checkEmailSpinner {
  position: absolute;
  right: -30px;
  width: 30px;
  height: 30px;
  top: 25px !important;
  bottom: 0px;
}

body[dir="rtl"] .checkEmailSpinner {
  position: absolute;
  right: 100%;
  width: 29px;
  top: 25px !important;
  height: 29px;
  top: 30px;
}

.errorMessage {
  font-weight: 600;
  font-size: 10px;
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 400 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 15px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 400 !important;
}

.isEmailUnique {
  position: absolute;
  right: -30px;
  top: 25px;
  width: 30px;
  height: 30px;
  bottom: 0px;
  color: green;
}

body[dir="rtl"] .isEmailUnique {
  position: absolute;
  right: 100%;
  width: 30px;
  bottom: 30px;
  height: 30px;
  color: green;
}
