.disabled-row {
  background-color: red;
  pointer-events: none;
}

.rowColor {
  background-color: #fff !important;
}

.ant-table-thead thead tr th {
  background-color: #fff !important;
}

.ant-table-row {
  margin: 30px 0 0 0 !important;
  background: #fff !important;
}

.ant-table-tbody {
  margin: 20px 0 20px 0 !important;
}

.ant-table-thead > tr > th {
  background-color: #fff !important;
  border-bottom: 2px solid #e1e1e1 !important;
}

.ant-table-thead tr {
  border-bottom: 3px solid #ccc;
}

.ant-table-cell::before {
  height: 0px !important;
}

.nodata-table-icon {
  font-size: 5rem;
  color: #2097d6;
}

.NoData-Button {
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.title {
  color: #2097d6;
}
