.ForgotPassword_EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px !important;
  padding: 0 30px;
}

.ForgotPassword_heading1 {
  font-size: calc(35px - 20%) !important;
  user-select: none;
  color: #6172d6 !important;
  font-weight: 600 !important;
  line-height: 22px;
}

body[dir="rtl"] .ForgotPassword_heading1 {
  font-weight: 600 !important;
  line-height: 32px !important;
}

.Forgot_Password_Email_Field {
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
}

body[dir="rtl"] .Forgot_Password_Email_Field {
  font-weight: 500 !important;
}

.Forgot_Password_Email_Field input::-webkit-autofill {
  background-color: #fff !important;
}

.Forgot_Password_Email_Field input:focus:-webkit-autofill {
  background-color: #fff !important;
}

.Forgot_Password_Email_Field::placeholder {
  background-color: #fff !important;
}

.Forgot_Password_Email_Field:focus-visible {
  border-color: lightgray !important;
}

.Forgot_PasswordNext_button_EmailVerify {
  width: 90%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: calc(18px - 20%) !important;
}

.Forgot_PasswordNext_button_EmailVerify:focus-visible {
  width: 90%;
  border-radius: 4px;
  font-size: calc(18px - 20%) !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  box-shadow: none !important;
}

body[dir="rtl"] .Forgot_PasswordNext_button_EmailVerify {
  font-weight: 600 !important;
}

.Forgot_PasswordNext_button_EmailVerify:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6;
}

.Forgot_Password_Auth_Icon {
  position: absolute;
  right: -115px;
  bottom: -135px;
  transform: rotate(-15deg);
  z-index: 99999;
}

.Login_page_text {
  display: flex;
  margin-top: 12%;
  flex-direction: column;
  margin-left: 40px;
  justify-content: flex-start;
  align-items: flex-start;
}

.Login_page_text h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 114%;
}

.Forgotpasswordloginbox_auth_paper {
  margin-left: 60px;
  padding: 30px 0;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

body[dir="rtl"] .Forgotpasswordloginbox_auth_paper {
  margin-right: 60px;
  padding: 30px 0;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.heading-1 {
  color: #6172d6;
}

body[dir="rtl"] .heading-1 {
  font-weight: 600 !important;
}

.heading-2 {
  color: #4adede;
}

body[dir="rtl"] .heading-2 {
  font-weight: 600 !important;
}

.Forgot_passwordforogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400 !important;
  font-size: calc(13px - 20%) !important;
  margin-top: 4px !important;
  color: #171d2d !important;
}

.Forgot_passwordforogt_email_link a {
  color: #171d2d !important;
  font-weight: 500 !important;
  font-size: 12px;
}

body[dir="rtl"] .Forgot_passwordforogt_email_link a {
  margin-left: 50px !important;
  font-weight: 500 !important;
}

.ForgotPassword_heading2 {
  margin-top: -1rem !important;
  font-size: calc(35px - 20%) !important;
  user-select: none;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.signup-text-inloginpage {
  display: flex;
  justify-content: center;
  color: #000;
  font-weight: 500 !important;
  font-size: calc(16px - 20%) !important;
}

.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 500 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 13px;
  color: #f16b6b;
}

.Forgot_Password_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Forgot_Password_select-language-signin:focus,
.Forgot_Password_select-language-signin:focus-within,
.Forgot_Password_select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.Forgot_Password_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

body[dir="rtl"] .Forgot_Password_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 80%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

.Forgot_password_language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.Forgot_password_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .Forgot_password_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 80.5%;
  top: 37px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .Forgot_PasswordNext_button_EmailVerify {
  margin-left: 35px !important;
}

.ErrorMessege {
  color: red;
  margin-left: 2px !important;
  font-size: 10px !important;
  margin-top: 5px !important;
}

.forgotpassword_label {
  color: #6172d6 !important;
  font-weight: 700 !important;
  font-size: calc(18px - 20%);
}

body[dir="rtl"] .forgotpassword_label {
  font-weight: 600 !important;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}
