.Create-Committee-paper {
  width: 100% !important;
  height: auto !important;
  padding: 60px 60px 30px 60px !important;
  margin-bottom: 20px;
  display: inline-block !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  background: #fff !important;
}

.details-class-Create-Committee {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .details-class-Create-Committee {
  font-weight: 600;
}

.scroll-bar-createCommittee {
  height: 100%;
  max-height: 41vh;
  overflow-x: hidden !important;
}

.scroll-bar-createCommittee::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-createCommittee::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Create-Committee-Head-Heading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Create-Committee-Head-Heading {
  font-weight: 600;
}

.group-head-info-Create-Committee {
  line-height: 15px !important;
}

.name-create-Committee {
  color: #5a5a5a;
  font-size: 12px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

body[dir="rtl"] .name-create-Committee {
  font-weight: 600;
}

.Designation-create-Committee {
  color: #5a5a5a;
  font-size: 10px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Designation-create-Committee {
  font-weight: 600;
}

.email-create-Committee {
  color: #6172d6;
  text-decoration: underline !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

body[dir="rtl"] .email-create-Committee {
  font-weight: 400;
}

body[dir="rtl"] .No-member {
  font-weight: 600;
}
.No-member {
  font-weight: 600;
}

.members-create-Committee-page {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .members-create-Committee-page {
  font-weight: 600;
}

.Addmembers-class-Create-Committee {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Addmembers-class-Create-Committee {
  font-weight: 600;
}

.Create-CreateCommittee-btn {
  font-size: 12px !important;
  padding: 12px 30px !important;
  font-weight: 600;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Create-CreateCommittee-btn {
  font-weight: 600;
}

.Create-CreateCommittee-btn:hover,
.Create-CreateCommittee-btn:active,
.Create-CreateCommittee-btn:focus,
.Create-CreateCommittee-btn:focus-visible,
.Create-CreateCommittee-btn:visited {
  font-size: 12px !important;
  padding: 12px 30px !important;
  font-weight: 600;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.ADD-Committee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  height: 39px !important;
  font-weight: 600;
  border-radius: 4px !important;
  margin-top: 2px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .ADD-Committee-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.ADD-Committee-btn:hover,
.ADD-Committee-btn:active,
.ADD-Committee-btn:focus,
.ADD-Committee-btn:focus-visible {
  font-size: 12px !important;
  margin-top: 2px !important;
  padding: 12px 30px !important;
  height: 39px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.scrollbar-addmember-createcommittee {
  height: 14vh !important;
  overflow-x: hidden !important;
  max-width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.scrollbar-addmember-createcommittee::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scrollbar-addmember-createcommittee::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.group-head-info-Add-Members-Create-Committee {
  margin-left: 3px !important;
  line-height: 15px !important;
}

.Cancell-CreateCommittee-btn {
  font-size: 12px !important;
  background: #ffffff;
  padding: 12px 30px !important;
  color: #5a5a5a;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Cancell-CreateCommittee-btn {
  font-weight: 600;
}

.Cancell-CreateCommittee-btn:hover,
.Cancell-CreateCommittee-btn:active,
.Cancell-CreateCommittee-btn:visited,
.Cancell-CreateCommittee-btn:focus,
.Cancell-CreateCommittee-btn:focus-visible {
  font-size: 12px !important;
  background: #ffffff !important;
  padding: 12px 30px !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Create-Committee-Heading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .Create-Committee-Heading {
  font-weight: 600;
}

.Cancell-CreateGroup-btn:hover {
  background-color: none;
}

.Label_Of_CheckBox {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-left: 4px !important;
}

body[dir="rtl"] .Label_Of_CheckBox {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-right: 5px !important;
}

.errorMessage {
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 600;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 600;
}

.CheckAlignment {
  display: flex !important;
  justify-content: start !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .CheckAlignment {
  margin-right: -20px !important;
}

.Committee_error_coloumn {
  display: flex !important;
  justify-content: end !important;
  margin-right: 9.5% !important;
}

body[dir="rtl"] .Committee_error_coloumn {
  margin-left: 19.5% !important;
}

.create_committee_title::placeholder {
  color: #e1e1e1 !important;
}

.Attachments_Heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin-left: 3px !important;
}

.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600;
}

.dragdrop_attachment_create_resolution {
  height: auto !important;
  border: 1px dashed #49dbdb !important;
}

.Outer_Border-Line {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.filesStyles {
  width: 100% !important;
  display: flex !important;
  gap: 35px !important;
}

.Leftpolygon {
  background: none;
  border: none;
  display: flex !important;
  align-items: center !important;
}

body[dir="rtl"] .Leftpolygon {
  transform: rotate(180deg) !important;
}

.Leftpolygon:hover,
.Leftpolygon:active,
.Leftpolygon:focus,
.Leftpolygon:focus-visible {
  background: none !important;
  border: none !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.Bottom {
  border: 1px solid #e1e1e1 !important;
  width: 100% !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}
.files_View {
  min-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  max-height: 120px;
}
