.Title_desc {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 310px !important;
}

.RatioClass {
  color: #505050 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.meetingTitle {
  font-weight: 600 !important;
  color: #6172d6;
  font-size: 12px !important;
}

.meetingTitle_view {
  font-weight: 600 !important;
  color: #6172d6;
  font-size: 14px !important;
  cursor: pointer !important;
  overflow: hidden !important;
  display: block !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  margin: 0 5px;
}

.meeting_date {
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
}

.publish_meeting_btn {
  background: #6172d6 !important;
  border: 1px solid #e1e1e1 !important;
  padding: 8px 20px;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}

.publish_meeting_btn_View_poll {
  background: #4adede !important;
  border: 1px solid #e1e1e1 !important;
  width: 145px !important;
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.DefineTableFixedheight {
  height: 60vh !important;
}

.meeting-expanded-row {
  margin-left: 22px !important;
}

.align-organizer-col {
  display: flex !important;
  justify-content: center !important;
}
.orgaizer_value {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FilterResetBtn {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

body[dir="rtl"] .ResetOkBtn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}
