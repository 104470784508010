.EmailVerifyBoxSendEmail {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Next_button_EmailVerifySendEmail_sendCode {
  width: 100%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}

.Next_button_EmailVerifySendEmail_sendCode:disabled {
  filter: grayscale(0.5);
}

.Next_button_EmailVerifySendEmail_sendCode:focus-visible {
  width: 100%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}

.forogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .forogt_email_link {
  font-weight: 400 !important;
  cursor: pointer !important;
}

.forogt_email_link a {
  color: #5a5a5a !important;
}

.SelectLineTwofacSendEmail_twofacmultidevice {
  color: #5a5a5a;
  font-weight: 600 !important;
  margin-bottom: 28px;
}
body[dir="rtl"] .SelectLineTwofacSendEmail_twofacmultidevice {
  font-weight: 600 !important;
}
.VerifyHeadingTwofacSendEmail_twofacmultidevice {
  color: #5a5a5a;
  margin-top: 2rem !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}
body[dir="rtl"] .VerifyHeadingTwofacSendEmail_twofacmultidevice {
  font-weight: 600 !important;
}
.Auth_Icon1SendEmailRealme {
  position: absolute;
  left: 24%;
  top: 15%;
  z-index: 9;
}

.circle-imageSendEmailRealme {
  position: absolute;
  right: -250px;
  bottom: -220px;
  transform: rotate(-66deg);
}

.SendEmailOnDeiveColor {
  color: #5a5a5a;
  font-weight: 600 !important;
}

body[dir="rtl"] .SendEmailOnDeiveColor {
  font-weight: 600 !important;
}

.SendEmailOnDeiveColor_active {
  color: #e1e1e1 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .SendEmailOnDeiveColor_active {
  font-weight: 600 !important;
}

.Two-fac-send-language {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Two-fac-send-language:focus,
.Two-fac-send-language:focus-within,
.Two-fac-send-language:focus-visible {
  border-color: rgb(133, 133, 133) !important;
  box-shadow: none !important;
  outline: none !important;
}

.Send_Email_multipleDevice {
  padding: 40px 30px;
  border: 1px solid #e1e1e1;
  max-width: 330px;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.select-language-signin_twofacmultidevice {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  outline: none !important;
}

.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin:focus,
.select-language-signin:focus-within,
.select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}
body[dir="rtl"] .select-language-signin_twofacmultidevice {
  right: 80%;
}
.languageIcon_twofacmultidevice {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon_twofacmultidevice {
  z-index: 99999 !important;
  position: absolute;
  right: 80.7%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.MultiFac_Auth_Icon {
  position: fixed;
  right: -115px;
  bottom: -175px;
  transform: rotate(45deg);
}
body[dir="rtl"] .MultiFac_Auth_Icon {
  position: absolute;
  left: -290px !important;
  bottom: -190px;
  right: 50px;
  transform: rotate(22deg);
}
.EmailBoxSendRealme_twofacmultidevice {
  margin-bottom: 35px;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.phone-image {
  position: absolute;
  top: 130px;
  right: -120px;
  z-index: 1;
  user-select: none;
  width: 23.125rem;
}

body[dir="rtl"] .phone-image {
  position: absolute;
  top: 19%;
  right: 290px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
