.header2-container {
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
  z-index: 99;
  display: flex;
  align-items: center;
  max-height: calc(100vh - 90vh);
  width: 100%;
  padding: 10px 0 !important;
}

body[dir="rtl"] .header2-container {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.profilebtn-dropdown button {
  background: #fff !important;
  border-radius: 4px;
  width: 200px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

.profilebtn-dropdown .dropdown_menu {
  padding: 0 !important;
  transform: translate(0px, 0px) !important;
  border-radius: 2px;
}

.profilebtn-dropdown .dropdown_menu:hover {
  overflow: hidden !important;
}

.profilebtn-dropdown button::after {
  border: solid #707070 !important;
  border-width: 0 1px 1px 0 !important;
  display: block !important;
  margin: 0 5px 5px 12px;
  padding: 5px !important;
  transform: rotate(45deg) !important;
  position: absolute;
  right: 10px;
}

.profilebtn-dropdown {
  width: 200px;
}

button.dropdown-toggle.dropdown-toggle.btn.btn-primary:has(p.user-name) {
  width: 200px;
  text-align: left !important;
  justify-content: left;
  margin-top: 2px;
  border: none !important;
}

.user-name {
  margin: 0;
  padding: 0;
  max-width: 140px;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.search-Icon {
  margin: 0 0 0 5px;
  transition: all 0.5s;
}

.bell-Icon {
  margin: 15px 10px 0 20px;
  transition: all 0.5s;
  position: relative;
}

.mx-2 {
  margin-left: 10px;
}

.dropdown-menu-list-item {
  padding: 0px 15px;
}

.user-dropdown {
  height: 45px;
}

.user-dropdown .dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  padding: 16px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  gap: 10px;
  cursor: pointer;
}

.dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  padding: 16px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.show .dropdown {
  border: none !important;
}

.dropdown-toggle:hover {
  background: #ffffff;
}

.user-dropdown .dropdown-toggle .header-icon {
  margin: 0 4px 0 0;
}

.user-dropdown .dropdown-menu.active {
  display: block;
}

.user-dropdown .dropdown-menu.show {
  width: 100%;
  margin-top: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.user-dropdown .dropdown-menu-list-item {
  margin-bottom: 20px;
  position: relative;
}

.user-dropdown .dropdown-menu-list-item a {
  padding: 5px 0 3px 0;
  display: block;
}

.user-dropdown .dropdown-menu-list-item:after {
  position: relative !important;
  bottom: -10px;
  width: 85%;
  height: 1px;
  background: #e1e1e1;
  content: "";
  display: block;
}

.nav-item {
  text-align: none;
  list-style: none;
}

.user-dropdown .dropdown-toggle .user-name {
  margin: 0 5px;
}

.dropright .dropdown-menu.show {
  right: 0px;
  left: inherit;
  display: block;
}

.dropmidle .dropdown-menu {
  transform: translate(-55%, 30px) !important;
  display: none;
}

.dropmidle .dropdown-menu.show {
  display: block;
  margin-top: 5px;
}

.dropdown-item {
  white-space: nowrap !important;
  font-size: 0.8rem;
}

.dropdown-item:hover {
  background: none;
}

.notification-dropdown .notification-dropdown-menu {
  min-width: 320px;
  max-width: 320px;
  max-height: 70vh;
  overflow: auto;
}

.notification-dropdown > [data-bs-toggle="dropdown"].show:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #e1e1e1;
  content: "";
  display: block;
  position: absolute;
  top: 53px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.icon-list-item {
  width: 20px;
  height: 20px;
  background: #e1e1e1;
  border-radius: 50%;
}

.desc-notification-user {
  width: 50px !important;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.desc-item-time {
  margin: 0 10px 0 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
}

.icon-notification-list-item {
  width: 50px;
  height: 50px;
  background: #cccc;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.desc-notification-list-item {
  padding: 0 10px;
  width: 100% !important;
  height: auto;
}

.notification-dropdown-menu-item {
  color: #505050;
}

.notification-dropdowntoggle {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px !important;
  color: #000;
}

.notification-dropdowntoggle:hover {
  border: none !important;
}

.notification-dropdowntoggle::after {
  border: none;
}

.user-name {
  margin: 0px 10px 0 0 !important;
  padding: 0 !important;
  font-size: 13px !important;
  font-weight: 600;
  color: #505050 !important;
  max-width: 100px;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

.user-name.orgStyle {
  font-weight: 500 !important;
  font-size: 11px !important;
}
body[dir="rtl"] .user-name {
  font-weight: 600;
}

.dropdown-toggle {
  margin: 0;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  color: #000;
}

.dropdown-toggle:focus {
  border: none;
  --bs-btn-focus-box-shadow: none;
}

.dropdown_menu {
  width: 100% !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  color: #000 !important;
}

.notification-dropdown_menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 320px;
  max-width: 320px;
  margin: 0;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  inset: 0px auto auto 0px;
  transform: translate(-106px, 255px) !important;
  overflow-x: hidden;
  max-height: 70vh;
}

.notification-day {
  margin: 0;
  font-weight: 700;
  font-size: 0.9rem;
  color: #000;
}

.header-heading {
  font-size: 1.1rem;
}

.icon-class {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.notification-dropdown_menu {
  inset: 0px 0px auto 0px !important;
}

.notification-icon {
  font-size: 5rem;
  color: #2097d6;
}

/* change color  from blue to this */
.notification-text .ant-result-subtitle {
  font-size: 1.2rem !important;
  color: #2097d6;
}

.header-container {
  z-index: 1 !important;
}

.language-dropdown {
  background: transparent;
  border: none !important;
  color: #fff !important;
  padding: 5px;
  border-radius: 6px;
  font-size: 10px;
  height: 32px;
}

.language-dropdown:focus-visible,
.language-dropdown:active,
.language-dropdown:focus {
  background: transparent;
  border: none !important;
  color: #fff !important;
  padding: 5px;
  border-radius: 6px;
  font-size: 10px;
  height: 32px;
  outline: none !important;
}

.language-dropdown-value {
  color: #000 !important;
  width: 120px !important;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  padding: 0;
}

.dropdown-menu.show {
  border-radius: 4px;
  margin: 0;
  padding: 0;
  width: 100% !important;
}
.profilebtn-dropdown .dropdown-toggle .dropdown_menu.dropdown-menu.show {
  left: 0 !important;
  top: 0 !important;
  position: absolute;
}

body[dir="rtl"] .dropdown-menu.show {
  top: 27px;
}

.Profile_dropdown_menu.dropdown-menu.show {
  width: 200px;
  top: 33px !important;
  position: absolute;
  left: 0px;
}

body[dir="rtl"] .Profile_dropdown_menu.dropdown-menu.show {
  left: auto;
  right: 0px !important;
  top: 33px !important;
}

@media screen and (max-width: 1100px) {
  .desc-notification-user {
    width: 35px !important;
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  /* for changing n upcoming event */

  .notification-icon {
    font-size: 3rem !important;
    color: #2097d6;
  }

  .notification-text .ant-result-subtitle {
    font-size: 0.7rem !important;
    color: #2097d6;
  }

  /* for recent activity days font-size */

  .desc-item-time {
    text-align: right !important;
    color: #9a9a9a !important;
    font-size: 9px !important;
  }
}

.successfull-label {
  font-size: 28px;
  font-weight: 400;
  color: #6172d6;
  text-align: center !important;
  font-weight: 600;
  font-size: 12px;
}

.Ok-Successfull-btn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 2px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
}

.Cancel-btn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #fff !important;
  color: #000 !important;
  margin-bottom: 20px !important;
  font-size: 12px;
  font-weight: 600;
}

body[dir="rtl"] .Cancel-btn {
  font-weight: 600;
}

.Cancel-btn:hover {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #fff !important;
  color: #000 !important;
  margin-bottom: 20px !important;
}

label.logout-confirmation-label {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] label.logout-confirmation-label {
  font-weight: 600;
}

.LogoutButtons {
  padding: 0 150px !important;
}

a.text-black.nav-link {
  border-bottom: 1px solid #d8d8d8;
  color: #5a5a5a;
  padding: 5px 0 !important;
  margin: 0 !important;
}

body[dir="rtl"] a.text-black.nav-link {
  font-weight: 400;
}

body[dir="rtl"] .title-className {
  font-weight: 400;
}

a.SignOutOptionMenu.text-black.border-none.nav-link {
  border-bottom: none !important;
}

body[dir="rtl"] img.user-img.me-3 {
  margin-right: 0 !important;
  margin-left: 5px;
}

body[dir="rtl"] .profilebtn-dropdown button::after {
  border: solid #707070 !important;
  border-width: 0 1px 1px 0 !important;
  display: block !important;
  margin: 0 5px 5px 12px;
  padding: 5px !important;
  transform: rotate(45deg) !important;
  position: absolute;
  right: auto;
  left: 10px;
}

body[dir="rtl"]
  button.dropdown-toggle.dropdown-toggle.btn.btn-primary:has(p.user-name) {
  justify-content: right;
  border: none !important;
}

body[dir="rtl"] .header2-container {
  padding-left: 25px;
  padding-right: 55px;
}

.dropdown_menu.dropdown-menu.show {
  margin-top: 0px;
}

.modal-header-logout {
  display: none !important;
}

.language-options {
  visibility: hidden;
  position: absolute;
  top: 22px !important;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 150px;
  right: 0;
  padding: 10px 15px 5px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  gap: 10px;
}

.language-options.active {
  visibility: visible;
}

.language-options span:not(:last-child) {
  border-bottom: 2px solid #e1e1e1;
}

.FontClass {
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 600;
}
.dropdown_menu_admin {
  left: 0 !important;
  width: 100% !important;
  top: 29px !important;
}

.UpgradeButtonsClass {
  display: flex !important;
  gap: 10px !important;
  margin-left: 150px !important;
}

.UpgradeNowbutton {
  height: 39px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  border: none !important;
  background: #fff !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.UpgradeNowbutton:is(:hover, :focus, :focus-visible, :active) {
  font-size: 14px !important;
  height: 39px !important;
  border-radius: 4px !important;
  border: none !important;
  background: #fff !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .UpgradeNowbutton {
  font-weight: 600 !important;
}

body[dir="rtl"] .UpgradeButtonsClass {
  margin-right: 150px !important;
}

.trialExpireButton {
  padding: 8px 30px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  border: 1px solid #f16b6b !important;
  background: #ffdcdc !important;
  font-weight: 600 !important;
}

.InnerText {
  color: #f16b6b !important;
}
.New_folder_shortcutkeys {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

body[dir="rtl"] a.SignOutOptionMenu.text-black.border-none.FontClass.nav-link {
  margin-left: 97px !important;
}

.BellNotificationIconStyles {
  position: relative !important;
  margin-left: 10px !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

body[dir="rtl"] .BellNotificationIconStyles {
  margin-right: 16px !important;
}

.NotficationCountSpan {
  width: 17px !important;
  height: 16px !important;
  background: #6172d6 !important;
  border-radius: 50% !important;
  color: #fff !important;
  position: absolute !important;
  top: -5px !important;
  left: 31px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 10px !important;
}
