.Confirmationmodal_body_text {
    padding: 0 20px !important;
    text-align: center;
    font-weight: 600;
    color: #5a5a5a;
    font-size: 16px;
}

.close-Add-notes-Modal {
    background: #6172d6 !important;
    border: none !important;
    padding: 12px 30px !important;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff !important;
    font-size: 12pt;
    font-weight: 600;
}

.cancel-Add-notes-Modal {
    background: #fff !important;
    padding: 12px 30px !important;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5a5a5a !important;
    border: 1px solid #e1e1e1 !important;
    font-size: 12pt;
    font-weight: 600;
    outline: none;
    box-shadow: none;
}

.cancel-Add-notes-Modal:is(:focus, :focus-visible, :hover, :visited, :active) {
    background: #fff !important;
    padding: 12px 30px !important;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5a5a5a !important;
    border: 1px solid #e1e1e1 !important;
    font-size: 12pt;
    font-weight: 600;
    outline: none;
    box-shadow: none;
}