.RecentAcitivy_newDashboard {
  height: 100%;
  max-height: 34vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 0;
}
.RecentActivity_Title {
  font-weight: 700;
  font-size: 20px;
  color: #5a5a5a;
}
