.circular_progressbar_div {
  position: relative;
  width: 160px !important;
  max-width: max-content;
  display: flex;
  height: 100% !important;
  align-items: center;
  justify-content: center;
}

body[dir="rtl"] .dashboard_progress_bar {
  font-weight: 600;
}

.circular_progress_counter {
  width: 100% !important;
  height: 100%;
}
