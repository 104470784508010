.View_resolution_heading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

.View_resolution_paper {
  width: 100% !important;
  height: auto !important;
  padding: 50px 50px !important;
  position: relative !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Details_ViewResolution {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .Details_ViewResolution {
  font-weight: 600;
}

.Heading_Authorize_Viewresolution {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
  word-break: break-word;
  max-height: 95px;
  display: block;
  overflow-y: auto;
}
.Heading_Authorize_Viewresolution::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Heading_Authorize_Viewresolution::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

body[dir="rtl"] .Heading_Authorize_Viewresolution {
  font-weight: 600;
}

.Sub_heading_ViewResolution {
  margin-left: 3px !important;
}

.Paragraph {
  font-size: 12px !important;
  color: #5a5a5a;
  word-wrap: break-word;
  overflow-y: auto;
  max-height: 85px !important;
}

body[dir="rtl"] .Paragraph {
  font-weight: 400;
}

.View_resolution_circulationHeading {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .View_resolution_circulationHeading {
  font-weight: 600;
}

.Datetime_view_resolution {
  font-size: 16px !important;
  font-weight: 600;
  color: #5a5a5a;
}

body[dir="rtl"] .Datetime_view_resolution {
  font-weight: 600;
}

.Reminder_viewResolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .Reminder_viewResolution {
  font-weight: 600;
}

.Public_resolution {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-left: 10px !important;
}

body[dir="rtl"] .Public_resolution {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-right: 5px !important;
}

.Frequency_Viewresolution {
  font-size: 16px !important;
  font-weight: 600;
  color: #5a5a5a;
}

body[dir="rtl"] .Frequency_Viewresolution {
  font-weight: 600;
}

.Voters_Btn_viewresolution {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Voters_Btn_viewresolution {
  font-weight: 600;
}

.Voters_Btn_viewresolution:hover,
.Voters_Btn_viewresolution:focus,
.Voters_Btn_viewresolution:focus-visible,
.Voters_Btn_viewresolution:active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Voters_Btn_viewresolution_Active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Voters_Btn_viewresolution_Active {
  font-weight: 600;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Non_Voters_Btn_viewresolution {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Non_Voters_Btn_viewresolution {
  font-weight: 600;
}

.Non_Voters_Btn_viewresolution:hover,
.Non_Voters_Btn_viewresolution:active,
.Non_Voters_Btn_viewresolution:focus,
.Non_Voters_Btn_viewresolution:focus-visible {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Non_Voters_Btn_viewresolution_Active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Non_Voters_Btn_viewresolution_Active {
  font-weight: 600;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Name_participant_viewresolution {
  font-size: 12px !important;
  font-weight: 600;
}

.Email_participant_viewresolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6166ff !important;
  text-decoration: underline !important;
}

.Participant_info_Viewresolution {
  line-height: 15px !important;
  margin-top: 5px !important;
}

.scroll-bar-view-resolution {
  height: 300px !important;
  overflow-x: hidden !important;
}

.scroll-bar-view-resolution::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-view-resolution::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Attachments_view_resolution {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .Attachments_view_resolution {
  font-weight: 600;
}

.Backward_button_viewresolution {
  background: none !important;
  border: none !important;
}

body[dir="rtl"] .Backward_button_viewresolution {
  transform: rotate(180deg) !important;
}

.CloseButton_ViewResolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  margin-top: 20px !important;
}

.CloseButton_ViewResolution:hover,
.CloseButton_ViewResolution:active,
.CloseButton_ViewResolution:focus,
.CloseButton_ViewResolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  margin-top: 20px !important;
}

.View_resolution_tag_box {
  width: auto !important;
  height: 25px !important;
  background: #6de595 !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute !important;
  left: 3.7%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .View_resolution_tag_box {
  width: auto !important;
  left: 91.5% !important;
}

.View_Tag_messege {
  font-size: 12px !important;
  color: #fff;
  font-weight: 600;
}

body[dir="rtl"] .View_Tag_messege {
  font-weight: 600;
}

.CheckboxAlignmnet {
  display: flex !important;
  justify-content: start !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .CheckboxAlignmnet {
  margin-right: -20px !important;
}

.line_Viewesolution {
  width: 1px;
  height: 100%;
  background-color: #5a5a5a !important;
}

.viewResolutionCheckBox {
  cursor: auto !important;
}
.ViewResolution_attachment {
  max-height: 120px !important;
  overflow-y: auto;
}
