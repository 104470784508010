.View-Group-heading {
  font-size: 30px !important;
font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .View-Group-heading {
  font-weight: 600;
}

.View-group-Subheading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .View-group-Subheading {
  font-weight: 600;
}

.Management-Heading-View-Group {
  font-size: 30px !important;
  font-weight: 600;
  color: #5a5a5a;
  word-wrap: break-word;
}

body[dir="rtl"] .Management-Heading-View-Group {
  font-weight: 600;
}
.paragraph-content-View-Group {
  font-size: 16px !important;
  font-weight: 600;
  color: #5a5a5a;
  word-wrap: break-word;
}

body[dir="rtl"] .paragraph-content-View-Group {
  font-weight: 600;
}
.Create-group-Head-Heading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

body[dir="rtl"] .Create-group-Head-Heading {
  font-weight: 600;
}
.group-head-info {
  line-height: 15px !important;
}

.name-create-group {
  color: #5a5a5a;
  font-size: 12px !important;
  font-weight: 600;
}

body[dir="rtl"] .name-create-group {
  font-weight: 600;
}

.Designation-create-group {
  color: #5a5a5a;
  font-size: 10px !important;
  font-weight: 600;
}

body[dir="rtl"] .Designation-create-group {
  font-weight: 600;
}

.email-create-group {
  font-weight: 600;
  color: #6172d6;
  text-decoration: underline !important;
  font-size: 10px !important;
}

body[dir="rtl"] .email-create-group {
  font-weight: 600;
}

.members-create-group-page {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .members-create-group-page {
  font-weight: 600;
}

.scroll-bar-creategroup {
  height: 39vh !important;
  overflow-x: hidden !important;
}

.scroll-bar-creategroup::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-creategroup::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.View-group-paper {
  height: auto !important;
  width: 100% !important;
  padding: 60px 40px !important;
}

.Close-ViewGroup-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Close-ViewGroup-btn {
  font-weight: 600;
}

.Close-ViewGroup-btn:is(:hover, :active, :visited, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Save-ViewGroup-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;;
  border-radius: 4px !important;
  border: none;
}

.Save-ViewGroup-btn:is(:hover, :active, :visited, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;;
  border-radius: 4px !important;
  border: none;
}
.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin-left: 3px !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  margin: 5px !important;
  cursor: pointer;
}

.Scroller_files {
  min-height: 43vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  max-height: 43vh;
}

.Scroller_files::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_files::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}
