/* .diskus-sidebar {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #171d2d;
  position: fixed;
  height: 100vh !important;
  width: 3.99vw !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
} */

.meeting-icon-tut {
  width: 40px;
  height: 40px;
  margin-top: 80px;
  margin-left: -12px !important;
}

.icon-meeting {
  color: #fff !important;
  margin: 20px 0 0 0;
  font-size: 30px !important;
}

.icon-check-list {
  color: #fff !important;
  margin: 110px 0 0 0;
  font-size: 30px !important;
}

.icon-note2 {
  color: #fff !important;
  font-size: 30px !important;
  margin: 20px 0 0 0;
}

.icon-chat {
  font-size: 18px !important;
}

.icon-calendar {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

.icon-user {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

.icon-help {
  font-size: 30px !important;
  color: #fff;
  margin: 220px 0 0 0;
}

.help-icon-margin {
  margin-top: 220px !important;
}

.icon-setting {
  font-size: 30px !important;
  color: #fff;
  margin: 20px 0 0 0;
}

.icon-attachment {
  font-size: 30px !important;
  color: #fff;
  margin: 30px 0 0 0;
}

.popper_attachment {
  background-color: #fff;
}

body[dir="rtl"] .meeting-icon-tut {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 80px;
}
