.File_name {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

.Row_Details_styles {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}
.sharedUserTitle {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
