p.user-name.ar {
  margin: 0 0 0 10px;
}

select.language-dropdown.ar {
  margin-left: 14px;
}

.notification-dropdown_menu.ar {
  inset: 0px -220px auto 0px !important;
}

.Setting.ar.accordion.accordion-flush .accordion-button::after {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.ABC.ar.accordion.accordion-flush .accordion-button::after {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.ar.dropdown-item {
  text-align: right;
}

.ToDo.ar span.ant-table-column-title {
  margin-left: 10px;
}

.Billing_TablePakageSelection.ar span.ant-table-column-title {
  margin-left: 10px;
}

body[dir="rtl"] li.ant-dropdown-menu-item {
  text-align: right;
  font-weight: 400;
}

body[dir="rtl"] label.ant-radio-wrapper {
  margin-left: 10px;
}

.add-another-agendabutton-div.ar {
  text-align: right;
}

.card-employee-name.ar {
  margin-right: 20px;
}

.card-employee-desgination.ar {
  margin: 0px 20px 0px 0px;
}

/* Dashboard CSS */
body[dir="rtl"] .rmdp-right i {
  transform: rotate(180deg) !important;
}

body[dir="rtl"] .rmdp-left i {
  transform: rotate(0deg) !important;
}

.rmdp-header-values {
  color: #5a5a5a !important;
  margin: auto;
}

.rmdp-week-day {
  color: #6172d6 !important;
  cursor: default !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .rmdp-week-day {
  font-weight: 600;
}

.rmdp-day.rmdp-today span {
  color: #fff !important;
  background-image: linear-gradient(
    to left,
    #49dbdb 0%,
    #6172d6 100%
  ) !important;
}

body[dir="rtl"] .rmdp-day.rmdp-today span {
  background-image: linear-gradient(
    to right,
    #49dbdb 0%,
    #6172d6 100%
  ) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #e1e1e1 !important;
  color: #000 !important;
}

.talk_nav.ar {
  left: 15px;
  bottom: 15px;
  right: auto !important;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 202px;
  width: 65px;
  border-radius: 2px;
  position: fixed;
}

body[dir="rtl"] li.ant-pagination-prev {
  transform: rotate(180deg);
  position: relative;
}

body[dir="rtl"] li.ant-pagination-next {
  transform: rotate(180deg);
  margin-left: 10px;
  position: relative;
}

body[dir="rtl"] span.anticon.anticon-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

body[dir="rtl"] span.anticon.anticon-right {
  position: absolute;
  top: 10px;
  left: 10px;
}

body[dir="rtl"] .ant-pagination-item,
.ant-pagination-total-text {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

body[dir="rtl"] .CreateCheckbox .checkboxParentClass {
  margin-top: 13px !important;
  margin-top: 5px !important;
}

body[dir="rtl"] .CreateCheckbox p {
  margin-top: 10px !important;
  /* margin-left: 15px !important; */
}

body[dir="rtl"] .checkboxParentClass .SearchCheckbox {
  margin-right: 10px !important;
}

body[dir="rtl"] button.accordion-button.collapsed:after {
  margin-left: 0 !important;
  margin-right: auto;
}

body[dir="rtl"] .modalupdatemeeting-add {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 0 !important;
  margin-right: -15px !important;
}

body[dir="rtl"] .ant-table-wrapper.hello .ant-table-thead > tr * {
  text-align: center !important;
}

body[dir="rtl"] span.ant-dropdown-trigger.ant-table-filter-trigger {
  margin-right: 0;
}

body[dir="rtl"] .ant-table-wrapper.hello td.ant-table-cell {
  text-align: right !important;
}

/* body[dir="rtl"] .ant-table-wrapper.hello .activebtn.text-center {
    text-align: right !important;
} */

body[dir="rtl"] th.ant-table-cell.ant-table-column-has-sorters {
  position: relative;
}

body[dir="rtl"] .todolist-title-col {
  text-align: right !important;
}

/* body[dir="rtl"] td.ant-table-cell {
  text-align: right !important;
  font-weight: 400;
} */

body[dir="rtl"] .ant-table-wrapper.ToDo .ant-table .ant-table-thead > tr > th {
  text-align: center !important;
}

body[dir="rtl"]
  .ant-table-wrapper.Billing_TablePakageSelection
  .ant-table
  .ant-table-thead
  > tr
  > th {
  text-align: center !important;
}

body[dir="rtl"]
  .ant-select.ant-select-borderless.orange.ant-select-single.ant-select-show-arrow {
  position: relative;
}

body[dir="rtl"]
  td.ant-table-cell:has(
    .ant-select.ant-select-borderless.orange.ant-select-single.ant-select-show-arrow
  )
  .ant-select-selection-item {
  padding-right: 0 !important;
}

/* body[dir="rtl"] .ant-select-selector {
  padding-right: 0 !important;
}

body[dir="rtl"] .ant-select-arrow {
  right: 120px !important;
} */

body[dir="rtl"] .Dashboard-Main-Container.container {
  margin-left: 0px;
  padding-left: 100px !important;
  padding-right: 0 !important;
  margin-right: 90px !important;
}

body[dir="rtl"] h1.upcoming-events {
  text-align: right !important;
  padding-left: 0 !important;
  padding-right: 20px;
}

body[dir="rtl"] p.desc-item-text.home-recentactivity-notification-description {
  margin-left: 0 !important;
  margin-right: 15px !important;
  font-weight: 500;
}

body[dir="rtl"] .Recent-Activity-Box {
  padding-right: 15px;
}

body[dir="rtl"] h1.recent-activity {
  padding-left: 0 !important;
  padding-right: 20px !important;
}

body[dir="rtl"] h1.recent-activity {
  font-weight: 600;
}

body[dir="rtl"]
  .DashboardTodoTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell.statusDashboard {
  border-left: 1px solid #e1e1e1 !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-right: none !important;
}

body[dir="rtl"]
  .DashboardTodoTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell.titleDashboard {
  border-right: 1px solid #e1e1e1 !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-left: none !important;
}

body[dir="rtl"]
  .ant-table-wrapper.dashboard-todo
  .ant-table
  .ant-table-thead
  > tr
  > th {
  text-align: right !important;
  padding-right: 0 !important;
}

body[dir="rtl"]
  .DashboardTodoTable
  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  position: absolute;
  top: -42px;
  right: auto;
  left: 0;
}

body[dir="rtl"] .DashboardTodoTable li.ant-pagination-prev {
  position: absolute;
  right: 0;
  /* left : 0 !important; */
  top: -12px;
}

body[dir="rtl"] .DashboardTodoTable li.ant-pagination-next {
  position: absolute;
  right: auto;
  left: 0 !important;
  top: -12px;
}

body[dir="rtl"] .comment-enter-button {
  margin-left: 0px !important;
  margin-right: 11px !important;
}

body[dir="rtl"] .dropdown-toggle::after {
  margin-right: 10px;
}

body[dir="rtl"] .react-tel-input .form-control {
  padding-right: 45px;
}

body[dir="rtl"] .react-tel-input .selected-flag .flag {
  left: 17px;
}

body[dir="rtl"] .react-tel-input .selected-flag .arrow {
  left: -20px;
}

body[dir="rtl"] .react-tel-input .country-list .country .dial-code {
  margin-right: 7px;
}

body[dir="rtl"] .navbar > .dropdown-menu.show {
  left: -34px !important;
}

body[dir="rtl"] .add-event.calendar-btn.calendar-dropdown .dropdown-item {
  text-align: right !important;
  padding-right: 10px !important;
}

body[dir="rtl"] .icon-text-alignment {
  right: auto !important;
  left: 30px !important;
}

body[dir="rtl"] .lineBar_cancelSub {
  width: 1px;
  min-height: 60vh;
  background-color: #ccc;
  vertical-align: middle;
  position: absolute;
  top: 15px;
  left: 0;
}

body[dir="rtl"] .upgradePackageAmoutnandList {
  position: relative;
}

body[dir="rtl"] .upgradePackageAmoutnandList .form-check {
  position: absolute;
  right: 0px;
}

body[dir="rtl"] .ant-table-wrapper.hello table tbody tr td:nth-child(5) {
  text-align: center !important;
}

body[dir="rtl"] .ant-table-wrapper.hello table thead tr th:nth-child(5) {
  text-align: center !important;
  padding: 0 55px 0 0;
}

button.btn.btn-primary.start-meeting-button.ar.btn.btn-primary {
  margin-left: 10px;
}

body[dir="rtl"] .todolist-search-row {
  margin-left: 0 !important;
}

body[dir="rtl"] i.icon-add-square.uploadfile {
  color: #6172d6 !important;
  font-size: 34px !important;
  margin-right: -30px;
}

body[dir="rtl"] .minutes-upper-btn {
  padding: 0px !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .DataRoomShowBtn {
  width: 130px !important;
  margin-left: 0 !important;
}

body[dir="rtl"] i.edit-icon-adminmeeting {
  margin-right: 10px;
}

.text-end.ar.col-lg-6.col-md-6.col-sm-6.col-12 {
  text-align: left !important;
}

.text-start.ar.col-lg-6.col-md-6.col-sm-6.col-12 {
  text-align: right !important;
}

body[dir="rtl"] .eye_icon.valid-feedback {
  display: block;
  position: absolute;
  top: -5px;
  font-size: 22px;
  left: auto;
  right: 87%;
  cursor: pointer;
}

body[dir="rtl"]
  .ant-table-wrapper.Billing_TablePakageSelection
  table
  tbody
  tr
  td:nth-child(4) {
  text-align: right !important;
}

body[dir="rtl"] .ant-pagination-options {
  margin-left: 0 !important;
}
