.warningIcon {
  margin-right: 10px;
  margin-top: 1px;
  width: 21px;
  height: 21px;
}

body[dir="rtl"] .warningIcon {
  margin-right: 0 !important;
  margin-left: 10px !important;
  margin-top: 1px;
  width: 21px;
  height: 21px;
}

.warnignMessage {
  font-size: 18px;
}

.subscription_warning {
  
  color: #fff;
  margin: 0;
  border: none;
  height: 40px !important;
}

