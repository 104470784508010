.Resolution_create_heading {
  font-size: 30px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Resolution_create_heading {
  font-weight: 600;
}

.Details_New_resolution {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Details_New_resolution {
  font-weight: 600;
}

.Create_new_resolution_paper {
  width: 100% !important;
  height: auto !important;
  padding: 35px 58px !important;
  position: relative !important;
  margin: 10px 0;
  border-radius: 8px !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}

.Circulation_heading {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Circulation_heading {
  font-weight: 600;
}

.Voting_deadline_Create_resolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Voting_deadline_Create_resolution {
  font-weight: 600;
}

.decision_annoucement_Createresoulution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .decision_annoucement_Createresoulution {
  font-weight: 600;
}

.Reminder {
  font-size: 12px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Reminder {
  font-weight: 600;
}

.Voters_Btn_Createresolution {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Voters_Btn_Createresolution {
  font-weight: 600;
}

.Voters_Btn_Createresolution:focus,
.Voters_Btn_Createresolution:active,
.Voters_Btn_Createresolution:focus-visible,
.Voters_Btn_Createresolution:hover {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Voters_Btn_Createresolution_Active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Voters_Btn_Createresolution_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Non_Voters_Btn_Createresolution_Active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Non_Voters_Btn_Createresolution_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Non_Voters_Btn_Createresolution {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Non_Voters_Btn_Createresolution {
  font-weight: 600;
}

.ADD_Button_Createresolution {
  padding: 12px 30px !important;
  height: 39px !important;
  background: #6172d6 !important;
  color: #fff;
  font-size: 12px !important;
  border: none !important;
  display: flex;
  max-width: 100%;
  margin-top: 13px !important;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .ADD_Button_Createresolution {
  font-weight: 400;
}

.ADD_Button_Createresolution:focus,
.ADD_Button_Createresolution:active,
.ADD_Button_Createresolution:focus-visible,
.ADD_Button_Createresolution:hover {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  height: 39px !important;
  color: #fff !important;
  font-size: 12px !important;
  border: none !important;
  margin-top: 13px !important;
}

.Non_Voters_Btn_Createresolution:hover,
.Non_Voters_Btn_Createresolution:active,
.Non_Voters_Btn_Createresolution:focus,
.Non_Voters_Btn_Createresolution:focus-visible {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Attachments_resolution {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6;
}

body[dir="rtl"] .Attachments_resolution {
  font-weight: 600;
}

.Leftpolygon {
  background: none;
  border: none;
}

.Leftpolygon:hover,
.Leftpolygon:active,
.Leftpolygon:focus,
.Leftpolygon:focus-visible {
  background: none !important;
  border: none !important;
}

.Name_participant_resolution {
  font-size: 12px !important;
  font-weight: 600;
}

.Email_participant_createresolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6166ff !important;
  text-decoration: underline !important;
}

.Designation_Participant_createresolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a !important;
}

.Participant_info_createResolution {
  line-height: 15px !important;
}

.scroll-bar-Create-resolution {
  height: 200px !important;
  overflow-x: hidden !important;
}

.scroll-bar-Create-resolution::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-Create-resolution::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Save_button_Createresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

body[dir="rtl"] .Save_button_Createresolution {
  font-weight: 600;
}

.Discard_button_Createresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fb9494;
  font-weight: 600;
  color: #fff;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

body[dir="rtl"] .Discard_button_Createresolution {
  font-weight: 600;
}

.Discard_button_Createresolution:hover,
.Discard_button_Createresolution:active,
.Discard_button_Createresolution:focus,
.Discard_button_Createresolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fb9494 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

.Update_button_Createresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

body[dir="rtl"] .Update_button_Createresolution {
  font-weight: 600;
}

.Update_button_Createresolution:hover,
.Update_button_Createresolution:active,
.Update_button_Createresolution:focus,
.Update_button_Createresolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  font-weight: 600;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

.Save_button_Createresolution:hover,
.Save_button_Createresolution:active,
.Save_button_Createresolution:focus,
.Save_button_Createresolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

.circulate_button_Createresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .circulate_button_Createresolution {
  font-weight: 600;
}

.circulate_button_Createresolution:hover,
.circulate_button_Createresolution:active,
.circulate_button_Createresolution:focus,
.circulate_button_Createresolution:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  font-weight: 600;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Draft_box_Edit {
  width: auto !important;
  height: 25px !important;
  background: #6166ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute !important;
  top: 0;
  display: flex;
  left: 60px;
  justify-content: center;
  align-items: center;
}

.Class_for_label_Edit_resolution {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-left: 4px !important;
}

body[dir="rtl"] .Class_for_label_Edit_resolution {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-right: 5px !important;
}

body[dir="rtl"] .Draft_box_Edit {
  right: 58px;
  left: auto;
}

body[dir="rtl"] .Leftpolygon {
  transform: rotate(180deg);
}

.Circulated_box_Edit {
  width: 67px !important;
  height: 25px !important;
  background: #6de595;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute !important;
  top: 64px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .Circulated_box_Edit {
  font-weight: 600;
}

.Edit_draft_Tag {
  font-size: 12px !important;
  font-weight: 600;
  color: #fff;
}

body[dir="rtl"] .Edit_draft_Tag {
  font-weight: 600;
}

.CheckBoxalign {
  display: flex !important;
  justify-content: start !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .CheckBoxalign {
  margin-right: -20px !important;
}

.errorMessage {
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
  white-space: nowrap;
  font-weight: 600 !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 400;
}

.errorMessage_hidden {
  visibility: hidden;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 400;
}

.edit_resolution_attachments {
  height: 200px;
  margin-bottom: 95px !important;
}

.attachments_height {
  max-height: 120px !important;
  overflow-y: auto;
}
.EditResolution_dragger {
  height: auto !important;
}

.line_Editresolution {
  width: 1px;
  height: 100%;
  background-color: #5a5a5a !important;
}

.Go_Back_EditResolution {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
}

.dragdrop_attachment_create_resolution {
  height: auto !important;
  border: 1px dashed #49dbdb !important;
}

.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin-left: 3px !important;
}

body[dir="rtl"] .Choose_file_style {
  font-weight: 600;
}

.here_text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
  margin-left: 4px !important;
}

body[dir="rtl"] .here_text {
  font-weight: 600;
}

.Cancel_button_Createresolution {
  padding: 12px 15px !important;
  white-space: nowrap;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

.Cancel_button_Createresolution:is(
    :active,
    :focus-visible,
    :focus,
    :visited,
    :hover
  ) {
  padding: 12px 15px !important;
  white-space: nowrap !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
}

body[dir="rtl"] .Cancel_button_Createresolution {
  font-weight: 600;
}

.Resolution_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  padding: 0 10px;
  cursor: pointer !important;
}
.Resolution_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}
.datePicker_Container {
  width: 100% !important;
  display: block !important;
}
