.CancelNowBtn {
  width: 100%;
  font-size: 11px;
  background: #6172d6;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 10px 0;
}

body[dir="rtl"] .CancelNowBtn {
  font-weight: 600 !important;
}

.CancelNowBtn:hover,
.CancelNowBtn:active,
.CancelNowBtn:focus-visible,
.CancelNowBtn:active,
.CancelNowBtn:focus,
.CancelNowBtn:visited {
  width: 100% !important;
  box-shadow: none !important;
  font-size: 11px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  padding: 10px 0;
  font-weight: 600 !important;
}

.CancelNowBtn2 {
  width: 100% !important;
  height: 50px;
  font-weight: 500 !important;
  font-size: 12px !important;
  background: #fff;
  border: none;
  color: #5a5a5a;
  border-radius: 4px;
  text-transform: capitalize;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid #707070;
  box-shadow: none !important;
}

body[dir="rtl"] .CancelNowBtn2 {
  font-weight: 500 !important;
}

.CancelNowBtn2:hover,
.CancelNowBtn2:active,
.CancelNowBtn2:focus-visible,
.CancelNowBtn2:active,
.CancelNowBtn2:focus,
.CancelNowBtn2:visited {
  width: 100% !important;
  height: 50px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  background: #fff !important;
  border: none !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
  padding: 10px 15px !important;
  border: 1px solid #707070 !important;
  box-shadow: none !important;
}

.CompleteContract {
  width: 100%;
  font-size: 11px;
  border-radius: 4px;
  border: none;
  background: #6172d6;
  padding: 10px 0;
  font-weight: 600 !important;
}

body[dir="rtl"] .CompleteContract {
  font-weight: 600 !important;
}

.CompleteContract:hover,
.CompleteContract:active,
.CompleteContract:focus-visible,
.CompleteContract:active,
.CompleteContract:focus,
.CompleteContract:visited {
  width: 100% !important;
  box-shadow: none !important;
  font-size: 11px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  font-weight: 600 !important;
  padding: 10px 0;
}

.Prorgress_heading {
  color: #5a5a5a;
  text-align: center;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin: 5px 0;
}

.upgradePackageAmoutnandList {
  position: relative;
}

.cancelBoxContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1;
}

.cancelBoxCard {
  margin: 150px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningmessageArea {
  padding: 20px;
}

.ProceedBtn {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background: #6172d6;
  text-transform: capitalize;
  font-weight: 600 !important;
  margin-right: 20px;
}

.ProceedBtn2 {
  width: 100%;
  height: 50px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background: #f16b6b !important;
  text-transform: capitalize;
  font-weight: 600 !important;
  padding: 10px 65px;
}

.ProceedBtn2[disabled] {
  background: #f16b6b !important;
  opacity: 0.39;
  font-weight: 600 !important;
  padding: 10px 65px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  border-radius: 4px;
  filter: none;
  border: none;
  cursor: pointer;
}

.ProceedBtn:hover,
.ProceedBtn:active,
.ProceedBtn:focus-visible,
.ProceedBtn:active,
.ProceedBtn:focus,
.ProceedBtn:visited {
  width: 100% !important;
  box-shadow: none !important;
  font-size: 14px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  font-weight: 600 !important;
}

.ProceedBtn2:hover,
.ProceedBtn2:active,
.ProceedBtn2:focus-visible,
.ProceedBtn2:active,
.ProceedBtn2:focus,
.ProceedBtn2:visited {
  width: 100%;
  height: 50px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background: #6172d6;
  text-transform: capitalize;
  font-weight: 600 !important;
  padding: 10px 65px;
}

.proceedwithCancelatioBtn {
  width: 80%;
  height: 50px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  background: #f16b6b;
  font-weight: 700 !important;
  margin-right: 20px;
  padding: 10px 65px;
}

body[dir="rtl"] .proceedwithCancelatioBtn {
  font-weight: 700 !important;
}

.proceedwithCancelatioBtn[disabled] {
  opacity: 0.5 !important;
  filter: none !important;
  background: #f16b6b !important;
}

.proceedwithCancelatioBtn:hover,
.proceedwithCancelatioBtn:active,
.proceedwithCancelatioBtn:focus-visible,
.proceedwithCancelatioBtn:active,
.proceedwithCancelatioBtn:focus,
.proceedwithCancelatioBtn:visited {
  width: 80%;
  height: 50px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  background: #f16b6b;
  font-weight: 700 !important;
  margin-right: 20px;
  padding: 10px 65px;
}

.goBackBTN {
  color: #6172d6;
  font-weight: 400 !important;
  font-size: 16px;
  text-decoration: underline;
}

body[dir="rtl"] .goBackBTN {
  font-weight: 400 !important;
}

.goBackBTN:hover,
.goBackBTN:active,
.goBackBTN:focus-visible,
.goBackBTN:active,
.goBackBTN:focus,
.goBackBTN:visited {
  color: #6172d6;
  font-weight: 400 !important;
  font-size: 16px;
  text-decoration: underline;
}

.CancelSubModal {
  position: relative;
  padding: 0px;
}

.packageheading {
  text-transform: uppercase;
  font-weight: 700 !important;
  text-align: center;
  margin-top: 70px;
  color: #5a5a5a;
  font-size: 32px;
}

.packageAmountText {
  font-weight: 700 !important;
  text-align: center;
  color: #6172d6;
  font-size: 32px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}

body[dir="rtl"] .packageAmountText {
  font-weight: 700 !important;
}

.packageAmountText_p {
  font-size: 20px;
  margin: 0;
}

.packageAmountText_p2 {
  text-align: center;
  font-weight: 500 !important;
  font-size: 11px;
}

body[dir="rtl"] .packageAmountText_p2 {
  font-weight: 500 !important;
}

.ExecutiveMembersRange {
  width: 75%;
  margin: 0 auto;
}

.BoardMembersRange {
  width: 75%;
  margin: 0 auto;
}

.lineBar_cancelSub {
  width: 1px;
  min-height: 75vh;
  background-color: #ccc;
  vertical-align: middle;
  position: absolute;
  top: 15px;
  right: 0;
}

.crossicon {
  color: #707070;
}

.CancelSubHeaderModal {
  display: none;
}

.CancelSubModalTextArea {
  width: 100%;
  resize: none;
  height: 50px;
  overflow-y: auto;
}

.packagecard {
  border-radius: 5px;
  background-color: #fff !important;
  padding: 30px 100px;
  position: relative;
}

.packagecard_two h3 {
  color: #076db7;
  font-weight: 900;
}

.packagecard_pricebox {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px 0px;
}

body[dir="rtl"] .packagecard_pricebox {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
  padding: 10px 0px;
}

.packagecard_priceBox_container {
  width: 180px;
  margin: 10px auto;
}

.packagecard_priceBox_container h4 {
  text-transform: lowercase;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 900;
}

.packagecard_priceBox_container p:nth-child(1) {
  font-size: 10px;
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
}

.packagecard_priceBox_container span {
  text-align: center;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.packagecard_priceBox_container span:hover {
  color: #fff;
  transition: 0.3s ease-in-out;
  background-color: #6172d6;
}

.packagecard_one {
  border: 1px solid #ccc;
}

.selectedPackage_priceDetails {
  padding: 12px 0;
}

.selectedPackageAmount {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

body[dir="rtl"] .selectedPackageAmount {
  font-weight: 700 !important;
}

.selectedPackageAmount p:nth-child(1) {
  text-transform: capitalize;
  font-size: 24px !important;
}

.selectedPackage_priceDetails h4 {
  color: #6172d6;
  font-weight: 700 !important;
  font-size: 32px;
}

body[dir="rtl"] .selectedPackage_priceDetails h4 {
  font-weight: 700 !important;
}

.packagecard_two {
  border: 2px solid #6172d6;
  visibility: visible;
  margin-bottom: 50px;
}

.packagecard_two h4 {
  color: #076db7;
  font-weight: 900 !important;
}

.packagecard_two_visible {
  border: 2px solid #6172d6;
  visibility: hidden;
}

.packagecard_disoucntprice_para {
  text-align: center;
  margin: 0 !important;
}

.selectedpackagecard_disoucntprice_para {
  text-align: center;
  margin: 0;
  font-weight: 500 !important;
  font-size: 14px;
}

body[dir="rtl"] .selectedpackagecard_disoucntprice_para {
  font-weight: 500 !important;
}

.packagecard_usersallows {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.packagecard_usersallows + p {
  font-size: 12px;
}

.packagecard_usersallows_heading {
  font-weight: 900;
  text-transform: uppercase;
  color: #000;
}

.packagecard_usersallows_members {
  padding: 8px 0;
  font-size: 11px;
  width: 120px;
  text-align: center;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  color: #fff;
  text-transform: uppercase;
}

body[dir="rtl"] .packagecard_usersallows_members {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.packagecard_usersallows_members p {
  margin: 0;
}

.packagecard_usersallows_members_value {
  border: 1px solid #ccc;
}

.packagecard_btn {
  width: 100%;
  background-color: #6172d6 !important;
  border-radius: 1px !important;
  border: 1px solid #6172d6 !important;
  visibility: visible;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.selected-package-text {
  padding: 0;
  height: auto;
  overflow: hidden;
  margin: 10px 0;
}

body[dir="rtl"] .selected-package-text {
  font-weight: 300 !important;
}

.ExecutiveMembersRange {
  width: 100%;
  border-radius: 20px !important;
  border: 0.5px solid #949494 !important;
  background-color: #fff !important;
}

.BoardMembersRange {
  width: 100%;
  border-radius: 20px !important;
  border: 0.5px solid #949494 !important;
  background-color: #fff !important;
}

.crossicon {
  color: #707070 !important;
}

.subcriptionkey_1 {
  background: linear-gradient(to left, #6172d6 5%, #6172d6 95%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500 !important;
  border-top-left-radius: 3px;
}

body[dir="rtl"] .subcriptionkey_1 {
  background: linear-gradient(to right, #6172d6 5%, #6172d6 95%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 3px !important;
}

.subcriptionkey_2 {
  background: linear-gradient(to right, #6172d6 1%, #49dbdb 99%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500 !important;
  border-top-right-radius: 3px;
}

body[dir="rtl"] .subcriptionkey_2 {
  background: linear-gradient(to left, #6172d6 1%, #49dbdb 99%);
  margin: 0;
  padding: 5px;
  color: #fff;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500 !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 3px !important;
}

.packageCard_title {
  color: #5a5a5a;
  text-transform: uppercase;
  font-size: 32px;
  text-align: center;
  font-weight: 500 !important;
  margin-bottom: 20px;
}

body[dir="rtl"] .packageCard_title {
  font-weight: 700 !important;
}

.cancel_subscription {
  text-align: center;
  text-transform: capitalize;
  margin: 10px 0;
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .cancel_subscription {
  font-weight: 600 !important;
}

.subcriptionvalue_1 {
  border-bottom-left-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 500 !important;
  padding: 3px 0;
}

body[dir="rtl"] .subcriptionvalue_1 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border: 1px solid #ccc;
  font-weight: 500 !important;
  padding: 3px 0;
}

.subcriptionvalue_2 {
  border-bottom-right-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 500 !important;
  padding: 3px 0;
}

body[dir="rtl"] .subcriptionvalue_2 {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px !important;
  border: 1px solid #ccc;
  font-weight: 500 !important;
  padding: 3px 0;
}

.UpgradePackageCard_box1 {
  padding: 12px 80px !important;
  position: relative;
}

.UpgradePackageCard_box2 {
  padding: 12px 80px !important;
  position: relative;
}

body[dir="rtl"] .UpgradePackageCard_box2 {
  font-weight: 600 !important;
}

.lineBar {
  width: 1px;
  height: 80%;
  background-color: #ccc;
  vertical-align: middle;
  position: absolute;
  top: 55px;
  right: 0;
}

body[dir="rtl"] .lineBar {
  right: 100% !important;
}

.height-20 {
  height: 50px;
}

.reason-heading {
  font-weight: 700 !important;
  color: #5a5a5a;
}

body[dir="rtl"] .reason-heading {
  font-weight: 700 !important;
}

.reason-lines {
  font-weight: 500 !important;
}

img.package-icon {
  position: absolute;
  top: -11px;
  left: 40px;
  width: 85px;
}

body[dir="rtl"] img.package-icon {
  position: absolute;
  top: -11px;
  left: auto !important;
  right: 40px !important;
  width: 85px;
}

.warning {
  font-size: 120px !important;
}

.modalFootercancel-sub {
  display: none !important;
}
