.Prposed_Meeting_heading {
  font-weight: 600 !important;
  font-size: 30px !important;
  color: #5f78d6 !important;
}

.Paper_styling {
  width: 100% !important;
  height: auto !important;
  padding: 40px 40px !important;
  border-radius: 8px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Heading_prposed_meeting {
  font-weight: 600 !important;
  font-size: 30px !important;
  color: #5f78d6 !important;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 100% !important;
}

.Staff_meeting_Heading {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5a5a5a !important;
}

.Paragraph_Styles {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.Prposed_On_Heading {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5f78d6 !important;
}

.Steric_Color {
  color: #f16b6b !important;
}

.Box_for_Send_Request {
  height: 39px !important;
  width: 100%;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.Add_Dates_Btn_Class {
  border: 1px dotted #ccc !important;
  background: #fff !important;
  border-radius: 4px !important;
  height: 39px !important;
  width: 100%;
}

.Add_dates_label {
  font-size: 12px !important;
  color: #5f78d6 !important;
  font-weight: 500 !important;
}

.Scroller_meeting {
  height: 22vh !important;
  overflow-x: hidden !important;
}

.Scroller_meeting::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_meeting::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Cancel_Button_ProposedMeeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff;
  border: 1px solid #e1e1e1;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.Cancel_Button_ProposedMeeting:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.Save_Button_ProposedMeeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #5f78d6;
  border: none;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.Save_Button_ProposedMeeting:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #5f78d6 !important;
  border: none !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
}

.Box_To_Show_Time {
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.Time_Class {
  color: #5a5a5a;
  font-family: "Montserrat";
  font-size: 12px !important;
  display: flex !important;
}

.CheckBox_Class {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.Inner_Send_class {
  margin-top: 10px !important;
}

.OR_Heading {
  font-size: 30px !important;
  font-weight: 800 !important;
  color: #a2a2a2;
}

.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 600 !important;
}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 600 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 0px;
  color: #f16b6b;
}

.Scroller_Prposed_Meeting_date {
  height: 22vh !important;
  overflow-x: hidden !important;
}

.Scroller_Prposed_Meeting_date::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_Prposed_Meeting_date::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Width_Date_SendResponseBy {
  width: 100% !important;
}
