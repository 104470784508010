.Add-note-QuillRow {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.modal-Note-Title-Input :focus {
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .modal-Note-Title-Input {
  font-weight: 500 !important;
}

.quill-height-addNote {
  border-top: 1px solid lightgrey;
  height: 18vh !important;
  border-radius: 3px !important;
  position: relative;
}

.quill-height-addNote .ql-toolbar.ql-snow {
  color: #a2a2a2 !important;
}

.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 700 !important;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
  margin-left: 3px !important;
}

.Leftpolygon {
  background: none;
  border: none;
  display: flex !important;
  align-items: center !important;
}

body[dir="rtl"] .Leftpolygon {
  transform: rotate(180deg) !important;
}

.Leftpolygon:hover,
.Leftpolygon:active,
.Leftpolygon:focus,
.Leftpolygon:focus-visible {
  background: none !important;
  border: none !important;
}

.Attachments_Heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.filesStyles {
  width: 100% !important;
  display: flex !important;
  gap: 35px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}

.Outer_Border-Line {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  margin-top: 8px !important;
}

.Button_General {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 73px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_General:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 73px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_General_nonActive {
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 73px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Agenda {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 127px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Agenda_nonActive {
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 127px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Agenda:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 127px !important;
  height: 29px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Save {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 89px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Save:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 89px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Reset_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-color: #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 95px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Reset_Button:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-color: #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 95px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Add_Reviewers {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 153px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Add_Reviewers:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 153px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.PublishMinutes,
.PublishMinutes:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 159px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin-right: 10px;
}

.Button_Next {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 88px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Button_Next:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 88px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Invite_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-color: #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 179px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.Invite_Button:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-color: #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  width: 179px !important;
  height: 39px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.RedCrossClass {
  position: absolute !important;
  right: 0;
  top: -10px;
  cursor: pointer !important;
}

body[dir="rtl"] .RedCrossClass {
  position: absolute !important;
  right: auto;
  left: 0;
  top: -10px;
  cursor: pointer !important;
}

.Name {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
}

.Uploaded_heading {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5f78d6 !important;
}

.Line_heigh {
  margin-top: 7px !important;
  line-height: 15px !important;
}

.Profile_minutes {
  border-radius: 50% !important;
}

.Date_Minutes_And_time {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5f78d6 !important;
}

.Box_Minutes {
  width: 100% !important;
  height: auto !important;
  min-height: 92px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  position: relative !important;
}

.Sizing_Saved_Minutes {
  padding: 0px 10px !important;
}

.Title_File {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5a5a5a !important;
  display: flex !important;
  gap: 10px !important;
}

.Show_more {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.Cancel_button_Minutes {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_button_Minutes:is(:active, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.errorNotesMessage {
  height: 0px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden {
  font-weight: 600 !important;
}

.errorNotesMessage_description {
  margin: 7% 4px 4px 0px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage_description {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden_description {
  visibility: hidden;
  margin: 7% 4px 4px 4px;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden_description {
  font-weight: 600 !important;
}
.Next_button_Minutes {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

p.Attachments {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #6172d6 !important;
}

.viewMinutesAttachments {
  max-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.agenda-wrapper-open {
  padding: 15px 0px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.agenda-wrapper-closed {
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.agenda-title {
  font-weight: 600;
  font-size: 16px;
  color: #5f78d6;
  margin: 0 !important;
}

.Arrow_Expanded {
  transition: transform 0.5s ease;
}

.Arrow {
  transition: transform 0.5s ease;
  transform: rotate(180deg);
}

.pendingApprovalContainer {
  width: 98% !important;
  height: 91vh !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

.pendingApprovalHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #6172d6;
}

.Accept-all {
  width: 123px;
  height: 39px;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

.Accept-all:is(:active, :hover, :focus, :focus-visible) {
  width: 123px;
  height: 39px;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

span.No-of-reviews {
  width: 292px;
  height: 40px;
  background: #fff8e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ddb227;
  margin-right: 15px;
}

body[dir="rtl"] span.No-of-reviews {
  width: 292px;
  height: 40px;
  background: #fff8e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ddb227;
  margin-right: auto !important;
  margin-left: 15px !important;
}

.pendingApprovalPaper {
  border: 1px solid #e1e1e1;
  height: auto !important;
  min-height: 78vh !important;
  width: 100% !important;
}

p.Parent-title-heading {
  font-weight: 600;
  font-size: 16px;
  color: #5f78d6;
}

p.minutes-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #5a5a5a;
  margin: 0 !important;
  overflow-wrap: break-word !important;
  max-width: 100% !important;
}

span.bar-line {
  border: 1px solid #e1e1e1;
  display: block;
  height: 100%;
  border-top: none;
  border-right: none;
  border-bottom: none;
  position: absolute;
}

img.Image {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

p.agendaCreater {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #5a5a5a;
}

.uploaded-details {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
}

.uploaded-details-rejected {
  border: 1px solid #f16b6b;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative; /* Added position relative for positioning the ::after element */
  margin-left: 63px;
}

.uploaded-details-accepted {
  border: 1px solid #81db86;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 100px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative; /* Added position relative for positioning the ::after element */
  margin-left: 63px;
}

p.uploadedbyuser {
  font-size: 12px;
  font-weight: 700;
  color: #5f78d6;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
}

body[dir="rtl"] p.uploadedbyuser {
  font-size: 12px;
  font-weight: 700;
  color: #5f78d6;
  margin-bottom: 0 !important;
  margin-left: auto !important;
  margin-right: 20px !important;
}

.gap-ti {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px !important;
}

body[dir="rtl"] .gap-ti {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto !important;
  margin-right: 20px !important;
}

p.time-uploader {
  margin: 0;
  position: absolute;
  bottom: 20px;
  margin-left: 20px;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

body[dir="rtl"] p.time-uploader {
  margin: 0;
  position: absolute;
  bottom: 20px;
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

p.date-uploader {
  margin: 0;
  position: absolute;
  bottom: 5px;
  margin-left: 20px;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

body[dir="rtl"] p.date-uploader {
  margin: 0;
  position: absolute;
  bottom: 5px;
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #5f78d6 !important;
  font-weight: 500;
  font-size: 12px;
}

.inherit-height {
  min-height: inherit !important;
}

.review-minutes-wrapper-scroll {
  height: 57vh;
  overflow: hidden auto;
  padding-right: 15px;
  margin-bottom: 15px;
}

body[dir="rtl"] .review-minutes-wrapper-scroll {
  height: 57vh;
  overflow: hidden auto;
  padding-right: 0 !important;
  padding-left: 15px !important;
  margin-bottom: 15px;
}

.review-minutes-wrapper-scroll::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.review-minutes-wrapper-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.review-minutes-wrapper {
  height: 57vh;
  overflow: hidden auto;
  margin-bottom: 15px;
}

.review-minutes-wrapper::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.review-minutes-wrapper::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.viewAgendaWiseAttachments {
  max-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.viewAgendaWiseFiles {
  margin-bottom: 10px;
}

img.Attachment {
  margin-right: 20px;
}

body[dir="rtl"] img.Attachment {
  margin-right: auto;
  margin-left: 20px;
}

.agenda-wise-minutes {
  max-height: 370px;
  overflow: hidden auto;
  padding-right: 10px;
}

.agenda-wise-minutes::-webkit-scrollbar {
  width: 0.4rem !important;
  border-radius: 20px !important;
  background-color: #e1e1e1 !important;
}

.agenda-wise-minutes::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.reviewer-progress-text {
  display: flex;
  gap: 10px;
  color: #6172d6;
  font-weight: 600;
  font-size: 12px;
}

.reviewer-progress-wrapper {
  background: #f6f9fc !important;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

p.text-wrapper-review {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 12px;
}

span.Review-accepted {
  color: #81db86;
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}

span.Review-declined {
  color: #f16b6b;
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}

span.Review-pending {
  color: #ddb227;
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}

.box-agendas {
  position: relative;
  display: contents;
}

.popup-agenda-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 9;
  top: 32px;
  right: 10px;
  transition: opacity 0.3s ease;
  display: block;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.078));
  border-radius: 6px;
  width: 200px;
  height: 70px;
}

body[dir="rtl"] .popup-agenda-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 9;
  top: 32px;
  right: auto;
  transition: opacity 0.3s ease;
  display: block;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.078));
  border-radius: 6px;
  width: 200px;
  height: 70px;
  left: 10px;
}

.popup-agenda-menu span {
  color: #5a5a5a;
  text-decoration: none;
  text-align: left;
  display: flex;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #e1e1e1;
  padding: 7px;
  margin-left: 15px;
  margin-right: 15px;
  gap: 10px;
  cursor: pointer;
}

.popup-agenda-menu span p {
  background: #6172d6;
  color: #fff;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 100%;
  font-size: 9px;
}

span.reviewer-count {
  background: #ecefff;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 700;
  color: #5f78d6;
  height: 20px;
  width: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-block {
  display: flex;
  margin-bottom: 15px;
}
