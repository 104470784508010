.Proceed_btn_removeParticipant_modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Proceed_btn_removeParticipant_modal {
  font-weight: 600 !important;
}

.Proceed_btn_removeParticipant_modal:hover,
.Proceed_btn_removeParticipant_modal:focus,
.Proceed_btn_removeParticipant_modal:focus-visible,
.Proceed_btn_removeParticipant_modal:active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: none !important;
}

.Discard_btn_removeParticipant_modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Discard_btn_removeParticipant_modal:hover,
.Discard_btn_removeParticipant_modal:active,
.Discard_btn_removeParticipant_modal:focus,
.Discard_btn_removeParticipant_modal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Discard_btn_removeParticipant_modal {
  font-weight: 600 !important;
}

.Heading_For_Remove_participant {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .Heading_For_Remove_participant {
  font-weight: 600 !important;
}
