.skipButtons-onboardFinal_screen5 {
  width: 200px !important;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999999;
}

.skipButtons-onboard_screen5 {
  width: 95px !important;
  height: 46px !important;
  border: none !important;
  background: #6172d6 !important;
  border-radius: 2px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
}
