.Cancel_Upload_options_Heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6;
}
.paragrapgh {
  font-size: 16px !important;
  font-weight: 600;
  color: #5a5a5a;
}
.Continue_Upload_button_UploadFile {
  background: #6172d6;
  border: none;
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.Continue_Upload_button_UploadFile:focus,
.Continue_Upload_button_UploadFile:hover,
.Continue_Upload_button_UploadFile:active,
.Continue_Upload_button_UploadFile:focus-visible {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  border-radius: 4px !important;
}
.Cancel_Upload_button_UploadFile {
  background: #fff;
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
}

.Cancel_Upload_button_UploadFile:focus,
.Cancel_Upload_button_UploadFile:hover,
.Cancel_Upload_button_UploadFile:active,
.Cancel_Upload_button_UploadFile:focus-visible {
  background: #fff !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}
