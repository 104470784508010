.Forgot_Password_Verification_Tagline {
  font-size: 13px !important;
  margin-left: 16px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .Forgot_Password_Verification_Tagline {
  font-size: 13px !important;
  margin-right: 16px !important;
  font-weight: 400 !important;
}

.Forgot_Password_Verification_ResendCode {
  font-size: 14px !important;
  margin-left: 4px;
  color: #5f78d6 !important;
  text-decoration: underline;
}

.EmailVerifyLabel {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.ForgotPassword_Verification_EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px !important;
  padding: 0 6px;
}

body[dir="rtl"].ForgotPassword_Verification_EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto !important;
  padding: 0 10px !important;
}

.Forgot_Password_Verification_Next_button_EmailVerify {
  width: 90%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: calc(18px - 20%) !important;
}

.Forgot_Password_Verification_Next_button_EmailVerify:focus-visible {
  width: 90%;
  border-radius: 4px;
  font-size: calc(18px - 20%) !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.Forgot_Password_Verification_Next_button_EmailVerify:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6;
}

.Forgot_Password_Verification_Auth_Icon {
  position: absolute;
  right: -115px;
  bottom: -135px;
  transform: rotate(-16deg);
  z-index: 99999;
}

.Login_page_text {
  display: flex;
  margin-top: 12%;
  flex-direction: column;
  margin-left: 40px;
  justify-content: flex-start;
  align-items: flex-start;
}

.Login_page_text h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 114%;
}

.Forgot_password_Verification_loginbox_auth_paper {
  margin-left: 60px;
  padding: 50px 0px;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

body[dir="rtl"] .Forgot_password_Verification_loginbox_auth_paper {
  margin-right: 60px;
  padding: 30px 0;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.heading-1 {
  color: #6172d6;
}

.heading-2 {
  color: #4adede;
}

.Forgot_passwordforogt_verification_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  margin-top: 4px !important;
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

.Forgot_passwordforogt_verification_email_link a {
  color: #5a5a5a !important;
  font-weight: 400 !important;
}

.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 600 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 13px;
  color: #f16b6b;
}

.select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Forgot_Password_Verification_select-language-signin:focus,
.Forgot_Password_Verification_select-language-signin:focus-within,
.Forgot_Password_Verification_select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.Forgot_Password_Verification_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

body[dir="rtl"] .Forgot_Password_Verification_select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 80%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

.Forgot_password_Verification_language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.Forgot_password_Verification_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .Forgot_password_Verification_languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 80.5%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.OTPHandler {
  margin-left: 18px !important;
}

body[dir="rtl"] .OTPHandler {
  margin-right: 15px !important;
}

body[dir="rtl"] .EmailVerifyLabel {
  margin-right: 5px !important;
  font-size: 19px !important;
  margin-top: 10px !important;
}

body[dir="rtl"].Forgot_Password_Verification_ResendCode {
  margin-right: 15px !important;
}

.OTP_Error_Messege {
  font-size: 13px !important;
  color: red !important;
  margin-left: 17px;
  margin-top: 5px;
}

.Forgot_Password_Verification_resendCode_btn {
  margin-left: 3px !important;
  background: none !important;
  color: #000 !important;
  margin: 0;
  user-select: none;
  border: none !important;
  padding: 0;
  text-decoration: underline !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.Forgot_Password_Verification_resendCode_btn:hover {
  margin-right: 5px;
  background: none !important;
  color: #000 !important;
  margin: 0;
  user-select: none;
  border: none !important;
  padding: 0;
  text-decoration: underline !important;
}

.Forgot_Password_Verification_resendCode_btn:disabled {
  opacity: 30% !important;
  color: #000 !important;
}

.Forgot_Password_Verification_resendCode_btn span {
  user-select: none;
}

.Forgot_Password_Verification_resendCode_btn:hover {
  color: #000 !important;
  background: none !important;
  border: none !important;
  text-decoration: none;
}

.Forgot_password_Verification_update_OTPCounter {
  user-select: none !important;
  margin-right: 42px !important;
}

body[dir="rtl"] .Forgot_password_Verification_update_OTPCounter {
  user-select: none !important;
  margin-left: 35px !important;
  margin-right: 0px !important;
}

.BoxesforOTP {
  margin-top: -23px !important;
  margin-left: 5px !important;
}

body[dir="rtl"] .BoxesforOTP {
  margin-right: 5px !important;
  margin-left: 0px !important;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}
