/* select by default class */

.verification-email-modal-title {
  font-size: calc(35px - 20%) !important;
  color: #6172d6;
  font-weight: 600 !important;
}
body[dir="rtl"] .verification-email-modal-title {
  font-weight: 600 !important;
}
.allow-limit-modal-title {
  margin: 20px 0;
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
}

body[dir="rtl"] .allow-limit-modal-title {
  font-weight: 600 !important;
}

.verification-email-modal-paragraph {
  margin-top: -20px !important;
  margin-bottom: 40px;
  font-size: 14px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .verification-email-modal-paragraph {
  font-weight: 600 !important;
}
.Ok-modal-btn {
  width: 119px !important;
  height: 46px !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

body[dir="rtl"] .Ok-modal-btn {
  font-weight: 500 !important;
}

.Ok-modal-btn:hover {
  width: 119px !important;
  height: 46px !important;
  border: none;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.label-addUser {
  font-size: 18px !important;
  margin-top: 8px;
  font-weight: 600;
}

.label-others {
  font-size: 17px !important;
  margin-top: 10px;
}

.formcontrol-name-fieldssss {
  margin-top: 5px;
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
}
body[dir="rtl"] .formcontrol-name-fieldssss {
  font-weight: 500 !important;
}
.formcontrol-name-fieldssss:focus {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.formcontrol-name-fieldssss[placeholder] {
  font-size: 12px !important;
  font-weight: 500 !important;
}
body[dir="rtl"] .formcontrol-name-fieldssss[placeholder] {
  font-weight: 500 !important;
}
.formcontrol-Phone-Input-Textfield {
  margin-top: 9px;
  width: 100% !important;
  height: 39px !important;
  padding: 2px 8px;
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
}

.formcontrol-Phone-Input-Textfield:focus {
  border: 1px solid #707070 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.formcontrol-Phone-Input-Textfield[placeholder] {
  font-size: 12px !important;
  font-weight: 500 !important;
}
body[dir="rtl"] .formcontrol-Phone-Input-Textfield[placeholder] {
  font-weight: 500 !important;
}

div#react-select-2-placeholder {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.formcontrol-Phone-field {
  margin-top: 20px;
}

.AddProgressBar {
  margin: 10px 0;
  border-radius: 20px !important;
  border: 0.5px solid #ccc !important;
  background-color: linear-gradient(
    to right,
    #49dbdb 20%,
    #6172d6 80%
  ) !important;
}

body[dir="rtl"] .AddProgressBar {
  background-color: linear-gradient(
    to left,
    #49dbdb 20%,
    #6172d6 80%
  ) !important;
}

.chartbarBorder-adduser {
  margin-top: 0px !important;
  padding: 0px 0px 10px 0px;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #fff !important;
}

.labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  margin-left: 77px;
}

body[dir="rtl"] .labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  margin-left: 0 !important;
  margin-right: 77px !important;
}

.Total-labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.Admin-labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  margin-left: 77px;
}

body[dir="rtl"] .Admin-labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  margin-left: 0 !important;
  margin-right: 77px !important;
}

.Executive-labelChart-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.labelChart-Number {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}
body[dir="rtl"] .labelChart-Number {
  font-weight: 600 !important;
}
.Executive-labelChart-Number {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.Admin-labelChart-Number {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}
body[dir="rtl"] .Admin-labelChart-Number {
  font-weight: 600 !important;
}
.labelChart-Remain-Title {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: red !important;
}

.labelChart-RemainNum {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 25px;
  color: red !important;
}

.borderLine-title {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: 17%;
  width: 65% !important;
  border-bottom: 1px solid #949494 !important;
}

body[dir="rtl"] .borderLine-title {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  margin-left: auto;
  margin-right: 20%;
  width: 57% !important;
  border-bottom: 2px solid #adadad !important;
}

.addUser-Heading {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}
body[dir="rtl"] .addUser-Heading {
  font-weight: 600 !important;
}
.errorMessage {
  margin: 0;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 600 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 600 !important;
}

.errorMessageemail {
  margin: 0;
  height: 25px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessageemail {
  font-weight: 600 !important;
}

.errorMessageemail_hidden {
  visibility: hidden;
  margin: 0;
  height: 25px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessageemail_hidden {
  font-weight: 600 !important;
}

.add-User-Reset-btn {
  width: 120px;
  height: 40px;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  background-color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .add-User-Reset-btn {
  font-weight: 500 !important;
}

.add-User-Reset-btn:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
}

.Add-User-Create {
  width: 120px;
  height: 40px;
  border-radius: 4px !important;
  border: 1px solid #6172d6 !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

body[dir="rtl"] .Add-User-Create {
  font-weight: 500 !important;
}

.Add-User-Create[disabled] {
  filter: grayscale(0.5);
}

body[dir="rtl"] .Add-User-Create[disabled] {
  font-weight: 500 !important;
}

.Add-User-Create:hover {
  background-color: #6172d6 !important;
  color: #fff !important;
  border: 1px solid #6172d6 !important;
}

.allowModalIcon {
  color: #f16b6b;
  border: none !important;
  border-radius: 0px !important;
}

body[dir="rtl"]
  div[aria-label="A tabular representation of the data in the chart."] {
  left: 0 !important;
}

.isEmailUnique {
  position: absolute;
  right: -22px;
  width: 30px;
  bottom: 30px;
  height: 30px;
  color: green;
}

body[dir="rtl"] .isEmailUnique {
  position: absolute;
  right: 100%;
  width: 30px;
  bottom: 30px;
  height: 30px;
  color: green;
}

.checkEmailSpinner {
  position: absolute;
  left: 100%;
  width: 29px;
  height: 29px;
  top: 30px;
}

body[dir="rtl"] .checkEmailSpinner {
  position: absolute;
  right: 100%;
  width: 29px;
  height: 29px;
  top: 30px;
}

.emailcheckbox {
  position: relative;
}

.emailcheckbox .checker {
  position: absolute;
  right: 0;
  top: -10px !important;
}

.IconStyle {
  display: block !important;
  width: 92% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: relative !important;
  cursor: pointer;
}

.react-flag {
  height: 30px !important;
  padding: auto !important;
}

.react-flag button {
  cursor: pointer !important;
  margin-top: 9px !important;
  max-width: 80px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 8px 4px !important;
  font-family: inherit;
  color: #4d4d4d !important;
  font-size: 9px !important;
  font-weight: 600;
  border-radius: 4px !important;
  background: white !important;
}

.react-flag input {
  cursor: pointer !important;
  padding: 5px 8px !important;
  margin: 4px 5px !important;
  white-space: nowrap !important;
}

.react-flag button:after {
  border-top: 4px solid #4d4d4d;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 0;
  margin-left: -3px;
}

.react-flag button[aria-expanded="true"]:after {
  border-top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #4d4d4d;
  margin-left: -3px;
}

.close-modal-verification.modal-header button.btn-close {
  display: none;
}

.react-flag ul::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-block: 25px;
}

.react-flag ul::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #e1e1e1;
}

body[dir="rtl"] .react-flag button:after {
  margin-left: 0px !important;
}

span.ReactFlagsSelect-module_selectFlag__2q5gC {
  margin-right: -5px;
}

button#rfs-btn {
  min-width: 85px !important;
  height: 30px;
  margin-top: 20px !important;
  background: #fff;
}

.Select-box-column .css-qbdosj-Input {
  display: flex !important;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 2px !important;
  padding-left: 12px !important;
  padding-bottom: 2px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

/* css usermanagement start*/

.flex-direction-column {
  display: flex !important;
  flex-direction: column !important;
}

.label-styling {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 10px !important;
}

.associates-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.aesterick-color {
  color: #f16b6b !important;
}

.checkbox-label {
  margin-left: 7px !important;
}

.container-main-class {
  padding: 0px 40px !important;
}
