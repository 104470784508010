.talk-video-heading {
  /* position: relative; */
  /* top: 12px; */
  font-size: 15px;
  color: #5a5a5a !important;
  font-weight: 600;
}

.videoFilter {
  /* margin-top: 10px; */
  width: 108px;
}

.videoFilter .ant-select-selector {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 4px !important;
    width: 85px !important;
    color: #fff !important;
    font-weight: 600 !important;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft:has(
    .rc-virtual-list-holder-inner
      .ant-select-item.ant-select-item-option.talk-video-filter
  ) {
  min-width: 87px !important;
  width: 87px !important;
}

.rc-virtual-list-holder-inner:has(.talk-video-filter) {
  display: flex !important;
}
