.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 2px;
}

.onboard_atthendees_btn {
  margin-left: 20px !important;
}

.onboard_details {
  margin-top: 10px !important;
}

.onboard_detail_meetingtitle {
  margin-bottom: -10px !important;
}

.onboard_detail_description {
  margin-bottom: -10px !important;
}

.onboard_detail_description_input {
  resize: none !important;
  height: 151px !important;
}

.onboard_agenda {
  border: 1px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 8px;
}

.onboard_fileIcon {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
}

.onboard_addAgenda {
  font-size: 18px !important;
  color: #266fdc !important;
  text-align: left !important;
}

.onboard_attendees {
  border: 1px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.onboard_add_attendees_btn {
  margin-top: 8px;
  margin-bottom: 10px;
}
.onboard_attendees_organizer_label {
  color: #266fdc;
  margin-top: 5px;
  font-size: 20px;
}
.onboard_attendees_participant_label {
  color: #266fdc;
  margin-top: 10px;
  font-size: 20px;
}

.modal-isDetail-upperBtn {
  cursor: auto;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%);
  width: 90px !important;
  font-size: 15px;
  border: 1px solid #e1e1e1 !important;
  height: 33px !important;
  border-radius: 4px !important;
}

.modal-isDetail-upperBtn:hover {
  cursor: auto;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%);
  width: 90px !important;
  font-size: 15px;
  border: 1px solid #e1e1e1 !important;
  height: 33px !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .modal-isDetail-upperBtn {
  cursor: auto;
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%);
  width: 84px !important;
  font-size: 15px;
  border: 1px solid #e1e1e1;
  height: 33px !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .onboard_detail_camera {
  cursor: auto;
  border: 1px solid #e1e1e1;
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%);
}

.onboard_detail_camera {
  position: relative !important;
  margin-top: 14px !important;
  padding: 3px 12px !important;
  cursor: auto;
  border: 1px solid #e1e1e1 !important;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%);
}

.onboard_detail_camera:hover {
  cursor: auto;
  border: 1px solid #e1e1e1 !important;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%);
}

.next-btn {
  width: 100px !important;
  font-size: 12px;
  height: 36px !important;
  cursor: auto;
  border: 1px solid #e1e1e1 !important;
  background: #6172d6 !important;
}

.next-btn:hover,
.next-btn:active {
  width: 100px !important;
  font-size: 12px;
  cursor: auto;
  border: 1px solid #e1e1e1 !important;
  background: #6172d6 !important;
  height: 36px !important;
}

.onboard-date-picker {
  margin-top: -12px;
  padding: 0px 10px !important;
  height: 35px !important;
}

.createCheckbox-tik {
  margin-top: 8px !important;
  margin-right: 10px !important;
  color: #a2a2a2 !important;
  font-weight: 600 !important;
}

@media (min-width: 576px) {
  .fade.onboard-modal.modal.show {
    --bs-modal-margin: 28px !important;
  }
}
