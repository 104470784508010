.badge-icon {
  background-color: #6172d6 !important;

  width: 18px;
  height: 18px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
}

.minutes-view {
  overflow-y: auto;
  max-height: 355px;
}

.minutes-view-2 {
  overflow-y: auto;
  max-height: 346px;
}

.minutes-box {
  max-height: 220px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  filter: grayscale(0.5) !important;
}

.modalMeetingUpdateFooter {
  border-top: none !important;
  height: 20% !important;
  max-height: 20% !important;
  min-height: 20% !important;
}

.modalMeetingUpdateBody {
  max-height: 75% !important;
  height: 75% !important;
  min-height: 75% !important;
  padding: 0 35px !important;
}

.modalMeetingUpdateFooter {
  height: 20%;
  max-height: 20%;
  min-height: 20%;
}

.minutesOftheMeatingStatus-col {
  margin-left: 0px;
}

.udpateeetingtime-row-1 {
  margin-top: 25px;
  height: 59px;
}

.updatemeetingvideoiconbtrrow {
  margin-top: -10px !important;
  height: 79px !important;
}

.updatemeetingtextarearow {
  margin-top: -25px;
  height: 220px !important;
}

.updatemeetingtextarea {
  resize: none !important;
  height: 151px !important;
  overflow-y: auto !important;
}

.file-icon-updateMeeting {
  position: relative;
  margin: 10px 0 !important;
  width: 90px !important;
  height: 100px;
  cursor: pointer;
}

.file-icon-updatemeeting-p {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

.updatemeeting-attendees-row {
  margin-top: 20px;
  border-radius: 4px !important;
}

.updatemeeting-participant-scroll {
  max-height: 53vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-top: 10px !important;
}

.updatemeeting-participant-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.updatemeeting-participant-scroll::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.updatemeeting-participant-scroll-organizer {
  color: #6172d6;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.updatemeeting-participant-scroll-participant {
  color: #6172d6;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.updatemeetingofminutes-row {
  height: 334px !important;
}

.updatemeetingofminutes-agendaTitle {
  font-weight: 600;
  color: #6172d6;
  height: 100% !important;
}

.updatemeeting-minutesofmeetings-none {
  margin-top: 75px !important;
}

.updatemeeting-publishMeeting-btn {
  width: 450px !important;
  margin-top: 20px !important;
}

.updatemeeting-cancel-btn {
  width: 450px !important;
  margin: 30px 0 !important;
}

button.updatemeetingmodalconfirmationdiscard {
  width: 119px !important;
  height: 46px !important;
  background-color: #ffffff !important;
  border: 1px solid #e1e1e1 !important;
  color: #000 !important;
  border-radius: 4px !important;
}

button.cancelmeetingmodalgoBackbtn {
  width: 119px !important;
  height: 46px !important;
  background-color: #ffffff !important;
  border: 1px solid #e1e1e1 !important;
  color: #000 !important;
  border-radius: 4px !important;
}

button.cancelmeetingmodalcancelbtn {
  width: 119px !important;
  height: 46px !important;
  background-color: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}

button.updatemeetingmodalconfirmationsubmit {
  width: 119px !important;
  height: 46px !important;
  background-color: #6172d6 !important;
  border-radius: 4px !important;
}

.display-contents {
  display: contents !important;
}

.CancelMeeting-modal {
  background-color: #f16b6b !important;
  color: #f16b6b !important;
}

.modalMeetingUpdateHeader {
  margin-top: 15px !important;
}

.modal-update-meeting-details {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-weight: 600 !important;
}

.modal-update-meeting {
  background: #6172d6 !important;
  border: none !important;
  width: 119px !important;
  height: 46px !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  font-weight: 500 !important;

  font-weight: 600 !important;
}

body[dir="rtl"] .modal-update-meeting {
  margin-left: 3px;
}

.modal-update-addagenda {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-weight: 600 !important;
}

.modal-update-addagenda:disabled {
  filter: grayscale(0.5) !important;
  background: #6172d6 !important;
}

body[dir="rtl"] .modal-update-addagenda {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 2px !important;
  margin-top: 6px !important;
  font-weight: 600 !important;
}

.update-add-meeting-btn {
  background: #6172d6 !important;
  width: 100px !important;
  height: 31px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 10px;
  margin-right: -10px;
}

.update-add-meeting-btn:disabled {
  filter: grayscale(0.5);
}

.update-add-attendee-btn {
  background: #6172d6 !important;
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border: none !important;
}

.update-add-attendee-btn:disabled {
  filter: grayscale(0.5);
}

.update-modal-discard-btn {
  background-color: #f16b6b !important;
  border-color: #f16b6b !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  margin-right: 9px;
  font-weight: 600;
}

.cancel-meeting-btn {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.publish-meeting-btn {
  background: #6172d6 !important;
  border: none !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-weight: 600 !important;
}

.isminutes-discard-btn {
  background-color: #f16b6b !important;
  border-color: #f16b6b !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.isminutes-cancel-btn {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #171d2d !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.ismeeting-finish-btn {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
  width: 150px !important;
  height: 45px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.update-isminutes-addbtn {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
  width: 92px !important;
  height: 32px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.update-isminutes-addbtn:disabled {
  filter: grayscale(0.5) !important;
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
}

.modalupdatemeeting-add {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.agenda-upper-button {
  padding: 0px !important;
  margin-left: 4px !important;
}

.attendees-upper-button {
  padding: 0px !important;
  margin-left: 18px !important;
}

.minutes-upper-btn {
  padding: 0px !important;
  margin-left: 20px !important;
  margin-right: -10px;
}

.updatemeeting-attendees-row
  .inputSearchFilter
  .search-container-assignee
  .dropdown-assignee {
  width: 260px !important;
  top: 29px !important;
}

.addattendee-textfield-Update {
  width: 290px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.select-Update-participant-box {
  margin-top: 5px !important;
  width: 33% !important;
}

.isDetail-Update-btn,
.isDetail-Update-btn:is(
    :active,
    :hover,
    :focus,
    :focus-visible,
    :visited,
    :target
  ) {
  padding: 8px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  font-size: 15px !important;
  color: #fff !important;
  outline: none !important;
  font-weight: 600;
}

body[dir="rtl"] .isDetail-Update-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isDetail-Update-Outline-btn,
.isDetail-Update-Outline-btn:is(
    :hover,
    :active,
    :focus,
    :focus-visible,
    :visited,
    :target
  ) {
  color: #666666 !important;
  padding: 8px 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #fff !important;
  outline: none !important;
  border-color: none !important;
  font-size: 15px !important;
  font-weight: 600;
}

.isAgenda-Update-btn {
  padding: 3px 20px !important;
  border: none !important;
  margin-left: 20px;
  border-radius: 3px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 600;
}

body[dir="rtl"] .isAgenda-Update-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isAgenda-Update-Outline-btn {
  color: #666666 !important;
  border-color: #e1e1e1 !important;
  padding: 3px 20px !important;
  margin-left: 20px;
  border-radius: 3px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isAttendees-Update-btn {
  padding: 3px 20px !important;
  border: none !important;
  margin-left: 40px;
  border-radius: 3px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
}

body[dir="rtl"] .isAttendees-Update-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isAttendees-Update-Outline-btn {
  color: #666666 !important;
  border-color: #e1e1e1 !important;
  padding: 3px 20px !important;
  margin-left: 40px;
  border-radius: 3px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isMinutes-Update-btn {
  padding: 3px 20px !important;
  border: none !important;
  margin-left: 50px;
  border-radius: 3px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
}

body[dir="rtl"] .isMinutes-Update-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isMinutes-Update-Outline-btn {
  color: #666666 !important;
  border-color: #e1e1e1 !important;
  padding: 3px 20px !important;
  margin-left: 50px;
  border-radius: 3px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .isDetail-Update-btn {
    padding: 3px 20px !important;
    border: none !important;
    border-radius: 3px !important;
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    font-size: 12px !important;
  }

  .isDetail-Update-Outline-btn {
    color: #666666 !important;
    border-color: #e1e1e1 !important;
    padding: 3px 20px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
  }

  .isAgenda-Update-btn {
    padding: 3px 20px !important;
    border: none !important;
    margin-left: 20px;
    border-radius: 3px !important;
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    font-size: 12px !important;
  }

  .isAgenda-Update-Outline-btn {
    color: #666666 !important;
    border-color: #e1e1e1 !important;
    padding: 3px 20px !important;
    margin-left: 20px;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
  }

  .isAttendees-Update-btn {
    padding: 3px 20px !important;
    border: none !important;
    margin-left: 40px;
    border-radius: 3px !important;
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    font-size: 12px !important;
  }

  .isAttendees-Update-Outline-btn {
    color: #666666 !important;
    border-color: #e1e1e1 !important;
    padding: 3px 20px !important;
    margin-left: 40px;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
  }

  .isMinutes-Update-btn {
    padding: 3px 20px !important;
    border: none !important;
    margin-left: 50px;
    border-radius: 3px !important;
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    font-size: 12px !important;
  }

  .isMinutes-Update-Outline-btn {
    color: #666666 !important;
    border-color: #e1e1e1 !important;
    padding: 3px 20px !important;
    margin-left: 50px;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
  }
}

.deleteModal-message {
  font-size: calc(19px - 20%);
  text-align: center !important;
  font-weight: 600 !important;
}

.modalupdate_updatebtn {
  width: 119px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: 600 !important;
  font-size: 12px;
}

.modalupdate_updatebtn:hover,
.modalupdate_updatebtn:visited,
.modalupdate_updatebtn:focus,
.modalupdate_updatebtn:focus-visible,
.modalupdate_updatebtn:target {
  width: 119px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.modalupdate_CancelBtn {
  width: 119px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #5a5a5a !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.select-participant-update-box {
  margin-top: -1px !important;
}
.meeting_update .modal-content {
  width: 700px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  padding: 20px 0 0 0;
}

.deleteAgendaBtn {
  background-color: #f16b6b !important;
  width: 80px !important;
  height: 32px !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.UpdateMeeting_discardChangesBtn {
  background-color: #f16b6b !important;
  padding: 12px 15px;
  border: none !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UpdateMeeting_cancelMeetingBtn {
  background-color: #6172d6 !important;
  padding: 12px 15px;
  border: none !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}
.UpdateMeeting_publishMeetingBtn {
  background-color: #6172d6 !important;
  padding: 12px 30px;
  border: none !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.errorMessage {
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
  font-weight: 600;
}

body[dir="rtl"] .errorMessage {
  font-weight: 600;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}
.modal_update_bodyClass {
  border-bottom: 0px !important;
}
