.Guest-Removed-Video {
  padding: 60px 90px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.Removed-Main-Heading {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  color: #6172d6 !important;
}

.Sub-Removed-Main-Heading {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #5a5a5a !important;
}

.Rejoin-Button-Class {
  padding: 8px 24px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}
