.Vote_poll_Heading {
  font-size: 20px !important;
  color: #6172d6;
  font-weight: 600;
}

.Multiple_vote_poll {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Yes_Vote_poll {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600;

  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  width: 100% !important;
}

.Vote_Poll_cross_ICon {
  cursor: pointer !important;
  margin: 0;
  padding: 0;
}

.ViewTitleTOShowOnProgress {
  font-size: 14px !important;
  margin-top: 10px !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.Border_box {
  width: 100%;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.OverAll_padding {
  padding: 0px 45px !important;
  margin-top: -25px !important;
}

.CheckBox_ViewProgressPolls {
  margin: 0;
  padding: 0;
  margin-top: 8px !important;
}

.Progress_bar_view_polls {
  margin: 0;
  padding: 0;
}

.Close_Btn_votepoll {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.Close_Btn_votepoll:hover,
.Close_Btn_votepoll:active,
.Close_Btn_votepoll:focus,
.Close_Btn_votepoll:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.Submit_btn_votepoll {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Submit_btn_votepoll:hover,
.Submit_btn_votepoll:active,
.Submit_btn_votepoll:focus,
.Submit_btn_votepoll:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.Scroller-Vote-Poll {
  height: 20vh !important;
  overflow-x: hidden !important;
  width: 100%;
}

.Scroller-Vote-Poll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.Scroller-Vote-Poll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.BOx_for_yes {
  width: 100%;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding-left: 10px;
  margin: 0 0 10px 0;
}
.scrollable-title {
  max-height: 100px;
  min-height: 39px;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
  overflow-y: auto;
}
.scrollable-title::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.scrollable-title::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.scrollable-title2 {
  min-height: 39px !important;
}
.Scroller_View_Poll_Published {
  height: 28vh !important;
  overflow-x: hidden !important;
  margin-top: 6px !important;
}

.Scroller_View_Poll_Published::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.Scroller_View_Poll_Published::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
