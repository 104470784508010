.Grant_Access_Styles {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.Cancel_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.FixedHeight {
  height: 60vh !important;
}

.Cancel_Meeting_Details {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Empty_state_heading {
  color: #505050 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.Empty_state_Subheading {
  color: #5a5a5a !important;
  font-size: 12px !important;
font-weight: 500;
}
