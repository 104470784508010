.View-Committee-heading {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

body[dir="rtl"] .View-Committee-heading {
  font-weight: 600;
}

.View-Committee-Subheading {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600;

}

body[dir="rtl"] .View-Committee-Subheading {
  font-weight: 600;
}

.Management-Heading-View-Committee {
  font-size: 30px !important;
  color: #5a5a5a;
  word-wrap: break-word;
  font-weight: 600;

}

body[dir="rtl"] .Management-Heading-View-Committee {
  font-weight: 600;
}

.View-Committee-Head-Heading {
  font-size: 20px !important;
  color: #6172d6 !important;
  word-wrap: break-word;
  font-weight: 600;

}

body[dir="rtl"] .View-Committee-Head-Heading {
  font-weight: 600;
}

.ViewCommittee-head-info {
  line-height: 15px !important;
}

.name-ViewCommittee-group {
  color: #5a5a5a;
  font-size: 12px !important;
  font-weight: 600;

}

body[dir="rtl"] .name-ViewCommittee-group {
  font-weight: 600;
}

.paragraph-content-View-Committee {
  color: #5a5a5a;
  font-size: 16px !important;
  word-wrap: break-word;
  font-weight: 600;

}

body[dir="rtl"] .paragraph-content-View-Committee {
  font-weight: 600;
}

.Designation-ViewCommittee-group {
  color: #5a5a5a;
  font-size: 10px !important;
  font-weight: 600;

}

body[dir="rtl"] .Designation-ViewCommittee-group {
  font-weight: 600;
}

.email-ViewCommittee-group {
  color: #6172d6;
  text-decoration: underline !important;
  font-size: 10px !important;
  font-weight: 400;

}

body[dir="rtl"] .email-ViewCommittee-group {
  font-weight: 400;
}

.members-ViewCommittee-group-page {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600;

}

body[dir="rtl"] .members-ViewCommittee-group-page {
  font-weight: 600;
}

.scroll-bar-ViewGroup {
  height: 39vh !important;
  overflow-x: hidden !important;
}

.scroll-bar-ViewGroup::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-ViewGroup::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.View-Committee-paper {
  height: auto !important;
  width: 100% !important;
  padding: 30px 40px 20px 40px !important;
  /* margin-top: 8px !important; */
}

.Close-ViewCommittee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .Close-ViewCommittee-btn {
  font-weight: 600;
}

.Close-ViewCommittee-btn:is(:hover, :active, :focus, :focus-visible) {
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Save-ViewCommittee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Save-ViewCommittee-btn:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin-left: 3px !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  position: relative;
  margin: 5px;
  cursor: pointer;
}
.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}
.files_View {
  min-height: 43vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  max-height: 43vh;
}
