.Voter_modal_heading {
  font-size: 20px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Vote_switch_heading {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Bottom_line {
  width: 100% !important;
  height: 1px !important;
  display: block;
  margin-top: 10px;
  background-color: #e1e1e1;
}

.Vote_title {
  font-size: 20px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block;
  width: 80% !important;
}

.OVer_padding {
  padding: 0px 30px !important;
}

.Vote_question_heading {
  font-size: 12px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Outer_description_box {
  height: 69px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.Inner_text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block;
  width: 90% !important;
}

.plus_button {
  height: 39px !important;
  width: 39px !important;
  border: 1px dotted #e1e1e1 !important;
  background: #fff !important;
}

.plus_button:hover,
.plus_button:active,
.plus_button:focus,
.plus_button:focus-visible {
  height: 39px !important;
  width: 39px !important;
  border: 1px dotted #e1e1e1 !important;
  background: #fff !important;
}

.IconClass {
  display: flex;
  justify-content: center !important;
  margin-top: -5px !important;
  margin-left: -3px !important;
}

.ResCrossIcon {
  position: absolute !important;
  /* left: 79%; */
  bottom: 30px !important;
  right: 0 !important;
  left: 130px;
  z-index: 6;
  /* bottom: 58% !important; */
  display: block !important;
}

.Cancell_Btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Cancell_Btn:hover,
.Cancell_Btn:focus-visible,
.Cancell_Btn:focus,
.Cancell_Btn:active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Save_btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.Save_btn:hover,
.Save_btn:active,
.Save_btn:focus,
.Save_btn:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.Vote_modal_heading {
  font-size: 12px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Members_Heading {
  font-size: 12px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Add_more_Btn {
  width: 80px !important;
  height: 29px !important;
  border-radius: 4px !important;
  background: #5f78d6 !important ;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 8px !important;
  border: none !important;
}

.Add_more_Btn:hover,
.Add_more_Btn:active,
.Add_more_Btn:focus,
.Add_more_Btn:focus-visible {
  width: 80px !important;
  height: 29px !important;
  border-radius: 4px !important;
  background: #5f78d6 !important ;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 8px !important;
  border: none !important;
}

.Add_more_Class {
  color: #fff !important;
}
.Add_more_Class span {
  color: #fff !important;
  font-size: 8px !important;
}

.Add_more_Class:hover,
.Add_more_Class:active,
.Add_more_Class:focus,
.Add_more_Class:focus-visible {
  color: #fff !important;
}

.Participant_names {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
}

.Image_profile {
  border-radius: 50% !important;
}

.Cancel_Vote_Modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.Cancel_Vote_Modal:hover,
.Cancel_Vote_Modal:active,
.Cancel_Vote_Modal:focus,
.Cancel_Vote_Modal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
}

.Save_Vote_Modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  color: #fff !important;
  background: #5f78d6 !important;
  border-radius: 4px !important;
}

.Save_Vote_Modal:hover,
.Save_Vote_Modal:active,
.Save_Vote_Modal:focus,
.Save_Vote_Modal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  color: #fff !important;
  background: #5f78d6 !important;
  border-radius: 4px !important;
}

.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 600 !important;

}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 600 !important;

}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 0px;
  color: #f16b6b;
}
