.ModalHeader_View_poll_progress {
  width: 100% !important;
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  height: auto;
  margin-top: -5px !important;
}

.ModalRequestHeader_polling_View_modal_progress {
  margin: 0 !important;
  padding: 5px 12px !important;
  width: 100%;
}

.BackGround_strip_viewmodal_progress {
  background: rgba(194, 194, 194, 0.14);
  height: 40px !important;
  width: 100% !important;
}

.BackGround_strip_viewmodal_progress {
  background: rgba(194, 194, 194, 0.14);
  padding: 5px 5px !important;
  margin: 0 !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.ViewPRogressDueDate {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.Box_For_Title_toShow {
  width: 100% !important;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.OverAll_padding {
  padding: 0px 45px !important;
  margin-top: -25px !important;
}

.View_cross_icon {
  cursor: pointer !important;
  z-index: 1 !important;
}

.ViewTitleTOShowOnProgress {
  width: 100%;
  font-size: 14px !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  margin: 4px !important;
}

.Box_For_Yes_ViewPoll {
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.YesAndNoOptions {
  align-items: center !important;
}

.multiple_answers_allowed {
  width: 100% !important;
  margin-left: 0;
}

.Participants_Heading {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #6172d6 !important;
}

.Scroller_For_CreatePollModal2_View_poll {
  height: 22vh !important;
  overflow-x: hidden !important;
  width: 100%;
}

.Scroller_For_CreatePollModal2_View_poll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_For_CreatePollModal2_View_poll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Card_border2 {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  margin-left: 0px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

body[dir="rtl"] .Card_border2 {
  margin-right: 0px !important;
}

.Name_cards {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
  margin-left: 5px !important;
}

body[dir="rtl"] .Name_cards {
  margin-right: 5px !important;
}

.CheckBox_ViewProgressPolls {
  margin: 0;
  padding: 0;
  margin-top: 8px !important;
}

.Progress_bar_view_polls {
  margin: 0;
  padding: 0;
}

.Scroller_View_Poll_Published {
  height: 28vh !important;
  overflow-x: hidden !important;
  margin-top: 6px !important;
}

.Scroller_View_Poll_Published::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.Scroller_View_Poll_Published::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Close_Button_viewprogress {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
}

.Close_Button_viewprogress:hover,
.Close_Button_viewprogress:active,
.Close_Button_viewprogress:focus,
.Close_Button_viewprogress:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: 1px solid #e1e1e1 !important;
}

.View_votes_btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
}

.View_votes_btn:hover,
.View_votes_btn:active,
.View_votes_btn:focus,
.View_votes_btn:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  font-weight: 600;

  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.View_poll_heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.Yes_ViewProgress {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  width: 100% !important;
}

.Multiple_answer {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.BOx_for_yes {
  width: 100%;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding-left: 10px;
  margin: 0 0 10px 0;
}
.scrollable-title {
  max-height: 100px;
  min-height: 39px;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
  overflow-y: auto;
}
.scrollable-title::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}
.scrollable-title::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.scrollable-title2 {
  min-height: 39px !important;
}
