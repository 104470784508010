.SubagendaViewBox {
  width: 100% !important;
  height: auto !important;
  min-height: 84px !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 1px 15px !important;
  position: relative !important;
}

.SubagendaViewBox_Expanded {
  width: 100% !important;
  height: auto !important;
  min-height: 254.4px !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 1px 15px !important;
  position: relative !important;
}

.subAgendaExpandableSideStyles {
  background: #4adede;
  height: 100%;
  width: 23px !important;
  left: 0;
  top: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  position: absolute !important;
}

body[dir="rtl"] .subAgendaExpandableSideStyles {
  right: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.subagendaArrow {
  cursor: pointer !important;
  margin-top: 35px !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .subagendaArrow {
  margin-right: -9px !important;
}

.SubagendaTitle {
  color: #5f78d6;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.overAll_padding_subagenda {
  padding: 0px 30px !important;
}

.SubAgenda_Show_more_Class {
  color: #5f78d6;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.ParticipantName {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Profile_Class {
  border-radius: 50% !important;
}

.Top_Spacing {
  margin-top: 4px !important;
}

.Date_Time {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.Date_Time_Section {
  margin-top: 8px !important;
}

.midLine {
  border: 1px solid #707070;
  width: 12px !important;
  height: 1px !important;
  margin-top: 7px !important;
}

.subagendaArrow_Expand {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 105px !important;
  margin-left: -10px !important;
}

body[dir="rtl"] .subagendaArrow_Expand {
  margin-right: -10px !important;
}

.Attachments_Heading {
  color: #6172d6 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.Files_Structure {
  width: 100% !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 4px !important;
  height: 54.4px !important;
  padding: 0px 20px !important;
}

.SubagendaFilesScrollerView {
  height: 12vh !important;
  overflow-x: hidden !important;
}

.SubagendaFilesScrollerView::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.SubagendaFilesScrollerView::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.File_Name {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 200px !important;
  display: flex !important;
  align-items: center !important;
}
