.CancelBtn {
    padding: 12px 30px !important;
    background-color: #fff !important;
    color: #5a5a5a !important;
    border: 1px solid #e1e1e1 !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    font-weight: 600;
}

.ProccedBtn {
    padding: 12px 30px !important;
    background-color: #6172d6 !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    font-weight: 600;
    border: none !important;
}

.modalText {
    color: #5a5a5a;
    font-size: 20px !important;
    font-weight: 600;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
}