.NotesDashboard {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 34vh;
  min-height: 34vh;
  padding-right: 7px;
}
.emptystateNotesDashboard {
  margin-top: 10px !important;
  color: #e1e1e1 !important;
  font-weight: 600;
  font-size: 16px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
}
.noteheading {
  font-size: 20px !important;
  font-weight: 600;
  margin: 0 !important;
  text-align: left !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .noteheading {
  font-weight: 600;
}

.notesdescription {
  border: 1px solid #dddddd;
  margin: 10px 0 !important;
  padding: 5px !important;
  cursor: pointer;
  border-radius: 4px !important;
}
.DataTimeDay {
  font-size: 8px !important;
  width: auto !important;
  text-align: end;
  font-weight: 400;
}

body[dir="rtl"] .DataTimeDay {
  font-size: 8px !important;
  width: auto !important;
  font-weight: 400;
}
.ViewMoreLink_notes {
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 600;
}

.notescontent {
  font-size: 12px !important;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 600;
  cursor: pointer;
  margin: 5px 5px !important;
  color: #6172d6 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  word-wrap: break-word;
}

body[dir="rtl"] .notescontent {
  font-weight: 600;
}