.Update-Committee-paper {
  width: 100% !important;
  height: auto !important;
  padding: 60px 60px 30px 60px !important;
  margin-bottom: 20px;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.details-class-Update-Committee {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .details-class-Update-Committee {
  font-weight: 600;
}

.scroll-bar-Update-Committee {
  height: 30vh !important;
  overflow-x: hidden !important;
}

.scroll-bar-Update-Committee::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-bar-Update-Committee::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Update-Committee-Head-Heading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Update-Committee-Head-Heading {
  font-weight: 600;
}

.Executive-Member-info {
  line-height: 15px !important;
}

.name-Update-Committee {
  color: #5a5a5a;
  font-size: 12px !important;
  font-weight: 600 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body[dir="rtl"] .name-Update-Committee {
  font-weight: 600;
}

.Designation-Update-Committee {
  color: #5a5a5a;
  font-size: 10px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .no-member {
  font-weight: 600;
}

body[dir="rtl"] .Designation-Update-Committee {
  font-weight: 600;
}

.email-Update-Committee {
  color: #6172d6;
  text-decoration: underline !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body[dir="rtl"] .email-Update-Committee {
  font-weight: 400;
}

.members-Upadate-Committee-page {
  font-size: 18px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .members-Upadate-Committee-page {
  font-weight: 600;
}

.Addmembers-class-Update-Committee {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Addmembers-class-Update-Committee {
  font-weight: 600;
}

.Create-updatecommittee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Create-updatecommittee-btn {
  font-weight: 600;
}

.Create-updatecommittee-btn:hover,
.Create-updatecommittee-btn:active,
.Create-updatecommittee-btn:focus,
.Create-updatecommittee-btn:focus-visible,
.Create-updatecommittee-btn:visited {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

.ADD-Update-Committee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  height: 39px !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .ADD-Update-Committee-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.ADD-Update-Committee-btn:hover,
.ADD-Update-Committee-btn:active,
.ADD-Update-Committee-btn:focus,
.ADD-Update-Committee-btn:focus {
  padding: 12px 30px !important;
  height: 39px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

.scrollbar-addmember-Update-Committee {
  height: 14vh !important;
  overflow-x: hidden !important;
  max-width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.scrollbar-addmember-Update-Committee::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scrollbar-addmember-Update-Committee::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Update-Committee-head-info-Add-Members {
  margin-left: 3px !important;
  line-height: 15px !important;
}

.Cancell-updatecommittee-btn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #ffffff;
  color: #5a5a5a;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .Cancell-updatecommittee-btn {
  font-weight: 600;
}

.Cancell-updatecommittee-btn:hover,
.Cancell-updatecommittee-btn:active,
.Cancell-updatecommittee-btn:focus,
.Cancell-updatecommittee-btn:focus-visible,
.Cancell-updatecommittee-btn:visited {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #ffffff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Update-Committee-Heading {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Update-Committee-Heading {
  font-weight: 600;
}

.Label_Of_CheckBox {
  font-size: 12px !important;
  color: #5a5a5a;
  margin-top: 13px !important;
  margin-left: 4px !important;
  font-weight: 600;
}

body[dir="rtl"] .Label_Of_CheckBox {
  margin-right: 5px !important;
}

.CheckboxAlignment {
  display: flex !important;
  justify-content: start;
  margin-left: -10px !important;
}

.body[dir="rtl"] .CheckboxAlignment {
  margin-right: -20px !important;
}

.errorMessage {
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 600;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 600;
}

.ADD-Committee-btn {
  padding: 10px 30px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  margin-top: 2px !important;
  border: 1px solid #e1e1e1 !important;
}

.ant-upload-text {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

body[dir="rtl"] .ant-upload-text {
  font-weight: 600;
}

.Choose_file_style {
  font-size: 12px !important;
  color: #6172d6 !important;
  font-weight: 600;
  margin-left: 3px !important;
}

.Leftpolygon {
  background: none;
  border: none;
  display: flex !important;
  align-items: center !important;
}

body[dir="rtl"] .Leftpolygon {
  transform: rotate(180deg) !important;
}

.Leftpolygon:hover,
.Leftpolygon:active,
.Leftpolygon:focus,
.Leftpolygon:focus-visible {
  background: none !important;
  border: none !important;
}

.Attachments_Heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

.Outer_Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.filesStyles {
  width: 100% !important;
  display: flex !important;
  gap: 35px !important;
}

.backGround_name_Icon {
  background: #fff;
}

.IconTextClass {
  display: flex;
  text-align: center !important;
  align-items: center !important;
  gap: 7px !important;
}

.IconPDF {
  margin-left: 10px !important;
}

.FileName {
  font-size: 10px !important;
  color: #5a5a5a !important;
  font-weight: 400;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 100px !important;
}

.Crossicon_Class {
  cursor: pointer !important;
  position: absolute !important;
  top: -7px !important;
  right: -4px !important;
}

.Outer_Border-Line {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  margin-top: 8px !important;
}

.UpdateCommitteeAttachment {
  max-height: 120px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
