.BackGround_Agenda {
  width: 100% !important;
  height: auto !important;
  min-height: 126px !important;
  padding: 1px 15px !important;
  position: relative;
  transition: height 0.5s ease;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #e1e1e1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.BackGround_Agenda_InActive {
  width: 100% !important;
  min-height: 126px !important;
  height: auto !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 1px 15px !important;
  filter: opacity(0.5);
  position: relative;
}

.BackGround_Agenda_InActive:has(.lockBtn_inActive) .lockBtn_inActive {
  filter: opacity(1) !important;
  cursor: pointer !important;
}

.lockBtn_inActive_coursor {
  cursor: pointer !important;
}

.lockBtn {
  cursor: pointer !important;
}

.SubajendaBox {
  width: 90% !important;
  height: auto !important;
  min-height: 126px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
  padding: 10px 15px !important;
  margin-left: 10px !important;
  position: relative;
  margin-bottom: 25px;
  border-bottom: none !important;
  border-right: none !important;
}

.borderTopNone {
  border-top: none !important;
}

body[dir="rtl"] .SubajendaBox {
  width: 90% !important;
  height: auto !important;
  min-height: 126px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
  padding: 10px 15px !important;
  margin-left: 10px !important;
  position: relative;
  margin-bottom: 25px;
  border-bottom: none !important;
  border-left: none !important;
  border-right: 1px solid #e1e1e1 !important;
}

.SubajendaBox_Inactive {
  width: 90% !important;
  height: auto !important;
  min-height: 126px !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 10px 15px !important;
  margin-left: 10px !important;
  filter: opacity(0.5);
  position: relative;
}

.Agenda_Heading {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5f78d6;
}

.Radio_Button_options {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
}

.ant-upload-text-Meetings {
  color: #bbbbbb !important;
  font-size: 22px !important;
  margin: 0;
  padding: 0;
  font-weight: 600 !important;
}

.Choose_file_style-Meeting {
  color: #bbbbbb !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Agenda_Buttons {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Agenda_Buttons:hover,
.Agenda_Buttons:active,
.Agenda_Buttons:focus,
.Agenda_Buttons:focus-visible {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Save_Agenda_btn {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  font-weight: 600 !important;
}

.Save_Agenda_btn:hover,
.Save_Agenda_btn:active,
.Save_Agenda_btn:focus,
.Save_Agenda_btn:focus-visible {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  font-weight: 600 !important;
}

.Image_class_Agenda {
  border-radius: 50% !important;
  cursor: pointer !important;
}

.Name_Class {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.ClassImage {
  margin-left: 13px !important;
}

.AddMoreBtnAgenda {
  border: 1px dashed #ccc !important;
  background: #fff !important;
  border-radius: 4px !important;
  height: 39px !important;
  width: 100%;
}

.AddMoreBtnAgenda:hover,
.AddMoreBtnAgenda:active,
.AddMoreBtnAgenda:focus,
.AddMoreBtnAgenda:focus-visible {
  border: 1px dashed #ccc !important;
  background: #fff !important;
  border-radius: 4px !important;
  height: 39px !important;
  width: 100% !important;
}

.Add_Agen_Heading {
  font-size: 16px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Scroller_Agenda {
  min-height: 52vh !important;
  overflow-x: hidden !important;
}

.Scroller_Agenda::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_Agenda::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Scroller_document {
  height: 18vh !important;
  overflow-x: hidden !important;
}

.Scroller_document::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_document::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.SubAgendaDocScroller {
  height: 14vh !important;
  overflow-x: hidden !important;
}

.SubAgendaDocScroller::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.SubAgendaDocScroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.RedCross_Icon_class {
  margin-right: -10% !important;
  margin-top: -24.9% !important;
  cursor: pointer !important;
}

.RedCross_Icon_class_SubAgenda {
  position: absolute !important;
  right: -10px !important;
  top: -8px !important;
  cursor: pointer !important;
}

body[dir="rtl"] .RedCross_Icon_class_SubAgenda {
  right: 99% !important;
}

.RedCross_Icon_class_Main_agenda {
  position: absolute !important;
  right: -10px !important;
  top: -7px !important;
  cursor: pointer !important;
}

body[dir="rtl"] .RedCross_Icon_class_Main_agenda {
  right: 99% !important;
}

.Documentdiv {
  border: 1px solid #d4d4d4;
  border-radius: 4px !important;
  background: #fff;
  margin-top: 10px !important;
  width: 20% !important;
  height: 42px !important;
}

.TitleFile {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
  display: inline-block !important;
  width: 180px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.SubagendaFilesName {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
  display: inline-block !important;
  width: 180px !important;
  font-weight: 400 !important;
}

/* card */

.card {
  width: 221px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  padding: 10px 10px;
  border-radius: 4px;
  gap: 10px;
}

.cardSubAgenda {
  width: 221px !important;
  height: 42px !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4;
  padding: 0px 15px !important;
}

.LineStyles {
  width: 100% !important;
}

.BackGroundNewImplemented {
  background: #5f78d6;
  height: 100%;
  width: 48px !important;
  left: 0;
  top: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  position: absolute !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .BackGroundNewImplemented {
  right: 0;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.Arrow {
  cursor: pointer !important;
  transition: transform 0.5s ease;
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  top: 10px;
  width: 40px;
  z-index: 9 !important;
}

body[dir="rtl"] .Arrow {
  cursor: pointer !important;
  transition: transform 0.5s ease;
  transform: rotate(180deg);
  position: absolute;
  right: auto;
  top: 10px;
  left: 0;
  width: 40px;
  z-index: 9 !important;
}

.SubAgendaArrow {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
}

body[dir="rtl"] .SubAgendaArrow {
  margin-right: 6px !important;
}

.Arrow_Expanded {
  cursor: pointer !important;
  transition: transform 0.5s ease;
  position: absolute;
  right: 0;
  top: 10px;
  width: 40px;
  z-index: 9 !important;
}

body[dir="rtl"] .Arrow_Expanded {
  position: absolute;
  right: auto;
  top: 10px;
  left: 0;
  width: 40px;
  z-index: 9 !important;
}

.subAgendaArrowExpand {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

body[dir="rtl"] .subAgendaArrowExpand {
  margin-right: 6px !important;
}

.Meeting_title_heading {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.blueLine {
  background: #5f78d6 !important;
  height: auto !important;
  max-height: 126px !important;
  width: 6px !important;
  position: fixed !important;
  top: 123px;
  right: 96.2%;
}

.backGroundDubTree {
  background: #000 !important;
  height: 100%;
  width: 48px !important;
  left: 0;
  top: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  position: absolute !important;
}

.backGorund {
  background: #4adede !important;
  height: 100% !important;
  width: 30px !important;
  position: absolute !important;
  left: 0;
  top: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .backGorund {
  right: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.Meeting_subAgenda {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Show_More_Styles {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.SubAgendaSection {
  width: 100% !important;
  z-index: 1;
}

body[dir="rtl"] .SubAgendaSection {
  margin-right: 36px !important;
}

.Show_Details_Tag {
  color: #5f78d6 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.AgendaTitle_Heading {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #5a5a5a !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-top: 5px;
}

.URLTitle_Heading {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5f78d6;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-left: 24px;
}

.ContributorTitle_Heading {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #6172d6 !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ContributorDescription {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a;
}

.gridViewFolder__name {
  background-color: #fff;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  display: flex;
  gap: 10px !important;
  padding: 12px 16px !important;
  justify-content: space-between;
  margin-bottom: 10px !important;
  position: relative;
  cursor: pointer;
}

.folderName__text {
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agendaFileAttachedView {
  cursor: pointer;
  user-select: none !important;
  box-sizing: border-box;
  background: #f6f9fc;
  border: 1px solid #d4d4d4;
  border-radius: 25px;
  width: 200px;
  height: 30px;
}

span.agendaFileSpan {
  color: #5a5a5a !important;
  font-size: 15px !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

img.Image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.agendaCreationDetail {
  display: flex;
  justify-content: start;
  align-items: center;
  vertical-align: middle;
}

p.agendaCreater {
  font-weight: 600 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15px;
  margin: 0;
  color: #5a5a5a;
}

p.agendaCreaterTime {
  font-size: 18px;
  line-height: 2.8;
  margin: 0;
  text-align: end;
  color: #5a5a5a;
}

.agendaCreationTime {
  margin: 0 !important;
  padding-left: 10px;
  padding-right: 20px;
  font-size: 12px;
  color: #5a5a5a;
}

.Cancel_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.ParaGraph_SavedMeeting {
  color: #5a5a5a !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  white-space: pre-wrap !important;
  margin: 10px 0px;
  display: block;
}

.startVotingButton {
  width: 114px !important;
  height: 25 !important;
  background: #81db86 !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
}

.startVotingButton:is(:hover, :active, :focus, :focus-visible) {
  width: 114px !important;
  height: 25 !important;
  background: #81db86 !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

.CastYourVoteButton {
  width: 136px !important;
  height: 25 !important;
  background: #81db86 !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
}

.CastYourVoteButton:is(:hover, :active, :focus, :focus-visible) {
  width: 136px !important;
  height: 25 !important;
  background: #81db86 !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

.EndVotingButton {
  width: 114px !important;
  height: 25 !important;
  background: #f16b6b !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
}

.EndVotingButton:is(:hover, :active, :focus, :focus-visible) {
  width: 114px !important;
  height: 25 !important;
  background: #f16b6b !important;
  color: #fff !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

.ViewVoteButton {
  width: 114px !important;
  height: 25 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-left: 15px;
  margin-bottom: 10px;
}

.ViewVoteButton:is(:hover, :active, :focus, :focus-visible) {
  width: 114px !important;
  height: 25 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-left: 15px;
}

body[dir="rtl"] .ViewVoteButton {
  width: 114px !important;
  height: 25 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 15px !important;
}

body[dir="rtl"] .ViewVoteButton:is(:hover, :active, :focus, :focus-visible) {
  width: 114px !important;
  height: 25 !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 15px !important;
}

.Cancel_Meeting_Details {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Empty_state_heading {
  color: #505050 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.Empty_state_Subheading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500;
}

img.Image-Add-Agenda {
  margin-top: 40px;
}

span.NoFiles_Heading {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #000;
}

img.AttachmentIconImage {
  margin-left: 15px;
  margin-right: auto;
}

body[dir="rtl"] img.AttachmentIconImage {
  margin-left: auto;
  margin-right: 15px;
}

.Save_Classname {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Save_Classname:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.box-agendas {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.popup-agenda-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 999 !important;
  top: 32px;
  right: 0;
  transition: opacity 0.3s ease;
  display: block;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  width: 330px;
  padding: 7px;
}

body[dir="rtl"] .popup-agenda-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  z-index: 9;
  top: 32px;
  right: auto;
  left: 0;
  transition: opacity 0.3s ease;
  display: block;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  width: 330px;
  padding: 7px;
}

.popup-agenda-menu span {
  color: #5a5a5a;
  text-decoration: none;
  text-align: left;
  display: block;
  font-size: 18px !important;
  font-weight: 600 !important;
  border-bottom: 3px solid #d8d8d8;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
}

.popup-agenda-menu span.disabledEntity {
  color: #c6c6c6 !important;
  cursor: default !important;
}

body[dir="rtl"] .popup-agenda-menu span {
  color: #5a5a5a;
  text-decoration: none;
  text-align: right;
  display: block;
  font-size: 18px !important;
  font-weight: 600 !important;
  border-bottom: 3px solid #d8d8d8;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
}

.popup-agenda-menu span img {
  margin-right: 10px;
}

body[dir="rtl"] .popup-agenda-menu span img {
  margin-right: auto;
  margin-left: 10px;
}

span.dashMinute {
  margin-left: 6px;
  margin-right: 9px;
  letter-spacing: -3px;
}

.filesParentClass {
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
}

.filesParentClassSubAgenda {
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
  margin-left: 22px;
}

.fileNameAttachment {
  font-style: normal;
  font-size: 12px;
  text-decoration-line: underline;
  color: #5a5a5c;
  font-weight: 500 !important;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fileNameAttachmentNotOpened {
  font-style: normal;
  font-size: 12px;
  text-decoration-line: underline;
  color: #5a5a5c;
  font-weight: 500 !important;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default !important;
}

body[dir="rtl"] .fileNameAttachment {
  font-style: normal;
  font-size: 12px;
  text-decoration-line: underline;
  color: #5a5a5c;
  font-weight: 500 !important;
  margin-left: 0;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.borderFileName {
  border-right: 1px solid #e1e1e1;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

body[dir="rtl"] .borderFileName {
  border-right: none;
  border-left: 1px solid #e1e1e1;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.fileNameTruncateStyle {
  width: 120px !important;
  justify-content: center !important;
  display: flex !important;
}

.SubAgendaTitle_Heading {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #6172d6 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-top: 5px;
  margin-left: 35px;
}

span.SubAgenda_Description {
  color: #5a5a5a !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  white-space: pre-wrap !important;
  margin: 10px 0px;
  display: block;
  margin-left: 35px;
}

.Show_More_Button,
.Show_More_Button:is(:hover, :active, :focus, :focus-visible, :target) {
  box-sizing: border-box !important;
  background: #f6f9fc !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 13px !important;
  font-style: normal !important;
  font-size: 12px !important;
  text-decoration-line: underline !important;
  color: #5a5a5c !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  height: 30px !important;
  width: 85px !important;
  margin-top: 10px;
}

.box-agendas-camera {
  border: 1px solid #81db86;
  background-color: #81db86;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  right: 80px;
  top: 0;
}

body[dir="rtl"] .box-agendas-camera {
  border: 1px solid #81db86;
  background-color: #81db86;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  right: auto;
  left: 90px;
  top: 0;
}

.disabled-box-agenda-camera {
  border: 1px solid #c7c6c6;
  background-color: #c7c6c6;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: not-allowed;
  right: 80px;
  top: 0;
}

body[dir="rtl"] .disabled-box-agenda-camera {
  border: 1px solid #c7c6c6;
  background-color: #c7c6c6;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: not-allowed;
  right: auto;
  left: 90px;
  top: 0;
}

.icons-block {
  display: flex;
  gap: 20px;
}

.box-agendas-leave {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
}

.Start-presenter-view-class {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  color: #6172d6;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  padding: 5px 30px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}

.Start-presenter-view-class p {
  margin: 0;
  display: inline-block;
}

.Stop-presenter-view-class {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  color: #f16b6b;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  padding: 5px 30px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
}

.Stop-presenter-view-class p {
  margin: 0;
  display: inline-block;
}
