.talk_nav {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* height: 202px; */
  width: 65px;
  border-radius: 2px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999 !important;
}

.talk_Icon {
  /* background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 55px;
  position: absolute;
  bottom: 0;
  border-radius: 4px; */
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
  height: 55px;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 2px solid #e1e1e1;
  border-top: 0px solid;
}
body[dir="rtl"] .talk_Icon {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
  height: 55px;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 2px solid #e1e1e1;
  border-top: 0px solid;
}
.talk_subIcon {
  background: #ffff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 62px;
  display: flex;
  cursor: pointer;
  transition: 0.5s;
  padding: 4px;
}

.talk_subIcon:hover svg path {
  fill: #fff;
  overflow: hidden;
  border: 2px;
}

.talk_subIcon:hover span {
  color: #fff !important;
}

.talk_subIcon:hover svg rect#Rectangle_830 {
  fill: #fff !important;
}

.talk_subIcon:hover svg rect#Rectangle_831 {
  fill: #fff !important;
}

.talk_subIcon:hover svg rect#Rectangle_832 {
  fill: #fff !important;
}

/* Css Implemented on Notes Inner lines  */
/* .talk_subIcon:hover .talk_subIcon svg rect#Rectangle_830 {
  fill: #fff !important;
}
.talk_subIcon:hover .talk_subIcon svg rect#Rectangle_831 {
  fill: #fff !important;
}
.talk_subIcon:hover .talk_subIcon svg rect#Rectangle_832 {
  fill: #fff !important;
} */

.talk_subIcon_hidden {
  display: none;
  transition: 0.5s;
  border: none;
}

.talk_nav_hidden {
  margin: 10px 0 0 0 !important;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 240px;
  border: none;
  width: 60px;
  border-radius: 2px;
  position: relative;
}

.talk_subIcon:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  transition: 0.5s;
}
body[dir="rtl"] .talk_subIcon:hover {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
  transition: 0.5s;
}
.talk_subIcon.hover img {
  filter: #fff;
}

.talk-count {
  position: absolute;
  top: 5px;
  left: 3px;
  background: #6172d6;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 9px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.talk-count.total {
  top: -8px;
  left: -8px;
  font-size: 11px;
  width: 20px;
  height: 20px;
}

body[dir="rtl"] .talk-count.total {
  top: -8px;
  left: auto !important;
  right: -8px !important;
  font-size: 10px;
  width: 22px;
}

@media screen and (max-width: 1250px) {
  .talk_nav {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 240px;
    width: 45px;
    border-radius: 2px;
    position: fixed;
    right: 15px;
    bottom: 15px;
  }

  body[dir="rtl"] .talk_nav {
    display: flex !important;
    align-items: flex-end !important;
    flex-direction: column !important;
    height: 240px !important;
    width: 45px !important;
    border-radius: 2px !important;
    position: fixed !important;
    right: auto !important;
    left: 15px !important;
    bottom: 15px !important;
  }

  .talk_nav .border.w-100.rounded {
    margin-top: 13px;
  }

  .talk_Icon {
    height: 45px;
  }

  .talk_Icon svg {
    width: 23px;
  }

  .talk_subIcon svg {
    width: 28px;
  }
}

.talk_Icon svg {
  width: 30px;
}

.talk-nav-icons {
  /* border: 2px solid #e1e1e1;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: absolute;
  bottom: 55px; */
  border: 2px solid #e1e1e1;
  border-bottom: 0px solid;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 4px;
  position: absolute;
  bottom: 55px;
}

body[dir="rtl"] .talk-count {
  position: absolute;
  top: 5px;
  left: auto;
  background: #6172d6;
  border: 3px solid #fff;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  text-align: center;
  font-size: 9px;
  right: 3px;
}

.talk_subIcon.with-hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  transition: 0.5s;
}
body[dir="rtl"] .talk_subIcon.with-hover {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
  transition: 0.5s;
}
.talk_subIcon.with-hover {
  filter: #fff;
}

.talk_subIcon.with-hover svg path {
  fill: #fff;
  overflow: hidden;
  border: 2px;
}

.talk_subIcon.with-hover svg rect#Rectangle_830 {
  fill: #fff !important;
}

.talk_subIcon.with-hover svg rect#Rectangle_831 {
  fill: #fff !important;
}

.talk_subIcon.with-hover svg rect#Rectangle_832 {
  fill: #fff !important;
}

.TalkIconPannelLabels {
  font-size: 8px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  margin-left: 2px !important;
}
