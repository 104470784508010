.DeleteOrganization_box {
  border: 1px solid #e1e1e1 !important;
}

.DeleteOrganization_box_heading {
  color: #6172d6;
  text-align: center;
  font-size: 26px !important;
}
body[dir="rtl"] .DeleteOrganization_box_heading {
  font-weight: 600 !important;
}

body[dir="rtl"] .DeleteOrganization_content {
  font-weight: 600 !important;
}
.DeleteOrganization_content {
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600 !important;
  background: white !important;
  padding: 25px 30px !important;
  min-height: 10vh !important;
  margin: 20px auto !important;
  text-align: center;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.DeleteOrganization_content p {
  padding: 30px 20px !important;
}

.DeleteOrganizationConfirmationModalHeader {
  display: none !important;
}
.DeleteOrganizationConfirmationModalBody {
  margin: 20px 0 0 0 !important;
  padding: 0px !important;
}
.DeleteOrganizationConfirmationModalFooter {
  margin: 0 0 20px 0 !important;
  padding: 0px !important;
  height: 50px !important;
}
.deleteOrganization_btn {
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background: #f16b6b !important;
  text-transform: capitalize;
  font-weight: 600 !important;
  padding: 10px 45px;
}
body[dir="rtl"] .deleteOrganization_btn {
  font-weight: 600 !important;
}
.deleteOrganization_btn:hover,
.deleteOrganization_btn:focus,
.deleteOrganization_btn:focus-visible,
.deleteOrganization_btn:active {
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background: #f16b6b !important;
  text-transform: capitalize;
  font-weight: 600 !important;
  box-shadow: none !important;
  padding: 10px 45px;
}

.title {
  font-weight: 800 !important;
  margin-left: 5px !important;
}

.title_two {
  margin-left: 5px !important;
}

body[dir="rtl"] .title {
  font-weight: 800 !important;
}
