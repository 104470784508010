.chatBox {
  position: fixed;
  bottom: 0;
  right: 85px;
  width: 325px;
  height: 87.3vh;
  background: #fff;
  border: 3px solid #4adede;
  overflow: hidden auto;
  border-radius: 4px;
  top: 80px;
  opacity: 1;
  /* Initial opacity */
  transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in !important;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
}

.chatBox.height {
  position: fixed;
  bottom: 0;
  right: 85px;
  width: 325px;
  height: 37vh;
  background: #fff;
  border: 1px solid #4cd7de;
  overflow: hidden auto;
  border-radius: 4px;
  top: 73px;
}

svg.pointer-chat-icon {
  position: fixed;
  bottom: 91px;
  right: 74px;
  transform: rotate(90deg);
  background: #fff;
  color: #6172d6;
  border-left: none !important;
}

.chat-block {
  height: 70px;
  cursor: pointer;
  margin-top: 9px;
  margin-left: 10px;
  position: relative;
}

span.new-message-count {
  display: block;
  width: 25px;
  height: 25px;
  background: #5f78d6;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  font-size: 11px;
  position: absolute;
  right: 0;
  top: 11px;
  font-weight: 500;
  align-items: center;
  display: grid;
}

body[dir="rtl"] span.new-message-count {
  display: block;
  width: 17px;
  height: 17px;
  background: #5f78d6;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  font-size: 11px;
  position: absolute;
  right: auto;
  top: 11px;
  font-weight: 500;
  left: 0;
}

body[dir="rtl"] .chat-block {
  height: 70px;
  cursor: pointer;
  margin-top: 9px;
  /* margin-left: 10px; */
  margin-right: 10px !important;
}

.chat-messenger-head {
  height: 53vh;
  position: fixed;
  bottom: 7px;
  right: 85px;
  width: 325px;
  background: #fff;
  border: 1px solid #6172d6;
  border-radius: 4px;
  z-index: 9999999;
  overflow: hidden;
}

.chat-messenger-head-video {
  z-index: 9999999;
  height: 47.3vh;
  position: fixed;
  bottom: 0px;
  right: 1%;
  width: 365px;
  background: #fff;
  border: 1px solid #6172d6;
  border-radius: 4px;
}

.chat-messenger-head-video .container .row .col .chat-section {
  position: relative;
  height: 30.7vh;
  padding: 15px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column-reverse;
}

.chat-messenger-head-video .container .row .col .chat-input-section {
  border-top: 1px solid #e1e1e1;
  height: auto;
  display: flex;
  position: fixed;
  bottom: 2px;
  width: 362px !important;
  background: #fff;
}

.chat-messenger-head-video
  .container
  .row
  .col
  .chat-input-section
  .chat-input-field {
  margin: 5px 0px;
  width: 330px;
}

.chatFilter {
  margin-top: 10px;
  width: 160px;
}

.rc-virtual-list-holder-inner {
  width: 300px !important;
  overflow: hidden !important;
  display: grid !important;
  grid-template-columns: 150px 150px;
}

.rc-virtual-list {
  width: 300px !important;
}

#rc_select_1_list {
  width: 300px !important;
}

.chat-inner-content
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  width: 300px !important;
  min-width: 300px !important;
}

.chat-inner-content .ant-select-selection-item {
  text-overflow: unset !important;
  font-size: 11px !important;
  font-weight: 600;
}

body[dir="rtl"] .chat-inner-content .ant-select-selection-item {
  padding-right: 0 !important;
}

.chatFilter .ant-select-selector {
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 3px !important;
  width: 140px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.chat-inner-content span.ant-select-arrow {
  color: #fff !important;
  right: 32px;
  top: 14px;
}

body[dir="rtl"] .chat-inner-content span.ant-select-arrow {
  color: #fff !important;
  right: auto;
  top: 14px;
  left: 30px;
}

.single-chat.row:hover {
  background: #f2f2f2 !important;
  transition: 0.2s ease;
}

body[dir="rtl"]
  .ant-select.chatFilter.ant-select-single.ant-select-show-arrow
  span.ant-select-selection-item {
  padding-right: 0 !important;
}

.chat-inner-content
  .ant-select.chatFilter.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
  color: #fff !important;
  font-weight: bold;
}

.chat-inner-content
  .ant-select.chatFilter.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open
  .ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #fff !important;
  font-weight: bold;
}

.chat-inner-content
  .ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #fff !important;
}

.chat-inner-content .ant-select-item {
  font-size: 12px;
}

.chat-inner-content
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #4adede !important;
  font-size: 12px;
  font-weight: bold;
}

/* .ant-select.chatFilter.ant-select-focused.ant-select-single.ant-select-show-arrow.ant-select-open {
  background-color: #4adede !important;
  font-size: 12px;
  font-weight: bold;
} */

.chat-icons {
  margin-top: 15px;
  margin-left: -5px;
  cursor: pointer;
}

.chat-icons img {
  width: 20px !important;
}

.chat-profile-icon {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px;
  margin-top: 5px;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

span.user-active-status {
  width: 12px;
  position: absolute;
  background-color: #81db86 !important;
  right: 1px;
  bottom: 2px;
  height: 12px;
  border-radius: 10px;
  border: 3px solid #fff;
}

span.user-active-status.offline {
  background-color: #bbbbbb !important;
}

.row.single-chat {
  margin-top: 10px;
  border-bottom: 1px solid #d6d6d6;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}

p.chat-message.m-0 {
  font-size: 14px;
  color: #5a5a5a;
}

p.chat-message.m-0 {
  font-size: 12px;
  color: #505050;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
}

p.chat-date {
  text-align: right !important;
  font-size: 8px;
}

span.triangle-overlay-chat {
  position: fixed;
  background: red;
  height: 13px;
  width: 2px;
  bottom: 97px;
  right: 100px;
  background: #fff;
  z-index: 1;
}

.encryption-box.row {
  background: #f16b6b !important;
  color: #fff;
  z-index: 9999999999;
  position: absolute;
  padding: 14px;
  height: 600px;
  width: 316px;
  margin-left: -13px !important;
  border-radius: 3px;
  display: block;
  margin-top: 5px;
}

.encryption-message {
  font-size: 10px;
}

p.level-heading {
  font-size: 12px;
}

p.level {
  font-size: 12px;
}

span.securityicon-box {
  position: absolute;
  right: 15px;
  top: -2px;
}

.encryption-level {
  margin-top: 10px;
}

.chat-header .chat-profile-icon {
  width: 40px !important;
  height: 40px !important;
  /* margin-left: 5px; */
  margin-top: 10px;
}

.chat-header .chat-profile-icon span.user-active-status {
  width: 10px;
  position: absolute;
  background-color: #81db86 !important;
  right: 0px;
  bottom: 2px;
  height: 10px;
  border-radius: 10px;
  border: 3px solid #fff;
}

p.chat-username {
  margin: 0 !important;
  font-size: 12px;
  margin-bottom: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  z-index: 999999;
  position: relative;
  width: 180px;
}

body[dir="rtl"] p.chat-username {
  margin-left: 0;
  margin-right: 25px;
}

body[dir="rtl"] p.chat-username.chathead {
  right: 18px !important;
}

.chat-box-icons img {
  width: 18px;
  cursor: pointer;
}

.encryption-level-chat {
  background: #f16b6b;
  color: #fff;
  /* margin-top: 10px; */
}

.encryption-level-chat p.level-heading {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

body[dir="rtl"] .encryption-level-chat p.level-heading {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 8px;
}

.encryption-level-chat p.level {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.encryption-level-chat span.securityicon-box {
  position: absolute;
  right: 25px;
  top: 4px;
}

/* .chat-section {
  height: 285px;
   background: #d6d6d6;
} */

.chat-input-section {
  border-top: 1px solid #e1e1e1;
  height: auto;
  /* Update the height to be dynamic based on the input field */
  display: flex;
  position: fixed;
  bottom: 9px;
  width: 323px !important;
  background: #fff;
}

.chat-input-section:has(.Ok-btn) {
  justify-content: center;
  align-items: center;
  vertical-align: middle !important;
}

.chat-input-section .Ok-btn {
  width: 100px !important;
  height: 35px !important;
  border: 1px solid #5f78d6 !important;
  border-radius: 2px;
  background-color: #5f78d6 !important;
  color: #fff !important;
  margin-top: 8px;
  margin-bottom: 6px;
  font-size: 10px;
}

.chat-input-section .White-btn {
  border: 1px solid #e1e1e1 !important;
  background-color: #fff !important;
  color: #232323 !important;
  width: 100px !important;
  height: 35px !important;
  border-radius: 2px;
  margin-top: 8px;
  margin-bottom: 6px;
  font-size: 10px;
  margin-right: 10px;
}

.add-chat.height {
  position: fixed;
  bottom: inherit;
  top: 35%;
  right: 100px;
}

.chat-box-icons {
  height: 100%;
  width: 25px;
  /* cursor: pointer; */
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin-top: 5px;
  margin-left: -12px;
  z-index: 9999999;
  position: relative;
}

body[dir="rtl"] .chat-box-icons {
  height: 100%;
  width: 25px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin-top: 5px;
  margin-left: 0;
  margin-right: -17px;
}

.chat-header {
  margin-bottom: 9px;
}

.add-chat {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  position: fixed;
  top: 88%;
  right: 8%;
  border: none !important;
  cursor: pointer;
  z-index: 99999999;
}

.add-chat img {
  width: 18px;
}

em-emoji-picker {
  width: 352px;
  height: 312px;
  position: absolute;
  top: -313px;
  left: -28px;
}

.emoji-click {
  height: 45px;
  width: 35px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

.upload-click {
  width: 35px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

.sendChat-click {
  width: 45px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

body[dir="rtl"] .sendChat-click {
  transform: rotate(180deg);
}

.chat-input-field {
  margin: 5px 0px;
  width: 250px;
}

.emoji-click img {
  width: 20px;
  cursor: pointer;
}

.upload-click img {
  cursor: pointer;
  width: 20px;
}

.sendChat-click img {
  cursor: pointer;
  width: 20px;
}

.chat-input-section input#contained-button-file {
  display: none;
}

.uploaded-file-section {
  position: absolute;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  height: 65px !important;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: end;
  bottom: 45px;
}

.file-upload {
  height: 45px;
  width: 210px;
  background: #e8eef3;
  border-radius: 7px;
  margin-right: 50px;
}

.delete-uplaoded-file {
  position: absolute;
  top: 0;
  right: -160px;
}

.chat-input-field input {
  font-size: 14px;
  width: 208px;
}

.chat-upload-icon {
  position: relative;
}

img.attachment-icon {
  position: absolute;
  top: 6px;
  left: 30px;
  width: 25px;
}

.chat-upload-icon p.chat-upload-text {
  position: absolute;
  left: 65px;
  top: 5px;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-uplaoded-file {
  position: absolute;
  top: 11px;
  right: -195px;
}

input.chat-message-input.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
  height: 30px;
}

.close-addChat-filter img {
  cursor: pointer;
  width: 15px;
}

.dropdown-menus-chat {
  position: absolute;
  background: #fff;
  display: inline-grid;
  text-align: center;
  top: 45px;
  width: 105px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  z-index: 9;
}

.dropdown-menus-chat span {
  border-bottom: 1px solid #ccc;
  margin: 10px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
}

.fade.modal-backdrop.show:has(.fade.chat-menu-modal.modal.show) {
  background: none !important;
  backdrop-filter: none !important;
  z-index: 0 !important;
}

.fade.chat-menu-modal.modal.show .modal-dialog.modal-sm.modal-dialog-centered {
  left: 487px;
  top: 130px;
}

body[dir="rtl"] .chatBox {
  position: fixed;
  bottom: 0;
  right: auto;
  width: 325px;
  height: 88.5vh;
  background: #fff;
  border: 1px solid #4cd7de;
  overflow: hidden auto;
  border-radius: 4px;
  top: 80px;
  left: 85px;
}

body[dir="rtl"] .chatBox.height {
  position: fixed;
  bottom: 0;
  right: auto;
  width: 325px;
  height: 37vh;
  background: #fff;
  border: 1px solid #4cd7de;
  overflow: hidden auto;
  border-radius: 4px;
  top: 73px;
  left: 85px;
}

body[dir="rtl"] .add-chat {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  position: fixed;
  bottom: 40px;
  right: auto;
  border: 1px solid #ccc;
  cursor: pointer;
  left: 110px;
}

body[dir="rtl"] svg.pointer-chat-icon {
  position: fixed;
  bottom: 97px;
  right: auto;
  transform: rotate(270deg);
  background: #fff;
  color: #6172d6;
  border-left: none !important;
  left: 73px;
}

.chat-modal-Heading h1 {
  font-size: 14px;
  text-align: center;
  /* margin-top: -20px; */
  /* margin-bottom: 15px; */
  font-weight: 600;
}

.chat-options {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.Ok-btn {
  width: 100px !important;
  height: 30px !important;
  border: 1px solid #5f78d6 !important;
  border-radius: 2px;
  background-color: #5f78d6 !important;
  color: #fff !important;
  font-size: 10px;
  position: relative;
  top: 4px;
}

.Ok:hover,
.Ok:active,
.Ok:focus,
.Ok:focus-visible,
.Ok:visited,
.Ok:target {
  width: 100px !important;
  height: 30px !important;
  border: 1px solid #5f78d6 !important;
  border-radius: 2px;
  background-color: #5f78d6 !important;
  color: #fff !important;
  font-size: 10px;
  position: relative;
  top: 4px;
}

.White-btn {
  border: 1px solid #e1e1e1 !important;
  background-color: #fff !important;
  color: #232323 !important;
  width: 100px !important;
  height: 30px !important;
  border-radius: 2px;
  font-size: 10px;
  position: relative;
  top: 4px;
}

.White:hover,
.White:active,
.White:focus,
.White:focus-visible,
.White:visited,
.White:target {
  border: 1px solid #e1e1e1 !important;
  background-color: #fff !important;
  color: #232323 !important;
  width: 100px !important;
  height: 30px !important;
  border-radius: 2px;
  font-size: 10px;
  position: relative;
  top: 4px;
}

.chat-menu-popups {
  position: absolute;
  top: 59px;
  background: #fff;
  width: 100%;
  padding: 15px 0px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.chatBox::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.chatBox::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.applyBlur {
  filter: blur(3px);
  pointer-events: none !important;
}

.chat-header.applyBlur {
  filter: blur(3px);
  pointer-events: none !important;
}

.chat-section.applyBlur {
  filter: blur(3px);
  pointer-events: none !important;
}

.chat-input-section.applyBlur {
  filter: blur(3px);
  pointer-events: none !important;
}

span.chat-tick-icon img {
  margin-right: 5px;
}

body[dir="rtl"] span.chat-tick-icon {
  margin-right: 0;
  margin-left: 5px;
}

body[dir="rtl"] p.chat-date {
  text-align: left !important;
  font-size: 10px;
}

body[dir="rtl"] .chat-messenger-head {
  height: 53vh;
  position: fixed;
  bottom: 7px;
  right: auto;
  width: 325px;
  background: #fff;
  border: 1px solid #6172d6;
  border-radius: 4px;
  left: 85px;
}

body[dir="rtl"] .encryption-level-chat span.securityicon-box {
  position: absolute;
  right: auto;
  top: 4px;
  left: 30px;
}

body[dir="rtl"] .delete-uplaoded-file {
  position: absolute;
  top: 11px;
  right: auto;
  left: -195px;
}

body[dir="rtl"] .chat-upload-icon p.chat-upload-text {
  position: absolute;
  left: auto;
  top: 10px;
  right: 65px !important;
  width: 150px;
}

body[dir="rtl"] .file-upload {
  height: 45px;
  width: 210px;
  background: #e8eef3;
  border-radius: 7px;
  margin-right: auto;
  margin-left: 50px;
}

body[dir="rtl"] img.attachment-icon {
  position: absolute;
  top: 6px;
  left: auto;
  width: 25px;
  right: 30px;
}

body[dir="rtl"] em-emoji-picker {
  left: -15px;
}

body[dir="rtl"] span.securityicon-box {
  position: absolute;
  right: auto;
  top: -2px;
  left: 15px;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft:has(
    .rc-virtual-list-holder-inner
      .ant-select-item.ant-select-item-option.talk-chat-filter
  ) {
  min-width: 300px !important;
  width: 300px !important;
}
body[dir="rtl"]
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft:has(
    .rc-virtual-list-holder-inner
      .ant-select-item.ant-select-item-option.talk-chat-filter
  ) {
  min-width: 300px !important;
  width: 300px !important;
  left: 96px !important;
}

.chat-profile-icon svg {
  width: 25px;
}

.chathead-box-icons {
  display: none;
  position: absolute;
  right: 0;
  top: -10px;
}

.chathead-box-icons img {
  width: 18px;
}

body[dir="rtl"] .chathead-box-icons img {
  margin-right: 190px !important;
}

.dropdown-menus-chathead {
  background: #fff;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  display: inline-grid;
  padding-top: 10px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 18px;
  width: 100px;
  z-index: 9999999;
}

body[dir="rtl"] .dropdown-menus-chathead {
  margin-right: 100px !important;
}

.dropdown-menus-chathead span {
  border-bottom: 1px solid #ccc;
  margin: 0px 10px 7px 10px;
  font-size: 10px;
  font-weight: 600;
}

.chat-block:hover .chathead-box-icons {
  display: block !important;
}

.upload-options {
  position: absolute;
  bottom: 30px;
  left: -20px;
}

.upload-options span.MuiButton-label {
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 18px;
  visibility: visible !important;
  position: relative;
}

.upload-options span.MuiButton-label img {
  position: absolute;
  width: 17px;
  top: 9px;
  left: 10px;
}

.upload-options
  span.MuiButtonBase-root.MuiButton-root.MuiButton-contained.UploadFileButton.MuiButton-containedPrimary {
  margin: 5px 0px;
}

.chat-section {
  position: relative;
  height: 34.7vh;
  padding: 15px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column-reverse;
}

.chat-section.searchField {
  position: relative;
  height: 34.7vh;
  padding: 15px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column-reverse;
  top: 25px;
}

.chat-box-content .message-box {
  word-wrap: break-word;
}

.message-outbox {
  background: #fff;
  color: #000000;
  border: 1px solid #bcbcbc;
}

.message-inbox {
  background: #2197d7;
  color: #fff;
}

.message-inbox,
.message-outbox {
  padding: 6px 10px 6px 10px;
  display: inline-block;
  width: 80%;
  border-radius: 8px;
  position: relative;
}

.message-inbox:hover,
.message-outbox:hover {
  box-shadow: inset 0 15px 9px -7px rgb(108 70 70 / 40%);
}

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  height: 20px;
  border-bottom: 1px solid;
}

.chat-box-content .message-box {
  word-wrap: break-word;
}

.message-outbox .chat-datetime {
  color: #6d7274;
  margin-right: 7px;
}

.message-inbox .dropdown,
.message-outbox .dropdown {
  position: absolute;
  top: 10px;
  right: 5px;
}

span.direct-chat-body {
  font-size: 14px;
}

span.chat-datetime {
  font-size: 9px;
}

.chat-section::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.chat-section::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

body[dir="rtl"] .chathead-box-icons {
  text-align: left !important;
}

.direct-chat-text {
  position: relative;
}

.direct-chat-text:hover .chatmessage-box-icons img.dropdown-icon {
  display: block !important;
}

.direct-chat-text .chatmessage-box-icons img.dropdown-icon {
  position: absolute;
  width: 12px;
  top: 5px;
  right: 15px;
  z-index: 9;
  cursor: pointer;
  display: none;
}

.dropdown-menus-chatmessage {
  position: absolute;
  background: #fff;
  display: inline-grid;
  text-align: center;
  top: 14px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  z-index: 9;
  right: -1px;
}

.last-message .dropdown-menus-chatmessage {
  position: absolute;
  background: #fff;
  display: inline-grid;
  text-align: center;
  top: -125px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  z-index: 9;
  right: -1px;
}

.dropdown-menus-chatmessage span {
  border-bottom: 1px solid #ccc;
  margin: 5px 5px 3px 5px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #171d2d;
}

.chat-feature-action {
  position: absolute;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  height: 65px !important;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: end;
  margin-left: -15px;
  margin-bottom: -15px;
  margin-right: -15px;
  margin-top: -15px;
}

.chat-feature {
  height: 45px;
  width: 200px;
  background: #e8eef3;
  border-radius: 7px;
  margin-right: 50px;
}

.chat-feature-option {
  position: relative;
}

p.chat-feature-text {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 180px;
  font-size: 14px;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.chat-feature-text span {
  display: block;
  margin-bottom: -6px;
  color: #2197d7;
  font-weight: 500;
  font-size: 10px;
}

.remove-chat-feature {
  position: relative;
}

img.Remove-feature {
  position: absolute;
  top: -8px;
  right: 20px;
  width: 12px;
  cursor: pointer;
}

img.chat-feature-image {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 15px;
}

p.feature-name {
  margin: 0;
  font-size: 9px;
  padding-right: 5px;
  color: #fff;
}

span.direct-chat-body {
  font-size: 14px;
  display: flex;
  word-break: break-word;
}

body[dir="rtl"] .direct-chat-text .chatmessage-box-icons img.dropdown-icon {
  position: absolute;
  width: 12px;
  top: 0px;
  right: auto;
  z-index: 9;
  cursor: pointer;
  right: 195px;
}

body[dir="rtl"] .dropdown-menus-chatmessage {
  position: absolute;
  background: #fff;
  display: inline-grid;
  text-align: center;
  top: 14px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  z-index: 9;
  right: auto;
  left: -1px;
}

body[dir="rtl"] p.feature-name {
  margin: 0;
  font-size: 9px;
  padding-left: 5px;
  padding-right: 0 !important;
  color: #fff;
  font-weight: 600;
}

body[dir="rtl"] .chat-feature {
  height: 45px;
  width: 210px;
  background: #e8eef3;
  border-radius: 7px;
  margin-left: 45px;
  margin-right: 0;
}

body[dir="rtl"] p.chat-feature-text {
  position: absolute;
  left: auto;
  top: 10px;
  width: 150px;
  right: 65px;
}

body[dir="rtl"] img.chat-feature-image {
  position: absolute;
  top: 15px;
  left: auto;
  width: 15px;
  right: 20px;
}

body[dir="rtl"] img.Remove-feature {
  position: absolute;
  top: -8px;
  right: auto;
  width: 12px;
  cursor: pointer;
  left: 20px;
}

label.ant-checkbox-wrapper.chat-message-checkbox-sender {
  position: relative;
  margin-right: 10px;
  top: -12px;
  left: -3px;
}

label.ant-checkbox-wrapper.chat-message-checkbox-receiver {
  position: relative;
  margin-left: 10px;
  top: -12px;
  right: -3px;
}

.talk-screen-innerwrapper {
  position: absolute;
  margin-top: 59px !important;
  width: 100%;
  min-height: 50px;
  height: auto;
  background: #fff;
  top: 0;
  z-index: 4;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* opacity: 0; */
  transition: opacity 0.3s ease-in;
}

.talk-screen-innerwrapper .talk-screen-content {
  height: calc(87%);
}

.talk-screen-innerwrapper .message-footer,
.talk-screen-innerwrapper .talk-screen-content,
.talk-add-user-innerwrapper .talk-add-user-content {
  padding: 10px 15px;
}

.message-heading {
  color: #036ab2;
  font-size: 16px;
  font-weight: 700;
}

.heading-info {
  font-size: 14px;
  width: 280px;
  font-weight: 600;
  color: #232323;
}

i.icon-close.close-message-info {
  font-size: 23px;
}

.message-info-item {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 9px;
}

.Sent-with-icon {
  display: flex;
}

.heading-info.status {
  width: auto;
  margin-right: 15px;
}

.Sent-with-icon img {
  width: 18px;
  height: 10px;
  margin-top: 5px;
}

.time-info {
  color: #5a5a5a;
  font-size: 10px;
}

body[dir="rtl"] .heading-info.status {
  width: auto;
  margin-right: 0;
  margin-left: 15px;
}

body[dir="rtl"] label.ant-checkbox-wrapper.chat-message-checkbox-sender {
  position: relative;
  margin-right: 0;
  top: -12px;
  left: auto;
  right: -3px;
  margin-left: 10px;
}

.delete-chat-popup {
  position: absolute;
  top: 40%;
  background: #fff;
  width: 94%;
  padding: 15px 0px;
  margin: 0px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  z-index: 9;
  left: 0;
}

.Cancel-btn-delete {
  width: 100% !important;
  height: 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #232323 !important;
  font-size: 8px;
  margin-top: 15px;
}

.Delete-btn-delete {
  width: 100% !important;
  height: 30px !important;
  border: 1px solid #6172d6 !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  font-size: 8px;
  margin-top: 15px;
}

.Unblock-btn {
  width: 75px !important;
  height: 25px !important;
  border: 1px solid #5f78d6;
  border-radius: 4px;
  background-color: #5f78d6 !important;
  color: #fff !important;
  font-size: 10px;
}

p.chat-username.blocked-users {
  max-width: 145px !important;
}

.chat-block.blocked-users {
  height: 50px !important;
}

.chat-block.add-user-section {
  height: 50px !important;
}

.chat-block.add-user-section p.chat-username {
  margin-top: 20px !important;
}

.star-time-status {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  gap: 5px !important;
}

.chat-profile-icon.forward {
  width: 30px !important;
  height: 30px !important;
}

.users-forward {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.users-forward p {
  padding-left: 10px;
  padding-top: 6px;
}

.users-list-forward {
  height: 185px;
  overflow: hidden auto;
}

.users-list-forward::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.users-list-forward::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.Ok-btn.forward-user {
  margin-top: 0 !important;
}

.chat-profile-icon.starred-message {
  width: 25px !important;
  height: 25px !important;
}

.chat-block.starred-message {
  margin-top: 7px !important;
}

p.date.starred-message {
  font-size: 8px;
  margin-top: 8px !important;
}

p.chat-username.starred-message {
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px !important;
  /* margin-left: 10px !important; */
}

.reply-message {
  background: #2197d7;
  color: #fff;
  width: 240px;
  padding: 5px 10px;
  height: auto;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.bottom-border-starred {
  border-bottom: 1px solid #d6d6d6;
  margin-left: 3px;
  margin-right: 3px;
}

.starred-icon-date {
  display: flex;
  justify-content: end;
  align-items: center;
  vertical-align: middle;
}

.starred-icon-date span {
  margin-right: 5px;
}

.starred-icon-date p {
  font-size: 10px;
  margin-top: 2px !important;
}

.add-group-members-list {
  height: 60vh;
  overflow: hidden auto;
}

.single-user {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.user-profile-icon {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px;
  margin-top: 5px;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

span.user-active-status-group {
  width: 10px;
  position: absolute;
  background-color: #81db86 !important;
  right: 1px;
  bottom: 2px;
  height: 10px;
  border-radius: 10px;
  border: 3px solid #fff;
}

.group-add-user {
  height: 35px !important;
  margin-top: 0px;
  margin-left: 10px;
  position: relative;
}

.group-add-user p.chat-username-group {
  margin-top: 8px !important;
  font-size: 13px !important;
  font-weight: 600;
}

.group-add-user span {
  font-size: 10px;
  position: absolute;
  top: 16px;
}

label.ant-checkbox-wrapper.chat-message-checkbox-group {
  position: relative;
  margin-left: 10px;
  top: 10px;
  /* right: -3px; */
}

.chat-groupinfo-icon {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  padding: 10px;
  margin-top: 5px;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

p.groupinfo-groupname {
  font-size: 14px;
  margin-top: 5px !important;
  font-weight: 600;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.groupinfo-createdon {
  font-size: 9px;
  color: #a2a2a2;
  margin-bottom: 8px !important;
}

.users-list-groupinfo {
  height: 130px;
  overflow: hidden auto;
}

.users-list-groupinfo::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.users-list-groupinfo::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.users-groupinfo {
  display: flex;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}

.chat-profile-icon.groupinfo {
  width: 30px !important;
  height: 30px !important;
  margin-bottom: 7px;
}

p.groupinfo-groupusersname {
  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
  margin-left: 10px !important;
  width: 100%;
  position: relative;
}

span.groupinfo-admin {
  color: #5f78d6;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
}

span.starred-status {
  margin-right: 5px;
  margin-top: -3px;
}

.talk-overallchat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

img.Edit-Group-Title-Icon {
  width: 13px;
  margin-left: 10px;
  margin-top: 2px;
}

mark.YourHighlightClass {
  background: #000;
  color: #fff;
}

.chat-searchfield {
  position: absolute;
  width: 100%;
  /* width: 90%;
  top: 95px;
  z-index: 9999; */
}

.replied-message-send {
  background: #ebebeb;
  height: 50px;
  padding: 8px;
  border-radius: 8px;
}

.replied-message-receive {
  background: #e9f6ff;
  height: 50px;
  padding: 8px;
  border-radius: 8px;
}

p.replied-message-sender {
  font-weight: bold;
}

p.replied-message-receiver {
  font-weight: bold;
  color: #2197d7;
}

p.replied-message.m-0 {
  color: #505050;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
}

label.ant-checkbox-wrapper.group-edit-users-add {
  margin-right: 10px;
}

.chat-username.chathead {
  width: 128px !important;
  margin-left: 25px !important;
  margin-top: 16px !important;
  font-weight: 500;
  font-size: 16px !important;
}

p.group-sender-name {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}

/* input.form-control2.Saved_money_Tagline.form-control {
  margin: 10px 0px;
} */

input[placeholder="Search Chat"] {
  margin-top: 10px;
}

.edit-group-button {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.participant-warning {
  font-weight: 500;
  color: #f16b6b;
  font-size: 12px;
  margin-bottom: 10px !important;
}

.Retry-Delete-Feature {
  text-align: center;
}

span.retry {
  margin-right: 20px;
  color: #0071c5;
  cursor: default;
}

span.retry:hover {
  text-decoration: underline;
  cursor: pointer !important;
}

span.delete {
  color: #ff0000;
  cursor: default;
}

span.delete:hover {
  text-decoration: underline;
  cursor: pointer !important;
}

button.UploadFileButton {
  visibility: hidden !important;
  margin-top: 11px;
  margin-left: -3px;
}

.image-thumbnail {
  display: block;
  height: 270px;
  width: 91%;
  text-align: center;
  padding-top: 35px;
  /* border: 1px solid #ccc; */
  position: absolute;
  background: #e1e1e1;
}

img.thumbnailImage {
  height: 200px;
  width: 70%;
}

.removeImage-thumbnail {
  position: absolute;
  z-index: 9;
  right: 20px;
  top: 17px;
}

.chat-input-field.no-upload-options input {
  width: 250px !important;
}

.image-thumbnail-chat {
  height: 165px;
}

.image-thumbnail-chat img {
  height: 165px;
  width: 100%;
  border-radius: 4px;
}

.file-upload-options {
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 18px;
  position: relative;
  margin: 8px 11px;
}

label.image-upload {
  position: absolute;
  top: 7px;
  left: 8px;
}

.file-uploaded-chat {
  background: #e1e1e1;
  padding: 10px;
  border-radius: 4px;
}

.file-uploaded-chat.received {
  background: #e9f6ff !important;
}

span.attached-file {
  margin-left: 10px;
  font-size: 12px;
  color: #171d2d;
  font-weight: 600;
  margin-right: 10px;
  display: inline-block;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.chat-input-field textarea.chat-message-input.form-control:focus {
  box-shadow: none !important;
}

textarea.chat-message-input.form-control {
  width: 100%;
}

textarea.chat-message-input.form-control::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

textarea.chat-message-input.form-control::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.emoji-section {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.ant-result.ant-result-info.emptyRecentChats {
  transform: translate(0px, 80px);
}

.emptyRecentChats .ant-result-title {
  font-size: 14px;
  color: #5a5a5a;
}

.chat-box-security {
  height: 100%;
  width: 25px;
  /* cursor: pointer; */
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin-top: 5px;
  margin-left: -12px;
  z-index: 9999999;
  position: relative;
}

.chat-box-security img {
  width: 18px;
}

.chat-box-icons.closechat img {
  width: 14px !important;
}

.chat-searchfield input {
  border: 2px solid #6172d6 !important;
  border-left: none !important;
  background: #ffffff;
  height: 35px !important;
  border-radius: 0 !important;
  border-right: none !important;
  z-index: 9;
  position: absolute;
}

.chat-searchfield input:hover,
.chat-searchfield input:active,
.chat-searchfield input:focus,
.chat-searchfield input:focus-visible,
.chat-searchfield input:visited,
.chat-searchfield input:target {
  border: 2px solid #6172d6 !important;
  border-left: none !important;
  background: #ffffff;
  height: 35px !important;
  border-radius: 0 !important;
  border-right: none !important;
  z-index: 9;
}

.chat-messenger-head-video .chat-searchfield {
  top: 100px !important;
}

.chat-inner-content .row input {
  border: 1px solid #cdcdcd !important;
  background: #f9f9f9;
  height: 35px !important;
  margin-top: 10px;
}

.chat-messages-section {
  margin-bottom: 48px;
}

.chat-searchfield .valid-feedback {
  display: block;
  position: absolute;
  right: -7px !important;
  width: 40px;
  top: 4px;
  z-index: 9;
}

span.background-close-search {
  display: flex;
  width: 34px;
  height: 33px;
  text-align: center;
  margin-top: -7px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
}

.options-rd {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.option-r {
  cursor: pointer;
  text-decoration: none;
  color: #6172d6;
  font-weight: bold;
  transition: text-decoration 0.2s;
}

.option-d {
  cursor: pointer;
  text-decoration: none;
  color: #ff0000;
  font-weight: bold;
  transition: text-decoration 0.2s;
}

/* Underline on hover */
.option-r:hover,
.option-d:hover {
  text-decoration: underline;
}

.dropdown-menu-upwards {
  position: absolute !important;
  top: -160px !important;
  transform: none !important;
  left: -100px !important;
}

.dropdown-menu-upwardsReciever {
  position: absolute !important;
  top: -160px !important;
  transform: none !important;
  left: -100px !important;
}

body[dir="rtl"] .dropdown-menu-upwardsReciever.dropdown-menu.show {
  position: absolute !important;
  top: -160px !important;
  transform: none !important;
  left: -100px !important;
}

.dropdown-menu-upwardsSender {
  position: absolute !important;
  top: -140px !important;
  transform: none !important;
  right: 28px !important;
  left: auto !important;
}

body[dir="rtl"] .dropdown-menu-upwardsSender.dropdown-menu.show {
  position: absolute !important;
  top: -160px !important;
  transform: none !important;
  right: auto !important;
  left: -168px !important;
}

::placeholder {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
}

img.image-close {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}

body[dir="rtl"] img.image-close {
  position: absolute;
  right: auto;
  top: 0px;
  cursor: pointer;
  left: 10px;
}

.image-modal.modal-header .modal-title {
  width: 100% !important;
}

button.talk-dropdown-toggle.dropdown-toggle {
  border: none !important;
}

body[dir="rtl"] .chat-box-icons .dropdown-menu .show {
  top: 0px !important;
  right: 50px !important;
}

.chat-box-icons.cursor-pointer.positionRelative.show.dropdown a.dropdown-item {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.talkchatMsg .dropdown .dropdown-menu {
  left: 0 !important;
}
body[dir="rtl"] .talkchatMsg .dropdown {
  right: 0 !important;
}

body[dir="rtl"] .ChatsOneToOneDropDownMenuReciever.dropdown-menu.show {
  left: auto !important;
  right: 49px !important;
  top: 15px !important;
  transform: none !important;
}

.ChatsOneToOneDropDownMenuSender.dropdown-menu.show {
}

body[dir="rtl"] .ChatsOneToOneDropDownMenuSender.dropdown-menu.show {
  margin-top: -12px !important;
  transform: none !important;
  inset: initial !important;
}

body[dir="rtl"] .ChatsOneToOneDropDownRecieverToggle button {
  border: none !important;
  display: none !important;
}
