.EmailVerifyBoxVerificationIphone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.languageSelectors {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelectors {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.phone-image {
  position: absolute;
  top: 130px;
  right: -200px;
  z-index: 1;
  user-select: none;
  width: 22.125rem;
}

body[dir="rtl"] .phone-image {
  position: absolute;
  top: 19%;
  right: 255px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth_Icon {
  position: fixed;
  right: -115px;
  bottom: -175px;
  transform: rotate(45deg);
}
body[dir="rtl"] .Auth_Icon {
  position: absolute;
  left: -290px !important;
  bottom: -190px;
  right: 50px;
  transform: rotate(22deg);
}

.Next_button_EmailVerifyVerificationIphone {
  width: 100%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Next_button_EmailVerifyVerificationIphone {
  font-weight: 600 !important;
}

.Next_button_EmailVerifyVerificationIphone:focus-visible {
  width: 280px;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}
.Next_button_EmailVerifyVerificationIphone:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6 !important;
}
.loginbox_auth_paperForiphone {
  padding: 40px 27px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 469px !important;
  width: 330px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.forogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400 !important;
}

body[dir="rtl"] .forogt_email_link {
  font-weight: 400 !important;
}

.forogt_email_link a {
  color: #5a5a5a !important;
}

.SelectLineIphone {
  margin-top: -0.9rem;
  color: #5a5a5a;
  font-weight: 600 !important;
}
body[dir="rtl"] .SelectLineIphone {
  font-weight: 600 !important;
}

.VerifyHeadingIphone {
  color: #5a5a5a;
  font-weight: 600 !important;
  font-size: calc(35px - 20%) !important;
}

body[dir="rtl"] .VerifyHeadingIphone {
  font-weight: 600 !important;
}
.Auth_Icon1SendEmailVerificationIphone {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: 9;
}

.circle-imageVerifcationIphone {
  position: absolute;
  right: -250px;
  bottom: -220px;
  transform: rotate(-66deg);
}

.verificationIphoneLabels {
  color: #5a5a5a;
  font-weight: 600 !important;
}

body[dir="rtl"] .verificationIphoneLabels {
  font-weight: 600 !important;
}

.verificationIphoneLabels_active {
  color: #e1e1e1 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .verificationIphoneLabels_active {
  font-weight: 600 !important;
}

.Verification-Iphone-language {
  position: absolute;
  overflow: hidden !important;
  top: 50px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Verification-Iphone-language,
.Verification-Iphone-language:focus-within,
.Verification-Iphone-language:focus-visible {
  border-color: rgb(133, 133, 133) !important;
  box-shadow: none !important;
  outline: none !important;
}
.select-language-signin_selectfrommultidevices {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  outline: none !important;
}

.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin_selectfrommultidevices:focus,
.select-language-signin_selectfrommultidevices:focus-within,
.select-language-signin_selectfrommultidevices:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}
body[dir="rtl"] .select-language-signin_selectfrommultidevices {
  right: 80%;
}
.languageIcon_selectfrommultidevices {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 37px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon_selectfrommultidevices {
  right: 80.7%;
}

.Scroll_bar_For_devices {
  height: 16vh !important;
  overflow-x: hidden !important;
}

.Scroll_bar_For_devices::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroll_bar_For_devices::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
