.Ok-Successfull-btn {
  width: 119px !important;
  height: 46px !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none;
  margin-bottom: 0px !important;
  /* margin-top: -10px; */
}

.deleteModal-message {
  text-align: center !important;
  font-size: 14px;
  font-weight: 600 !important;
  display: block;
  padding: 10px 0px;
  margin-top: 10px !important;
}

body[dir="rtl"] .deleteModal-message {
  font-weight: 600 !important;
}

.allowModalIcon {
  color: #f16b6b;
  border: none !important;
  border-radius: 0px !important;
  margin-top: 24px !important;
}

.PaymentActivitySubtitle {
  font-weight: 700 !important;
  color: #5a5a5a;
  font-size: 20px;
}

body[dir="rtl"] .PaymentActivitySubtitle {
  font-weight: 700 !important;
}

.SummarayOpenInvoiceRecords {
  font-weight: 400 !important;
  color: #5a5a5a !important;
  font-size: 16px !important;
}

body[dir="rtl"] .SummarayOpenInvoiceRecords {
  font-weight: 400 !important;
}

.Pay_invoice_button {
  padding: 6px 12px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.Pay_invoice_button:is(:hover, :active, :focus, :focus-visible) {
  padding: 6px 12px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}
