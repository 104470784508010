.Delete_notification_bar {
    width: 134px !important;
    height: 33px !important;
    background: #5a5a5a;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    position: absolute;
    bottom: 0;
    left: 5% !important;
    z-index: 1;
}

.Folder_removed_heading {
    font-size: 12px !important;
    color: #f6f6f6;
    font-weight: 600;
}