.Update_Poll_Heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6 !important;
}

body[dir="rtl"] .Update_Poll_Heading {
  font-weight: 600;
}

.Overall_padding {
  padding: 0px 15px !important;
}

.Cross_Icon_Styling_Update_Poll_Modal {
  cursor: pointer !important;
  z-index: 1 !important;
}

.Back_Ground_strip_Create_Poll_modal {
  background: rgba(194, 194, 194, 0.14);
  height: 40px !important;
  width: 100% !important;
}

.ModalHeader_Update_poll {
  width: 100% !important;
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  height: auto;
  margin-top: -5px !important;
}

.Back_Ground_strip_Create_Poll_modal {
  background: rgba(194, 194, 194, 0.14);
  padding: 5px 5px !important;
  margin: 0 !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalRequestHeader_polling_update_modal {
  margin: 0 !important;
  padding: 20px 20px !important;
  width: 100%;
}

.Due_Date_heading {
  font-size: 12px !important;
  font-weight: 600;
}

body[dir="rtl"] .Due_Date_heading {
  font-weight: 600;
}

.Add_Another_field {
  border: 1px dashed #e1e1e1;
  height: 40px !important;
  border-radius: 4px !important;
  width: 100% !important;
  display: inline-block;
}

.Scroller_For_CreatePollModal2_Update_poll {
  height: 25vh !important;
  overflow-x: hidden !important;
}

.Scroller_For_CreatePollModal2_Update_poll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_For_CreatePollModal2_Update_poll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Scroller_For_UpdatePollModal {
  height: 26vh !important;
  overflow-x: hidden !important;
}

.Scroller_For_UpdatePollModal::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_For_UpdatePollModal::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Add_another_field {
  font-size: 12px !important;
  font-weight: 500;
  color: #bbbbbb;
}

body[dir="rtl"] .Add_another_field {
  font-weight: 500;
}

.polling_Options_backGround {
  background: #f16b6b !important;
  display: block !important;
  position: absolute;
  width: auto !important;
  right: 0;
  margin-top: 0 !important;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important ;
  align-items: center;
}

body[dir="rtl"] .polling_Options_backGround {
  position: absolute !important;
  left: 13px !important;
  right: auto;
  display: flex !important;
  width: auto !important;
  z-index: 1 !important;
  justify-content: end !important;
  margin-top: 0 !important;
  top: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important ;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important ;
  height: 100% !important;
  align-items: start !important;
}

.Cross-icon-Create_poll {
  padding: 5px 5px !important;
  margin-top: 4px !important;
  cursor: pointer !important;
}

.PlusFaddedClass {
  margin-left: 10px !important;
}

.CheckBoxClass {
  display: flex;
}

.CheckBoxTitle {
  margin: 0;
  padding: 0;
  font-size: 12px !important;
  font-weight: 600;
}

.ADD_Btn_CreatePool_Modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  border: none;
  border-radius: 4px !important;
}

.ADD_Btn_CreatePool_Modal:hover,
.ADD_Btn_CreatePool_Modal:active,
.ADD_Btn_CreatePool_Modal:focus,
.ADD_Btn_CreatePool_Modal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
}

.Card_border2 {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  margin-left: 0px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

body[dir="rtl"] .Card_border2 {
  margin-right: 0px !important;
}

.Name_cards {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-left: 5px !important;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

body[dir="rtl"] .Name_cards {
  margin-right: 5px !important;
}

.Cancell_btn_class_Update_polls {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #fff;
  color: #5a5a5a;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}

.Cancell_btn_class_Update_polls:hover,
.Cancell_btn_class_Update_polls:active,
.Cancell_btn_class_Update_polls:focus,
.Cancell_btn_class_Update_polls:focus-visible {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Update_btn_class {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #6172d6;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Update_btn_class:hover,
.Update_btn_class:active,
.Update_btn_class:focus,
.Update_btn_class:focus-visible {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Update_Publish_btn_class {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #6172d6;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Update_Publish_btn_class:active,
.Update_Publish_btn_class:hover,
.Update_Publish_btn_class:focus,
.Update_Publish_btn_class:focus-visible {
  font-size: 12px !important;
  padding: 12px 30px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.LostClass {
  display: flex;
  justify-content: center;
}

.Unsaved_heading {
  font-weight: 600;
  font-size: 16px !important;
  color: #5a5a5a;
}

.No_Btn_polls_delModal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.No_Btn_polls_delModal:hover,
.No_Btn_polls_delModal:active,
.No_Btn_polls_delModal:focus,
.No_Btn_polls_delModal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
}

.Yes_Btn_polls_delModal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Yes_Btn_polls_delModal:active,
.Yes_Btn_polls_delModal:focus,
.Yes_Btn_polls_delModal:focus-visible,
.Yes_Btn_polls_delModal:hover {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Date_update_poll {
  font-weight: 600;
  font-size: 12px !important;
  color: #5a5a5a;
}

.ClassAddButton {
  height: 0 !important;
  display: flex;
  justify-content: start !important;
  gap: 10px !important;
}

.Add_another_options {
  height: 39px !important;
  background: #fff !important;
  color: #d4d4d4 !important;
  width: 100%;
  font-size: 12px !important;
  border: 1px dashed #a2a2a2 !important;
}

.Add_another_options:active,
.Add_another_options:hover,
.Add_another_options:focus,
.Add_another_options:focus-visible {
  height: 39px !important;
  background: #fff !important;
  color: #d4d4d4 !important;
  width: 100% !important;
  border: 1px dashed #a2a2a2 !important;
  font-size: 12px !important;
}

.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 600;
}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 50;
}

body[dir="rtl"] .errorMessage-inLogin_1 {
  font-weight: 500;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 0px;
  color: #f16b6b;
}

.errorMessage-inLogin_1 {
  margin: 2px;
  height: 13px;
  font-size: 12px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage-inLogin_1_hidden {
  visibility: hidden;
  margin: 0;
  font-size: 12px;
  height: 13px;
  color: #f16b6b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Participant_heading {
  font-size: 20px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-top: 10px;
}

.Box_For_Title_toShow {
  width: 100% !important;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.ViewTitleTOShowOnProgress {
  width: 100%;
  font-size: 14px !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  margin: 4px !important;
}

.scroll-height {
  height: 17vh !important;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.scroll-height::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scroll-height::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.BOx_for_yes {
  width: 100%;
  height: auto !important;
  min-height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding-left: 10px;
  margin: 0 0 10px 0;
}

.scrollable-title {
  max-height: 100px;
  min-height: 39px;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
  overflow-y: auto;
}

.scrollable-title::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.scrollable-title::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.OptionBodyMaxHeight {
  margin-top: 14px;
  max-height: 17vh; /* Set the maximum height you desire for the title */
  overflow: hidden;
  overflow-y: auto; /* Enable vertical scrolling when text overflows */
  margin-right: 0 !important;
}

.scrollable-title2 {
  min-height: 39px !important;
}

body[dir="rtl"] .scrollable-title2 {
  margin-right: 20px !important;
}

/* Common styles for all browsers except Firefox */
.polling_Options_backGround {
  display: block !important;
  position: absolute !important; /* Default position for non-Firefox browsers */
  width: auto !important;
  right: 0;
  margin-top: 0 !important;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  align-items: center;
}

.CreatePolling_polling_Options_backGround__6-6qw {
  display: block !important;
  position: absolute !important; /* Default position for non-Firefox browsers */
  /* Other shared styles here */
}

/* Firefox-specific CSS */
@-moz-document url-prefix() {
  .polling_Options_backGround {
    /* Firefox-specific styles here */
    background: #f16b6b !important;
    position: relative !important; /* Change position for Firefox */
    width: 6% !important;
    left: 94% !important;
    margin-top: -40px !important;
    padding: 2px 0px !important;
  }

  .CreatePolling_polling_Options_backGround__6-6qw {
    /* Firefox-specific styles here */
    background: #f16b6b !important;
    position: relative !important; /* Change position for Firefox */
  }
}

.MiniHeadings {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.redSteric {
  color: #f16b6b !important;
}
