.WebNotificationOuterBox {
  max-width: 452px !important;
  width: 100% !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  position: absolute !important;
  top: 65px !important;
  right: 119px !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
  transition: 0.5s !important;
}

body[dir="rtl"] .WebNotificationOuterBox {
  left: 125px !important;
  right: auto !important;
}

.NotificationCateogries {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.WebNotificationOuterBox {
  height: 75vh !important;
  overflow-x: hidden !important;
}

.WebNotificationOuterBox::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.WebNotificationOuterBox::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.BackGroundUnreadNotifications {
  width: 100% !important;
  background-color: #f3f3f3 !important ;
  cursor: pointer !important;
}

.BackGroundreadNotifications {
  width: 100% !important;
  background-color: #fff !important ;
  cursor: pointer !important;
}

.NotificationCategories {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.NotificationEmptyState {
  color: #a2a2a2 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.TopMargin {
  margin-top: 164px !important;
}
