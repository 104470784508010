.document-card {
  background: #f6f9fc;
  border-radius: 9999px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  /* padding: 0.5rem 1rem; */
  width: 100%;
  max-width: 15rem;
  border: 1px solid #d4d4d4 !important;
  /* outline: none !important; */
}
.document-card-none {
  background: #f6f9fc;
  border-radius: 9999px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  padding: 5px;
  width: 100%;
  max-width: 15rem;
  border: 1px solid #d4d4d4 !important;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* .icon-container svg {
    color: rgb(239 68 68);
  } */

.document-name {
  color: rgb(55 65 81);
  font-size: 0.9em;
  cursor: pointer;
}

.action-button {
  padding: 0.5rem;
  border-radius: 9999px;
  transition: background-color 0.2s;
  border: none !important;
  background: transparent;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background-color: rgb(243 244 246);
}

.action-button:focus {
  box-shadow: none !important;
}

.action-button svg {
  color: rgb(75 85 99);
}

.divider {
  width: 1px;
  height: 1.25rem;
  background-color: rgb(229 231 235);
  margin: 0 0.25rem;
}
