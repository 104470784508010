.UpdateNote-heading {
  color: #6172d6 !important;
  font-size: 23px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0 !important;
}

body[dir="rtl"] .UpdateNote-heading {
  font-weight: 600 !important;
}

.star-updatenote {
  color: #4adede !important;
  border: #707070;
  cursor: pointer;
}

.date-updatenote {
  font-size: 10px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .date-updatenote {
  font-weight: 400 !important;
}

.date-updatenote2 {
  font-size: 10px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .date-updatenote2 {
  font-weight: 400 !important;
}

.QuillRow {
  position: relative;
}

.cancel-Update-notes {
  background: white !important;
  border: 1px solid #e1e1e1 !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 12pt !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .cancel-Update-notes {
  font-weight: 600 !important;
}

.Update-notes-Button {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 12pt !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Update-notes-Button {
  font-weight: 600 !important;
}

.Delete-notes-Button {
  background: white !important;
  border: 1px solid #e1e1e1 !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 12pt !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Delete-notes-Button {
  font-weight: 600 !important;
}

.save-Update-notes {
  background: #5a5a5a !important;
  border: none !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 12pt !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.modaltodolist-attachment-icon {
  margin-top: 10px !important;
  position: relative;
}

.delete-note-modal-text {
  padding: 5px 100px;
  margin-top: 30px !important;
  font-weight: 600 !important;
  color: #5a5a5a;
  font-size: 20px;
  font-weight: 600;
  text-align: center !important;
}

body[dir="rtl"] .delete-note-modal-text {
  font-weight: 600 !important;
}

.delete-note-modal-size {
  width: 450px !important;
  align-items: center !important;
}

.cancel-note-modal-btn {
  background: white !important;
  border: 1px solid #e1e1e1 !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12pt !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .cancel-note-modal-btn {
  font-weight: 600 !important;
}

.delete-note-modal-btn {
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 12px 30px !important;
  font-size: 12pt !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .delete-note-modal-btn {
  font-weight: 600 !important;
}

.modalUpdateNotes {
  display: block !important;
}

.modal-update-attachment-heading {
  font-size: 13px !important;
  font-weight: 600;
  margin-top: 80px !important;
}

.quill-update-height {
  position: relative;
  height: 19vh !important;
  border-top: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.header-UpdateNotesModal-close-btn {
  height: 40px !important;
}

.header-UpdateNotesModal-close-btn button {
  color: #000 !important;
  opacity: 1 !important;
}

.header-UpdateNotesModal-close-btn button:hover {
  color: #000 !important;
}

.errorNotesMessage {
  position: absolute;
  left: 145px !important;
  top: 60px !important;
  color: #f16b6b !important;
  border: 1px solid #bababa;
  width: 50%;
  font-weight: 600 !important;
  visibility: visible !important;
  background-color: white !important;
  z-index: 9 !important;
  padding: 5px 25px;
  text-align: center;
  border-radius: 4px !important;
  font-size: 18px !important;
}

.attached-title {
  font-weight: 600 !important;
}

body[dir="rtl"] .attached-title {
  font-weight: 600 !important;
}

body[dir="rtl"] .star-updatenote {
  position: absolute;
  right: 185px !important;
}

body[dir="rtl"] .quill-update-height {
  width: 100% !important;
}

.notes-updates-attachment {
  min-width: 100% !important;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  padding: 0 !important;
  max-height: 90px !important;
  flex-wrap: wrap;
  gap: 15px;
}

.notes-updates-attachment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.notes-updates-attachment::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.deleteUpdateNoteAttachment {
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 10px;
  margin: 0;
}

.errorMessage {
  margin: 4px;
  height: 15px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 600 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 22px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 600 !important;
}

.errorUpdateMessage {
  position: absolute !important;
  margin: 7.2% 6px 4px 0px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorUpdateMessage {
  font-weight: 600 !important;
}

.errorUpdateMessage_hidden {
  visibility: hidden;
  margin-top: 0px;
  margin: 0;
  height: 0px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorUpdateMessage_hidden {
  font-weight: 600 !important;
}

.Confirmationmodal_body_text {
  padding: 0 20px !important;
  text-align: center;
  font-weight: 600 !important;
  color: #5a5a5a;
  font-size: 16px;
}
