.EditUsersHeading {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

body[dir="rtl"] .EditUsersHeading {
  font-weight: 600 !important;
}

.ModalAlignmnet {
  padding: 0px 20px !important;
}

.NameCreateAddtional {
  font-weight: 600 !important;
  font-style: normal;
  color: #6172d6 !important;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}

body[dir="rtl"] .NameCreateAddtional {
  font-weight: 600 !important;
}

.Steric {
  color: #f16b6b !important;
}

.AdminAlsoClass {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  margin-top: 3px !important;
}

body[dir="rtl"] .AdminAlsoClass {
  font-weight: 500 !important;
}

.EmailStyles {
  font-size: 12px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .EmailStyles {
  font-weight: 600 !important;
}

.EdituserModalUpdateButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.EdituserModalUpdateButton:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

body[dir="rtl"] .EdituserModalUpdateButton {
  font-weight: 600 !important;
}

.label-styling {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 10px !important;
}

.aesterick-color {
  color: #f16b6b !important;
}

.errorMessage {
  font-weight: 600;
  font-size: 10px;
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 400 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 15px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 400 !important;
}
