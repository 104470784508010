.cards-container {
  display: inline-block;
  width: 50% !important;
}
.card-employee-image {
  width: 60px !important;
  height: 60px !important;
  border-radius: 4px;

  position: relative;
}
.card-employee-name {
  color: #5a5a5a;
  margin-top: 15px;
  font-size: 16px;
  margin-left: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-bottom: -20px;
  font-weight: 600;
}
.card-employee-desgination {
  margin: 0px 0px 10px 25px;
  font-size: 12px;
  line-height: 15px !important;
}
.card-employee-checkIcon {
  font-size: 40px;
  color: #00e900;
  margin-left: -20px;
  margin-top: 15px;
  position: relative;
  top: -24px;
}
