.IntroHeading {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.MainHeading {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.outerboxForOptions {
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}

.AnswerStyles {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.Cast_vote_CancelButton {
  padding: 12px 30px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cast_vote_SaveButton {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 12px !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}
