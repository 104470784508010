.Notify_organisors {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
}

.Check_box_title {
  margin: 0;
  padding: 0;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Scroller_notify {
  height: 13vh !important;
  overflow-x: hidden !important;
}

.Scroller_notify::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_notify::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Box_for_Assignee {
  width: 246px !important;
  height: 50px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.ProfilePic {
  border-radius: 50% !important;
}

.Cancel_button_Notify {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #fff;
  color: #5a5a5a;
}

.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover,
.Cancel_button_Notify:hover {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
}

.Send_Notify {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6;
  color: #fff;
}

.Send_Notify:hover,
.Send_Notify:active,
.Send_Notify:focus,
.Send_Notify:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
}

.Hide_names {
  font-size: 12px !important;
  color: #6172d6;
  font-weight: 500 !important;
}

.Participants_Name {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.Cross_Icon_Styling {
  cursor: pointer;
}
.errorMessage-inLogin {
  margin: 2px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 600 !important;
}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 600 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0;
  height: 0px;
  color: #f16b6b;
}
