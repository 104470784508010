p.delete-comment-message {
  /* margin: 0; */
  font-weight: 600;
  font-size: 16px;
  color: #5a5a5a;
  text-align: center;
  margin-top: -20px;
  max-width: 290px;
}

.Yes_Modal,
.Yes_Modal:is(:hover, :active, :focus, :focus-visible, :target) {
  background: #6172d6 !important;
  border-radius: 4px !important;
  width: 104px !important;
  height: 39px !important;
  border-color: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.No_Modal,
.No_Modal:is(:hover, :active, :focus, :focus-visible, :target) {
  background: #fff !important;
  border-radius: 4px !important;
  width: 118px !important;
  height: 39px !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
