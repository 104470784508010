.Heading_vewPolls_Published {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.Scroller_View_Published_Polls {
  height: 50vh !important;
  overflow-x: hidden !important;
}

.Scroller_View_Published_Polls::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_View_Published_Polls::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Participant_Count {
  color: #5a5a5a !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  word-wrap: break-word !important;
}

.Partipants_box {
  width: 100% !important;
  height: 50px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  padding: 8px 20px !important;
}

.Participants_name {
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
}

.Profile_Style {
  border-radius: 50% !important;
}

.Cacnel_styles_button_view_Votes_screen {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Cacnel_styles_button_view_Votes_screen:is(
    :hover,
    :active,
    :focus,
    :focus-visible
  ) {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Messege_span_Class {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  word-wrap: break-word;
}
