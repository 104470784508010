.Scroller_View_Published_Polls {
  height: 35vh !important;
  overflow-x: hidden !important;
}

.Scroller_View_Published_Polls::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_View_Published_Polls::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Messege_span_Class {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0px 30px !important;
  word-wrap: break-word;
}

.Heading_vewPolls_Published {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  word-wrap: break-word !important;
}

.Date_Fetched {
  color: #5a5a5a !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.Multiple_Answers_zstyles {
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
}

.Date_Heading {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.Cancel_Button_Cast_vote_polls_Meeting {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Cancel_Button_Cast_vote_polls_Meeting:is(
    :hover,
    :active,
    :focus,
    :focus-visible
  ) {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Submit_Button_Cast_vote_polls_Meeting {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Submit_Button_Cast_vote_polls_Meeting:is(
    :hover,
    :focus,
    :active,
    :focus-visible
  ) {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Percentage_Class {
  color: #6172d6;
  font-weight: 600 !important;
  font-size: 16px !important;
}
