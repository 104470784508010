.search-container-assignee {
  width: 100%;
  position: relative;
}

.dropdown-assignee {
  max-height: 160px;
  overflow-y: scroll;
  width: 100% !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 9;
  border-top: none;
  top: 49px;
  background: #fff;
}

.dropdown-assignee_sharefolder {
  max-height: 160px;
  overflow-y: scroll;
  width: 100% !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  z-index: 9;
  border-top: none;
  top: 36px;
  background: #fff;
  border: 1px solid #e1e1e1;
}

.dropdown-assignee_sharefolder::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.dropdown-assignee_sharefolder::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.dropdown-assignee::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.dropdown-assignee::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.dropdown-assignee:has(
    .dropdown-row-assignee.d-flex.align-items-center.flex-row
  ) {
  border: 1px solid #ced4da !important;
}

.dropdown:empty {
  border: none;
}

.dropdown-row-assignee {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1 !important;
}
.dropdown-row-assignee:last-child {
  border: none !important;
}

.dropdown-row-assignee img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 10px;
}

.dropdown-row-assignee p {
  font-size: 15px;
  font-weight: 600;
}

.SearchFilter_Meetings {
  height: 39px !important;
}

.ImportPreviousMinutesSearch {
  height: 39px !important;
}
.searchFilterAgendaContributorLabel {
  display: none !important;
}
