.create-Resolution-btn {
  width: 177px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: none !important;
}

.Btn_create_text {
  margin-left: 4px !important;
  align-items: center !important;
}

body[dir="rtl"] .Btn_create_text {
  margin-right: 4px !important;
}

body[dir="rtl"] .create-Resolution-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.create-Resolution-btn:focus,
.create-Resolution-btn:active,
.create-Resolution-btn:hover,
.create-Resolution-btn:focus-visible {
  width: 177px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: none !important;
}

.Resolution-All-btn {
  width: 49px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Resolution-moderator-btn {
  padding: 8px 12px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Resolution-moderator-btn body[dir="rtl"] .Resolution-All-btn {
  font-weight: 600;
}

.Resolution-All-btn:hover,
.Resolution-All-btn:focus,
.Resolution-All-btn:active,
.Resolution-All-btn:focus-visible {
  width: 49px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
}

.Resolution-All-btn_Active {
  width: 49px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
}

.Resolution-moderator-btn_Active {
  padding: 8px 12px !important;
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
}

body[dir="rtl"] .Resolution-moderator-btn_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

body[dir="rtl"] .Resolution-closed-btn_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

body[dir="rtl"] .Resolution-All-btn_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Resolution-All-btn_Active:hover,
.Resolution-All-btn_Active:focus,
.Resolution-All-btn_Active:active,
.Resolution-All-btn_Active:focus-visible {
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;

  border: none !important;
}

.Resolution-vote-btn {
  width: 60px;
  height: 29px;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  cursor: pointer !important;
}

body[dir="rtl"] .Resolution-vote-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Resolution-vote-btn:hover,
.Resolution-vote-btn:focus,
.Resolution-vote-btn:active,
.Resolution-vote-btn:focus-visible {
  width: 60px !important;
  height: 29px !important;
  font-size: 12px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Resolution-closed-btn {
  width: 66px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: #fff;
  color: #5a5a5a;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .Resolution-closed-btn {
  font-weight: 600;
}

.Resolution-closed-btn:hover,
.Resolution-closed-btn:active,
.Resolution-closed-btn:focus-visible,
.Resolution-closed-btn:focus {
  width: 66px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Resolution-closed-btn_Active {
  padding: 6px 12px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .Resolution-closed-btn_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Resolution-closed-btn_Active:hover,
.Resolution-closed-btn_Active:active,
.Resolution-closed-btn_Active:focus-visible,
.Resolution-closed-btn_Active:focus {
  padding: 6px 12px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
  text-align: center !important;
}

.Resolution-Current-btn {
  width: 71.63px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: #fff;
  color: #5a5a5a;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  text-align: center !important;
}

body[dir="rtl"] .Resolution-Current-btn {
  font-weight: 600;
}

.Resolution-Current-btn:hover,
.Resolution-Current-btn:focus-visible,
.Resolution-Current-btn:focus,
.Resolution-Current-btn:active {
  width: 71.63px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #d4d4d4 !important;
  text-align: center !important;
}

.Resolution-Current-btn_Active {
  width: 71.63px !important;
  height: 31px !important;
  font-size: calc(14px - 20%);
  font-weight: 600;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
  text-align: center !important;
}

body[dir="rtl"] .Resolution-Current-btn_Active {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Resolution-Current-btn_Active:hover,
.Resolution-Current-btn_Active:focus-visible,
.Resolution-Current-btn_Active:focus,
.Resolution-Current-btn_Active:active {
  padding: 6px 12px !important;
  font-size: 12px !important;
  font-weight: 600;
  background: linear-gradient(to left #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
  text-align: center !important;
}

.Search_Icon {
  cursor: pointer !important;
  display: block;
  top: 6px !important;
  position: absolute;
  left: 93%;
  width: 20px;
  height: 20px;
}

.Search_IconWithCrossIcon {
  cursor: pointer !important;
  display: block;
  top: 6px !important;
  position: absolute;
  left: 90%;
  width: 20px;
  height: 20px;
}

body[dir="rtl"] .Search_IconWithCrossIcon {
  cursor: pointer !important;
  display: block;
  top: 6px !important;
  position: absolute;
  left: 6%;
  width: 20px;
  height: 20px;
}

body[dir="rtl"] .Search_Icon {
  right: 93% !important;
  cursor: pointer !important;
}

.Resolution-heading-size {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

body[dir="rtl"] .Resolution-heading-size {
  font-weight: 600;
}

.Resolution-Title {
  width: 100% !important;
  font-size: 12px !important;
  height: auto;
  --max-lines: 1;
  display: -webkit-box !important;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical !important;
  font-weight: 600;

  color: #707070 !important;
  overflow: hidden !important;
}

.Voting_Dealine {
  font-size: 12px !important;
  font-weight: 600;
  margin-right: 35px !important;
}

.Decision {
  color: #6de595;
  font-weight: 600;
  font-size: 12px !important;
  margin-left: 8px !important;
}

.Decision_pending {
  color: #d8a709 !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: 600;
  font-size: 12px !important;
}

.vote_button {
  width: 59px;
  height: 24px;
  font-size: 8px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Decision_dates {
  font-size: 12px !important;
  font-weight: 600;
  margin-right: 10px !important;
}

.circulation_dates {
  font-size: 12px !important;
  font-weight: 600;
  margin-right: 38px !important;
}

.Result_btn {
  background: none !important;
  border: none !important;
}

.show_of_hands {
  color: #5a5a5a;
  font-size: 12px !important;
  font-weight: 600;
  margin-right: 45px !important;
}

.attachment_Icon {
  display: flex !important;
  justify-content: end !important;
  margin-left: 40px !important;
}

.thumbs_vote {
  display: flex !important;
  justify-content: center !important;
  margin-left: 12px !important;
}

.Approved_vote_resolution {
  font-size: 12px !important;
  font-weight: 600;
  color: #6de595;
  margin-left: 16px !important;
}

.Pending_vote_resolution {
  font-size: 12pt !important;
  font-weight: 600;
  color: #d8a709;
}

.Search_Box_Main_Resolution_page {
  width: 455px !important;
  padding: 20px 20px !important;
  height: auto !important;
  background: #fff !important;
  border: 1px solid #e1e1e1;
  position: absolute;
  right: 0;
  border-radius: 4px !important;
  z-index: 1 !important;
}

body[dir="rtl"] .voterCountStyle {
  display: flex !important;
  justify-content: center !important;
  margin-right: 30px !important;
  font-weight: 600;
}

body[dir="rtl"] .Result_icon {
  margin-right: 30px !important;
  cursor: pointer !important;
}

.Result_icon {
  margin-right: 17px !important;
  cursor: pointer !important;
}

.Result_icon_disabled {
  margin-right: 17px !important;
}

body[dir="rtl"] .Result_icon_disabled {
  margin-right: 30px !important;
}

body[dir="rtl"] .resolution_date {
  margin-right: 15px !important;
  font-weight: 600;
}

body[dir="rtl"] .resolution_date_Decision_date {
  margin-right: 20px !important;
  font-weight: 600;
}

body[dir="rtl"] .Edit_Icon_moderator {
  margin-right: 20px !important;
  cursor: pointer !important;
}

.Edit_Icon_moderator {
  cursor: pointer !important;
}

.Search_icon_Btn {
  background: none !important;
  border: none !important;
}

.Cross_btn_search_icon {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
}

.Circulation_date {
  font-size: 12pt !important;
  font-weight: 600;

  color: #6172d6;
}

.resolution_container {
  width: 98%;
  margin: 0 auto;
  height: 91vh;
}

.ResetButton_SearchBar_Resolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a;
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .ResetButton_SearchBar_Resolution {
  font-weight: 400;
}

.ResetButton_SearchBar_Resolution:hover,
.ResetButton_SearchBar_Resolution:active,
.ResetButton_SearchBar_Resolution:focus-visible,
.ResetButton_SearchBar_Resolution:focus {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
}

.SearchButton_SearchBar_Resolution {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  font-size: 12px !important;
  color: #fff;
  border: none !important;
}

body[dir="rtl"] .SearchButton_SearchBar_Resolution {
  font-weight: 400;
}

.SearchButton_SearchBar_Resolution:hover,
.SearchButton_SearchBar_Resolution:focus,
.SearchButton_SearchBar_Resolution:focus-visible,
.SearchButton_SearchBar_Resolution:active {
  padding: 12px 30px !important;
  background: #6172d6 !important;
  font-size: 12px !important;
  color: #fff !important;
  border: none !important;
}

.Decision_pending {
  font-size: 12px !important;
  color: #d8a709;
  font-weight: 600;

  margin-right: 17px !important;
}

.Search_results {
  font-size: 16px !important;
  font-weight: 600;

  color: #5a5a5a;
}

.resolution_spinner {
  min-height: 50vh !important;
}

.decision_Approved {
  color: #6de595 !important;
  font-weight: 600;

  font-size: 12px !important;
  white-space: nowrap !important;
  word-wrap: normal;
}

body[dir="rtl"] .decision_Approved {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600;

  margin-left: 8px !important;
}

.decision_non_Approved {
  color: #f16b6b !important;
  font-weight: 600;

  font-size: 12px !important;
  white-space: nowrap !important;
  word-wrap: normal;
}

body[dir="rtl"] .decision_non_Approved {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600;

  margin-left: 8px !important;
}

.decision_text_Pending {
  color: #d8a709 !important;
  font-weight: 600;

  font-size: 12px !important;
  white-space: nowrap !important;
  word-wrap: normal;
}

body[dir="rtl"] .decision_text_Pending {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600;
  margin-left: 8px !important;
}

.decision_text {
  color: #d8a709 !important;
  font-weight: 600;
  font-size: 12px !important;
}

.resolution_date {
  font-weight: 600;
  font-size: 12px !important;
  color: #5a5a5a !important;
  white-space: nowrap !important;
  text-align: center;
}

.PLusICon {
  display: flex !important;
  justify-content: start !important;
  margin-right: 5px !important;
}

body[dir="rtl"] .PLusICon {
  margin-left: 5px !important;
}

.resolution_date_Decision_date {
  font-weight: 600;
  text-align: center !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.resolution_title {
  font-weight: 600;

  font-size: 12px !important;
  color: #2197d7 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .resolution_title {
  font-weight: 600;
}

.voterCountStyle {
  font-weight: 600;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.decision_text_Moderator_closed {
  display: flex !important;
  justify-content: center !important;
}

body[dir="rtl"] .decision_text_Moderator_closed {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600;
  margin-right: 50px !important;
}

.Result_Icon_cursor_pointer {
  margin-right: 30px !important;
  cursor: pointer;
}

body[dir="rtl"] .Result_Icon_cursor_pointer {
  margin-right: 30px !important;
  cursor: pointer;
}

body[dir="rtl"] .cancel_icon_resolution {
  margin-right: 20px !important;
}

.empty_Resolutions {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.NoResolutionHeading {
  color: #5a5a5a !important;
  font-weight: 600;
  font-size: 1.8rem !important;
}

.NoResolution_Tagline {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 400;
  margin: 0 !important;
  padding: 0 !important;
}

.searchBox_CrossIcon {
  cursor: pointer !important;
}

.PaginationStyle-Resolution {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-Resolution:is(:focus-visible) {
  outline: none !important;
}

body[dir="rtl"] .PaginationStyle-Resolution {
  width: auto !important;
  padding: 12px 10px !important;
}

.search_input {
  position: relative !important;
}
