body[dir="rtl"] .label-Edit-user-mobile {
  margin-left: 19px !important;
}

.user-container {
  padding: 15px 40px !important;
}

.label-Edit-user {
  cursor: pointer !important;
  color: #6172d6;
  margin-left: 20px;
  align-items: center !important;
  margin-top: 10px;
  text-decoration: underline;
}
.label-Edit-user-mobile {
  cursor: pointer !important;
  color: #6172d6;
  text-decoration: underline;
}

.User-bottom-line {
  height: 55px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-spacing: 10px !important;
}

.User-bottom-line2 {
  height: 55px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-spacing: 10px !important;
  margin-top: 8px;
}
.reset-User-btn {
  margin-left: 25px;
  font-size: 12px !important;
  background-color: #ffffff !important;
  color: #5a5a5a !important;
  font-weight: 600;
  width: 119px !important;
  height: 46px !important;
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
}

body[dir="rtl"] .reset-User-btn {
  margin-right: 25px !important;
}

.modal-header-class {
  position: relative;
  top: 10px !important;
  left: 15px;
  font-size: 12px;
}

.save-User-btn {
  font-size: 12px;
  background-color: #6172d6;
  border-color: #e1e1e1 !important;
  width: 119px;
  height: 46px;
  border-radius: 4px !important;
  float: right;
  margin-right: 25px;
}

body[dir="rtl"] .save-User-btn {
  margin-right: 125px !important;
}

.save-User-btn:hover {
  background-color: #6172d6 !important;
}

.Name-label-User {
  text-align: start !important;
  margin-top: 5px;
  font-size: 16px !important;
  color: #5a5a5a !important;
  font-weight: 500;
}

.Designation-label-User {
  text-align: start !important;
  margin-top: 10px;
  font-size: 16px;
  color: #5a5a5a !important;
  font-weight: 500;
}

.Mobile-label-User {
  text-align: start !important;
  font-size: 16px;
  color: #5a5a5a !important;
  font-weight: 500;
}

.text-fields-name {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: white !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
}

.text-fields-name-disabled {
  height: 39px !important;
  background-color: transparent !important;
  border: none !important;
  background-image: none !important;
  font-weight: 400;
  color: #5a5a5a !important;
}

.text-fields-name:focus {
  border: 1px solid #e1e1e1 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.text-fields-name:disabled {
  background-color: transparent !important;
}

.text-fields-designation {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: white !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
}

.text-fields-designation-disabled {
  height: 39px !important;
  background-color: transparent !important;
  border: none !important;
  font-weight: 400;
  color: #5a5a5a !important;
  background-image: none !important;
}

.text-fields-designation:focus {
  border: 1px solid #e1e1e1 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.text-fields-designation:disabled {
  background-color: transparent !important;
}

.text-fields-Mobile {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: none !important;
  background-color: white !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
  margin-left: 1px !important;
  height: 30px !important;
  padding: auto !important;
}

.text-fields-Mobile-disabled {
  background-color: transparent !important;
  border: none !important;
  font-weight: 400;
  color: #5a5a5a !important;
  background-image: none !important;
}

.text-fields-Mobile:focus {
  border: 1px solid #e1e1e1 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.text-fields-Mobile:disabled {
  background-color: transparent !important;
}

.modal-userprofile-footer {
  width: 100% !important;
  display: contents;
  margin: 20px 0px !important;
  padding: 0 40px !important;
}

.react-User-Profile {
  height: 30px !important;
  padding: auto !important;
}
.react-User-Profile .ReactFlagsSelect-module_flagsSelectInline__cUnnz button {
  margin-top: 0px !important;
}
.react-User-Profile button {
  cursor: pointer !important;
  width: 100% !important;
  min-height: 39px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px 3px !important;
  font-size: 9px !important;
  color: #4d4d4d !important;
  border: thin solid rgba(77, 77, 77, 0.3);
  border-radius: 2px !important;
  background: transparent;
}

.react-User-Profile input {
  cursor: pointer !important;
  padding: 5px 8px !important;
  margin: 4px 5px !important;
  font-size: 10px !important;
  white-space: nowrap !important;
}

.react-User-Profile button:after {
  border-top: 3px solid #4d4d4d;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 0;
  margin-left: -3px;
}

.react-User-Profile button[aria-expanded="true"]:after {
  border-top: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid #4d4d4d;
  margin-left: -3px;
}

.react-User-Profile button:focus {
  cursor: pointer !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 6px 3px !important;
  font-size: 9px !important;
  color: #000000 !important;
  border: thin solid #e1e1e1 !important;
  border-radius: 2px !important;
  background: transparent;
}

.ErrorMessege {
  font-size: 10px !important;
  margin-left: 158px;
  color: #5a5a5a !important;
  margin-top: -13px !important;
  font-weight: 600;
}

body[dir="rtl"] .ErrorMessege {
  font-size: 10px !important;
  margin-right: 150px !important;
  color: #5a5a5a !important;
  margin-top: -13px !important;
  font-weight: 600;
}

.Modalsize {
  width: 599px !important;
  height: 242 !important;
}
.userProfileModal {
  --bs-modal-margin: 0px !important;
}

.span-on-number-on-userprofile {
  margin-left: 10px !important;
}
.userProfileFlagSelect button {
  margin-top: 4px !important;
  border-radius: 4px !important;
}

.errorMessage {
  height: 15px;
  margin-top: -9px;
  margin-left: 34px;
  color: #f16b6b;
  visibility: visible !important;
  font-weight: 600 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 15px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage {
  height: 15px;
  margin-top: -9px;
  margin-left: -35px !important;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 15px;
  color: #f16b6b !important;
}

.errorMessage-Mobile-user {
  margin: 4px;
  height: 8px;
  margin-left: 55px;
  margin-top: -5px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage-Mobile-user {
  margin: 4px;
  height: 8px;
  margin-left: -45px;
  margin-top: -5px;
  color: #f16b6b;
  visibility: visible !important;
}

.errorMessage_hidden-Mobile-user {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage-Designation-user {
  position: absolute;
  margin: 0px;
  height: 20px;
  top: 280px;
  margin-left: -45px;
  color: #f16b6b;
  visibility: visible !important;
}

.errorMessage-Designation-user {
  position: absolute;
  margin: 0px;
  height: 20px;
  top: 280px;
  margin-left: 50px;
  color: #f16b6b;
  visibility: visible !important;
}

.errorMessage_hidden-Designation-user {
  visibility: hidden;
  margin: 0;
  height: 0px !important;
  color: #f16b6b !important;
}
