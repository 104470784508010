.EmailVerifyBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 360px !important;
  padding: 30px 41px;
}

.signIn_heading {
  font-size: calc(35px - 20%) !important;
  user-select: none;
  color: #6172d6;
  font-weight: 600 !important;
  margin-bottom: 30px;
}

body[dir="rtl"] .signIn_heading {
  font-weight: 600 !important;
  font-size: calc(33px - 20%) !important;
}

.Next_button_EmailVerify {
  width: 90%;
  border-radius: 4px;
  background-color: #6172d6;
  font-weight: 600 !important;
  text-transform: uppercase;
  border: none !important;
}

body[dir="rtl"] .Next_button_EmailVerify {
  font-weight: 600 !important;
}

.Next_button_EmailVerify:hover,
.Next_button_EmailVerify:visited,
.Next_button_EmailVerify:focus-visible,
.Next_button_EmailVerify:focus,
.Next_button_EmailVerify:target,
.Next_button_EmailVerify:active {
  width: 90% !important;
  border-radius: 4px !important;
  background-color: #6172d6 !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border: none !important;
}

.subscribNow_button_EmailVerify {
  width: 280px;
  border-radius: 1px;
}

.Auth_Icon {
  position: absolute;
  right: -120px;
  bottom: -187px;
  z-index: 99999;
  transform: rotate(-9deg);
}

body[dir="rtl"] .Auth_Icon {
  transform: scaleX(-1);
}

.Login_page_text {
  display: flex;
  margin-top: 13%;
  flex-direction: column;
  margin-left: 40px;
}

.Login_page_text h1 {
  font-size: calc(60px - 20%) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  width: 114%;
}

.loginbox_auth {
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

body[dir="rtl"] .loginbox_auth {
  margin-right: 60px;
  border-radius: 8px !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}
@media screen and (max-width: 700px) {
  .loginbox_auth {
    margin-left: 0;
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px !important;
    border: 1px solid #e1e1e1 !important;
    --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: var(--Paper-shadow);
    display: inline-block !important;
    background: #fff !important;
  }
}
.heading-1 {
  color: #6172d6;
}

body[dir="rtl"] .heading-1 {
  font-weight: 600 !important;
  font-size: 70px !important;
}

.heading-2 {
  color: #4adede;
  font-size: 60px !important;
}

body[dir="rtl"] .heading-2 {
  font-weight: 600 !important;
}

.forogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.forogt_email_link a {
  color: #5a5a5a !important;
}

.signup-text-inloginpage {
  display: flex;
  justify-content: center;
  color: #000;
}

.errorMessage-inLogin {
  margin: 0px;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
  font-weight: 500 !important;
}

body[dir="rtl"] .errorMessage-inLogin {
  font-weight: 500 !important;
}

.errorMessage-inLogin_hidden {
  visibility: hidden;
  margin: 0px;
  height: 13px;
  color: #f16b6b;
  font-weight: 500 !important;
}

body[dir="rtl"] .errorMessage-inLogin_hidden {
  font-weight: 500 !important;
}

.Top_spaces {
  margin-top: 13px !important;
}

.ForgotPassword {
  font-weight: 400 !important;
  margin: 4px 0 0 0;
}

body[dir="rtl"] .ForgotPassword {
  font-weight: 400 !important;
}

.Remember-password {
  display: flex !important;
  align-items: center !important;
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

body[dir="rtl"] .Remember-password {
  font-weight: 400 !important;
}

/* End */
.IconStyle {
  display: block !important;
  width: 1% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: absolute !important;
  right: 40px !important;
  top: 18px !important;
  cursor: pointer;
}

.Enter-Password-language {
  position: absolute;
  overflow: hidden !important;
  top: 50px;
  right: 120px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
}

.Enter-Password-language:focus,
.Enter-Password-language:focus-within,
.Enter-Password-language:focus-visible {
  border-color: rgb(133, 133, 133) !important;
  box-shadow: none !important;
  outline: none !important;
}

.select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  width: 181px !important;
  height: 38px !important;
}

body[dir="rtl"] .select-language-signin {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 80%;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  width: 181px !important;
  height: 38px !important;
}

.select-language-signin:focus,
.select-language-signin:focus-within,
.select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon {
  z-index: 99999 !important;
  position: absolute;
  right: 80%;
  margin-right: 11px;
  top: 37px;
  width: 25px;
  height: 25px;
}

.PasswordTextField {
  position: relative !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  height: 39px !important;
}

body[dir="rtl"] .PasswordTextField {
  font-weight: 500 !important;
}

.PasswordTextField::placeholder {
  color: #171d2d !important;
  opacity: 50% !important;
}

.passwordIcon {
  position: absolute !important;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

body[dir="rtl"] .passwordIcon {
  position: absolute !important;
  right: 86%;
  left: 0px;
  cursor: pointer;
  top: 10px;
}

.PasswordTextField:focus,
.PasswordTextField:focus-visible {
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px !important;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px !important;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}
