.iconGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100 !important;
}

.iconItemExpandedMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  text-decoration: none;
  min-width: 85px;
}
.iconItemExpandedMenu:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
  color: #fff;
  min-width: 85px;
}

body[dir="rtl"] .iconItemExpandedMenu:hover {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.iconItem_activeExpandedMenu {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px;
  color: #fff;
  min-width: 85px;
}

body[dir="rtl"] .iconItem_activeExpandedMenu {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.iconItem_activeExpandedMenu img {
  height: 25px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
}

.iconItemExpandedMenu img {
  height: 25px;
  margin-bottom: 5px;
}
.iconItemExpandedMenu:hover img {
  height: 25px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
}
.iconItem_activeExpandedMenu p {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  color: #fff !important;
  white-space: nowrap;
}

.iconItemExpandedMenu p {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.iconItemExpandedMenu:hover {
  text-decoration: none;
  color: #fff;
}
