.Vote_Heading {
  font-size: 30px !important;
  font-weight: 600;

  color: #6172d6;
}

body[dir="rtl"] .Vote_Heading {
  font-weight: 600;
}

.Sub_heading_VoteResolution {
  font-size: 20px !important;
  font-weight: 600;

  color: #6172d6;
  overflow: hidden;
  text-overflow: ellipsis;
}

body[dir="rtl"] .Sub_heading_VoteResolution {
  font-weight: 600;
}

.VotingPage_paper {
  height: auto !important;
  width: 100% !important;
  padding: 50px 50px !important;
  margin-bottom: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.Gray_Border_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-direction: column;
  height: 230px;
  background: #fff !important;
  border: 1px solid #707070;
  margin-bottom: 20px;
  border-radius: 4px !important;
}

.Abstain_btn_vote_resolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #828282 !important;
  color: #828282 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Abstain_btn_vote_resolution:is(
    :hover,
    :active,
    :focus-visible,
    :focus,
    :visited
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #828282 !important;
  color: #828282 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Abstain_btn_vote_resolution_Active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #828282 !important;
  border: 1px solid #828282 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Abstain_btn_vote_resolution_Active:is(
    :hover,
    :active,
    :focus-visible,
    :focus,
    :visited
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #828282 !important;
  border: 1px solid #828282 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Notapproved_btn_voteresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #f16b6b !important;
  color: #f16b6b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Notapproved_btn_voteresolution:is(
    :hover,
    :focus,
    :focus-visible,
    :visited,
    :active
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #f16b6b !important;
  color: #f16b6b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Notapproved_btn_voteresolution_Active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #f16b6b !important;
  border: 1px solid #f16b6b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Notapproved_btn_voteresolution_Active:is(
    :hover,
    :focus,
    :focus-visible,
    :visited,
    :active
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #f16b6b !important;
  border: 1px solid #f16b6b !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.approved_btn_voteresolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #6de595 !important;
  color: #6de595 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.approved_btn_voteresolution:is(
    :hover,
    :focus,
    :focus-visible,
    :visited,
    :active
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #6de595 !important;
  color: #6de595 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.approved_btn_voteresolution_Active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6de595 !important;
  border: 1px solid #6de595 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.approved_btn_voteresolution_Active:is(
    :hover,
    :focus,
    :focus-visible,
    :visited,
    :active
  ) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6de595 !important;
  border: 1px solid #6de595 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
}

.Voters_voteResolution {
  font-size: 16px !important;
  font-weight: 600;

  color: #6172d6;
}

body[dir="rtl"] .Voters_voteResolution {
  font-weight: 600;
}

.Scroller_voteresolution {
  height: 16vh !important;
  overflow-x: hidden !important;
}

.Scroller_voteresolution::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_voteresolution::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Total_voters_voteResolution {
  font-size: 16px !important;
  font-weight: 600;

  color: #5a5a5a;
}

body[dir="rtl"] .Total_voters_voteResolution {
  font-weight: 600;
}

.No_of_Votes_voteResolution {
  font-size: 16px !important;
  font-weight: 600;

  color: #6172d6;
}

.Pending_status_voteResolutionBox {
  width: 100% !important;
  height: auto !important;
  background-color: #fff !important;
  border: 1px solid #ddb227;
  border-radius: 4px !important;
  padding: 15px 55px;
}

.Status_voteResolutioin {
  font-size: 30px !important;
  font-weight: 600;
}

body[dir="rtl"] .Status_voteResolutioin {
  font-weight: 600;
}

.close_btn_VoteResolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  border: 1px solid #e1e1e1;
  color: #5a5a5a;
  font-weight: 600;
}

body[dir="rtl"] .close_btn_VoteResolution {
  font-weight: 600;
}

.close_btn_VoteResolution:hover,
.close_btn_VoteResolution:focus,
.close_btn_VoteResolution:focus-visible,
.close_btn_VoteResolution:active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

.save_btn_VoteResolution {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: none !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600;
}

body[dir="rtl"] .save_btn_VoteResolution {
  font-weight: 600;
}

.save_btn_VoteResolution:hover,
.save_btn_VoteResolution:focus,
.save_btn_VoteResolution:focus-visible,
.save_btn_VoteResolution:active {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: none !important;
  background: #6172d6 !important;
  color: #fff !important;
}

.save_btn_VoteResolution:disabled {
  filter: grayscale(0.5) !important;
  background-color: #6172d6 !important;
}

.Your_vote_voteresolution {
  font-size: 30px !important;
  color: #6172d6;
  font-weight: 600;
}

body[dir="rtl"] .Your_vote_voteresolution {
  font-weight: 600;
}

.VotingMethods_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px !important;
  min-height: 200px;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.VotingMethods_box span {
  font-weight: 600;
  font-size: 16px !important;
}

.line_voteResolution {
  width: 1px;
  height: 100%;
  background-color: #5a5a5a !important;
}

.voting_method_heading {
  color: #5a5a5a !important;
  font-weight: 600;

  font-size: 16px !important;
}

.voting_methong_value {
  color: #6172d6 !important;
  font-weight: 600;
  font-size: 16px !important;
  margin-left: 3px !important;
}
.votingMethodKey {
  font-size: 600;
  color: #5a5a5a;
}
.votingMethodValue {
  font-size: 600;

  color: #6172d6;
}
.pending_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #ddb227;
  color: #ddb227 !important;
  border-radius: 4px !important;
}
.approved_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #6de595;
  border-radius: 4px !important;
  color: #6de595 !important;
}
.notApproved_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #f16b6b !important;
  color: #f16b6b !important;
  border-radius: 4px !important;
}
.Tie_state {
  width: 282px !important;
  height: 125px !important;
  background-color: #fff !important;
  border: 1px solid #5a5a5a;
  border-radius: 4px !important;
  color: #5a5a5a !important;
}
