.Table_Styles {
  border-collapse: collapse !important;
  width: 100%;
  background: #fff !important;
}
.Table_Styles th,
.Table_Styles td {
  border: 1px solid #6172d6;
  padding: 8px;
  text-align: center !important;
}
.Table_Styles th {
  color: #6172d6 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.Table_Styles tr {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.amount_styles {
  color: #6172d6 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.amount_heading_styles {
  color: #5a5a5a !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.background {
  background: #ecefff !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  padding: 8px 0px !important;
}

.Subsection_styles {
  margin: 0px auto !important;
  width: 92% !important;
}

.Downgraded_Subscription_styles {
  color: #6172d6 !important;
  font-size: 30px !important;
  font-weight: 800 !important;
}

.TableheadingTotal {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .TableheadingTotal {
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center !important;
}

.SubscritionNumber_Styles {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .SubscritionNumber_Styles {
  font-weight: 600 !important;
}

.Cancel_Button_Styles {
  padding: 12px 30px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
}

.Cancel_Button_Styles:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 12px !important;
}

.Downgrade_Button_Styles {
  padding: 12px 30px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
  font-size: 12px !important;
}

.Downgrade_Button_Styles:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  border: none !important;
  font-size: 12px !important;
}
