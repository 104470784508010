.meetingTitle {
  font-weight: 600 !important;
  color: #6172d6;
  font-size: 14px !important;
  cursor: pointer;
  overflow: hidden !important;
  /* width: 180px !important; */
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block !important;
}

.Start-Meeting {
  width: 118px;
  height: 28px;
  font-size: 12px;
  color: #fff;
  background: #81db86;
  border: none;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Start-Meeting:is(:hover, :focus, :focus-visible:active) {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #81db86 !important;
  border: none !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joining-Meeting {
  width: 118px;
  height: 28px;
  font-size: 12px;
  color: #fff;
  background: #6172d6;
  border: none;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joining-Meeting:active,
.joining-Meeting:hover,
.joining-Meeting:focus,
.joining-Meeting:focus-visible {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.End-Meeting {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #f16b6b !important;
  border: none !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.End-Meeting:is(:hover, :focus, :focus-visible:active) {
  width: 118px !important;
  height: 28px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #f16b6b !important;
  border: none !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.organizer-value {
  font-weight: 400 !important;
  font-weight: 500 !important;
}

.datetime-value {
  font-weight: 400 !important;
  font-weight: 500 !important;
}

.Create_Meeting_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

body[dir="rtl"] .Create_Meeting_Button {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Create_Meeting_Button:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.meeting-start {
  font-size: 14px;
  font-weight: 400 !important;
  /* white-space: ; */
}
