.Emplty_state_heading {
  font-weight: 600 !important;
  font-size: 40px !important;
  color: #5a5a5a;
}

.Empty_State_SubHeading {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #5a5a5a;
}

.UpgradePackageCard {
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 10px;
  position: relative;
}

.Go_Back_Styles {
  color: #6172d6;
  font-weight: 400 !important;
  font-size: 20px !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.UpgradeYourPakage {
  color: #6172d6;
  font-weight: 700 !important;
  font-size: 40px !important;
}
