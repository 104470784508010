.SectionViewAgenda {
  width: 100% !important;
  height: auto !important;
  min-height: 84px !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 1px 15px !important;
  position: relative !important;
}

.SectionViewAgendaExpanded {
  width: 100% !important;
  height: auto !important;
  min-height: 254.4px !important;
  border: 1px solid #bbbbbb !important;
  border-radius: 4px !important;
  padding: 1px 15px !important;
  position: relative !important;
}

.Expand_Class_ViewAgenda {
  background: #5f78d6;
  height: 100%;
  width: 48px !important;
  left: 0;
  top: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  position: absolute !important;
}

body[dir="rtl"] .Expand_Class_ViewAgenda {
  right: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.Arrow {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 12px !important;
}

.ArrowExpanded {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 85px !important;
}

.Heading_View_Agenda {
  color: #5f78d6;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.OverAll_Padding_View_agenda_Screen {
  padding: 0px 65px !important;
}

.Show_more_Class {
  color: #5f78d6;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.ParticipantName {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Profile_Class {
  border-radius: 50% !important;
}

.Date_Time {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.Date_Time_Section {
  margin-top: 8px !important;
}

.midLine {
  border: 1px solid #707070;
  width: 12px !important;
  height: 1px !important;
  margin-top: 7px !important;
}

.Top_Spacing {
  margin-top: 4px !important;
}

.Attachments_Heading {
  color: #6172d6 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.Border_Attachments {
  width: 100% !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 4px !important;
  height: 54.4px !important;
  padding: 0px 20px !important;
}

.File_Name {
  color: #5a5a5a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 200px !important;
  display: flex !important;
  align-items: center !important;
}

.MainAgendaScrollerView {
  height: 60vh !important;
  overflow-x: hidden !important;
}

.MainAgendaScrollerView::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.MainAgendaScrollerView::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.MainAgendaFileScroller {
  height: 12vh !important;
  overflow-x: hidden !important;
}

.MainAgendaFileScroller::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.MainAgendaFileScroller::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Cancel_Classname {
  padding: 12px 30px !important ;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Cancel_Classname:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important ;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Save_Classname {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Save_Classname:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}
