.Participant-Removed-Videopanel {
  position: fixed;
  height: 89vh;
  top: 70px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 9999 !important;
  padding: 10px 40px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.max-Participant-Removed-Video {
  margin-top: 10%;
  height: auto !important;
  border-radius: 16px;
  background: transparent;
}

.max-Participant-Removed-Main-Heading {
  font-size: 40px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 2px;
  color: #6172d6 !important;
}

.Sub-max-Participant-Removed-Main-Heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #5a5a5a !important;
}

.Participant-rejoin-Button-Class {
  padding: 8px 24px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  border: none;
}

.Participant-closeModal-Button-Class {
  padding: 8px 24px !important;
  background-color: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #e1e1e1;
}
