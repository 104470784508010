.input-container {
  position: relative;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 0;
}
.input-container .search-icon {
  position: relative;
  z-index: 2;
  font-size: 20px;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  color: black;
  right: 10px;
  float: right;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-container .inputtext {
  position: absolute;
  width: 0%;
  height: 40px;
  border-radius: 50% !important;
  top: 0;
  float: right;
  right: 10px;
  transition: all 0.5s;
  border: none;
}
.input-container .inputtext.active {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  float: right;
  border-radius: 50% !important;
  right: 10px;
}
.input-container .inputtext .input-field {
  width: 100%;
  height: 100%;
  float: right !important;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.input-container .inputtext .input-field::placeholder {
  color: violet;
}
.input-container .inputtext .input-field {
  border: none;
}
.input-container .inputtext.active .input-field {
  border: 1px solid violet;
}
.input-container .inputtext .input-field:focus {
  outline: none;
}
input-container .inputtext .input-field:-ms-input-placeholder {
  display: none;
}
