.title-header-name {
  font-size: 20px;
  font-weight: 600;
  color: #6172d6;
}

.class-Mic-Active {
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white !important;
  background-color: #6172d6 !important;
}

.Guest-Icons-state {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.Guest-Icons-state-Participant {
  position: relative;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.New-List-Participants {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  position: absolute;
  width: 230px;
  top: 50px;
  right: 0;
  height: 170px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden auto;
}
