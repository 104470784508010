p.delete-comment-message {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #5a5a5a;
  text-align: center;
  margin-top: -20px;
  max-width: 290px;
}

.Yes_Modal,
.Yes_Modal:is(:hover, :active, :focus, :focus-visible, :target) {
  background: #fff !important;
  border-radius: 4px !important;
  width: 82px !important;
  height: 38px !important;
  border-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  margin-bottom: 10px !important;
}

.No_Modal,
.No_Modal:is(:hover, :active, :focus, :focus-visible, :target) {
  background: #6172d6 !important;
  border-radius: 4px !important;
  width: 78px !important;
  height: 38px !important;
  border-color: #6172d6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
  font-size: 12px !important;
}

img.image-close {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}

body[dir="rtl"] img.image-close {
  position: absolute;
  right: auto;
  top: 0px;
  cursor: pointer;
  left: 10px;
}

textarea.textField-RejectComment {
  height: 156px !important;
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
}

textarea.textField-RejectComment::placeholder {
  color: #a2a2a2 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
