p.FileModalTitle {
  margin: 0;
  font-weight: 500 !important;
  font-size: 17px;
  color: #6172d6;
}

.allFileUI {
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  margin: 10px 0px;
  padding: 10px;
  cursor: pointer;
}

span.fileNameAttachment {
  margin-left: 20px;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 13px;
  text-decoration-line: underline;
  color: #5a5a5a;
  pointer-events: none;
}

body[dir="rtl"] span.fileNameAttachment {
  margin-left: 0px;
  margin-right: 20px;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 13px;
  text-decoration-line: underline;
  color: #5a5a5a;
  pointer-events: none;
}

.Cancel_Vote_Modal,
.Cancel_Vote_Modal:is(:hover, :active, :focus, :focus-visible, :target) {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px !important;
  padding: 10px 24px !important;
}

.FileSectionHeight {
  min-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 280px;
  padding-right: 10px;
}
