.Committee-heading-size {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 600;
}

body[dir="rtl"] .Committee-heading-size {
  font-weight: 600;
}

.create-Committee-btn {
  width: 219px !important;
  height: 31px !important;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  margin-top: 8px !important;
  align-items: center;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
}

body[dir="rtl"] .create-Committee-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.create-Committee-btn:active,
.create-Committee-btn:focus-visible,
.create-Committee-btn:focus,
.create-Committee-btn:hover {
  width: 219px !important;
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.PLusICon {
  display: flex !important;
  justify-content: start !important;
  margin-right: 5px !important;
}

body[dir="rtl"] .PLusICon {
  margin-left: 5px !important;
}

.Archived-Group-btn-Committee-section {
  width: 219px !important;
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex;
  justify-content: center !important;
  gap: 10px;
  align-items: center;
}

body[dir="rtl"] .Archived-Group-btn-Committee-section {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  font-weight: 600;
}

.Archived-Group-btn-Committee-section:active,
.Archived-Group-btn-Committee-section:focus,
.Archived-Group-btn-Committee-section:hover,
.Archived-Group-btn-Committee-section:focus-visible {
  width: 219px !important;
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px !important;
  align-items: center !important;
}

.In-Active-status-Committee-Group {
  background-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  padding: 4px !important;
  width: 100% !important;
  font-size: 15px !important;
  font-weight: 600;
  border-radius: 2px !important;
}

.CommitteeContainer {
  height: 91vh;
  width: 98% !important;
  position: relative !important;
  margin: 0 auto;
}

.Sub-Heading {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 8px !important;
}

.cover {
  padding: 10px !important;
}

.hyperlink {
  color: blue !important;
}

.MainIcon {
  background-color: lightgray;
  border-radius: 30px !important;
  height: 50px !important;
  width: 50px !important;
  padding: 8px !important;
}

.cvgicon {
  fill: white;
}

body[dir="rtl"] .Two-Icons-style-Committee-Group {
  padding-left: 22px !important;
}

.Committee-Main_Scrollbar {
  height: 70vh !important;
  overflow-x: hidden !important;
}

.Committee-Main_Scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Committee-Main_Scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px !important;
}

.PaginationStyle-Committee {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  height: 54px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
  margin-top: 3px !important;
}

body[dir="rtl"] .PaginationStyle-Committee {
  width: auto !important;
  padding: 12px 10px !important;
}

.Dropdown-container-Committee {
  background: #fff !important;
  height: 34.8vh !important;
  width: 80% !important;
  z-index: 99999 !important;
  position: absolute;
  top: 57px !important;
  left: 40px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
}

.HR-line-Committee-group {
  color: #5a5a5a !important;
  margin: 1px !important;
}

.Dropdown-container-editIcon-Committee-Group {
  background: #fff !important;
  height: 15.8vh !important;
  width: 40% !important;
  z-index: 99999 !important;
  position: absolute;
  top: 57px !important;
  left: 118px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
}

.Active-status-Committee-Group {
  background-color: #ebf8ef !important;
  color: #81db86 !important;
  width: 100% !important;
  padding: 4px !important;
  font-size: 15px !important;
  border-radius: 4px !important;
}

.Status-Committee-Group {
  margin-left: 17px !important;
}

.group-icon-Committee-Group {
  height: 55px;
  width: 55px;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 30px;
  font-size: 20px !important;
  padding: 10px !important;
}

body[dir="rtl"] .group-icon-Committee-Group {
  margin-left: 18px !important;
}

.Tagline-Committee-Group {
  line-height: 1rem !important;
}

.card-heading-Committee-Group {
  font-size: 20px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.namesCards-Committee-Group {
  font-size: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize !important;
}

.roundnumber {
  background-color: #6172d6;
  height: 30px !important;
  width: 30px !important;
  border-radius: 20px !important;
  color: #ffffff;
  font-size: 12px !important;
  padding: 6px !important;
}

body[dir="rtl"] .Two-Icons-style-Committee-Group {
  padding-left: 22px !important;
}

.update-Committee-btn {
  width: 247px !important;
  height: 39px;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #707070 !important;
}

.update-Committee-btn:hover,
.update-Committee-btn:active,
.update-Committee-btn:focus,
.update-Committee-btn:focus-visible {
  width: 247px !important;
  height: 39px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #707070 !important;
}

body[dir="rtl"] .Dropdown-container-Committee {
  left: auto !important;
  right: 43px !important;
}

body[dir="rtl"] .Dropdown-container-editIcon-Committee-Group {
  left: auto !important;
  right: 120px !important;
}

.Dropdown-container-Committee:hover {
  border-color: #6172d6 !important;
  border-radius: 4px !important;
}

.CommitteeNotFoundText {
  font-weight: 600;
  color: #bbbbbb !important;
  font-size: 20px !important;
}

body[dir="rtl"] .CommitteeNotFoundText {
  font-weight: 600;
}

.CommiiteeNotFoundContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 63vh !important;
  max-height: 63vh !important;
}

.committess_box {
  max-height: 65vh !important;
  min-height: 65vh !important;
  overflow: hidden auto;
}

.committess_box::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.committess_box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px !important;
}
