.CloseModal {
  padding: 12px 30px !important;
  font-size: calc(14px - 20%) !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .CloseModal {
  font-weight: 600 !important;
}

.CloseModal:has(:hover, :active, :focus, :focus-visible, :visited) {
  padding: 12px 30px !important;
  font-size: calc(14px - 20%) !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.Confirm-activegroup-modal {
  padding: 12px 30px !important;
  font-size: calc(14px - 20%) !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  border-radius: 4px !important;
}

.Confirm-activegroup-modal:has(
    :hover,
    :active,
    :focus,
    :focus-visible,
    :visited
  ) {
  padding: 12px 30px !important;
  font-size: calc(14px - 20%) !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  border-radius: 4px !important;
}

/* New CSS */

.Marketing-Modal-Heading {
  font-size: 20px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

.ADD-MarketingModal-btn {
  font-size: calc(14px - 20%);
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  font-weight: 600;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  width: 100% !important;
}

body[dir="rtl"] .ADD-MarketingModal-btn {
  font-weight: 600 !important;
  background: linear-gradient(to right #49dbdb 0%, #6172d6 100%) !important;
}

.modalMarketingTeamCommitteeModalBody {
  max-width: 500px !important;
}

.group_Icon_Box {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .group_Icon_Box {
  background: linear-gradient(to right #49dbdb 0%, #6172d6 100%) !important;
}

.group_Icon_Box img {
  object-fit: cover;
}

.MarketingTeamModalBody {
  padding: 0 30px !important;
}

.GroupsDataRow {
  height: 195px !important;
  overflow-y: auto;
}

.GroupsDataRow::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.GroupsDataRow::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.mapping_groups_scroll {
  max-height: 250px;
  min-height: 250px !important;
  overflow: hidden auto;
  margin: 10px 0 0 0;
}

.mapping_groups_scroll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.mapping_groups_scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.marketingDataCol {
  height: auto !important;
  margin: 3px 0;
}
