.ant-picker {
  border-radius: 4px;
}
.timepicker_width {
  width: 100px !important;
  border: 1px solid #e1e1e1 !important;
  cursor: pointer !important;
}

body[dir="rtl"] .timepicker_width {
  font-weight: 400;
  cursor: pointer !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}
