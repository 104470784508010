.warningIcon {
  margin-right: 10px;
  margin-top: 3px;
  width: 21px;
  height: 21px;
}

body[dir="rtl"] .warningIcon {
  fill: #fff;
  font-size: 16px;
  margin-right: 0 !important;
  margin-left: 10px !important;
  margin-top: 2px;
}
