.normal-avatar-large {
  height: 75vh;
}

.normal-avatar-large img {
  width: 100%;
}

.normal-Presenter-avatar-large {
  height: 85vh !important;
}

.normal-Presenter-avatar-large img {
  width: 100%;
}
