.EmailVerifyOTPbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px !important;
  padding: 30px 45px;
}

.TwoFa_heading {
  color: #5a5a5a;
  font-weight: 600 !important;
  font-size: calc(35px - 20%) !important;
}

body[dir="rtl"] .TwoFa_heading {
  font-weight: 600 !important;
  font-size: calc(35px - 10%) !important;
}

.verify_heading_line1 {
  font-weight: 700 !important;
  margin: 0;
  color: #5a5a5a;
  font-size: calc(16px - 20%);
}
body[dir="rtl"] .verify_heading_line1 {
  font-weight: 700 !important;
  font-size: calc(16px - 20%);
}
.verify_heading_line2 {
  font-weight: 700 !important;
  margin: 0;
  color: #5a5a5a;
  font-size: calc(16px - 20%);
}

body[dir="rtl"] .verify_heading_line2 {
  font-weight: 700 !important;
  font-size: calc(16px - 20%);
}

.Next_button_EmailVerify {
  width: 280px;
  border-radius: 1px;
}

.subscribNow_button_EmailVerify {
  width: 280px;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 600 !important;
  background-color: #6172d6 !important;
}

body[dir="rtl"] .subscribNow_button_EmailVerify {
  font-weight: 600 !important;
  font-size: 20px !important;
}

.subscribNow_button_EmailVerify:hover {
  width: 280px;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 600;
  background-color: #6172d6;
}
.subscribNow_button_EmailVerify:disabled {
  filter: grayscale(0.5) !important;
}
.errorMessage-OTP {
  margin: 0 0 10px 0;
  height: 13px;
  color: #f16b6b;
  visibility: visible;
}

.errorMessage-OTP_hidden {
  visibility: hidden;
  margin: 0 0 10px 0;
  height: 13px;
  color: #f16b6b;
}

/* Auth Icon and style of heading which we are using in all auth pages */
/* Start */

.OTP_auth_paper {
  padding: 40px 20px;
  border: 1px solid #e1e1e1;
  /* max-width: 330px; */
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

/* End */
.resendCode_btn {
  margin-right: 5px;
  background: none !important;
  color: #000;
  margin: 0;
  user-select: none;
  border: none !important;
  font-weight: 600 !important;
  padding: 0;
  text-decoration: underline !important;
  font-size: 14px;
}

.resendCode_bt:hover {
  margin-right: 5px;
  background: none !important;
  color: #000;
  margin: 0;
  user-select: none;
  border: none !important;
  padding: 0;
  text-decoration: underline !important;
}

body[dir="rtl"] .resendCode_btn {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.resendCode_btn:disabled {
  opacity: 30%;
  color: #000;
}

.resendCode_btn span {
  user-select: none;
}

.resendCode_btn:hover {
  color: #000 !important;
  background: none !important;
  border: none !important;
  text-decoration: none;
}

.OTPCounter {
  user-select: none !important;
}

body[dir="rtl"] .OTPCounter {
  font-weight: 400 !important;
  font-size: 16px !important;
}
.GoBackBtn {
  color: #000 !important;
  text-decoration: underline !important;
}

.OTPInput {
  border-radius: 0px !important;
}

.Go_back_link_VerifyCodeOne a {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400 !important;
  color: #5a5a5a !important;
}
body[dir="rtl"] .Go_back_link_VerifyCodeOne a {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.Auth_Icon1_VerifyCodeOne {
  position: absolute;
  left: 25%;
  top: 25%;
  z-index: 9;
}

.circle-image_VerifyCodeOne {
  position: absolute;
  right: -230px;
  bottom: -210px;
  transform: rotate(-90deg);
}

.VerifyCodeOneOverflow {
  overflow: hidden;
}
.select-language-signin_2FAverificationotp {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  outline: none !important;
}

.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin_2FAverificationotp:focus,
.select-language-signin_2FAverificationotp:focus-within,
.select-language-signin_2FAverificationotp:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}
body[dir="rtl"] .select-language-signin_2FAverificationotp {
  right: 80%;
}
.languageIcon_2FAverificationotp {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon_2FAverificationotp {
  right: 80.7%;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.phone-image {
  position: absolute;
  top: 130px;
  right: -200px;
  z-index: 1;
  user-select: none;
  width: 30.125rem;
}
body[dir="rtl"] .phone-image {
  position: absolute;
  top: 19%;
  right: 222px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth_Icon {
  position: fixed;
  right: -315px;
  bottom: -360px;
  transform: rotate(28deg);
  overflow: hidden !important;
  width: 50rem;
}

body[dir="rtl"] .Auth_Icon {
  position: absolute;
  left: -290px !important;
  bottom: -270px;
  right: 50px;
  transform: rotate(20deg);
}
