.date-addnote {
  font-size: 11px;
  font-weight: 400 !important;
}

.Addnote-heading {
  color: #6172d6 !important;
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 20pt !important;
}

body[dir="rtl"] .Addnote-heading {
  font-weight: 600 !important;
}

.star-addnote-modal {
  color: #4adede !important;
  cursor: pointer;
}

.Add-note-QuillRow {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

body[dir="rtl"] .Arabic_font_Applied {
  font-weight: 500 !important;
}

.quill-height-addNote {
  border-top: 1px solid lightgrey;
  height: 18vh !important;
  border-radius: 3px !important;
  position: relative;
}

.errorNotesMessage {
  margin: 5px 0;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden {
  font-weight: 600 !important;
}

.errorNotesMessage_description {
  margin: 7% 4px 4px 0px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorNotesMessage_description {
  font-weight: 600 !important;
}

.errorNotesMessage_hidden_description {
  visibility: hidden;
  margin: 7% 4px 4px 4px;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorNotesMessage_hidden_description {
  font-weight: 600 !important;
}

.cancel-create-modal-btn {
  background: white !important;
  border: 1px solid #e1e1e1 !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .cancel-create-modal-btn {
  font-weight: 600 !important;
}

.proceed-create-modal-btn {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] .proceed-create-modal-btn {
  font-weight: 600 !important;
}

.cancel-Add-notes-Modal {
  background: white !important;
  outline: 1px solid #e1e1e1 !important;
  border: none !important;
  padding: 12px 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  color: #5a5a5a !important;
  font-size: 12pt;
  font-weight: 600 !important;
}

body[dir="rtl"] .cancel-Add-notes-Modal {
  font-weight: 600 !important;
}

.save-Add-notes-Modal {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  font-size: 12pt;
  font-weight: 600 !important;
}

body[dir="rtl"] .save-Add-notes-Modal {
  font-weight: 600 !important;
}

.create-note-modal-text {
  margin-top: 42px;
  font-size: 20px;
  text-align: center !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .create-note-modal-text {
  font-weight: 600 !important;
}

.modal-userprofile-footer {
  display: block !important;
}

.createNoteModalCancelBtn {
  display: flex !important;
  justify-content: end !important;
}

.attachIcon-In-Collapse-material {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Add-Notes-Attachment {
  font-weight: 600 !important;
}

body[dir="rtl"] .Add-Notes-Attachment {
  font-weight: 600 !important;
}

.quill-height-addNote .ql-toolbar.ql-snow {
  color: #a2a2a2 !important;
}

.header-AddNotesModal-close-btn {
  height: 25px !important;
  border-bottom: none !important;
  margin-top: 10px !important;
}

.notes-create-attachment {
  width: 100% !important;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  padding: 0 !important;
  max-height: 90px !important;
  gap: 15px;
  flex-wrap: wrap;
}

.notes-create-attachment::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.notes-create-attachment::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.modal-Note-Title-Input :focus {
  border: 1px solid #e1e1e1 !important;
}

body[dir="rtl"] .modal-Note-Title-Input {
  font-weight: 500 !important;
}

.Confirmationmodal_body_text {
  padding: 0 20px !important;
  text-align: center;
  font-weight: 600 !important;
  color: #5a5a5a;
  font-size: 16px;
}

.close-Add-notes-Modal {
  background: #6172d6 !important;
  border: none !important;
  padding: 12px 30px !important;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  font-size: 12pt;
  font-weight: 600 !important;
}
