.card-header {
  background-color: transparent !important;
  border: none;
}
.table tr th {
  border: transparent;
  color: #000;
}
.accordion-btn {
  border: none;
  background-color: #fff;
}
.data-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0 5px;
}
