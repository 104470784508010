.videoOutgoing-max {
  position: relative;
  display: content !important;
  height: 67vh;
  width: 100%;
  background-color: #707070 !important;
  top: 10px !important;
  /* border: 2px solid #ccc; */
  justify-content: center;
  align-items: center;
}

.outgoing-call-text-max {
  font-size: 19px !important;
  color: #fff !important;
  font-weight: 600;
}

.Ringing-text-max {
  font-size: 15px !important;
  color: #fff !important;
}

.avatar-column-max {
  display: flex;
  justify-content: center;
  position: relative;
}

.outgoing-title-max {
  display: flex;
  justify-content: center;
  position: relative;
  top: 80px !important;
}

.calling-title-max {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px !important;
}

.button-img-max {
  background-color: transparent !important;
  border: none !important;
}

/* */
/* .videoIncoming-max-call {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 370px;
  width: 350px;
  background-color: #707070 !important;
  z-index: 9;
  padding-top: 25px;
  border-radius: 4px;
} */

.outgoing-call-text-max-call {
  font-size: 19px !important;
  color: #fff !important;
  font-weight: 600;
  margin-top: 20px;
}

.calling-text-max-call {
  font-size: 15px !important;
  color: #fff !important;
}

.avatar-column-max-call {
  text-align: center;
}

.someone-calling-title-max-call {
  text-align: center;

}

.calling-title-max-call {
  text-align: center;

}

.button-img-max-call {
  background-color: transparent !important;
  border: none !important;
}

.Caller-Status {
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: -15px;
  background: linear-gradient(to left, #4ADEDE 0%, #6172d6 100%) !important;
  color: #fff !important;
}

body[dir="rtl"] .Caller-Status {
  background: linear-gradient(to left, #6172d6 0%, #4ADEDE 100%) !important;
}