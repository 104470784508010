.AttendeesCard_attending {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 232px;
  padding: 0 20px;
  min-height: 55px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.AttendeesCard_attending::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: #55ce5c;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
}
.AttendeesCard_Maybe {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 232px;
  padding: 0 20px;
  min-height: 55px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.AttendeesCard_Maybe::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: #d8a709;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
}
.AttendeesCard_NotAttending {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 232px;
  padding: 0 20px;
  min-height: 55px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.AttendeesCard_NotAttending::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: #f16b6b;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
}
.AttendeesCard_awaiting {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 232px;
  padding: 0 20px;
  min-height: 55px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.AttendeesCard_awaiting::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 10px;
  background-color: gray;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
}

.AttendeesName {
  font-weight: 600;
  font-size: 16px;
  color: #505050;
  color: #505050;
}
.AttendeeDesgination {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #a2a2a2;
}
