.dateHeading_events__Modal {
  color: #6172d6;
  font-size: 1.2rem !important;
  font-weight: 600;
}

.eventsBox {
  max-height: 250px !important;
  min-height: 350px !important;
  overflow-y: auto;
  margin-right: 10px;
}

body[dir="rtl"] .eventsBox {
  margin-right: 0px !important;
}

.eventsBox::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.eventsBox::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
}

.eventBox {
  border-radius: 4px;
  padding: 3px 15px;
  margin: 10px 0;
}

.event__title {
  color: #5a5a5a !important;
  font-weight: 600;
  font-size: calc(24px - 20%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event_time {
  color: #5a5a5a !important;
  font-weight: 600;
  font-size: calc(16px - 20%);
}

.event__type {
  color: #5a5a5a !important;
  font-weight: 600;
  font-size: calc(16px - 20%);
  text-transform: uppercase;
  margin: 0 !important;
  text-align: right !important;
}

.joining-Meeting {
  width: 118px;
  height: 28px;
  font-size: 10px;
  color: #fff;
  background: #6172d6;
  border: none;
  border-radius: 4px !important;
}

.joining-Meeting:active,
.joining-Meeting:hover,
.joining-Meeting:focus,
.joining-Meeting:focus-visible {
  width: 118px !important;
  height: 28px !important;
  font-size: 10px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border: none !important;
  border-radius: 4px !important;
}

.Start-Meeting {
  width: 118px;
  height: 28px;
  font-size: 10px;
  color: #fff;
  background: #81db86;
  border: none;
  border-radius: 4px !important;
}

.Start-Meeting:is(:hover, :focus, :focus-visible:active) {
  width: 118px !important;
  height: 28px !important;
  font-size: 10px !important;
  color: #fff !important;
  background: #81db86 !important;
  border: none !important;
  border-radius: 4px !important;
}
