::-webkit-scrollbar {
  width: 0.3rem !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
  height: 0.3rem !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #ccc !important;
  margin-left: -15px !important;
}

::placeholder {
  font-weight: 600 !important;
  color: #bbbbbb !important;
  font-family: "Montserrat" !important;
}
html {
  height: 100%;
}
/*
File Updated on  Dated Aug 24 2022 

normalize bootstrap and other primary style 		
color												
font sizes     										
list item											
bs customize 										
Login Page Style 									
Sidebar Menu 										
page header 										
dropdown menu item 									
bs badge customize                                 
table style                                         
content box style                                  
checkbox and radio button                           
filter blur background on dropdown click            
                                   

*/

/*normalize bootstrap and other primary style*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat" !important;
  font-size: 13px !important;
  color: #000000;
  line-height: 1.5;
  background-color: #f6fafd !important;
  /* overflow: hidden auto; */
}

.MuiInputBase-root {
  font-family: "Montserrat" !important;
}

body[dir="rtl"] {
  font-family: "IBM Plex Sans Arabic" !important;
}

.MuiAutocomplete-paper {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-36 {
  font-size: 36px;
}

.form-control2 {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-size: 13px;
}

.form-control2 .view {
  overflow: hidden !important;
  word-wrap: break-word !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}

.form-control2::placeholder {
  font-weight: 600 !important;
  color: #bbbbbb;
}

.border {
  border: 1px solid #e1e1e1;
}

.txt-upercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-upload-input {
  position: relative;
  display: inline-block;
}

.custom-upload-input > input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  width: 100%;
}

.agenda-wrapper {
  position: relative;
}

.agenda-content {
  border: 1px solid #e0e0e0;
  padding: 15px 0;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
}

.calc-h-100 {
  height: calc(100%) !important;
  overflow: auto;
}

.calc-h-85 {
  height: calc(85%) !important;
  overflow: auto;
}

.calc-h-88 {
  height: calc(88%) !important;
  overflow: auto;
}

[data-bs-toggle] {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

/*color*/
.color-primary {
  color: #076db7 !important;
}

.color-primary2 {
  color: #3ca6d6 !important;
}

.color-red {
  color: #f26a6c !important;
}

.color-inherit {
  color: #000000 !important;
}

.color-white {
  color: #ffffff !important;
}

.bg-primary {
  background: #076db7 !important;
}

.bg-yellow {
  background: #ffd344 !important;
}

.bg-blue {
  background: #2298de !important;
}

.bg-light-green {
  background: #6be8c6 !important;
}

.bg-red {
  background: #f16b6c !important;
}

.bg-dark-gray {
  background: #787878 !important;
}

a {
  color: #3ca6d6;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

/*font sizes and other style*/
.font-sm {
  font-size: 75%;
}

.font-n {
  font-size: 15px !important;
}

.font-m {
  font-size: 18px !important;
}

.font-ml {
  font-size: 22px !important;
}

.font-l {
  font-size: 26px !important;
}

.font-xl {
  font-size: 30px !important;
}

.font-xxl {
  font-size: 36px !important;
}

.no-border {
  border: 0px !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  max-width: 100%;
}

/*list item*/
.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-list {
  margin: 0;
  padding: 0;
}

.list-item {
  padding: 0px 10px;
  display: inline-block;
  width: 48%;
  margin-bottom: 20px;
}

.list-item .user-thumb {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  -o-box-shadow: 0 0 3px rgb(0 0 0 / 30%);
}

.list-item .user-thumb img {
  width: 100%;
}

.list-item .user-name .name {
  font-size: 15px;
  color: #1f9cde;
  font-weight: 600;
}

.list-item .designation {
  font-size: 75%;
}

.list-heading {
  font-size: 16px;
  color: #0b6aae;
  font-weight: 600;
  margin-bottom: 5px;
  padding: 10px;
}

/*bs customize*/
.dropdown-menu {
  transform: translate(0px, 15px);
  font-size: 13px;
  border-color: #e1e1e1;
}

.btn-primary {
  background-color: #2197d7;
  border-color: #2197d7;
}

.btn-primary:hover {
  background-color: #076db7;
  border-color: #076db7;
}

.btn-warning {
  background-color: #f26a6c;
  border-color: #f26a6c;
}

.btn-warning:hover {
  background-color: #f26a6c;
  border-color: #f26a6c;
}

.btn {
  font-size: 13px;
}

.modal-md {
  max-width: 600px;
  min-width: 600px;
  width: 600px;
}

/* .modal-footer {
  padding: 0px !important;
} */

/* .modal-header {
  border-bottom: none;
  border-top: none;
  padding: 10px 20px;
  height: 5%;
  max-height: 5%;
  min-height: 5%;
}  */

.glyphicon-chevron-down:after {
  content: "\e90c";
  font-family: "font-icons" !important;
  font-size: 20px;
  color: #8a8a8a;
}

.glyphicon-chevron-up:after {
  content: "\e90f";
  font-family: "font-icons" !important;
  font-size: 20px;
  color: #8a8a8a;
}

.nav-tabs {
  border-bottom: 0px;
}

.nav-tabs .nav-link {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin-right: 8px;
  color: #4b4b4b;
  font-weight: 600;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
  border: 1px solid #d4d4d4;
  background: #1f8eca;
  color: #ffffff;
}

.ScheduleMetetingsModal .modal-body > .tab-content {
  height: calc(86%);
}

/*Login Page Style*/
.body-content-wrapper,
body.login-body {
  background: #f6fafd;
  min-height: 78vh;
}

.body-inner {
  min-height: 100vh;
}

.body-content-inner-wrapper {
  padding-left: 8vw;
  padding-right: 8vw;
}

.login-box {
  background: #ffffff;
  padding: 20px;
  max-width: 400px;
}

.login-box-heading {
  font-size: 28px;
  text-align: center;
  margin: 5px 0px;
}

.form-group {
  margin-bottom: 15px;
}

/*Sidebar Menu*/
.sidebar-menu {
  background: #171d2d;
  height: 100vh;
  width: 60px;
  position: fixed;
  top: 0;
  left: 0;
}

.icon-meeting {
  content: "/e91a" !important;
  color: #fff !important;
  margin: 20px 0 0 0;
  font-size: 30px !important;
}

.icon-note2 {
  content: "/e91f" !important;
  color: #fff !important;
  font-size: 30px !important;
  margin: 20px 0 0 0;
}

.icon-note {
  content: "/e91e" !important;
  font-size: 30px !important;
  color: #fff;
  margin: 50px 0 0 0;
}

.icon-help {
  content: "/e917" !important;
  font-size: 30px !important;
  color: #fff;
  /* margin   : 60px 0 0 0; */
}

.icon-setting {
  content: "/e923";
  font-size: 30px !important;
  color: #fff;
  margin: 20px 0 0 0;
}

.body-content-wrapper.has-sidebar-menu {
  padding-left: 60px;
  position: relative;
}

.logo-icon {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.menu-space {
  min-height: 10vh;
}

.about-menu-item {
  margin-top: 10vh;
}

/* .nav-item {
  margin-bottom: 15px;
} */

.about-menu-item span {
  display: block;
  border: 1px solid #ffffff;
  width: 33px;
  height: 33px;
  margin: 0 auto;
  border-radius: 50%;
  color: #ffffff;
  line-height: 33px;
  text-align: center;
  font-size: 20px;
}

/*page header*/
.header {
  padding-top: 8vh;
}

.user-img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 0 5px 0 0;
  display: inline-block;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
}

.header-icon {
  transform: translate(0px, 15px);
}

.search-filter-header {
  position: relative;
}

.search-filter-content {
  position: absolute;
  top: 17px;
  width: 428px;
  right: 44px;
}

/*dropdown menu item*/
.dropdown-menu-list-item {
  padding: 0px 15px;
}

.user-dropdown .dropdown-toggle {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  padding: 16px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.user-dropdown .dropdown-menu.show {
  width: 100%;
  margin-top: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.user-dropdown .dropdown-menu-list-item {
  margin-bottom: 20px;
  position: relative;
}

.user-dropdown .dropdown-menu-list-item a {
  padding: 5px 0 3px 0;
  display: block;
}

.user-dropdown .dropdown-menu-list-item:after {
  position: absolute;
  bottom: -10px;
  width: 85%;
  height: 1px;
  background: #e1e1e1;
  content: "";
  display: block;
}

.dropright .dropdown-menu.show {
  right: 0px;
  left: inherit;
}

.dropmidle .dropdown-menu {
  transform: translate(-47%, 0px);
}

.dropmidle .notification-dropdown-menu {
  transform: translate(-43%, 24px);
}

.notification-dropdown .notification-dropdown-menu {
  min-width: 320px;
  max-width: 320px;
  max-height: 70vh;
  overflow: auto;
}

.notification-dropdown > [data-bs-toggle="dropdown"].show:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #e1e1e1;
  content: "";
  display: block;
  position: absolute;
  top: 53px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.icon-list-item {
  width: 20px;
  height: 20px;
  background: #e1e1e1;
  border-radius: 50%;
  overflow: hidden;
}

.desc-item-time {
  text-align: right;
  color: #9a9a9a;
}

.icon-notification-list-item {
  width: 50px;
  height: 50px;
  background: #cccc;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}

.desc-notification-list-item {
  width: 78%;
  height: auto;
}

.notification-dropdown-menu-item {
  color: #505050;
}

.sidebar-menu .nav-item i {
  font-size: 31px;
}

.sidebar-menu .nav-item a i,
.sidebar-menu .nav-item a {
  color: #ffffff;
}

[class^="icon-"],
[class*=" icon-"] {
  font-size: 28px;
  display: inline-block;
  vertical-align: middle;
  color: #707070;
}

[class^="icon-"].nm-icn,
[class*=" icon-"].nm-icn {
  font-size: 26px;
}

[class^="icon-"].sm-icn,
[class*=" icon-"].sm-icn {
  font-size: 22px;
}

/* bs badge customize*/
.badge {
  padding: 4px 15px;
  font-size: 13px;
  font-weight: normal;
}

.badge.bg-active {
  background: #9bffcb;
  color: #000000;
  font-size: 13px;
}

/*table style*/
.v-align-middle tr td,
.v-align-middle tr th {
  vertical-align: middle;
}

.table tr th {
  border-bottom-color: #2094cd;
  color: #2094cd;
}

.table tr td {
  color: #505050;
}

.content-box.bg-transparent .table tr th {
  border-bottom-color: transparent;
}

.table.has-bg tr td,
.table .row-has-bg td {
  background: #ffffff;
}

.table .row-no-border td {
  border: none;
  box-shadow: none;
}

/*content box style*/
.body-content-wrapper {
  position: relative;
}

.content-box {
  background: #ffffff;
  padding: 15px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.content-box.radius {
  border-radius: 6px;
}

.content-box-heading {
  font-size: 16px;
}

.content-box.bg-transparent {
  background: transparent;
  box-shadow: none;
  margin-top: 20px;
}

/*checkbox and radio button*/
/*checkbox*/
.checkbox-block {
  width: 100%;
}

.small-radio,
.radio,
.radio-block,
.small-checkbox,
.radio-inline,
.checkbox,
.checkbox-block,
.radio-inline,
.checkbox-inline {
  position: relative;
  cursor: pointer;
}

/* input radio commit by Ali raza */

/* input[type="radio"], */
/* input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0px;
  width: 20px;
  height: 20px;
  visibility: hidden;
} */

/* input[type="radio"]:not(:checked) + span:after {content: '';position: absolute;top: 0px;left: 0px;background: url(../img/radio.png) no-repeat;width: 24px;height: 24px;display: block;background-position: 0;opacity: 1;visibility: visible;-webkit-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-webkit-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);} */

input[type="radio"]:checked+span:after,
/* input[type="radio"]:checked + span:focus:after {content: '';position: absolute;top: 0px;left: 0px;background: url(../img/radio-checked.png) no-repeat;width: 24px;height: 24px;display: block;background-position: 0;opacity: 1;-webkit-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-webkit-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);} */


.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  padding-left: 23px;
  margin-bottom: 15px;
}

/* input[type="radio"][disabled] + span:after, input[type="radio"][disabled] + span:focus:after {content: '';position: absolute;top: 0px;left: 0px;background: url(../img/radio-disabled.png) no-repeat;width: 20px;height: 20px;display: block;background-position: 0;opacity: 1;cursor: not-allowed;} */
/* .small-radio input[type="radio"][disabled] + span:after, .small-radio input[type="radio"][disabled] + span:focus:after, input.small-radio[type="radio"][disabled] + span:after, input.small-radio[type="radio"][disabled] + span:focus:after {background: url(../img/radio-disabled-small.png) no-repeat;} */
input[type="checkbox"]:not(:checked) + span:after {
  /* content:'';position:absolute;top:0px;left:0px;background:url(../img/checkbox.png) no-repeat;width:20px;height:20px;display:block; */
  background-position: 0 0;
  opacity: 1;
  visibility: visible;
  background-size: cover;
  -webkit-transition: all 100ms cubic-bezier(0.225, 0.34, 0.925, 0.37);
  -moz-transition: all 100ms cubic-bezier(0.225, 0.34, 0.925, 0.37);
  -o-transition: all 100ms cubic-bezier(0.225, 0.34, 0.925, 0.37);
  transition: all 100ms cubic-bezier(0.225, 0.34, 0.925, 0.37);
  -webkit-transition-timing-function: cubic-bezier(0.225, 0.34, 0.925, 0.37);
  -moz-transition-timing-function: cubic-bezier(0.225, 0.34, 0.925, 0.37);
  -o-transition-timing-function: cubic-bezier(0.225, 0.34, 0.925, 0.37);
  transition-timing-function: cubic-bezier(0.225, 0.34, 0.925, 0.37);
}

input[type="checkbox"]:checked+span:after,
/* input[type="checkbox"]:checked + span:focus:after {content: '';position: absolute;top: 0px;left: 0px;background: url(../img/checkbox.png) no-repeat;width: 20px;height: 20px;display: block;background-position:-21px 0px;opacity: 1;background-size:cover;-webkit-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-webkit-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);} */
.half-checked-active input[type="checkbox"].checkboxchecked-all+span:after,
/* .half-checked-active input[type="checkbox"].checkboxchecked-all + span:focus:after {content: '';position: absolute;top: 0px;left: 0px;background: url(../img/half-checkbox.png) no-repeat;width: 20px;height: 20px;display: block;background-position: 0;opacity: 1;background-size:cover;-webkit-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);transition: all 100ms cubic-bezier(0.225, 0.340, 0.925, 0.370);-webkit-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-moz-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);-o-transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);transition-timing-function: cubic-bezier(0.225, 0.340, 0.925, 0.370);} */

input[type="checkbox"][disabled]+span:after,
/* input[type="checkbox"][disabled] + span:focus:after {content: '';position: absolute;top:0px;left:0px;background: url(../img/checkbox.png) no-repeat;width: 20px;height: 20px;display: block;background-position:-42px 0;opacity:1;cursor:not-allowed;background-size:cover;} */


/*filter blur background on dropdown click*/
.blur-content .body-content-inner-wrapper .header .page-heading,
.blur-content .body-content-inner-wrapper .header .nav .nav-item,
.blur-content .body-content-inner-wrapper .body-content-wrapper {
  position: relative;
}

.blur-content .body-content-inner-wrapper .header .page-heading:after,
.blur-content .body-content-inner-wrapper .header .nav .nav-item:after,
.blur-content .body-content-inner-wrapper .body-content-wrapper:after {
  position: absolute;
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  top: 0;
  width: 100%;
  height: 100%;
}

.blur-content .body-content-inner-wrapper .header .nav .nav-item:after {
  top: 15px;
}

.modal-backdrop.show {
  /* background: rgba(255, 255, 255, 0.3) !important; */
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px) !important;
  /* opacity: inherit; */
}

/* .modal-backdrop.fade {
  opacity: 1 !important;
} */

/*other style*/
.agendas-list {
  font-size: 16px;
}

.agendas-list .count {
  width: 45px;
  height: 45px;
  max-width: 45px;
  min-width: 45px;
  border-radius: 100%;
  background: #046ab2;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  margin-right: 10px;
  line-height: 45px;
  padding: 0 5px;
}

.agendas-list .agenda-title {
  max-width: 65%;
  min-width: 65%;
}

.agendas-list .agendas-list-item {
  margin-bottom: 25px;
}

.icon-arrow-right.download {
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.icon-arrow-right.download:before {
  transform: translate(-8px, -6px);
  display: block;
}

.users-thumbs-list {
  position: relative;
}

.users-thumbs-list .user-thumb {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.users-thumbs-list .user-thumb img {
  width: 100%;
}

.user-thumb,
.attached-files .file {
  position: relative;
}

.user-thumb .remove-file,
.attached-files .file .remove-file {
  position: absolute;
  top: -6px;
  right: 0px;
  cursor: pointer;
}

.user-thumb .remove-file > i,
.attached-files .file .remove-file > i {
  color: #f26a6c;
  font-size: 13px;
}

.ToDoListDetailModal .assignee-list,
.ToDoListDetailEditModal .assignee-list {
  max-height: 200px;
  overflow: auto;
}

.close-square-fill {
  background: #f16b6c;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
}

.check-square-fill {
  background: #58f2b6;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
}

.notallowed-square-fill {
  background: #ffd344;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
}

.react-code-input {
  display: block !important;
}

.FocusedStyling.react-code-input input {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  border-radius: 4px !important;
  outline: 0;
  /* width: 46px !important; */
  height: 40px !important;
}

.FocusedStyling.react-code-input input:nth-child(1) {
  margin-left: 0px !important;
  margin-right: 4px !important;
}

body[dir="rtl"] .FocusedStyling.react-code-input input:nth-child(1) {
  margin-right: 0px !important;
  margin-left: 4px !important;
}

.FocusedStyling.react-code-input input[type="text"]:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  border-radius: 4px !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-datepicker__input-container {
  display: none !important;
}

.iconForDatePicker {
  margin-left: 23px !important;
  cursor: pointer;
}

.react-datepicker-popper {
  top: 40px !important;
}

.react-datepicker__triangle {
  left: 16px !important;
}

svg.DatePickerIcon {
  width: 29px;
  height: 29px;
  /* margin-top: 1px; */
  color: #fff !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  padding: 5px !important;
  border-radius: 4px;
  display: block;
  margin-top: 0;
}

body[dir="rtl"] svg.DatePickerIcon {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

@import "~antd/dist/antd.less";

/* // global css for sidebar ends here */
.ant-table-thead > tr * {
  color: #6172d6 !important;
  font-weight: 600;
  font-size: 14px !important;
  margin-right: 0px !important;
}

.ParticipantAV .ant-table-thead > tr * {
  color: #6172d6 !important;

  font-size: 14px !important;
  font-weight: 500 !important;
}

body[dir="rtl"] .ant-table-thead > tr * {
  font-weight: 600 !important;
  /* margin-left: 14px !important; */
}

/* .ant-table-row {
  background-color: #f6f6f6 !important;
} */

.ant-picker-input > input[disabled] {
  color: #000 !important;
}

th.ant-table-cell.ant-table-selection-column {
  width: 4% !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0 !important;
}

.ant-table-pagination-right {
  justify-content: flex-end !important;
}

.ant-table-pagination {
  display: flex !important;
  flex-wrap: wrap !important;
  grid-row-gap: 8px !important;
  row-gap: 8px !important;
}

.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.ant-pagination {
  box-sizing: border-box !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  font-variant: tabular-nums !important;
  line-height: 1.5715 !important;
  -webkit-font-feature-settings: "tnum", "tnum" !important;
  font-feature-settings: "tnum", "tnum" !important;
}

.ant-table-pagination > * {
  flex: none !important;
}

.ant-pagination-disabled,
.ant-pagination-disabled:focus-visible,
.ant-pagination-disabled:hover {
  cursor: not-allowed !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  font-weight: 600;
  outline: 0 !important;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  display: inline-block !important;
  min-width: 32px !important;
  height: 32px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 600;
  line-height: 32px !important;
  text-align: center !important;
  vertical-align: middle !important;
  list-style: none !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev {
  margin-right: 8px !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  cursor: not-allowed !important;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 12px !important;
  text-align: center !important;
  background-color: #fff !important;
  /* border          : 1px solid #d9d9d9 !important; */
  border-radius: 2px !important;
  outline: none !important;
  transition: all 0.3s !important;
}

.ant-pagination-item-active {
  font-weight: 500 !important;
  background: #fff !important;
  border-color: #6172d6 !important;
}

.ant-pagination-item {
  min-width: 32px !important;
  font-weight: 600;
  text-align: center !important;
  list-style: none !important;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  outline: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.ant-pagination-item,
.ant-pagination-total-text {
  display: inline-block !important;
  height: 32px !important;
  margin-right: 8px !important;
  line-height: 30px !important;
  vertical-align: middle !important;
}

.ant-pagination-item-active a {
  color: #6172d6 !important;
}

.ant-pagination-item a {
  display: block !important;
  padding: 0 6px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  transition: none !important;
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
  color: #6172d6 !important;
}

.ant-pagination-item-active a {
  color: #6172d6 !important;
  border: 1px solid#6172d6 !important;
}

li.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
  border: none !important;
}

h4.labelTitle {
  color: #6172d6 !important;
  font-size: calc(22px - 28%) !important;
  font-weight: 600 !important;
  margin-bottom: 15px;
  margin-top: 15px;
}

/* th.ant-table-cell {
  text-align: left !important;
} */

/* .ant-table-wrapper.hello table {
  border-collapse: collapse;
} */

.ant-table-wrapper.onBoardTable table {
  border-collapse: collapse;
}

/* .ant-table-wrapper.Resolution_table table {
  border-collapse: collapse;
} */

.ant-table-wrapper.hello td.ant-table-cell {
  text-align: left !important;
  color: #505050 !important;
  padding: 0.5rem 0.5rem !important;
  border-bottom: 1px solid #dee2e6 !important;
  font-size: calc(17px - 20%) !important;
  font-weight: normal !important;
  font-weight: 400;
}

.ant-table-wrapper.onBoardTable td.ant-table-cell {
  text-align: left !important;
  color: #505050 !important;
  padding: 0.5rem 0.5rem !important;
  border-bottom: 1px solid #dee2e6 !important;
}

tr.ant-table-row {
  border-bottom: 1px solid #dee2e6 !important;
}

/* tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  border: none !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
} */

tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:has(
    button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded
  ) {
  border-bottom: none !important;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-marginDense.MuiInputLabel-outlined.MuiFormLabel-filled {
  display: none !important;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-marginDense.MuiInputLabel-outlined.Mui-focused {
  transform: none !important;
}

.form-label {
  margin-bottom: 0 !important;
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

button.SignInButton {
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  background-color: #2197d7 !important;
  border-color: #2197d7 !important;
}

.signUp-box {
  background: #ffffff;
  padding: 20px;
  width: 850px !important;
}

.signUp-logo {
  text-align: center !important;
}

label.heavyLabel.form-label {
  color: #076db7 !important;
  font-weight: 600 !important;
}

label.lightLabel.form-label {
  color: #076db7 !important;
  font-weight: 400 !important;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

button.SignInButton {
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 0.2rem 3.6rem !important;
  background-color: #2197d7 !important;
  border-color: #2197d7 !important;
  border-radius: 3px !important;
}

/* .IconStyle.valid-feedback {
  display: block !important;
  width: 1% !important;
  font-size: 20px !important;
  color: #939393 !important;
  position: absolute !important;
  right: 37px !important;
  top: 18px !important;
} */

.signUp-box {
  background: #ffffff;
  padding: 20px;
  width: 800px !important;
}

.signUp-logo {
  text-align: center !important;
}

label.heavyLabel.form-label {
  color: #076db7 !important;
  font-weight: 600 !important;
}

label.lightLabel.form-label {
  color: #076db7 !important;
  font-weight: 400 !important;
}

.verification-box {
  background: #ffffff;
  padding: 20px 35px;
  max-width: 500px;
}

/* for Signup account verification succes */

/* .verification-box-heading {
  font-size : 30px;
  text-align: center;
  margin    : 15px 0px;
} */

.VerificationWidth {
  width: 350px !important;
  margin-bottom: 15px !important;
}

/* for Signup account verification succes */

/* small.VerificationText {
  font-size    : 16px !important;
  font-weight  : 400 !important;
  color        : #000000 !important;
  margin-bottom: 0px !important;
  
} */

.login-box-heading.Forgot-Password {
  margin: 20px 0px !important;
}

.active-start {
  background: #81db86 !important;
  color: #fff;
}

.activebtn {
  /* background: #9bffcb !important; */
  /* color     : #000000; */
  /* font-size : 13px; */
  /* padding   : 4px 15px; */
  border-radius: 50rem;
  width: 110px !important;
  text-align: center !important;
}

.activebtnp {
  /* background: #9bffcb !important; */
  /* color: #000000; */
  /* font-size: 13px; */
  /* padding: 4px 15px; */
  border-radius: 50rem;
  /* width     : 150px !important; */
}

.reactour__popover {
  position: fixed;
  width: 100% !important;
  background: none !important;
  padding: 24px 30px;
  box-shadow: none !important;
  color: inherit;
  z-index: 9999999 !important;
  transition: transform 0.3s ease 0s;
  top: 0px;
  left: 0px;
  transform: translate(0, 0) !important;
}

.tour-mask {
  color: #fff !important;
  opacity: 0.85 !important;
  z-index: 99999 !important;
  backdrop-filter: blur(20px) !important;
  background: rgba(255, 255, 255, 0.3) !important;
  pointer-events: none !important;
  z-index: 999999 !important;
}

/* .hightlightmask {
  pointer-events: none !important;
} */
/* .mask svg defs  rect:nth-child(2) {
  display: flex;
  position: absolute;
  width: 100%;
  padding: 20px !important;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999 !important;
  clip-path: circle(40%) !important;
  overflow: hidden;
  z-index: 999999999999999999999 !important;
  pointer-events: none !important;
} */
/* .mask svg defs  rect:nth-child(2) {
  display: flex;
  position: absolute;
  width: 100%;
  padding: 10px !important;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999 !important;
  clip-path: circle(60%) !important;
  overflow: hidden;
  z-index: 999999999999999999999 !important;
  pointer-events: none !important;

} */
/* .mask + rect:nth-child(2) {
  pointer-events: none;
}
.rect:nth-child(2) {
  z-index: 9999999;
  pointer-events: none !important;
} */
.reactour__popover span {
  display: none !important;
}

.dialog-box {
  width: 400px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 110px !important;
  left: 60px;
  pointer-events: none !important;
}

body[dir="rtl"] .dialog-box {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 80px !important;
  left: auto;
  pointer-events: none !important;
  right: -285%;
  top: 122px !important;

  /* width: 400px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 80px !important;
  left: 60px;
  pointer-events: none !important; */
}

.faq-icon {
  position: absolute !important;
  width: 400px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 470px !important;
  left: 80px;
  pointer-events: none !important;
}

.dialog1-box {
  width: 400px;
  display: flex;
  gap: 15px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 140px !important;
  left: 40px;
}

body[dir="rtl"] .dialog1-box {
  /* width: 400px; */
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: right !important;
  flex-direction: column;
  position: absolute;
  /* top: 90px !important; */
  left: 920px;
}

.dialog2-box {
  position: absolute;
  display: flex;
  height: 100vh;
  left: 120px;
  top: 95px;
  gap: 0;

  width: 300px;
  justify-content: flex-start;
  align-items: flex-start;
}

body[dir="rtl"] .dialog2-box {
  position: absolute;
  display: inline;
  height: 100vh;
  left: 60px;
  top: 100px;
  gap: 0;
  width: 300px;
  justify-content: flex-start;
  align-items: flex-start;
}

.welcome-screen {
  position: fixed;
  top: 50%;
  right: 50%;
  width: 100vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.welcome-screen-mask {
  display: none;
  clip-path: none !important;
}

.welcomePopver {
  min-height: 100vh;
  max-width: 1799px !important;
  width: 100vw !important;
  position: fixed !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: none !important;
  left: 0;
  z-index: 99999;
  background: none !important;
  box-shadow: 0 !important;
  transform: translate(0px, 0px) !important;
}

.dialog3-box {
  font-weight: 700;
  font-size: 2rem;
  width: 300px;
}

.CongratsPopver {
  min-height: 100vh;
  max-width: 2299px !important;
  width: 100vw !important;
  position: fixed !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: none !important;
  left: 0;
  font-weight: 700px;
  z-index: 999999999;
  background: none !important;
  box-shadow: 0 !important;
  transform: translate(0px, 0px) !important;
}

.circle {
  width: 100%;
  background-color: #0071c5 !important;
}

/* div:dir {
  width: 300px !important;
} */
.hello {
  color: #fff !important;
  opacity: 1 !important;
  mask: none !important;
  z-index: 9999999 !important;
  /* display: none; */
}

.onBoardTable {
  color: #fff !important;
  opacity: 1 !important;
  mask: none !important;
  z-index: 9999999 !important;
  /* display: none; */
}

/* for agenda */
button.addAgenda.btn.btn-primary {
  color: #0b5ed7 !important;
  border: none !important;
  background: none !important;
}

button.addAgenda.btn.btn-primary:focus {
  box-shadow: none !important;
  border: none !important;
}

span.MuiButtonBase-root.MuiButton-root.MuiButton-contained.UploadFileButton.MuiButton-containedPrimary {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer;
}

span.MuiButtonBase-root.MuiButton-root.MuiButton-contained.UploadFileButton.MuiButton-containedPrimary:hover {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer;
}

i.icon-add-square.uploadfile {
  color: #6172d6 !important;
  font-size: 34px !important;
  margin-left: -30px;
}

/* .ant-table-wrapper.ToDo
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
} */

.ant-table-wrapper.Billing_TablePakageSelection
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ant-table-wrapper.ToDo .ant-table {
  background: none !important;
}

.ant-table-wrapper.Billing_TablePakageSelection .ant-table {
  background: none !important;
}

.ant-table-wrapper.Resolution_table .ant-table-tbody > tr > td {
  background: #fff !important;
}

.ant-table-wrapper.Resolution_table
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ant-table-wrapper.Resolution_table .ant-table {
  background: none !important;
}

.ant-table-wrapper.Resolution_table .ant-table-tbody > tr > td {
  background: #fff !important;
}

/* .css-1s2u09g-control {
  width: 125px !important;
}

.css-26l3qy-menu {
  width: 120px !important;
}

.css-qc6sy-singleValue {
  overflow: visible !important;
  width: 93px !important;
} */

i.icon-close.icon-styling-close {
  font-size: 14px;
  background: #ccc;
  border-radius: 15px;
  position: absolute;
  right: -15px;
  top: 0px;
  padding: 2px;
  width: 93px !important;
}

.inputStyleSearch input.form-control {
  border-radius: 0 !important;
  margin-top: -24px;
  width: 230px;
  height: 60px;
}

.inputStyleSearch .dropdown-assignee {
  top: 56px !important;
  width: 230px !important;
}

.ant-empty-img-simple {
  display: none;
}

.ant-empty-description {
  display: none;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.selectHeight input {
  height: 0 !important;
}

input#MeetingReminderID {
  height: 10 !important;
  margin-top: -2px;
}

.input#MeetingReminderID::placeholder {
  color: #a2a2a2 !important;
}

input#Participant {
  height: 0;
}

.inputSearchFilter input {
  margin-top: -25px;
  border-radius: 0;
  height: 42px;
  width: 230px;
}

/* .inputSearchFilter .dropdown-assignee {
  width: 230px !important;
  top: 41px !important;
  border: none;
} */

.rbc-event-label {
  display: none !important;
}

.date-picker-style {
  padding: 0 !important;
  opacity: 0 !important;
}

.date-picker-style .ant-picker {
  padding: 0 !important;
}

.date-picker-style .ant-picker-input {
  display: none !important;
  opacity: 0 !important;
}

.ant-picker-panel-container {
  z-index: 99999999999999999999 !important;
  right: 20px;
}

.ant-table-wrapper.hello .ant-table-thead > tr * {
  color: #6172d6 !important;
  font-size: 17px;
  font-weight: 600;
}

.ant-table-wrapper.onBoardTable .ant-table-thead > tr * {
  color: #56d0ff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

button.btn.btn-primary.meetingStart.btn.btn-Primary {
  border-radius: 3px;
  background-color: #2095d2 !important;
  border-color: #2095d2 !important;
  padding: 5px 10px 5px 10px;
}

button.btn.btn-danger.meetingEnd.btn.btn-Primary {
  width: 130px !important;
  border-radius: 3px;
  background-color: #ed6c6e !important;
  border-color: #ed6c6e !important;
  padding: 5px 10px 5px 10px;
}

.meetingEnd {
  width: 130px;
}

tr.ant-table-expanded-row td.ant-table-cell {
  background: #fff !important;
}

tr.ant-table-row.ant-table-row-level-0:has(
    button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded
  ) {
  border-bottom: none !important;
}

.ant-table-wrapper.hello table thead.ant-table-thead tr {
  border-bottom: 1px solid #036ab2 !important;
}

.ant-table-wrapper.onBoardTable table thead.ant-table-thead tr {
  border-bottom: 1px solid #036ab2 !important;
}

.onBoardTable > .ant-table-pagination.ant-pagination {
  display: none !important;
  background-color: aquamarine !important;
}

/* .skip-buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #0071c5;
} */
/* .skipButtons-onboard {
  width: 200px !important;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999999;
}

.tour-skip {
  text-decoration: underline;
  cursor: pointer;
  color: #0071c5;
  font-weight: 500;
  letter-spacing: 2px;
}

.skip-button {
  border: none;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px !important;
} */

/* Owais CSS  */
button.btn.btn-primary.meeting {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 3px 20px !important;
  font-size: 15px !important;
}

body[dir="rtl"] button.btn.btn-primary.meeting {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

button.btn.btn-outline-primary.meeting {
  color: #666666 !important;
  border-color: #ccc !important;
  padding: 3px 20px !important;
  border-radius: 3px !important;
  background: #fff !important;
  font-size: 15px !important;
}

.MeetingViewDateTimeTextField label {
  display: none !important;
}

.MeetingViewDateTimeTextField input {
  background: none !important;
  border: none;
  font-size: 14px !important;
}

.MeetingViewLocationTextField {
  margin-top: -15px;
}

.MeetingViewLocationTextField label {
  display: none !important;
}

.MeetingViewLocationTextField input {
  background: none !important;
  border: none;
  font-size: 16px !important;
  margin-top: -10px;
  font-weight: 400;
}

.MeetingViewTitleTextField label {
  display: none !important;
}

.MeetingViewTitleTextField input {
  background: none !important;
  border: none;
  font-size: 22px !important;
  font-weight: 600;
  color: #5f78d6 !important;
}

.textAreaDivView textarea {
  background: none !important;
  border: none !important;
  resize: none !important;
  height: 285px;
  font-size: 12px !important;
  /* height : 185px; */
}

span.agendaIndex {
  font-size: 16px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: inline-block;
  text-align: center;
}

.MeetingAgendaView label {
  display: none !important;
}

.MeetingAgendaView p.agendaTitle {
  font-size: 16px !important;
  font-weight: 600;
  color: #6172d6 !important;
  margin: 0;
  overflow: hidden !important;
  word-wrap: break-word !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}

.MeetingAgendaPresented label {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 12px;
  float: right;
}

.MeetingAgendaPresented input {
  background: none !important;
  border: none !important;
  padding: 0;
  margin-top: -6px;
  font-size: 18px !important;
  color: #6172d6 !important;
  text-align: end;
}

.MeetingAgendaPresented.MeetingAgendaURL p.url {
  font-size: 12px;
  margin-top: 20px;
  text-decoration: underline;
  color: #6172d6 !important;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

p.fileUploadLabel {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center !important;
  white-space: nowrap !important;
  color: #000 !important;
}

.agendaList {
  max-height: 50vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-top: 10px;
}

.agendaList::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.agendaList::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.agendaList.update {
  height: 135px !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.agendaList.update.endmeeting {
  height: 400px !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  margin-top: 15px;
}

button.btn.btn-primary.meeting.start {
  margin-right: 15px !important;
  border-radius: 2px !important;
  padding: 5px 15px !important;
}

button.btn.btn-primary.meeting.end {
  margin-right: 15px !important;
  border-radius: 2px !important;
  padding: 5px 15px !important;
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.CreateMeetingTime .ant-picker {
  border: 1px solid #666666;
}

.CreateMeetingTime .ant-picker:hover {
  border: 1px solid #707070;
}

.CreateMeetingTime input {
  color: #666666;
  font-weight: 500;
}

.CreateMeetingDate svg.DatePickerIcon {
  background: #fff !important;
  color: #666666 !important;
  border: 1px solid #666 !important;
  border-radius: 4px !important;
}

.CreateMeetingReminder
  fieldset.PrivateNotchedOutline-root-5.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.CreateMeetingReminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  height: 30px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
}

.CreateMeetingReminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDens::placeholder {
  color: #a2a2a2 !important;
  font-weight: 600;
}

body[dir="rtl"]
  .CreateMeetingReminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDens::placeholder {
  color: #a2a2a2 !important;
  font-weight: 600;
}

.CreateMeetingReminder input::placeholder {
  color: #000 !important;
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
  overflow: hidden;
}

.MuiAutocomplete-option {
  color: #a2a2a2 !important;
  font-weight: 600;

  font-size: 14px !important;
  padding: 0;
  overflow: hidden;
}

/* .MuiAutocomplete-option::-webkit-scrollbar {
  width: 0.5rem !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.MuiAutocomplete-option::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #ccc !important;
  margin-left: -15px !important;
} */
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 0.5rem !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #ccc !important;
  margin-left: -15px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 0 !important;
}

.CreateMeetingReminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense:hover {
  border-radius: 4px;
  border: 1px solid #666666;
}

.CreateMeetingReminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense:focus {
  border-radius: 4px;
  border: 1px solid #666666;
}

.CreateMeetingInput input {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;
}

.CreateMeetingInput input::placeholder {
  font-weight: 600;
  color: #e1e1e1 !important;
  font-size: 12px;
}

.CreateMeetingInput .form-control:focus {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
}

.CreateMeetingInput.textAreaDiv
  textarea.Input_field_form-control2__wJWHa.form-control {
  border-radius: 4px !important;
  border: 1px solid #666666 !important;
}

.CreateMeetingInput.textAreaDiv
  textarea.Input_field_form-control2__wJWHa.form-control:focus {
  border-radius: 4px !important;
  border: 1px solid #666666 !important;
}

button.btn.btn-outline-primary.meeting.btn.btn-Primary:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

button.btn.btn-primary.meeting.btn.btn-Primary:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

button.btn.btn-primary.meeting.next {
  padding: 5px 35px !important;
  font-weight: 600;
}

.btn-primary.meeting.next:hover {
  background-color: #40a9ff !important;
  border-color: #40a9ff !important;
}

.CreateMeetingReminder fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.CreateMeetingAgenda label {
  display: none;
}

.CreateMeetingAgenda input {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-weight: 600;
}

.CreateMeetingAgenda input::placeholder {
  font-weight: 600;

  font-size: 12px;
  color: #bbbbbb;
}

.CreateMeetingAgenda .form-control:focus {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
}

button.btn.btn-primary.addAgenda {
  background: #2097d6 !important;
  border-color: #2097d6 !important;
  border-radius: 3px !important;
  padding: 8px 20px !important;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.inputSearchFilter.CreateMeetingParticipant input {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.inputSearchFilter.CreateMeetingParticipant input::placeholder {
  font-weight: 600;
}

.inputSearchFilter.CreateMeetingParticipant input:focus {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
}

button.cameraButton.btn.btn-primary {
  margin-top: 20px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #bbbbbb !important;
  color: #666 !important;
  height: 39px;
  width: 39px;
  position: relative;
}

button.cameraButton.btn.btn-primary img {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 24px;
}

button.cameraButton.btn.btn-primary:focus {
  margin-top: 20px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #bbbbbb !important;
  color: #666 !important;
  height: 39px;
  width: 39px;
  box-shadow: none !important;
}

button.cameraButton.Enable.btn.btn-primary {
  margin-top: 20px !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: 1px solid #bbbbbb !important;
  color: #fff !important;
  height: 39px;
  width: 39px;
  position: relative;
}

body[dir="rtl"] button.cameraButton.Enable.btn.btn-primary {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

button.cameraButton.Enable.btn.btn-primary:focus {
  margin-top: 20px !important;
  border-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  color: 1px solid #fff !important;
  height: 39px;
  width: 39px;
  box-shadow: none !important;
}

body[dir="rtl"] button.cameraButton.Enable.btn.btn-primary:focus {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.CreateCheckbox span.ant-checkbox-inner {
  height: 30px !important;
  width: 35px !important;
  /* margin-top   : -7px !important; */
  border-radius: 4px !important;
}

.CreateCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #81db86 !important;
  border-color: #e1e1e1 !important;
  height: 30px !important;
  width: 35px !important;
  /* margin-top      : -7px !important; */
}

.CreateCheckbox .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: -1px !important;
  width: 9.714286px !important;
  height: 24.142857px !important;
  top: 12px !important;
}

.CreateCheckbox .ant-checkbox-checked::after {
  border: none !important;
}

.CreateCheckbox p {
  margin-top: 10px !important;
  margin-right: 10px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

.UpdateCheckbox .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4px !important;
}

.UpdateCheckbox .checkboxParentClass span.ant-checkbox-inner:disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.UpdateCheckbox span.ant-checkbox-inner {
  border: 1px solid #e1e1e1 !important;
}

.UpdateCheckbox span.ant-checkbox-inner:focus-visible {
  outline: none !important;
}

.UpdateCheckbox span.ant-checkbox-inner:hover {
  border: 1px solid #e1e1e1 !important;
}

.UpdateCheckbox span.ant-checkbox-inner {
  height: 39px !important;
  width: 40px !important;
  /* margin-top   : -7px !important; */
  border-radius: 4px !important;
}

.UpdateCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #81db86 !important;
  border-color: #e1e1e1 !important;
  height: 39px !important;
  width: 40px !important;
  /* margin-top      : -7px !important; */
}

.UpdateCheckbox .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: 1px !important;
  width: 9.714286px !important;
  height: 24.142857px !important;
  top: 16px !important;
  border-color: #e1e1e1 !important;
}

.UpdateCheckbox .ant-checkbox-checked::after {
  border: none !important;
}

.UpdateCheckbox p:nth-child(1) {
  margin-top: 10px !important;
  margin-right: 10px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

.Update-committee-Checkbox .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4px !important;
}

.Update-committee-Checkbox span.ant-checkbox-inner {
  border: 1px solid #e1e1e1 !important;
}

.Update-committee-Checkbox span.ant-checkbox-inner:focus-visible {
  outline: none !important;
}

.Update-committee-Checkbox span.ant-checkbox-inner:hover {
  border: 1px solid #e1e1e1 !important;
}

.Update-committee-Checkbox span.ant-checkbox-inner {
  height: 39px !important;
  width: 40px !important;
  /* margin-top   : -7px !important; */
  border-radius: 4px !important;
}

.Update-committee-Checkbox span.ant-checkbox-disabled.ant-checkbox-checked {
  /* filter: grayscale(0.5) !important; */
  background-color: #d3d3d3 !important;
}

.UpdateCheckbox span.ant-checkbox-disabled.ant-checkbox-checked {
  /* filter: grayscale(0.5) !important; */
  background-color: #6172d6 !important;
}

.Update-committee-Checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #81db86 !important;
  border-color: #e1e1e1 !important;
  height: 39px !important;
  width: 40px !important;
  /* margin-top      : -7px !important; */
}

.Update-committee-Checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: 1px !important;
  width: 9.714286px !important;
  height: 24.142857px !important;
  top: 16px !important;
}

.Update-committee-Checkbox .ant-checkbox-checked::after {
  border: none !important;
}

.Update-committee-Checkbox p:nth-child(1) {
  margin-top: 10px !important;
  margin-right: 10px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

body[dir="rtl"] .Update-committee-Checkbox p:nth-child(1) {
  margin-top: 10px !important;
  margin-right: -10px !important;
  color: #a2a2a2 !important;
  font-weight: 600;
}

.UpdateCheckbox .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4px !important;
}

.UpdateCheckbox span.ant-checkbox-inner {
  border: 1px solid #e1e1e1 !important;
}

.UpdateCheckbox span.ant-checkbox-inner:focus-visible {
  outline: none !important;
}

.UpdateCheckbox span.ant-checkbox-inner:hover {
  border: 1px solid #e1e1e1 !important;
}

.CreateMeetingTime .ant-picker {
  height: 39px;
  border: 1px solid #e1e1e1 !important;
}

.timepicker_width .ant-picker-suffix {
  color: #707070 !important;
}

.CreateMeetingTime .ant-picker::placeholder {
  font-weight: 600;
}

body[dir="rtl"] .CreateMeetingTime .ant-picker::placeholder {
  font-weight: 600;
}

button.cameraButton.Enable.btn.btn-primary svg {
  margin-top: 0 !important;
  font-size: 22px;
}

button.cameraButton.btn.btn-primary svg {
  margin-top: 0;
  font-size: 22px;
}

.CreateCheckbox .ant-checkbox:hover::after {
  visibility: hidden;
}

.inputSearchFilter.CreateMeetingParticipant input {
  height: 30px !important;
  width: 95% !important;
}

/* .inputSearchFilter.CreateMeetingParticipant .dropdown-assignee {
  top: 29px !important;
} */

p.publishMessageModal {
  margin: 10px auto 0 auto;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  max-width: 350px;
  font-weight: 600;
}

/* svg.search-Icon.toExpandSearch {
  position: fixed !important;
  z-index : 1 !important;
} */

.expandableMenuSearch {
  display: inline-flex !important;
  vertical-align: middle !important;
  align-items: center !important;
  /* margin-top    : -10px !important; */
  /* margin-left: 35px !important; */
}

.expandableMenuSearch_Meeting {
  display: inline-flex !important;
  vertical-align: middle !important;
  align-items: center !important;
  /* margin-top    : -10px !important; */
  /* margin-left: 35px !important; */
}

body[dir="rtl"] .expandableMenuSearch_Meeting {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.expandableMenuSearch_Meeting label.textFieldSearch.form-label {
  display: none !important;
}

.expandableMenuSearch_Meeting input {
  margin-right: 15px !important;
}

.expandableMenuSearch label.textFieldSearch.form-label {
  display: none !important;
}

.expandableMenuSearch input {
  margin-right: 15px !important;
}

svg.search-Icon.toExpandSearch.Meeting {
  position: relative !important;
  width: 24px;
  height: 24px;
  z-index: 1 !important;
  /* top     : -5px; */
  /* left: 30px; */
  cursor: pointer;
}

svg.search-Icon.toExpandSearch.Meeting {
  position: relative !important;
  width: 24px;
  height: 24px;
  z-index: 1 !important;
  /* top     : -5px; */
  /* left: 30px; */
  cursor: pointer;
}

button.btn.btn-primary.meeting.search {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 0 9px !important;
  font-size: 20px !important;
  margin-top: 2px;
  height: 36px !important;
}

body[dir="rtl"] button.btn.btn-primary.meeting.search {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  margin-right: 20px;
}

button.btn.btn-primary.meeting.next.cancel {
  background: #fff !important;
  border: 1px solid #ccc !important;
  color: #000 !important;
  font-weight: normal;
}

button.btn.btn-primary.meeting.next.submit {
  font-weight: normal !important;
}

.CreateCheckboxEdit .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
}

.CreateCheckboxEdit span.ant-checkbox-inner {
  height: 33px !important;
  width: 33px !important;
  margin-top: -2px !important;
  border-radius: 4px;
}

.CreateCheckboxEdit .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6be8c6 !important;
  border-color: #666666 !important;
  height: 33px !important;
  width: 33px !important;
  margin-top: -2px !important;
}

.CreateCheckboxEdit .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: -1px !important;
  width: 9.714286px !important;
  height: 20.142857px !important;
  top: 12px !important;
}

.CreateCheckboxEdit .ant-checkbox-checked::after {
  border: none !important;
}

.CreateCheckboxEdit p {
  margin-top: 6px !important;
  margin-right: 10px !important;
  color: #a3a3a3 !important;
}

.CreateCheckboxEdit span.ant-checkbox-inner {
  border: 1px solid #666666 !important;
}

.CreateCheckboxEdit span.ant-checkbox-inner:hover {
  border: 1px solid #666666 !important;
}

.updateMinute button.btn.btn-primary.meeting {
  height: 37px;
  margin-top: 15px;
}

/* Huzeifa ki naiye css */

.LoginPageHeading {
  margin-top: -33%;
  font-weight: 600;
}

.LoginPageHeading h1 {
  font-weight: 400;
  color: #0036ab;
  font-size: 60px;
  margin: 0;
}

input#Participant {
  padding: 0px 8px !important;
  height: 15px;
  font-weight: 500;
  font-family: "Montserrat";
}

input#Participant::placeholder {
  font-weight: 600;
  color: #e1e1e1 !important;
}

.updateVersion .modal-dialog.modal-dialog-centered {
  height: auto !important;
}

/* .ToDo .ant-table table {
  border-spacing: 0 10px !important;
  border-radius: 50px !important;
  table-layout: fixed !important;
  margin-top: -19px !important;
} */

.Billing_TablePakageSelection .ant-table table {
  border-spacing: 0 30px !important;
  border-radius: 50px !important;
  table-layout: inherit !important;
  margin-top: -19px !important;
}

.package-TablePakageSelection .ant-table table {
  padding: 0px 5px;
  border-spacing: 0 25px !important;
  border-radius: 50px !important;
  table-layout: inherit !important;
  margin-top: -19px !important;
}

.Resolution .ant-table table {
  border-spacing: 0 10px !important;
  border-radius: 50px !important;
}

.ant-table-column-sorters {
  display: block !important;
}

.ant-table-column-sorter-inner {
  gap: 5px !important;
}

.paper-icon {
  transform: rotate(60deg);
  font-weight: 900;
}

.chat-icon {
  font-size: 12px !important;
  color: grey !important;
}

.ModalScroll-Todo {
  overflow-x: hidden;
  padding: 10px;
}

.password-strength-meter {
  margin-top: 5px !important;
  visibility: hidden;
  height: 18px;
  margin: 10px 0;
}

.employeecard_Card {
  position: relative;
}

.employeecard_deletebtn {
  position: absolute;
  top: -5px;
  right: -36px;
}

.MainContainer {
  position: relative;
  margin-top: 0px;
  padding-left: 0px;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.height-10 {
  height: 10px;
}

.file-icon-modalmeeting {
  position: relative;
  margin: 10px 0;
  width: 100px;
  height: 100px;
}

.file-icon-modalmeeting-p {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

.modalmeeting-participant-scroll {
  height: 161px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modalmeeting-participant-scroll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.modalmeeting-participant-scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.modalmeeting-attendees-row {
  border: 1px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4spx;
}

a.ant-picker-now-btn:hover {
  color: #6172d6 !important;
}

a.dropdown-item.dropdown-item:hover {
  background: #f9f9f9;
}

button.btn.btn-primary.meeting.start.btn.btn-primary:hover {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
}

button.btn.btn-primary.meeting.end.btn.btn-primary:hover {
  background-color: #cb0013 !important;
  border-color: #cb0013 !important;
}

.ant-picker {
  border: none;
}

.calendar-today {
  background-color: #2197d7 !important;
  border-color: #2197d7 !important;
}

@media screen and (max-width: 1200px) {
  .LoginPageHeading h1 {
    font-size: 45px;
  }
}

.f-0 {
  font-size: 0px !important;
}

.ant-table-column-title {
  z-index: 0 !important;
}

.rbc-row-content {
  z-index: 0 !important;
}

/* for signup page  */
/* company Name checkbox */
.com_name_checkbox span.ant-checkbox-inner {
  height: 33px !important;
  width: 38px !important;
  margin-top: -10px !important;
  border-radius: 4px !important;
}

.com_name_checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6be8c6 !important;
  border-color: #6be8c6 !important;
  height: 33px !important;
  width: 38px !important;
}

.com_name_checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: -1px !important;
  width: 9.714286px !important;
  height: 24.142857px !important;
  top: 12px !important;
}

.com_name_checkbox .ant-checkbox-checked::after {
  border: none !important;
}

.com_name_checkbox p {
  color: #666666 !important;
}

.com_name_checkbox .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
}

.com_name_checkbox span.ant-checkbox-inner {
  border-color: #ccc !important;
}

.com_name_checkbox span.ant-checkbox-inner:hover {
  border-color: #ccc !important;
}

/* email Name checkbox */
.email_checkbox span.ant-checkbox-inner {
  height: 33px !important;
  width: 38px !important;
  margin-top: -3px !important;
  border-radius: 4px !important;
}

.email_checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6be8c6 !important;
  border-color: #ccc !important;
  height: 33px !important;
  width: 38px !important;
}

.email_checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  margin-left: -1px !important;
  width: 9.714286px !important;
  height: 24.142857px !important;
  top: 12px !important;
}

.email_checkbox .ant-checkbox-checked::after {
  border: none !important;
}

.email_checkbox p {
  color: #666666 !important;
}

.email_checkbox .checkboxParentClass {
  display: flex !important;
  justify-content: flex-end !important;
}

.email_checkbox span.ant-checkbox-inner {
  border-color: #ccc !important;
}

.email_checkbox span.ant-checkbox-inner:hover {
  border-color: #ccc !important;
}

.CreateMeetingDate .rmdp-day.rmdp-today span {
  background: none !important;
  color: #000 !important;
}

.CreateMeetingDate .rmdp-day.rmdp-selected span:not(.highlight) {
  background-image: linear-gradient(#4bdbde, #5f7ad7) !important;
  color: #fff !important;
  box-shadow: none !important;
}

body[dir="rtl"]
  .CreateMeetingDate
  .rmdp-day.rmdp-selected
  span:not(.highlight) {
  background-image: linear-gradient(#5f7ad7, #4bdbde) !important;
}

.calendar.rbc-calendar .fr svg {
  transform: rotate(180deg);
}

.calendar.rbc-calendar .ar svg {
  transform: rotate(180deg);
}

.TodayButton.ar {
  text-align: left !important;
}

.TodayButton.fr {
  text-align: left !important;
}

label.filter.form-label {
  display: none !important;
}

.positionRelative {
  position: relative !important;
}

.icon-text-alignment {
  position: absolute !important;
  top: 2px !important;
  right: 30px !important;
  font-size: 21px !important;
  cursor: pointer !important;
}

.phone_input_editModal .react-tel-input .form-control {
  width: 76% !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 13px !important;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 13px !important;
  margin-left: 50px !important;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 2px !important;
  line-height: 25px;
  height: 35px;
  width: 82% !important;
  outline: none;
}

.navbar > .dropdown-menu.show {
  padding: 0;
  margin-top: -18px !important;
}

/* @media screen and (max-width: 1000px) {
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
    width: 80px !important;
  }
} */

.settingSwitch .ant-switch .ant-switch-handle {
  width: 12px !important;
  background-color: #000;
}

body.blur-background section.ant-layout.positionRelative .container {
  filter: blur(5px);
}

.eye_icon.valid-feedback {
  display: block;
  position: absolute;
  top: -5px;
  font-size: 22px;
  left: 87% !important;
  width: 30px !important;
  cursor: pointer;
}

.Summary-Table-Invoice .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0 !important;
}

.Summary-Table-Invoice
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell {
  border-bottom: 1px solid #f0f0f0 !important;
}

.Summary-Table-Invoice h4.labelTitle {
  display: none;
}

li.invalid {
  /* padding: 2px 0px; */
  display: flex;
  justify-content: center;
}

li.valid {
  /* padding: 2px 0px; */
  display: flex;
  justify-content: center !;
  align-items: center !important;
}

.invalid span {
  color: #ff0000 !important;
  white-space: nowrap;
  font-weight: 600;
}

body[dir="rtl"] .invalid span {
  font-weight: 600;
}

.valid span {
  color: #6172d6 !important;
  white-space: nowrap;
  font-weight: 600;
}

body[dir="rtl"] .valid span {
  font-weight: 600;
}

span.userstatus-signal-enabled {
  width: 10px;
  height: 10px;
  background: #6de595;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] span.userstatus-signal-enabled {
  width: 10px;
  height: 10px;
  background: #6de595;
  border-radius: 100%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-PublishedPolls {
  width: 10px !important;
  height: 10px !important;
  background: #6de595;
  border-radius: 50% !important;
  margin-right: 7px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

body[dir="rtl"] span.userstatus-signal-PublishedPolls {
  width: 10px !important;
  height: 10px !important;
  background: #6de595;
  border-radius: 50% !important;
  margin-right: 0px;
  margin-left: 7px;
  margin-top: 5px;
}

span.userstatus-signal-disabled {
  width: 10px;
  height: 10px;
  background: #f16b6b;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-disabled {
  width: 10px;
  height: 10px;
  background: #f16b6b;
  border-radius: 50%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-ExpiredPolls {
  width: 13px;
  height: 13px;
  background: #f16b6b;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-ExpiredPolls {
  width: 13px;
  height: 13px;
  background: #f16b6b;
  border-radius: 100%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-locked {
  width: 10px;
  height: 10px;
  background: #000000;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-locked {
  width: 10px;
  height: 10px;
  background: #000000;
  border-radius: 100%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-closed {
  width: 10px;
  height: 10px;
  background: #e1e1e1;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-closed {
  width: 10px;
  height: 10px;
  background: #e1e1e1;
  border-radius: 100%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-dormant {
  width: 10px;
  height: 10px;
  background: #949494;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-dormant {
  width: 10px;
  height: 10px;
  background: #949494;
  border-radius: 100%;
  margin-right: 0px;
  margin-left: 10px;
  margin-top: 5px;
}

span.userstatus-signal-Unpublished {
  width: 10px;
  height: 10px;
  background: #949494;
  border-radius: 50%;
  margin-right: 7px;
  margin-top: 5px;
}

body[dir="rtl"] span.userstatus-signal-Unpublished {
  width: 10px;
  height: 10px;
  background: #949494;
  border-radius: 50%;
  margin-right: 0px;
  margin-left: 7px;
  margin-top: 5px;
}

.modal-footer-update.modal-footer {
  display: block;
  margin-top: -40px;
}

.color-5a5a5a {
  color: #5a5a5a;
}

.color-505050 {
  color: #505050 !important;
}

.color-949494 {
  color: #949494;
}

.color-D8A709 {
  color: #d8a709;
}

.color-5F78D6 {
  color: #6172d6;
}

.color-F16B6B {
  color: #f16b6b;
}

.color-81DB86 {
  color: #81db86;
}

.color-F68732 {
  color: #f68732;
}

i.edit-icon-adminmeeting {
  margin-left: 10px;
}

.card-title.h5 {
  font-size: 18px !important;
}

p.card-text {
  font-size: 12px !important;
}

.MuiTypography-caption {
  font-weight: 400;
}

span.RememberEmail {
  font-size: calc(16px - 20%) !important;
}

.Enter-Code-Label label {
  font-size: calc(16px - 20%) !important;
}

body[dir="rtl"] .Enter-Code-Label label {
  font-weight: 500;
}

h4.selected-amount {
  font-size: calc(27px - 20%) !important;
}

p.details-labels {
  font-size: calc(17px - 22%);
}

body[dir="rtl"] .details-labels {
  font-weight: 600;
}

p.details-user-signup {
  font-size: calc(14px - 20%);
}

body[dir="rtl"] .details-user-signup {
  font-weight: 400;
}

h6.Satisfaction-Guaranteed {
  font-size: calc(20px - 20%);
}

body[dir="rtl"] .Satisfaction-Guaranteed {
  font-weight: 600;
}

p.Satisfaction-Message {
  font-size: calc(14px - 20%);
}

body[dir="rtl"] .Satisfaction-Message {
  font-weight: 600;
}

.Subtotal-Text {
  font-size: calc(22px - 20%);
}

body[dir="rtl"] .Subtotal-Text {
  font-weight: 400;
}

.Subtotal-Value {
  font-size: calc(36px - 20%) !important;
}

body[dir="rtl"] .Subtotal-Value {
  font-weight: 600;
}

.label-changepassword {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 30px;
  color: #5a5a5a !important;
}

.modal-button-style {
  font-weight: 500;
  font-size: 12px !important;
}

.close-modal-verification button.btn-close {
  display: none;
}

.AllUserTable.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-bottom: 2px solid #e1e1e1 !important;
}

.AllUserTable.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 2px solid #e1e1e1 !important;
}

.AllUserTable.ant-table-wrapper .ant-table table {
  border-collapse: collapse !important;
}

.AllUserTable.ant-table-wrapper .ant-table-thead tr {
  border-bottom: 1px solid #ccc !important;
}

.AllUserTable.ant-table-wrapper
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell {
  padding: 8px 16px !important;
}

.EditUserModal.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-bottom: 2px solid #e1e1e1 !important;
}

.EditUserModal.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 2px solid #e1e1e1 !important;
}

.EditUserModal.ant-table-wrapper .ant-table table {
  border-collapse: collapse !important;
}

.EditUserModal.ant-table-wrapper .ant-table-thead tr {
  border-bottom: 1px solid #ccc !important;
}

p.Disabled-Close {
  color: #e1e1e1;
  margin: 0 !important;
}

.EditUserModalBody.modal-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.EditUserModalHeader button.btn-close {
  margin-top: 5px !important;
  margin-right: -5px !important;
}

input[type="text"] {
  font-size: 12px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

input::placeholder {
  font-size: 12px !important;
  font-weight: 500;
  box-shadow: none !important;
  color: #e1e1e1 !important;
}

body[dir="rtl"] input::placeholder {
  font-weight: 500;
}

select[placeholder] {
  font-size: 12px !important;
  font-weight: 500;
}

.css-119dahi-control {
  border-radius: 4px !important;
}

.css-14el2xx-placeholder {
  font-size: 12px !important;
  font-weight: 500;
}

span.ReactFlagsSelect-module_selectValue__152eS {
  margin-left: -6px !important;
}

input:focus {
  border-radius: 4px !important;
  box-shadow: none !important;
}

.css-1pahdxg-control {
  border: 1px solid #707070 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover,
.css-1pahdxg-control:active {
  border: 1px solid #707070 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

input#react-select-4-input {
  font-size: 12px !important;
  font-weight: 500;
}

.EditUserModalBody button#rfs-btn {
  min-width: 72px !important;
}

label.organization-labels {
  font-size: 14px !important;
  font-weight: 600;
  color: #5a5a5a;
}

body[dir="rtl"] label.organization-labels {
  font-weight: 400;
}

.css-1s2u09g-control {
  margin-top: -4px !important;
}

.css-1pahdxg-control {
  margin-top: -4px !important;
  min-height: 30px !important;
}

.modal-labels {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

.Paymenthistorymodal button.btn-close {
  display: none !important;
}

.row-warning-message {
  background-color: #f16b6b !important;
  color: #fff !important;
  margin: 0 !important;
  border: none !important;
  position: absolute;
  width: 100%;
  left: 0;
  height: 40px;
}

.row-warning-text {
  font-size: 18px;
}

.limit-reached-container.container:has(.row-warning-message.row)
  .Add-User-Limit.row {
  padding-top: 40px;
}

body[dir="rtl"] span.ReactFlagsSelect-module_label__27pw9 {
  margin-right: 1px;
}

body[dir="rtl"] span.ReactFlagsSelect-module_secondaryLabel__37t1D {
  padding: 0;
  margin-right: 5px;
}

.ant-table-wrapper.AllUserTable {
  overflow: auto;
  padding-right: 10px;
}

.AllUserTable::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.AllUserTable::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.ant-table-wrapper.EditUserModal {
  overflow: auto;
  padding-right: 10px;
  min-height: 550px;
}

.edit-icon-edituser {
  cursor: pointer;
  margin-top: -10px;
  margin-right: 10px !important;
}

body[dir="rtl"] .edit-icon-edituser {
  cursor: pointer;
  margin-top: -10px;
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.ant-table-wrapper.AllUserTable {
  overflow: auto;
  padding-right: 10px;
  min-height: 350px;
}

body[dir="rtl"] .ant-table-wrapper.AllUserTable {
  overflow: auto;
  padding-right: 0;
  height: 350px;
  padding-left: 10px;
}

th.ant-table-cell.dateTimeColumn {
  text-align: center !important;
}

td.ant-table-cell.dateTimeColumn {
  text-align: center !important;
}

.Edit-Meetings-Modal.modal-header button.btn-close {
  margin-top: 7px;
  margin-right: -5px;
}

body[dir="rtl"] .Edit-Meetings-Modal.modal-header button.btn-close {
  margin-top: 7px;
  margin-right: -5px;
  position: absolute;
  left: 10px;
}

body[dir="rtl"]
  .text-start.color-949494.font-11.p-0.col-lg-12.col-md-12.col-sm-12 {
  text-align: right !important;
}

input[type="radio"].active,
input[type="radio"].focus,
input[type="radio"].hover,
input[type="radio"].target,
input[type="radio"].focus-within,
input[type="radio"].focus-visible {
  border-radius: 50% !important;
}

.form-check-input:focus {
  border-radius: 50% !important;
}

body[dir="rtl"] .radio-cancelsub-modal input.form-check-input {
  position: absolute;
  right: 15px;
}

body[dir="rtl"] .radio-cancelsub-modal label.form-check-label {
  margin-right: 30px;
}

.radio-cancelsub-modal label.form-check-label {
  margin-top: 2px;
}

.font-sizepaymentmethodcaption {
  font-size: 8px;
}

body[dir="rtl"] .font-sizepaymentmethodcaption {
  font-weight: 500;
}

.border-radius-4 {
  border-radius: 4px;
}

body[dir="rtl"] .MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  right: auto;
  left: 24px;
}

body[dir="rtl"] .MuiAlert-action {
  margin-right: auto !important;
  margin-left: -15px !important;
}

body[dir="rtl"] .MuiAlert-icon {
  margin-right: auto !important;
  margin-left: 12px !important;
}

.css-119dahi-control {
  min-height: 28px !important;
  border-radius: 4px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 2px 8px !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 2px 8px !important;
}

.css-319lph-ValueContainer {
  padding: 0px 8px !important;
}

.css-1rgpyyp-control {
  min-height: 28px !important;
}

.css-1rgpyyp-control:focus-within {
  min-height: 28px !important;
  border: 0px solid transparent !important;
}

div#rect-select-2-listbox {
  margin: 0 !important;
}

#react-select-9-listbox::-webkit-scrollbar {
  width: 0.5rem !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}

#react-select-9-listbox::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #ccc !important;
  margin-left: -15px !important;
}

.margin-left-13 {
  margin-left: 13px !important;
}

.margin-left-55 {
  margin-left: 55px !important;
}

.FocusedStyling.react-code-input input {
  text-align: center !important;
}

.css-6j8wv5-Input {
  margin: 0 !important;
}

.btn-close {
  padding: 10px !important;
  color: white !important;
}

.makeStyles-BackGroundSucces-2 {
  width: 100%;
  margin-top: 64px;
  height: 35px !important;
  background-color: #6172d6;
}
body[dir="rtl"] .MuiAlert-message {
  padding: 3px 0 !important;
  font-size: 13px;
  font-family: "IBM Plex Sans Arabic" !important;
  margin-left: 10px;
}
.MuiAlert-message {
  padding: 3px 0 !important;
  font-size: 13px;
  font-family: "Montserrat" !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
  margin-top: -6px;
}

svg.MuiSvgIcon-root {
  transform: rotate(180deg) !important;
}

svg.MuiSvgIcon-root .ant-notification-hook-holder:before {
  width: 25px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  height: 82px !important;
  display: block !important;
  content: "" !important;
  position: absolute !important;
  top: 0px !important;
  z-index: 9 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.ant-notification-notice-description body[dir="rtl"] .ant-table-wrapper.ToDo {
  padding-left: 10px;
  padding-right: 0px;
}

body[dir="rtl"] .ant-table-wrapper.Billing_TablePakageSelection {
  padding-left: 10px;
  padding-right: 0px;
}

/* .ToDo::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.ToDo::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
} */

.Billing_TablePakageSelection::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.Billing_TablePakageSelection::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.DashboardTodoTable .MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded {
  background: none !important;
}

h1.todo-Heading.color-5a5a5a {
  font-size: calc(19px - 20%);
  position: relative;
  top: 5px;
  color: #5a5a5a;
  font-weight: 600;
}

body[dir="rtl"] .iconForDatePicker.margin-right-20 {
  margin-right: 20px !important;
  margin-left: 10px !important;
}

body[dir="rtl"]
  .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow.ant-select-open
  .ant-select-selector {
  width: 110px;
}

.hello.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-bottom: 2px solid #e1e1e1 !important;
}

.hello.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 2px solid #e1e1e1 !important;
}

.hello.ant-table-wrapper .ant-table table {
  border-collapse: collapse !important;
}

.hello.ant-table-wrapper .ant-table-thead tr {
  border-bottom: 1px solid #ccc !important;
}

.hello.ant-table-wrapper
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cel:nth-child(1) {
  padding: 8px 0px !important;
}

.hello.ant-table-wrapper
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell {
  padding: 8px 0 !important;
}

td.ant-table-cell.titleClassMeeting {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.textAreaDiv textarea::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.textAreaDiv textarea::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

button.cameraButton.Enable.btn.btn-primary[disabled] svg {
  font-size: 22px;
}

button.cameraButton.update.btn.btn-primary svg {
  position: absolute;
  top: 8px;
  left: 6px;
}

body[dir="rtl"] .modalMeetingCreateFooter.modal-footer {
  margin-top: 35px;
}

body[dir="rtl"] .employeecard_deletebtn {
  position: absolute;
  top: -5px;
  right: auto;
  left: -36px;
}

body[dir="rtl"] .MeetingAgendaPresented label {
  float: left !important;
}

body[dir="rtl"] button.accordion-button.collapsed {
  text-align: right;
}

span.bordertop {
  border: 1px solid #707070;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
}

.All-Done-Congrats-Screen {
  text-align: center !important;
  justify-content: center !important;
  display: grid !important;
  z-index: 1 !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  background-color: #fff !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
}

.modal-header button:focus,
.modal-header button:focus-visible,
.modal-header button:hover {
  box-shadow: none !important;
  color: #000 !important;
  outline: none !important;
  opacity: 1 !important;
}

.rmdp-ep-shadow:after {
  box-shadow: none !important;
  border: 1px solid #e1e1e1 !important;
}

.rmdp-ep-arrow {
  display: none !important;
}

li.ant-pagination-options:hover,
li.ant-pagination-options:focus,
li.ant-pagination-options:focus-visible,
li.ant-pagination-options:visited {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #6172d6 !important;
  border-right-width: 1px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none !important;
}

.payment-history-select {
  width: 100% !important;
  height: 33px !important;
  border-radius: 2px !important;
}

.payment-history-select .css-1s2u09g-control {
  min-height: 30px !important;
  border-color: #ced4da !important;
}

.payment-history-select .css-1pahdxg-control:active,
.payment-history-select .css-1pahdxg-control:hover,
.payment-history-select .css-1pahdxg-control:focus,
.payment-history-select .css-1pahdxg-control:target,
.payment-history-select .css-1pahdxg-control:visited,
.payment-history-select .css-1pahdxg-control:focus-visible,
.payment-history-select .css-1pahdxg-control:focus-within {
  border-color: #ced4da !important;
}

.PaymentHistory-Datpickers input.form-control {
  height: 39px !important;
}

.PaymentHistory-Datpickers .react-datepicker__input-container {
  display: block !important;
  height: 39px !important;
  margin-top: 5px;
  border-radius: 4px !important;
}

.PaymentHistory-Datpickers .form-control:focus {
  border-color: #ced4da !important;
  border-radius: 4px !important;
}

.PaymentHistory-Datpickers .react-datepicker-popper {
  margin-top: -40px;
}

.ReactFlagsSelect-module_label__27pw9 {
  padding-left: 2px !important;
}

body[dir="rtl"] .ReactFlagsSelect-module_label__27pw9 {
  padding-left: 0px !important;
  padding-right: 2px !important;
}

.ReactFlagsSelect-module_selectFlag__2q5gC {
  display: inline-flex;
  font-size: 1.2em;
  margin-left: -2px;
  margin-right: 2px;
}

body[dir="rtl"] .ReactFlagsSelect-module_selectFlag__2q5gC {
  margin-left: 2px;
  margin-right: -7px;
}

.styled__control-is-focused {
  border: 1px solid #e1e1e1 !important;
}

.css-1sdxpkk-control {
  min-height: 30px !important;
}

.css-192rsg1-control {
  min-height: 30px !important;
}

.css-7i5nts-control {
  min-height: 30px !important;
}

.css-1w0vvs0-control {
  min-height: 30px !important;
}

.css-1s2u09g-control {
  min-height: 30px !important;
}

input.form-control {
  border-radius: 4px !important;
}

.SignupOrganization input.form-control {
  height: 39px !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  border-color: #e1e1e1 !important;
}

.SignupOrganization select.form-select {
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #6172d6 !important;
}

.ant-dropdown-menu {
  border-radius: 4px !important;
}

.css-6j8wv5-Input {
  padding: 0 !important;
}

.modal {
  --bs-modal-margin: 0px !important;
}

body[dir="rtl"] .mt-2.ps-0.col-lg-6.col-md-6.col-sm-12 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

body[dir="rtl"] .mt-2.pe-0.col-lg-6.col-md-6.col-sm-12 {
  padding-right: 1.5rem !important;
  padding-left: 0 !important;
}

.color-black {
  color: #000 !important;
}

.ant-select-selector:focus-visible,
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-select-selector:focus,
.ant-select-selector:visited {
  outline: none !important;
  border: none !important;
}

/* MU Accordian */
.MuiIconButton-label {
  transform: translateX(-0px) rotate(180deg);
  margin-right: 5px !important;
}

.TestAccordian .MuiIconButton-label {
  margin-right: 40px !important;
}

.MuiAccordion-root {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
}

body[dir="rtl"] .TestAccordian .MuiIconButton-label {
  margin-left: -10px !important;
}

.ql-toolbar {
  position: absolute;
  bottom: 0;
  width: 100% !important;
  transform: translateY(100%);
  z-index: 9 !important;
}

body[dir="rtl"]
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg {
  margin-right: 40px !important;
}

body[dir="rtl"] .ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  margin-right: 0px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "Normal";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Large";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px" !important;
  font-size: 18px !important;
}

.ql-font span[data-value="impact"]::before,
.ql-font span[data-label="Impact"]::before {
  content: "Impact" !important;
  font-family: "Impact" !important;
}

.ql-font span[data-value="courier"]::before,
.ql-font span[data-label="Courier"]::before {
  content: "Courier" !important;
  font-family: "Courier" !important;
}

.ql-font span[data-value="comic"]::before,
.ql-font span[data-label="Comic Sans MS"]::before {
  content: "Comic Sans MS" !important;
  font-family: "Comic Sans MS" !important;
}

.ql-font-impact {
  font-family: "Impact";
}

.ql-font-courier {
  font-family: "Courier";
}

.ql-font-comic {
  font-family: "Comic Sans MS";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16px" !important;
}

.ql-snow .ql-picker.ql-font {
  width: 117px !important;
}

.TestAccordian .MuiAccordionSummary-content {
  display: block !important;
  cursor: default !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Huge";
  font-size: 18px !important;
}

.ql-font span[data-value="impact"]::before,
.ql-font span[data-label="Impact"]::before {
  font-family: "Impact";
}

.ql-font span[data-value="courier"]::before,
.ql-font span[data-label="Courier"]::before {
  font-family: "Courier";
}

.ql-font span[data-value="comic"]::before,
.ql-font span[data-label="Comic Sans MS"]::before {
  font-family: "Comic Sans MS";
}

.ql-font-impact {
  font-family: "Impact";
}

.ql-font-courier {
  font-family: "Courier";
}

.ql-font-comic {
  font-family: "Comic Sans MS";
}

.MuiTouchRipple-root {
  display: none;
}

.MuiCardActionArea-focusHighlight {
  display: none;
}

a.m-0.p-0.icon-active-sidebar.nav-link.active rect#Rectangle_830 {
  fill: #fff !important;
}

a.m-0.p-0.icon-active-sidebar.nav-link.active rect#Rectangle_831 {
  fill: #fff !important;
}

a.m-0.p-0.icon-active-sidebar.nav-link.active rect#Rectangle_832 {
  fill: #fff !important;
}

.pagination-groups-table li.ant-pagination-item-active a {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 10px !important;
  border: none !important;
}

.pagination-groups-table .ant-pagination-prev button {
  border: none !important;
  outline: none !important;
}

.pagination-groups-table .ant-pagination-next button {
  border: none !important;
  outline: none !important;
}

.pagination-groups-table .ant-pagination-item {
  border: none !important;
}

.pagination-groups-table .ant-pagination-item-active a {
  color: #fff !important;
  border: none !important;
}

.pagination-groups-table a {
  color: #6172d6 !important;
  font-size: 17px !important;
}

.pagination-groups-table span.anticon.anticon-left {
  color: #6172d6 !important;
  font-size: 20px !important;
  border: #6172d6 !important;
  border: none !important;
}

.pagination-groups-table span.anticon.anticon-right {
  color: #6172d6 !important;
  font-size: 20px !important;
  border: #6172d6 !important;
  border: none !important;
}

/* Crousel */

.carousel-control-prev-icon {
  display: none !important;
}

.carousel-control-prev {
  pointer-events: none !important;
}

.carousel-control-next-icon {
  position: relative !important;
  bottom: 190px !important;
  left: 49px;
}

.carousel-indicators [data-bs-target] {
  display: none !important;
}

.select-voting-deadline > .css-1s2u09g-control {
  max-height: 35px !important;
}

.select-voting-deadline
  > .css-1s2u09g-control:has(
    :focus,
    :focus-visible,
    :hover,
    :target,
    :visited
  ) {
  border: 1px solid #ccc !important;
  max-height: 35px !important;
}

.css-1insrsq-control {
  max-height: 30px !important;
}

.css-109onse-indicatorSeparator {
  display: none !important;
}

.anticon svg {
  margin-top: -4px !important;
}

span.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.Editor_select .css-1jqq78o-placeholder {
  font-size: 12px !important;
}

.Editor_select .css-13cymwt-control {
  height: 12px !important;
}

.ql-editor.ql-blank::before {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500;

  box-shadow: none !important;
}

body[dir="rtl"] .ql-editor.ql-blank::before {
  font-weight: 600;

  text-align: right;
}

body[dir="rtl"] .ql-editor.ql-blank {
  font-weight: 600;

  text-align: right;
}

.form-control:focus {
  border: 1px solid #e1e1e1 !important;
}

.organization-timezone-col .css-ib3xtj-control {
  display: flex !important;
  width: 155% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.organization-timezone-col .css-7pfg3g-control {
  display: flex !important;
  width: 155% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.organization-timezone-col .css-1nmdiq5-menu {
  display: flex !important;
  width: 155% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.All-meeting-col .css-ib3xtj-control {
  display: flex !important;
  width: 100% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.All-meeting-col .css-7pfg3g-control:focus-within {
  max-height: 39px !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.Edit-user-col .css-ib3xtj-control {
  display: flex !important;
  width: 100% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.Edit-user-col .css-7pfg3g-control:focus-within {
  max-height: 39px !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.All-user-select-col .css-ib3xtj-control {
  display: flex !important;
  width: 100% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.All-user-select-col .css-7pfg3g-control:focus-within {
  max-height: 39px !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.Select-box-column .css-ib3xtj-control {
  display: flex !important;
  width: 100% !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin: 0%;
  height: 39px !important;
  padding: 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.Select-box-column .css-7pfg3g-control:focus-within {
  max-height: 39px !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.password-change-branch input.form-control,
.Enter-password-field input.form-control,
.user-Profile input.form-control,
.location-textbox input.form-control,
.agenda-title-field input.form-control,
.attendee-title-field input.form-control,
.todolist-modal-fields input.form-control,
.modalAddNote-fields input.form-control,
.modalNote-fields input.form-control,
.group-fields input.form-control,
.meeting-fields input.form-control,
.create-field-password input.form-control,
.todo-list-field input.form-control,
.create-committee-fields input.form-control,
.rename-modal-field input.form-control,
.resolution-search-input input.form-control,
.Search-filed-resolution input.form-control,
.Inputfield_for_data_room input.form-control,
.Group_input_field input.form-control {
  height: 39px !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
}

.ImportPreviousMinutes input.form-control {
  height: 39px !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  margin-left: -13px !important;
}

.Update_committee_input_searchfield input.form-control {
  margin-top: -5px !important;
}

.Create_group_Title_filed_margin input.form-control {
  margin-top: 5px !important;
}

.createmeeting-schedule-reminder
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  padding: 0px !important;
  margin-top: 2px;
  height: 39px !important;
}

.Atteendees-organizer-participant
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  height: 39px !important;
  margin-top: 5px;
}

.group-type-select-field
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-left: 10px !important;
  height: 39px !important;
  margin-top: 2px;
  font-family: "Montserrat";
}

.searchBox-dropdowns-resolution
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-left: 0px !important;
  height: 39px !important;
  margin-top: 2px;
}

.select-dropdowns-height .css-13cymwt-control {
  height: 39px !important;
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
}

body[dir="rtl"] .select-dropdowns-height .css-13cymwt-control {
  font-weight: 600;
}

.searchResult_Document__control {
  height: 39px !important;
  width: 100%;
  border-radius: 4px !important;
  font-weight: 600;

  box-shadow: none !important;
}

body[dir="rtl"]
  .group-type-select-field
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-right: 28px !important;
  height: 39px !important;
  margin-top: 2px;
}

body[dir="rtl"]
  .searchBox-dropdowns-resolution
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-right: 0px !important;
  height: 39px !important;
  margin-top: 2px;
}

.commmittee-type-select-field
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-left: 10px !important;
  height: 39px !important;
  margin-top: 2px;
}

body[dir="rtl"]
  .commmittee-type-select-field
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-right: 29px !important;
  height: 39px !important;
  margin-top: 2px;
}

.group-select-field
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  height: 39px !important;
  margin-top: 2px;
}

.committee-update-type-select-fields
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-left: 10px !important;
  height: 39px !important;
  margin-top: 2px;
}

body[dir="rtl"]
  .committee-update-type-select-fields
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  margin-right: 10px !important;
  height: 39px !important;
  margin-top: 2px;
}

.committee-select-fields
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  height: 39px !important;
  margin-top: 2px;
}

body[dir="rtl"]
  .committee-select-fields
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  border-radius: 4px !important;
  font-weight: 600;

  color: #a2a2a2 !important;
  height: 39px !important;
  margin-top: 2px;
}

.options_new .css-13cymwt-control {
  width: 137px !important;
  height: 39px !important;
}

.Resolution_table {
  height: 58vh !important;
}

.Resolution_table .ant-table table {
  border-spacing: 0 10px;
}

.Resolution_table tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 0px !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #505050 !important;
}

.Resolution_table
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body[dir="rtl"]
  .Resolution_table
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border-left: 0px !important;
}

.Resolution_table
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(9) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  text-align: center !important;
}

body[dir="rtl"]
  .Resolution_table
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(9) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px !important;
  border-right: 0px !important;
  text-align: center !important;
}

.ant-table-wrapper.Resolution_table
  .ant-table
  .ant-table-thead
  > tr
  > th:nth-child(9) {
  text-align: center !important;
}

.ant-table-wrapper.Resolution_table .ant-table .ant-table-thead > tr > th {
  padding: 2px 16px !important;
}

/* .ant-table-wrapper.ToDo.ant-table .ant-table-thead > tr > th {
  padding: 2px 16px !important;
} */

.ant-table-wrapper.Billing_TablePakageSelection.ant-table
  .ant-table-thead
  > tr
  > th {
  padding: 2px 16px !important;
}

/* for todo pagination focus color */
.Resolution_table
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e9e9e9 !important;
  font-size: 14px;
  font-weight: bold;
}

#contained-button-file {
  cursor: pointer !important;
}

.css-894a34-indicatorSeparator {
  display: none !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px 8px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.DataRoom_DropDown .dropdown-tggle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
  background-color: #fff !important;
}

.DataRoom_DropDown .dropdown-toggle::after {
  display: none !important;
}

.DataRoom_DropDown button {
  width: 160px;
  height: 31px;
  font-weight: 600;
  color: #fff !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  display: flex;
  justify-content: space-between;
  border: none !important;
}

.DataRoom_DropDown .dropdown-toggle.btn.btn-primary {
  border: none !important;
}

.DataRoom_DropDown .dropdown-menu.show {
  padding: 0 !important;
  width: 137px;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translate(0px, 45px);
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .DataRoom_DropDown .dropdown-menu.show {
  padding: 0 !important;
  width: 137px;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translateY(0px, 45px) !important;
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item {
  padding: 10px 10px;
  font-weight: 600;

  text-align: center;
  font-size: 12px !important;
  border-bottom: 2px solid #a2a2a2 !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item_folder {
  border-bottom: none !important;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.DataRoom_DropDown .dropdown-menu.show .dropdown-item:hover {
  background: none !important;
}

.DataRoom_DropDown {
  margin-top: 7px !important;
}

.DataRoom_DropDown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.Scroller-x-resolution {
  width: 100% !important;
  overflow-x: hidden;
  display: flex;
  scroll-behavior: smooth !important;
  align-items: center;
  padding: 0 !important;
  height: 120px !important;
}

.ScrolllerFiles_Committees {
  overflow-x: hidden;
  display: flex;
  scroll-behavior: smooth !important;
  align-items: center;
  gap: 5px !important;
  min-height: 138px !important;
  height: auto !important;
}

.Scroller-x-Meeting {
  overflow: hidden !important;
  display: flex;
  scroll-behavior: smooth !important;
  align-items: center;
  padding: 0 !important;
  height: 65px !important;
}

.file-icon-modalmeeting-p-View-resolution {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.css-t3ipsp-control:hover {
  border-color: #e1e1e1 !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #e1e1e1 !important;
  border-color: #e1e1e1 !important;
}

span.package-icon-style {
  position: absolute;
  top: -11px;
  left: auto;
  font-size: 65px;
}

.rbc-overlay {
  max-width: 100px !important;
  max-height: 145px !important;
  overflow-y: auto;
}

body[dir="rtl"] .SignupOrganization input.form-control {
  font-size: 13px !important;
  font-weight: 500;
}

body[dir="rtl"] .Arabicstyles {
  font-weight: 700;
}

body[dir="rtl"] .Arabicstyles_Subtotal_Not_include_taxes {
  font-weight: 500;
}

body[dir="rtl"] .Saved_money_Tagline {
  font-weight: 400;
}

body[dir="rtl"] .FontArabicRegular {
  font-weight: 400;
}

body[dir="rtl"] .ant-picker-now-btn {
  font-weight: 400;
}

body[dir="rtl"] .ant-btn-sm {
  font-weight: 400;
}

body[dir="rtl"] .ant-btn-link {
  font-weight: 400;
}

/* Calender */
body[dir="rtl"] .rbc-button-link {
  font-weight: 600;
}

body[dir="rtl"] .ant-btn-primary {
  font-weight: 400;
}

body[dir="rtl"] .modaltodolist-attachment-text {
  font-weight: 400;
}

.ant-table-body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

body[dir="rtl"] .FontArabicMedium {
  font-weight: 500;
}

body[dir="rtl"] .create-committee-fields input.form-control {
  height: 39px !important;
  font-weight: 600;

  border-radius: 4px !important;
}

body[dir="rtl"] .Group_input_field input.form-control {
  height: 39px !important;
  font-weight: 600;

  border-radius: 4px !important;
}

body[dir="rtl"] .Group_input_field input.form-control {
  height: 39px !important;
  font-weight: 600;

  border-radius: 4px !important;
}

body[dir="rtl"] .group-fields input.form-control {
  height: 39px !important;
  font-weight: 600;

  border-radius: 4px !important;
}

body[dir="rtl"] .modalNote-fields input.form-control {
  font-weight: 600;
}

.ql-container {
  font-weight: 600;
}

body[dir="rtl"] .ql-container {
  font-weight: 600;
}

body[dir="rtl"] .todolist-modal-fields input.form-control {
  font-weight: 500;
}

body[dir="rtl"] .ant-result-subtitle {
  font-weight: 400;
}

/* Dragger Uploder  */

.ant-upload.ant-upload-drag {
  background: none !important;
}

.Progress_bar_Polls .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 39px;
}

.Progress_bar_Polls {
  width: 90% !important;
}

.Progress_bar_Polls .ant-progress-bg {
  height: 39px !important;
  border-radius: 4px !important;
  background: #4adede !important;
}

.pollsDetailsProgress .ant-progress-text {
  display: none !important;
}

.pollsDetailsProgress .ant-progress-bg {
  background: #4adede !important;
}

.circle-color-picker {
  appearance: none;
  margin: 0;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 0;
  outline: none;
  cursor: pointer;
  margin-left: -21px !important;
}

.selectMember__control {
  width: 100% !important;
}

.selectMember__value-container {
  width: 100% !important;
}

.selectMember__multi-value {
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 50px !important;
}

.selectMember__multi-value__label {
  color: #fff !important;
}

.selectMember__clear-indicator {
  display: none !important;
}

.selectMember__multi-value__remove {
  background: none !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: 50% !important;
}

.selectMember__multi-value__remove:hover {
  background: none !important;
  color: #fff !important;
  padding-right: 10px;
  border-radius: 50% !important;
}

.date_Picker .rmdp-header-values {
  color: #000 !important;
  font-weight: 700 !important;
  margin: auto;
}

.date_Picker .rmdp-week-day {
  color: #000 !important;
  cursor: default;
  font-size: 12px;
  font-weight: 700;
}

.date_Picker .rmdp-calendar {
  height: max-content;
  padding: 0px;
}

.date_Picker .rmdp-day-picker {
  padding: 0px;
}

.date_Picker .rmdp-day {
  cursor: pointer;
  height: 30px;
  position: relative;
  width: 34px;
}

.date_Picker .rmdp-day span {
  border-radius: 0%;
  bottom: 0px;
  font-size: 13px;
  font-weight: 500;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.date_Picker .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #2e3cb2 !important;
  border-radius: 4px;
  border-color: #2e3cb2 !important;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.date_Picker .rmdp-arrow {
  border: solid #000000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 3px;
  width: 3px;
}

.UpdateCheckbox .checkboxParentClass .ant-checkbox-wrapper-disabled {
  cursor: default !important;
}

.editSelector__control {
  height: 30px !important;
}
body[dir="rtl"] .dataroom_options .dropdown-menu {
  z-index: 100 !important;
  height: 100% !important;
}
.dataroom_options {
  z-index: 100 !important;
}
.dataroom_options .dropdown-menu.show {
  transform: none !important;
  left: auto !important;
  right: 15px !important;
  position: absolute !important;
  z-index: 1050;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 12px !important;
  width: auto !important;
  min-height: max-content;
}

body[dir="rtl"] .dataroom_options .dropdown-menu.show {
  top: 0px !important;
  min-height: max-content;
}

.dataroom_options .dropdown-menu.show .dropdown-item {
  border-bottom: 1px solid #5a5a5a;
  color: #5a5a5a !important;
  font-weight: 600;

  padding: 4px 0px;
}

.dataroom_options
  .dropdown-menu.show
  .dropdown-item:is(:hover, :focus, :focus-visible) {
  background: none !important;
}

.dataroom_options .dropdown-menu.show .dropdown-item:last-child {
  border: none !important;
}

.tablerowFeatures {
  display: none !important;
  visibility: hidden !important;
}

.ant-table-wrapper.DataRoom_Table
  .ant-table-tbody
  > tr
  td:last-child
  .row
  .otherstuff
  .tablerowFeatures {
  display: none !important;
  visibility: hidden !important;
}

.ant-table-wrapper.DataRoom_Table .ant-table-tbody > tr:hover {
  background-color: #f1f1f1 !important;
}

.ant-table-wrapper.DataRoom_Table .ant-table-tbody > tr:nth-child(1) {
  background-color: #f1f1f1 !important;
}

.ant-table-wrapper.DataRoom_Table
  .ant-table-tbody
  > tr:hover
  td:last-child
  .row
  .otherstuff
  .tablerowFeatures {
  display: flex !important;
  visibility: visible !important;
}

abbr[title] {
  text-decoration: none !important;
  border-bottom: 0px !important;
  cursor: default !important;
}

.form-check-input:checked {
  border-radius: none !important;
  border: none;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
  border-radius: none !important;
}

/* timePicker */
.containerResolutionTimePicker {
  width: 100% !important;
}

.containerResolutionTimePicker .rmdp-input {
  height: 39px !important;
  width: 100% !important;
}

.containerResolutionTimePicker .rmdp-time-picker.bottom {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
  width: 188px !important;
  padding: 0px 7px;
}

.containerResolutionTimePicker i.rmdp-arrow {
  border: solid #0074d9 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  height: 3px !important;
  margin-top: 5px !important;
  padding: 4px !important;
  width: 3px !important;
}

.containerResolutionTimePicker input[type="text"] {
  color: #5f78d6 !important;
  width: 100% !important;
  height: 18px;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;

  padding: 10px !important;
}

.containerResolutionTimePicker
  input[type="text"]:is(:focus, :focus-visible, :active, :visited) {
  outline: none !important;
}

.containerResolutionTimePicker .rmdp-time-picker div .rmdp-am {
  color: #5f78d6 !important;
  width: 42px !important;
  height: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;

  padding: 0px !important;
}

.containerClassTimePicker .rmdp-input {
  height: 39px !important;
  width: 100% !important;
}

.containerClassTimePicker .rmdp-time-picker.bottom {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
  width: 188px !important;
  padding: 0px 7px;
}

.containerClassTimePicker i.rmdp-arrow {
  border: solid #0074d9 !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  height: 3px !important;
  margin-top: 5px !important;
  padding: 4px !important;
  width: 3px !important;
}

.inputTIme {
  width: 200px !important;
  height: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  color: #000 !important;
}

.inputTIme:is(:focus, :focus-visible, :active, :visited) {
  border: 1px solid #ccc !important;
  outline: none !important;
  color: #000 !important;
}

.inputTImeMeeting {
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  color: #000 !important;
}

.inputTImeMeeting:is(:focus, :focus-visible, :active, :visited) {
  border: 1px solid #ccc !important;
  outline: none !important;
  color: #000 !important;
}

.containerClassTimePicker input[type="text"] {
  color: #5f78d6 !important;
  width: 100% !important;
  height: 18px;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;

  padding: 10px !important;
}

.containerClassTimePicker
  input[type="text"]:is(:focus, :focus-visible, :active, :visited) {
  outline: none !important;
}

.containerClassTimePicker .rmdp-time-picker div .rmdp-am {
  color: #5f78d6 !important;
  width: 42px !important;
  height: 12px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;

  padding: 0px !important;
}

.resolutionTimePicker {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  width: 100%;
  height: 39px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-resolution .ant-checkbox-disabled .ant-checkbox-input {
  cursor: default !important;
  pointer-events: all;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.todoCreate label .rmdp-container div div .rmdp-input {
  width: 100% !important;
  margin-left: 15px !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgb(255, 255, 255) !important;
  animation-name: none;
}

.resolution-search-input input.form-control {
  font-size: 12px !important;
}

.select_dormant-days__control {
  width: 100% !important;
  max-width: 100% !important;
}

.ReactFlagsSelect-module_label {
  font-weight: 500;

  font-size: 13px !important;
}

.AdvancePermission__placeholder {
  font-size: 12px !important;
}

.AdvancePermission__menu {
  font-size: 12px !important;
}

.form-check-input {
  cursor: pointer !important;
}

.ant-layout {
  background: none !important;
}

.AdvancePermission__control.css-13cymwt-control {
  height: 39px !important;
}

.AdvancePermission__placeholder {
  margin-top: -5px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  margin-left: -29px !important;
}

.AdvancePermission__indicator.AdvancePermission__dropdown-indicator.css-1xc3v61-indicatorContainer {
  margin-top: -5px;
}

.AdvancePermission__single-value.css-1dimb5e-singleValue {
  font-size: 12px;
  margin-top: -3px;
}

.NewMeeting_table .ant-table-thead > tr:last-child {
  color: #fff !important;
}

.ant-table-wrapper.NewMeeting_table .ant-table-thead > tr > th:last-child {
  display: none !important;
  visibility: hidden !important;
}

.SelectOptions_drop__control.css-13cymwt-control {
  border: 1px solid #f16b6b !important;
}

.SelectOptions_drop_active__control.css-13cymwt-control {
  border: 1px solid #e1e1e1 !important;
}

.ProposedMeeting_Checkbox-input {
  width: 15.5px !important;
  height: 15.5px !important;
}

.bg-white.border-radius-4.border {
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  width: 98%;
  margin-right: 0;
}

body[dir="rtl"] .bg-white.border-radius-4.border {
  margin-right: calc(var(--bs-gutter-x) * 0.5);
  width: 98%;
  margin-left: 0;
}

.ant-modal-wrap {
  z-index: 1000000000 !important;
}

.folder_upload_loader-inner {
  width: 25px !important;
  height: 25px !important;
  font-size: 0px !important;
  display: block !important;
}

.group-fields .css-b62m3t-container {
  width: 100% !important;
}

.MuiAccordionSummary-expandIcon {
  width: 4vw !important;
}

/* When click outside time picker will open */

input[type="time"] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
    see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="time"]:after {
  content: "\1F550";
  color: #555;
  margin-left: 12px !important;
  /* padding: 0 5px; */
}

/* change color of symbol on hover */
input[type="time"]:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="time"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type="time"]::-webkit-clear-button {
  z-index: 1;
}

.rmdp-header-values {
  color: #5a5a5a;
  font-weight: 600;

  font-size: calc(19px - 20%);
}

.ProgressUNpublishedMeeting .ant-progress-text {
  display: none !important;
}

.VoteModalAgenda .ant-progress-success-bg,
.ant-progress-bg {
  background-color: #4adede !important;
}

.VoteModalAgenda .ant-progress-outer {
  width: 88% !important;
}

.VoteModalAgenda .ant-progress-text {
  font-size: 20px !important;
  font-weight: 600;

  color: #6172d6 !important;
  margin-top: -25px !important;
}

/* Scedule Proposed meeting */

.border-column:last-child {
  border-bottom: 1px solid #6172d6 !important;
}

.AbstainProgressViewModal .ant-progress-success-bg,
.ant-progress-bg {
  background-color: #f16b6b !important;
}

.AbstainProgressViewModal .ant-progress-outer {
  width: 88% !important;
}

.AbstainProgressViewModal .ant-progress-text {
  font-size: 20px !important;
  font-weight: 600;

  color: #6172d6 !important;
  margin-top: -25px !important;
}

.NOProgressViewModal .ant-progress-text {
  font-size: 20px !important;
  font-weight: 600;

  color: #6172d6 !important;
  margin-top: -25px !important;
}

.NOProgressViewModal .ant-progress-outer {
  width: 88% !important;
}

.NOProgressViewModal .ant-progress-success-bg,
.ant-progress-bg {
  background-color: #6172d6 !important;
}

.input-with-icon {
  padding-right: 30px;
  /* Adjust the padding to position the icon on the right side */
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%707070' class='bi bi-clock' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM7 1.5a.5.5 0 0 0-1 0V8a.5.5 0 0 0 .252.434l5 3a.5.5 0 0 0 .496-.868L7 7.999V1.5z'/%3E%3C/svg%3E");
  /* Clock Icon SVG Data URL */
  background-repeat: no-repeat;
  background-position: right 5px center;
  /* Position icon on the right side */
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 0px 5px !important;
  outline: none !important;
  cursor: pointer !important;
}
body[dir="rtl"] .input-with-icon {
  padding-right: 30px;
  /* Adjust the padding to position the icon on the right side */
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%707070' class='bi bi-clock' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM7 1.5a.5.5 0 0 0-1 0V8a.5.5 0 0 0 .252.434l5 3a.5.5 0 0 0 .496-.868L7 7.999V1.5z'/%3E%3C/svg%3E");
  /* Clock Icon SVG Data URL */
  background-repeat: no-repeat;
  background-position: left 5px center;
  /* Position icon on the right side */
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 0px 5px !important;
  outline: none !important;
  cursor: pointer !important;
}

.input-with-icon:focus,
.input-with-icon:active,
.input-with-icon:focus-visible {
  border-color: #e1e1e1 !important;
  /* Set the border color when focused to match the default color */
}

.ant-table-row-expand-icon-cell {
  width: 20px !important;
  text-align: left !important;
}

.ant-table-wrapper.newMeetingTable .ant-table-tbody tr td {
  padding: 6px 0px !important;
}

.unpublished_meeting-body tr td {
  padding: 6px 0px !important;
}

.newMeetingTable .ant-table-body {
  overflow-x: hidden !important;
}

/* .ant-table-wrapper.newMeetingTable .ant-table-thead tr th:nth-child(1) {
  text-align: left !important;
  padding: 6px 4px !important;
} */

/* .ant-table-expand-icon-col {
  width: 15px !important;
} */

.newMeetingProgressbar .progress-bar {
  background: #6172d6 !important;
  margin: 2px !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-progress .progress-bar {
  background-color: #81db86 !important;
  /* Add any other custom styles you want */
}

/* status Slector TodoList Css */
.custom-class span.ant-select-selection-item {
  margin-left: 12px !important;
  font-weight: 600;
}

.td.ant-table-column-sort {
  background-color: transparent !important;
}

.shareFolderEditor_Selector__control {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.shareFolderEditor_Selector_empty {
  background: #fff !important;
}

.shareFolderEditor_Selector__single-value {
  color: #fff !important;
}

.shareFolderEditor_Selector__dropdown-indicator {
  color: #fff !important;
}

.shareFolderEditor_Selector__option:hover {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.shareFolderEditor_Selector__option--is-focused {
  background: none !important;
  color: #5a5a5a !important;
}

.shareFolderEditor_Selector__option--is-selected {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}
/* .shareFolderEditor_Selector_empty__option--is-selected, */
.shareFolderEditor_Selector_empty__option--is-selected {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.shareFolderEditor_Selector_empty__option--is-focused {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.shareFolderEditor_Selector__placeholder {
  color: #ffffff !important;
}

.ant-pagination-item-active .todolist-pagination-current {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 10px !important;
  border: none !important;
  max-width: 32px !important;
  color: #fff;
  font-size: 1.2rem;
  padding: 5px 10px;
}

body[dir="rtl"] .ant-pagination-item-active .todolist-pagination-current {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.todolist-pagination-current {
  border-radius: 10px !important;
  border: none !important;
  max-width: 32px !important;
  color: #6172d6;
  font-size: 1.2rem;
  padding: 5px 10px;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled.sendNotificationOrganizer {
  cursor: auto !important;
}

.sendNotificationOrganizer
  span.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
  cursor: auto !important;
}

.proposedMeetindatesDatePicker {
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;

  width: auto !important;
}

.discussion-menu {
  display: contents;
}

.newMeetingTable .ant-table .ant-table-container::before,
.newMeetingTable .ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 30px;
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}

.discussion-menu.disabled {
  color: #ccc;
  pointer-events: default !important;
}

.ant-table-wrapper.NewMeeting_table.AgendaVoting .ant-table-body {
  overflow-y: auto !important;
  max-height: 100px !important;
}

.ant-table-cell-scrollbar {
  display: none;
}

.SelectOrganizersSelect_active__menu {
  z-index: 9999999999 !important;
}

.meeting-status-value {
  font-weight: 400 !important;
}

textarea {
  font-size: 12px !important;
  font-weight: 500;

  box-shadow: none !important;
  resize: none !important;
}

.ant-pagination-active-on-Action .todolist-pagination-current {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 0px !important;
  border: none !important;
  max-width: 32px !important;
  color: #fff;
  font-size: 1.2rem;
  padding: 5px 10px;
}

.css-13cymwt-control {
  cursor: pointer !important;
  outline: none !important;
}

.rmdp-time-picker .dvdr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px !important;
  margin-top: 5px !important;
}

.ant-select-arrow {
  margin-top: 0 !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.rmdp-container.containerClassTimePicker div {
  z-index: 1 !important;
}

.file-icon-updateMeeting.col-lg-3.col-md-3.col-sm-12 p {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.file-uploaded-section {
  display: flex;
  flex-wrap: wrap;
}

.MeetingAgendaPresented.MeetingAgendaURL.col-lg-4.col-md-4.col-12 input {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.data-room-file-upload-section.mt-4.row {
  height: 100%;
  overflow: hidden auto !important;
}

.ant-notification {
  z-index: 9999999999 !important;
}

.bottom-20 {
  bottom: 20px;
}

.Polling_main_table {
  height: calc(91vh - 30vh) !important;
}
.Polling_main_table .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
.Polling_table .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
.ToDo .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
.ApprovalsTable .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
.newMeetingTable .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
.DataRoom_Table .ant-table-column-sorters .ant-table-column-sorter {
  display: none !important;
}
button.grayScaled {
  filter: grayscale(0.8);
  background: #ccc !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

button.grayScaled:hover,
button.grayScaled:active,
button.grayScaled:focus,
button.grayScaled:focus-visible button.grayScaled:focus-within {
  filter: grayscale(0.8);
  background: #ccc !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}

th.ant-table-cell.notification-class-table {
  text-align: center !important;
}

/* custom.css */

/* Customize the outer circle of the radio button */
.ant-radio-wrapper {
  font-size: 16px;
  /* Adjust font size as needed */
  color: #333;
  /* Adjust text color as needed */
}

/* Customize the inner circle of the radio button */
.ant-radio-inner {
  background-color: yellow;
  /* Change inner color as needed */
  border-color: yellow;
  /* Change border color as needed */
}

/* Change the color of the radio button label */
.ant-radio-wrapper .ant-radio + span {
  color: #333;
  /* Adjust label text color as needed */
}

.ant-table-wrapper.userlogin_history_tableP .ant-table-tbody tr td {
  font-weight: 600;
}

.textAreaDivVieww.vv {
  padding: 0;
}

.textAreaDivVieww.vv input {
  color: #505050 !important;
  font-weight: 400;
  font-size: 16px !important;
  overflow: hidden;
  display: block;
  width: 100%;
  border: none !important;
  word-wrap: break-word;
}

/* dataroom Searches React select */
.searchResult_Document__control.css-13cymwt-control {
  width: 200px !important;
}
.searchResult_Document__option--is-selected .row .rs-col span {
  color: #fff !important;
}
.searchResult_Document__menu-list.css-1n6sfyn-MenuList {
  width: 200px !important;
}

.searchResult_Document__control--is-focused {
  width: 200px !important;
}

.pakageselectionSpanUsermanagement {
  line-height: 15px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
}

body[dir="rtl"] .Tableheading {
  font-weight: 700 !important;
}

.Billing_document .ant-table table {
  border-spacing: 0 10px !important;
  border-radius: 50px !important;
  table-layout: inherit !important;
  margin-top: -19px !important;
}

.ant-table-wrapper.Billing_document.ant-table .ant-table-thead > tr > th {
  padding: 2px 16px !important;
}

.ant-table-wrapper.Billing_document
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ant-table-wrapper.Billing_document.ant-table .ant-table-thead > tr > th {
  padding: 2px 16px !important;
}

span.subAgendaBorderClass {
  display: block;
  width: 30px;
  background: #e1e1e1;
  height: 1px;
  position: absolute;
  left: -4px;
  top: 17px;
}

body[dir="rtl"] span.subAgendaBorderClass {
  display: block;
  width: 30px;
  background: #e1e1e1;
  height: 1px;
  position: absolute;
  left: auto;
  right: -39px;
  top: 17px;
}

span.separatorSubAgendaWidth {
  display: block;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
  margin-bottom: 25px;
  border-bottom: none !important;
  border-right: none !important;
  margin-left: 35px;
}

body[dir="rtl"] span.separatorSubAgendaWidth {
  display: block;
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
  margin-bottom: 25px;
  border-bottom: none !important;
  border-right: none !important;
  margin-left: 0;
  margin-right: 35px;
}

span.separatorSubAgendaHeight {
  content: "";
  display: block;
  position: absolute;
  height: 100px;
  border: 1px solid #e1e1e1;
  top: -27px;
  left: 10px;
  border-bottom: none;
  border-right: none;
}

body[dir="rtl"] span.separatorSubAgendaHeight {
  content: "";
  display: block;
  position: absolute;
  height: 100px;
  border: 1px solid #e1e1e1;
  top: -27px;
  left: auto;
  right: 0;
  border-bottom: none;
  border-right: none;
}

.borderTopNone {
  border-top: none !important;
}

.allFileModalClass .modal-dialog.modal-md.modal-dialog-centered {
  height: auto !important;
}

.SelectAgendaModal .modal-dialog.modal-sm.modal-dialog-centered {
  height: auto !important;
}

.ShareEmailAgendaModal .modal-dialog.modal-md.modal-dialog-centered {
  height: auto !important;
}

.ParticipantAVModal .modal-dialog.modal-xl.modal-dialog-centered {
  height: auto !important;
}

.PrintModalAgenda .modal-dialog.modal-xl.modal-dialog-centered {
  height: auto !important;
}

.FullScreenModal
  .modal-dialog.modal-dialog-centered.modal-fullscreen
  .modal-content {
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.FullScreenModal.blurEffect
  .modal-dialog.modal-dialog-centered.modal-fullscreen
  .modal-content {
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  filter: blur(25px);
}

.PrintExportScreen
  .modal-dialog.modal-dialog-centered.modal-fullscreen
  .modal-content {
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.PrintExportScreen
  .modal-dialog.modal-dialog-centered.modal-fullscreen
  .modal-content
  .modal-body {
  padding-top: 0 !important;
}

.PrintExportScreen.blurEffect
  .modal-dialog.modal-dialog-centered.modal-fullscreen
  .modal-content {
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  margin: 30px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  filter: blur(25px);
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper {
  display: flex !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  width: 265px !important;
  padding: 7px 10px !important;
  margin: 10px 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper
  span {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #a2a2a2 !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #5a5a5a !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span
  span.ant-radio-inner:after {
  content: none !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  label.ant-radio-wrapper.ant-radio-wrapper-checked {
  display: flex !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  width: 265px !important;
  padding: 7px 10px !important;
  margin: 10px 0px !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup
  .ant-radio-checked
  .ant-radio-inner {
  background: linear-gradient(180deg, #4adede 0%, #6172d6 100%) !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  top: -1px !important;
}

.ant-radio-group.ant-radio-group-outline.AgendaSelectGroup .ant-radio-inner {
  background: #e1e1e1 !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  top: -1px !important;
}

.selectMemberAgendaView__multi-value {
  background-color: #6172d6 !important;
  color: #fff !important;
  border-radius: 50px !important;
}

.selectMemberAgendaView__multi-value__label {
  color: #fff !important;
}

.selectMemberAgendaView__clear-indicator {
  display: none !important;
}

.selectMemberAgendaView__multi-value__remove {
  background: none !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: 50% !important;
}

.selectMemberAgendaView__multi-value__remove:hover {
  background: none !important;
  color: #fff !important;
  padding-right: 10px;
  border-radius: 50% !important;
}

.ParticipantAV {
  overflow-y: auto;
}

.ParticipantAV tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 7px 16px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-weight: 400;

  font-size: 14px !important;
}

.ParticipantAV
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #5a5a5a !important;
}

body[dir="rtl"]
  .ParticipantAV
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
}

.ParticipantAV
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e9e9e9 !important;
  font-size: 14px;
  font-weight: bold;
}

.ParticipantAV .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.ParticipantAVModal .modal-body {
  padding-top: 0 !important;
}

th.ant-table-cell.leaveTimeParticipant {
  text-align: center !important;
}

td.ant-table-cell.leaveTimeParticipant {
  text-align: center !important;
}

th.ant-table-cell.minutesParticipant {
  text-align: center !important;
}

td.ant-table-cell.minutesParticipant {
  text-align: center !important;
}

/* React Stepper Styles */

/* CSS for the Round Button of Stepper */
.custom-stepper .StepButtonContent-d1-0-2-7 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.custom-stepper .StepButtonContent-d3-0-2-13 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.custom-stepper .StepButtonContent-d5-0-2-21 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}
/* CSS for the Round Button of Stepper */
.custom-stepper .StepButtonContent-d7-0-2-27 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.custom-stepper .StepButtonContent-d9-0-2-31 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.custom-stepper .StepButtonContent-d11-0-2-37 {
  color: #fff !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

/* Connector Stepper CSS Starts */
.custom-stepper .Connector-0-2-15 {
  border-color: #e1e1e1 !important;
  border-top-style: dashed !important;
  margin-top: 2px !important;
  margin-left: -24px !important;
  width: 130% !important;
}

/* Customer Stepper Buttons Z index  */

button#RFS-StepButton {
  z-index: 1 !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

/* Specific RTL handling */
body[dir="rtl"] .custom-stepper .Connector-0-2-15 {
  margin-right: -163% !important;
}
/* Connector Stepper CSS Ends */

/* Selecting pakage Details Css Usermangement */
tr.ant-table-row.ant-table-row-level-0.PakageDetailsTable {
  outline: solid;
  outline-color: #e1e1e1;
  outline-width: 1px;
}

tr.ant-table-row.ant-table-row-level-0.PakageDetailsTable:last-child {
  outline: none !important;
  background: none !important;
  border: none !important;
}

tr.ant-table-row.ant-table-row-level-0.PakageDetailsTable:last-child:hover {
  outline: none !important;
  background: none !important;
  border: none !important;
}

.ant-table-tbody > tr.PakageDetailsTable:last-child.ant-table-row:hover > td,
.ant-table-tbody
  > tr.PakageDetailsTable:last-child
  > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr.PakageDetailsTable > td {
  border-bottom: none !important;
}

/* PakageDetails Table Css usermanagement */
tr.ant-table-row.ant-table-row-level-0.PakageDetails {
  outline: solid;
  outline-color: #e1e1e1;
  outline-width: 1px;
  border-radius: 4px !important;
  background: none !important;
}

tr.ant-table-row.ant-table-row-level-0.PakageDetails:last-child {
  outline: none !important;
  background: #e1e1e1 !important;
  border-radius: 4px !important;
  border: none !important;
}

tr.ant-table-row.ant-table-row-level-0.PakageDetails:last-child:hover {
  outline: none !important;
  border-radius: 4px !important;
  background: #e1e1e1 !important;
  border: none !important;
}

.ant-table-tbody > tr.PakageDetails:last-child.ant-table-row:hover > td,
.ant-table-tbody > tr.PakageDetails:last-child > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr.PakageDetails > td {
  border-bottom: none !important;
}

/* pakageDetails UpgradeUsermanagement */

tr.ant-table-row.ant-table-row-level-0.UpgradePakageDetails {
  outline: solid;
  outline-color: #e1e1e1;
  outline-width: 1px;
  border-radius: 4px !important;
  background: none !important;
}

tr.ant-table-row.ant-table-row-level-0.UpgradePakageDetails:last-child {
  outline: none !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}

tr.ant-table-row.ant-table-row-level-0.UpgradePakageDetails:last-child:hover {
  outline: none !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  border: none !important;
}

.ant-table-tbody > tr.UpgradePakageDetails:last-child.ant-table-row:hover > td,
.ant-table-tbody
  > tr.UpgradePakageDetails:last-child
  > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr.UpgradePakageDetails > td {
  border-bottom: none !important;
}

/* this radiobutton is for polls vote start */
.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper {
  display: flex !important;
  background: #ffffff !important;
  padding: 0px 4px !important;
  margin: 10px 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 50% !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper
  span {
  font-style: normal !important;
  font-weight: 500 !important;
  color: #a2a2a2 !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span {
  font-weight: 500;

  font-style: normal !important;
  /* font-weight: 600 !important; */
  color: #5a5a5a !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span
  span.ant-radio-inner:after {
  content: none !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect
  .ant-radio-checked
  .ant-radio-inner {
  background: #6172d6 !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 13px !important;
  height: 13px !important;
  top: 0px !important;
}

.ant-radio-group.ant-radio-group-outline.AnotherRadioSelect .ant-radio-inner {
  background: #e1e1e1 !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 13px !important;
  height: 13px !important;
  top: 0px !important;
}

/* this radiobutton is for polls vote end */
.width-60 {
  width: 60% !important;
}

.DataRoom_Table thead.ant-table-thead {
  position: sticky !important;
  z-index: 1 !important;
  background-color: #ffffff !important;
  top: 0 !important;
}

.title_tooltip:hover::after {
  content: attr(title);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  /* Ensure tooltip appears on top of other elements */
}

.title_tooltip {
  border-radius: 50%;
}

.border-none.dropdown button {
  border: none !important;
}

a.pendingApprovalsNav.nav-link {
  padding: 0 !important;
  color: #505050 !important;
}

.PendingApprovalsTable .ant-table-thead > tr * {
  font-weight: 700 !important;
  /* padding-bottom: 5px !important; */
  font-size: 16px !important;
  color: #5f78d6 !important;
}

.PendingApprovalsTable {
  margin-bottom: 50px;
  overflow-y: auto;
}

.PendingApprovalsTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell {
  padding: 10px 16px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
}

.PendingApprovalsTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 15px;
  color: #6172d6 !important;
}

body[dir="rtl"]
  .PendingApprovalsTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
}

.PendingApprovalsTable
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e9e9e9 !important;
  font-size: 14px;
  font-weight: bold;
}

.PendingApprovalsTable .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

th.ant-table-cell.statusParticipant .ant-table-filter-column {
  border: none !important;
  padding-bottom: 0 !important;
}

th.ant-table-cell.statusParticipant span.ant-table-column-title {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

th.ant-table-cell.statusParticipant
  span.ant-dropdown-trigger.ant-table-filter-trigger {
  border-bottom: none !important;
}

th.ant-table-cell.statusParticipant
  svg.bi.bi-chevron-down.filter-chevron-icon-todolist {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.PendingApprovalsTable
  tr.ant-table-row.ant-table-row-level-0.last-row-class
  td.ant-table-cell {
  border-bottom: none !important;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mxl-50 {
  margin-left: 50px !important;
}

.mxr-50 {
  margin-right: 50px !important;
}

.fade.DeleteCommentModal.modal.show
  .modal-dialog.modal-md.modal-dialog-centered {
  width: 405px !important;
  justify-content: center !important;
  max-width: 405px !important;
  min-width: 405px !important;
}

.d-block.loader-zindex .videoCallScreen {
  z-index: 99999999999 !important;
}

.PendingApprovalsTable .ant-table-thead > tr > th {
  border-bottom: 1px solid #5a5a5a !important;
}

.ant-table-wrapper.PendingApprovalsTable
  .ant-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter {
  display: none;
}

.fade.DeleteCommentModal.modal.show .modal-content {
  border: 1px solid #e1e1e1 !important;
  border-radius: 3px;
}
.ApprovalsTable .ant-table-thead > tr * {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #5f78d6 !important;
  padding: 2px 0px;
}
.ApprovalsTable {
  margin-bottom: 50px;
  overflow-y: auto;
}

.ApprovalsTable tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 10px 16px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
}

.ApprovalsTable {
  margin-bottom: 50px;
  overflow-y: auto;
}

.ApprovalsTable tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 10px 16px !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
}

.ApprovalsTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 15px;
  color: #6172d6 !important;
}

body[dir="rtl"]
  .ApprovalsTable
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-top: none !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: none !important;
}

.ApprovalsTable
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #e9e9e9 !important;
  font-size: 14px;
  font-weight: bold;
}

.ApprovalsTable .ant-table-tbody > tr > td {
  border-bottom: none !important;
}
.ApprovalsTable
  tr.ant-table-row.ant-table-row-level-0.last-row-class
  td.ant-table-cell {
  border-bottom: none !important;
}
/* BoardDeck Css */

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  label.ant-radio-wrapper
  span {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #a2a2a2 !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #5a5a5a !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  label.ant-radio-wrapper
  input {
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  label.ant-radio-wrapper.ant-radio-wrapper-checked
  span
  span.ant-radio-inner:after {
  content: none !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection
  .ant-radio-checked
  .ant-radio-inner {
  background: linear-gradient(180deg, #4adede 0%, #6172d6 100%) !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  top: -1px !important;
}

.ant-radio-group.ant-radio-group-outline.BoarddeckSelection .ant-radio-inner {
  background: #e1e1e1 !important;
  transform: rotate(90deg) !important;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  top: -1px !important;
}
/* BoardDeck Ends */

/* Responsiveness CancelSubscription starts*/
.Billing_TableContainer {
  overflow-x: auto; /* Ensures the table can scroll horizontally if it overflows */
  width: 100%; /* Ensures the container takes the full width */
}

@media (max-width: 1024px) {
  .CardCancelSubscription {
    padding: 10px 20px !important; /* Reduce padding for smaller screens */
  }

  .Billing_TablePakageSelection {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .CardCancelSubscription {
    padding: 10px 10px !important; /* Further reduce padding for smaller screens */
  }

  .Billing_TablePakageSelection {
    font-size: 12px; /* Further adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .CardCancelSubscription {
    padding: 5px 5px !important; /* Further reduce padding for very small screens */
  }

  .Billing_TablePakageSelection {
    font-size: 10px; /* Further adjust font size for very small screens */
  }
}

/* Responsiveness CancelSubscription Ends*/
.ant-table-thead th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th.ant-table-cell.leaveTimeParticipant.ant-table-cell-ellipsis.ant-table-column-has-sorters {
  display: flex !important;
  margin-top: 1px !important;
}

.w-91 {
  width: 91%;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.scrollStyle::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.scrollStyle::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.mt-16p {
  margin-top: 16px !important;
}

.mt-16n {
  margin-top: -16px !important;
}

/* Responsiveness CancelSubscription Ends*/
.ant-table-thead th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datepickerlength .rmdp-container {
  width: 96%;
}

.datepickerlength .rmdp-container svg {
  color: #f16b6b !important;
}

span.text-truncate.description {
  font-weight: 500;
  font-size: 12px;
  display: block;
  color: #5a5a5a;
}

span.text-truncate.description p {
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* SubscriptionExpiry Table Styles */
tr.ant-table-row.ant-table-row-level-0.SubscriptionExpiry {
  border-radius: 4px !important;
  background: #fff !important;
}

tr.ant-table-row.ant-table-row-level-0.SubscriptionExpiry:last-child {
  outline: none !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}

tr.ant-table-row.ant-table-row-level-0.SubscriptionExpiry:last-child:hover {
  outline: none !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  border: none !important;
}

.ant-table-tbody > tr.SubscriptionExpiry:last-child.ant-table-row:hover > td,
.ant-table-tbody
  > tr.SubscriptionExpiry:last-child
  > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr.SubscriptionExpiry > td {
  border-bottom: none !important;
}

.ant-table-wrapper.SubscriptionExpiryTableStyles
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ant-table-wrapper.SubscriptionExpiryTableStyles .ant-table {
  background: none !important;
}

.SubscriptionExpiryTableStyles .ant-table table {
  border-spacing: 0px 9px !important;
  table-layout: fixed !important;
  background: transparent !important;
}

.SubscriptionExpiryTableStyles .ant-table table .ant-table-thead > tr > th {
  background: transparent !important;
  border-bottom: none !important;
}

body[dir="rtl"] .ant-table-wrapper.SubscriptionExpiryTableStyles {
  padding-left: 10px;
  padding-right: 0px;
}

.SubscriptionExpiryTableStyles::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.SubscriptionExpiryTableStyles::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.SubscriptionExpiryTableStyles {
  overflow-y: auto !important;
}

.SubscriptionExpiryTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell {
  padding: 10px 10px !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #505050 !important;
}

.SubscriptionExpiryTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

body[dir="rtl"]
  .SubscriptionExpiryTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border-left: 0px !important;
}

/* DownGrade Subscription Table */

tr.ant-table-row.ant-table-row-level-0.DownGradeSubscription {
  border-radius: 4px !important;
  background: #fff !important;
}

tr.ant-table-row.ant-table-row-level-0.DownGradeSubscription:last-child {
  outline: none !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}

tr.ant-table-row.ant-table-row-level-0.DownGradeSubscription:last-child:hover {
  outline: none !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  border: none !important;
}

.ant-table-tbody > tr.DownGradeSubscription:last-child.ant-table-row:hover > td,
.ant-table-tbody
  > tr.DownGradeSubscription:last-child
  > td.ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-table-tbody > tr.DownGradeSubscription > td {
  border-bottom: none !important;
}

.ant-table-wrapper.DowngradeTableStyles
  .ant-table
  .ant-table-container
  .ant-table-content
  thead.ant-table-thead {
  background: none !important;
}

.ant-table-wrapper.DowngradeTableStyles .ant-table {
  background: none !important;
}

.DowngradeTableStyles .ant-table table {
  border-spacing: 0px 9px !important;
  table-layout: inherit !important;
  background: transparent !important;
}

.DowngradeTableStyles .ant-table table .ant-table-thead > tr > th {
  background: transparent !important;
  border-bottom: none !important;
}

body[dir="rtl"] .ant-table-wrapper.DowngradeTableStyles {
  padding-left: 10px;
  padding-right: 0px;
}

.DowngradeTableStyles::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.DowngradeTableStyles::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.DowngradeTableStyles {
  overflow-y: auto !important;
}

.DowngradeTableStyles tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell {
  padding: 10px 10px !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  color: #505050 !important;
}

.DowngradeTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-left: 1px solid #e1e1e1 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.DowngradeTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:last-child {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

body[dir="rtl"]
  .DowngradeTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:last-child {
  border-left: 1px solid #e1e1e1 !important;
  border-right: none !important;
  border-radius: 0px !important;
}

body[dir="rtl"]
  .DowngradeTableStyles
  tr.ant-table-row.ant-table-row-level-0
  td.ant-table-cell:nth-child(1) {
  border-right: 1px solid #e1e1e1 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 4px !important;
  border-left: 0px !important;
}

.SignatoriesList .ant-table-thead > tr > th:nth-child(1) span {
  color: #55ce5c !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.SignatoriesList .ant-table-thead > tr > th:nth-child(2) span {
  color: #f16b6b !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.SignatoriesList .ant-table-thead > tr > th:nth-child(3) span {
  color: #d8a709 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
th.Tasks_newDashboardTable__L1Rc2-cell.titleDashboard.Tasks_newDashboardTable__L1Rc2-cell-ellipsis {
  color: #6127d6 !important;
  font-size: 12px;
  font-weight: 500;
}
th.Tasks_newDashboardTable__L1Rc2-cell.deadlineDashboard {
  color: #6127d6 !important;
  font-size: 12px;
  font-weight: 500;
}
th.Tasks_newDashboardTable__L1Rc2-cell.statusDashboard {
  color: #6127d6 !important;
  font-size: 12px;
  font-weight: 500;
}
.emptystatereviewers {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Customer information */
input.CustomerInformation_formcontrol-ContactName-field_disabled__10CfD.form-control {
  font-size: 13px !important;
}

input.CustomerInformation_formcontrol-ZipCode-field_disabled__21oSQ.form-control {
  font-size: 13px !important;
}

input.CustomerInformation_formcontrol-City-field_disabled__yIYuo.form-control {
  font-size: 13px !important;
}

input.CustomerInformation_formcontrol-State-field_disabled__JVh83.form-control {
  font-size: 13px !important;
}

input.CustomerInformation_formcontrol-Addresstwo-field_disabled__HYn7E.form-control {
  font-size: 13px !important;
}

input.CustomerInformation_formcontrol-Addressone-field_disabled__rG6HS.form-control {
  font-size: 13px !important;
}

.removeEditors .ql-toolbar.ql-snow {
  display: none !important;
}

.removeEditors .ql-container.ql-snow {
  height: 156px !important;
  border-radius: 4px !important;
  border-color: #e1e1e1 !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
  border-top: 1px solid #ccc !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  z-index: 0 !important;
}

::marker {
  color: #5a5a5a !important;
}

.fade.AllReviewedModal.modal.show .modal-content {
  border: 1px solid #e1e1e1 !important;
  border-radius: 3px;
  min-width: 600px !important;
  max-width: 600px !important;
  padding: 0px 60px;
}
.Polls_Meeting--is-disabled {
  width: 100%;
}

body.guest-video-call {
  overflow: hidden;
  background: #fff !important;
}

.MobilePopUpModal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(35%);
  z-index: 1000;
}

@media screen and (max-width: 767px) {
  .MobilePopUpModal {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .MobilePopUpModal {
    width: 100%;
  }
}

.MobilePopUpModal .modal-content {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
}

.menu-flags button#rfs-btn {
  height: 38px !important;
}
.MQTT_Notification {
  width: 100% !important;
  padding: 23px 15px !important;
  height: auto !important;
  display: flex !important;
  align-items: center !important;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16) !important;
  overflow: visible !important;
  z-index: 0;
  border-radius: 4px !important;
}
.MQTT_Notification::before {
  content: "";
  position: absolute;
  width: 20px !important;
  left: -15px;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  border-top-left-radius: 4px !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-top-left-radius: 4px !important;
}

body[dir="rtl"] .MQTT_Notification::before {
  content: "";
  position: absolute;
  width: 20px !important;
  right: -15px;
  left: auto;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 0px !important;
}

.MQTT_Notification .ant-notification-notice-close span svg {
  color: #5a5a5a !important;
}

.MQTT_Notification span.ant-notification-notice-icon img {
  width: 38px !important;
}

/* .MQTT_Notification
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  width: 100% !important;
  display: flex !important;
  font-size: 14px !important;
  justify-content: flex-start;
  align-items: center !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  line-height: 1.2 !important
  ;
} */
.MQTT_Notification .ant-notification-notice-message {
  display: none !important;
}
.MQTT_Notification .ant-notification-notice-description {
  word-wrap: break-word;
  text-overflow: unset;
  display: block;
  min-width: 400px !important;
  margin-right: 40px !important;
  margin-left: 60px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}
body[dir="rtl"] .MQTT_Notification .ant-notification-notice-description {
  word-wrap: break-word;
  text-overflow: unset;
  display: block;
  min-width: 425px !important;
  margin-right: 60px !important;
  margin-left: 20px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}
.MQTT_Notification .ant-notification-notice-with-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.MQTT_Notification .ant-notification-notice-close {
  top: 25px !important;
}
body[dir="rtl"] .MQTT_Notification .ant-notification-notice-close {
  top: 25px !important;
}

/* Scedule a meeting  Starts  */
.SceduleMeetingButton .dropdown-tggle::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
  background-color: #fff !important;
}

.SceduleMeetingButton .dropdown-toggle::after {
  display: none !important;
}

.SceduleMeetingButton button {
  width: 220px;
  height: 31px;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex;
  justify-content: space-between;
}

body[dir="rtl"] .SceduleMeetingButton button {
  font-weight: 600;
}

.SceduleMeetingButton .dropdown-toggle.btn.btn-primary {
  height: 31px !important;
  font-size: calc(14px - 20%) !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600;
  border: none !important;
  display: flex;
  justify-content: center;
}

body[dir="rtl"] .SceduleMeetingButton .dropdown-toggle.btn.btn-primary {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.SceduleMeetingButton .dropdown-menu.show {
  padding: 0 !important;
  width: 220px;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translate(0px, 45px);
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .SceduleMeetingButton .dropdown-menu.show {
  padding: 0 !important;
  width: 100% !important;
  padding: 0 10px !important;
  margin: 0 !important;
  transform: translateY(0px, 45px) !important;
  border-width: 0px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item {
  padding: 10px 0px;
  font-weight: 600;

  text-align: center;
  font-size: 11px !important;
}

body[dir="rtl"] .SceduleMeetingButton .dropdown-menu.show .dropdown-item {
  font-weight: 600;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item:nth-child(2) {
  border-bottom: 1px solid #e1e1e1;
}
.SceduleMeetingButton .dropdown-menu.show .dropdown-item:nth-child(3) {
  border-bottom: 1px solid #e1e1e1;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item:hover {
  background: none !important;
  color: #5a5a5a !important;
}

.SceduleMeetingButton .dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item {
  padding: 10px 10px;
  font-weight: 600;

  text-align: center;
  font-size: 11px !important;
}

body[dir="rtl"] .SceduleMeetingButton .dropdown-menu.show .dropdown-item {
  font-weight: 600;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.SceduleMeetingButton .dropdown-menu.show .dropdown-item:hover {
  background: none !important;
}

.add-event .dropdown-item {
  width: 170px;
  padding: 10px 5px;
}

.add-event .dropdown-item {
  width: 190px;
  padding: 10px 5px;
}

.add-event .dropdown-item {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 !important;
  font-weight: 600;
}

.schedule_button {
  font-size: calc(14px - 20%);
}

body[dir="rtl"] .schedule_button {
  margin-right: 17px !important;
}

body[dir="rtl"] .SceduleMeetingButton .dropdown-menu.show {
  top: 0px !important;
}
/* Scedule a meeting  Ends  */

/* Menu Polls  Status Starts */
.menu-text {
  flex: 1;
  margin-left: 8px;
}

body[dir="rtl"] .menu-text {
  margin-right: 8px !important;
}

.checkmark {
  color: black; /* Adjust color as needed */
  font-weight: bold;
}

.userstatus-signal-PublishedPolls_Menu {
  background: #6de595 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block !important;
}

.userstatus-signal-Unpublished_Menu {
  background: #949494 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block !important;
}

.userstatus-signal-disabled_Menu {
  background: #f16b6b !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block !important;
}

.checkmark {
  margin-left: 10px !important;
}

.Filter_polls span {
  margin-right: 30px !important;
}

.Polls_Menu_items {
  width: 135px !important;
}
/* Menu Polls  Status Ends */

.Confirmationmodal_body_text_meeting_update {
  padding: 0 20px !important;
  text-align: center !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
  font-size: 16px !important;
  margin-top: 10px 0 !important;
}

.Confirmationmodal_cancel_btn_meeting_update_ {
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  color: #5a5a5a !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.Confirmationmodal_close_btn_meeting_update_ {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
body[dir="rtl"] .Confirmationmodal_close_btn_meeting_update_ {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
td.ant-table-column-sort {
  background: none !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none !important;
}

/* Antd Table Filter Hover Class  */
.ant-table-filter-trigger:hover {
  background: none !important;
}

/* Prevent animation or conflicting positioning */
.google-visualization-tooltip {
  position: absolute !important; /* Ensure absolute positioning */
  right: 0 !important; /* Disable animations */
}

/* Auto Close Resolution Select Dropdown in Organization Admin Configurations */
.select_dormant-days__control {
  min-width: 80px !important;
}
.ant-tooltip-inner {
  border-radius: 4px !important;
}
.ant-tooltip {
  max-width: 100% !important;
}