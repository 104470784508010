.EmailVerifyBox_sendmailwithdevice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 270px !important;
}

.Next_button_EmailVerifySendEmailRealme {
  width: 100%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}

.Next_button_EmailVerifySendEmailRealme:focus-visible {
  width: 100%;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}
.Next_button_EmailVerifySendEmailRealme:disabled {
  filter: grayscale(0.5);
  background-color: #6172d6 !important;
}

body[dir="rtl"] .Next_button_EmailVerifySendEmailRealme {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.Send_Email_Realme_sendmailwithdevice {
  padding: 40px 30px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 469px !important;
  width: 330px !important;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}

.forogt_email_link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
  font-weight: 400 !important;
}

.forogt_email_link a {
  color: #5a5a5a !important;
}

body[dir="rtl"] .forogt_email_link a {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.EmailBoxSendRealme {
  margin-left: 1rem;
  margin-top: 2rem;
}
.FAsendEmailRealme {
  margin-left: 0.1rem;
}

.SelectLine {
  color: #5a5a5a;
  font-weight: 600 !important;
  margin-bottom: 28px;
}

body[dir="rtl"] .SelectLine {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.VerifyHeading_sendmailwithdevice {
  color: #5a5a5a;
  margin-top: 3rem;
  font-weight: 600 !important;
  font-size: calc(35px - 20%) !important;
  margin-bottom: 0px !important;
}
body[dir="rtl"] .VerifyHeading_sendmailwithdevice {
  font-weight: 600 !important;
  font-size: calc(35px - 10%) !important;
}
.Auth_Icon1SendEmailRealme {
  position: fixed;
  right: -315px;
  bottom: -360px;
  transform: rotate(285deg);
  overflow: hidden !important;
  width: 50rem;
}

body[dir="rtl"] .Auth_Icon1SendEmailRealme {
  position: absolute;
  left: -266px !important;
  bottom: -300px;
  right: 50px;
  transform: rotate(34deg);
}

.phone-image {
  position: absolute;
  top: 130px;
  right: -200px;
  z-index: 1;
  user-select: none;
  width: 27.125rem;
}

body[dir="rtl"] .phone-image {
  position: absolute;
  top: 19%;
  right: 222px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-imageSendEmailRealme {
  position: absolute;
  right: -250px;
  bottom: -220px;
  transform: rotate(-66deg);
}

.SendRealmeXtraZoomColor {
  color: #5a5a5a;
  font-weight: 600 !important;
}
body[dir="rtl"] .SendRealmeXtraZoomColor {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.SendRealmeXtraZoomColor_active {
  color: #e1e1e1 !important;
  font-weight: 600 !important;
}
body[dir="rtl"] .SendRealmeXtraZoomColor_active {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.faSendEmailRealmeXtra {
  accent-color: red;
}

.Sendemailreal-language {
  position: absolute;
  overflow: hidden !important;
  top: 50px;
  right: 150px;
  padding: 8px 40px !important;
  z-index: 9999;
  border-color: rgb(64, 64, 64) !important;
  box-shadow: none !important;
  z-index: 1;
}

.Sendemailreal-language,
.Sendemailreal-language:focus-within,
.Sendemailreal-language:focus-visible {
  border-color: rgb(133, 133, 133) !important;
  box-shadow: none !important;
  outline: none !important;
}

.select-language-signin_sendmailwithdevice {
  position: absolute;
  overflow: hidden !important;
  top: 30px;
  right: 150px;
  padding: 0 50px !important;
  z-index: 9999;
  height: 2.9em !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  border-radius: 4px;
  outline: none !important;
}
body[dir="rtl"] .select-language-signin_sendmailwithdevice {
  right: 80%;
}
.language_options {
  -webkit-appearance: none !important;
  -mox-appearance: none !important;
  appearance: none !important;
  padding: 20px;
}

.select-language-signin:focus,
.select-language-signin:focus-within,
.select-language-signin:focus-visible {
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.languageIcon_sendmailwithdevice {
  z-index: 99999 !important;
  position: absolute;
  right: 295px;
  top: 38px;
  width: 25px;
  height: 25px;
}

body[dir="rtl"] .languageIcon_sendmailwithdevice {
  z-index: 99999 !important;
  position: absolute;
  right: 80.8%;
  top: 37px;
  width: 25px;
  height: 25px;
}

.auth_container {
  position: relative;
}

.auth_container.container-fluid {
  overflow: hidden;
}

.languageSelector {
  position: absolute;
  right: 100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
}

body[dir="rtl"] .languageSelector {
  position: absolute;
  right: -100px;
  z-index: 99999 !important;
  margin-top: 50px;
  margin-right: 0 !important;
  display: flex;
  justify-content: flex-end;
}
