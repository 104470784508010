.Add_organization {
  font-size: 20px !important;
  color: #5f78d6 !important;
  font-weight: 600 !important;
}

.Cross_Icon_Styling {
  cursor: pointer !important;
}

.Profile {
  border-radius: 50% !important;
}

.ADD_Organizers {
  padding: 12px 30px !important;
  border: none;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #5f78d6;
  color: #fff !important;
}

.ADD_Organizers:hover,
.ADD_Organizers:active,
.ADD_Organizers:focus,
.ADD_Organizers:focus-visible {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.remove_Organizers {
  width: 90px;
  height: 29px !important;
  border: 1px solid #f16b6b;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #fff;
  color: #f16b6b !important;
}

.remove_Organizers:hover,
.remove_Organizers:active,
.remove_Organizers:focus,
.remove_Organizers:focus-visible {
  width: 90px;
  height: 29px !important;
  border: 1px solid #f16b6b;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #fff;
  color: #f16b6b !important;
}

.Done_btn_organizor_modal {
  padding: 12px 30px !important;
  border: none;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #5f78d6;
  color: #fff !important;
}

.Done_btn_organizor_modal:active,
.Done_btn_organizor_modal:hover,
.Done_btn_organizor_modal:focus,
.Done_btn_organizor_modal:focus-visible {
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #5f78d6 !important;
  color: #fff !important;
}

.OverAll_styling {
  padding: 0px 40px !important;
}

.Card_border2 {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  margin-left: 0px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.Name_cards {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
  margin-left: 5px !important;
}

.Scroller_For_CreatePollModal2 {
  height: 14vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-top: 20px !important;
}

.Scroller_For_CreatePollModal2::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_For_CreatePollModal2::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Padding_Class {
  padding-right: 20px !important;
}

.ADD_Btn_CreatePool_Modal {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff;
  border: none;
  border-radius: 4px !important;
}

.ADD_Btn_CreatePool_Modal:hover,
.ADD_Btn_CreatePool_Modal:active,
.ADD_Btn_CreatePool_Modal:focus,
.ADD_Btn_CreatePool_Modal:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
}
