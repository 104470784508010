.Upload_options_Heading {
  font-size: 30px !important;
  color: #6172d6;
  font-weight: 600;
}

.paragrapgh {
  font-size: 16px !important;
  color: #5a5a5a;
  overflow: hidden;
  word-wrap: break-word;
  font-weight: 600;
}

.paragraph_fileName {
  font-size: 16px !important;
  color: #6172d6;
  font-weight: 600;
  margin: 0 2px !important;
}

.Options {
  font-size: 16px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Cancel_button_UploadFile {
  background: #fff;
  border: 1px solid #e1e1e1;
  color: #5a5a5a;
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
}

.Cancel_button_UploadFile:focus,
.Cancel_button_UploadFile:active,
.Cancel_button_UploadFile:hover,
.Cancel_button_UploadFile:focus-visible {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
}

.Create_button_UploadFile {
  background: #6172d6;
  color: #fff;
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  border: none;
}

.Create_button_UploadFile:active,
.Create_button_UploadFile:focus,
.Create_button_UploadFile:hover,
.Create_button_UploadFile:focus-visible {
  color: #fff !important;
  padding: 12px 30px !important;
  background: #6172d6 !important;
  font-weight: 600;
  font-size: 12px !important;
  border: none !important;
}

.dataroomUploadCheckBox .form-check-input:checked[type="radio"] {
  background-image: none !important;
}