.PaymentMethodHeading {
  font-size: 30px !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .PaymentMethodHeading {
  font-weight: 700 !important;
}

.PaymentHiistoryCardStyling {
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 50px 90px !important;
}

.nameStyles {
  font-size: 10px !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
}

body[dir="rtl"] span.billing-address {
  font-weight: 600 !important;
}

.BackButtonPaymentMethod {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .BackButtonPaymentMethod {
  font-weight: 600 !important;
}

.BackButtonPaymentMethod:is(:hover, :focus, :focus-visible, :active) {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ConfirmBillingDetails {
  background: #6172d6 !important;
  color: #fff !important;
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .ConfirmBillingDetails {
  font-weight: 600 !important;
}

.ConfirmBillingDetails:is(:hover, :focus, :focus-visible, :active) {
  background: #6172d6 !important;
  color: #fff !important;
  padding: 12px 30px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ContainerStylesPaymentHistory {
  padding: 20px 0px !important;
}

.errorMessage {
  margin: 4px;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

body[dir="rtl"] .errorMessage {
  font-weight: 400 !important;
}

.errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

body[dir="rtl"] .errorMessage_hidden {
  font-weight: 400 !important;
}
