.allMeeting-cloumn-row {
  position: relative !important;
  /* transition: opacity 0.3s; */
  overflow: hidden !important;
  padding: 10px !important;
  background: #fff !important;
  border-radius: 4px !important;
  margin-top: 20px !important;
  height: 61vh;
}

.btnAllmeeting-Reset {
  width: 120px !important;
  height: 29px !important;
  border: none;
  border-radius: 2px;
  background-color: #076db7;
}

.searchbar-Meeting-textfield {
  padding: 0 0px 0px 0px;
  position: relative !important;
  margin-top: 6px;
}

.MeetingfilterModal {
  position: absolute;
  top: -3px;
  right: 30px;
  font-size: 21px;
  cursor: pointer;
}

body[dir="rtl"] .MeetingfilterModal {
  top: -1px;
  right: auto;
  left: 30px;
  cursor: pointer;
}

.btnMeetingReset {
  width: 120px !important;
  margin-right: -10px !important;
  height: 35px !important;
  border: none;
  border-radius: 1px;
  background-color: rgb(117, 117, 117);
  margin-bottom: 30px !important;
}

.btnMeetingReset:hover {
  background-color: rgb(232, 232, 232) !important;
  color: rgb(8, 8, 8) !important;
  border: none !important;
}

.Meeting-Main-Heading {
  /* margin-top: -5px; */
  color: #6172d6;
  font-size: 30px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .Meeting-Main-Heading {
  font-weight: 600 !important;
}

.Meeting-modal-container {
  padding: 10px !important;
}

.Meeting-label-heading {
  font-weight: 600 !important;
  font-size: 35px !important;
  color: #6172d6 !important;
  margin-top: -25px;
  margin-left: 15px;
}

.Meeting-Name-label {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600 !important;
  color: #5a5a5a;
  opacity: 0.5;
}

.formcontrol-names-fields-Meeting {
  margin-top: 20px;
  width: 100% !important;
  height: 33px !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  background: none !important;
  border: none !important;
  font-weight: 400 !important;
  color: #000 !important;
  opacity: 0.5 !important;
}

.selectbox-Meeting-organizationrole {
  margin-top: 10px !important;
  width: 140px !important;
}

.Meeting-Update-Btn {
  margin: 20px 0;
  width: 200px !important;
  height: 44px !important;
  border: none;
  border-radius: 0px;
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
  border-radius: 4px;
  margin-right: 22px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.Meeting-Update-Btn:hover,
.Meeting-Update-Btn:focus,
.Meeting-Update-Btn:active {
  margin: 20px 0;
  width: 200px !important;
  height: 44px !important;
  border: none;
  border-radius: 0px;
  background-color: #6172d6 !important;
  border-color: #6172d6 !important;
  border-radius: 4px;
  margin-right: 22px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.formcontrol-fieldfor-filtermodalmeeting {
  margin-top: 20px;
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
}

body[dir="rtl"] .formcontrol-fieldfor-filtermodalmeeting {
  font-weight: 500 !important;
}

.formcontrol-fieldfor-filtermodalmeeting:focus {
  border: 1px solid #e1e1e1;
}

.formcontrol-Date-filtermodalmeeting {
  margin-top: 5px;
  width: 100% !important;
  height: 30px !important;
  border-radius: 2px !important;
}

.formcontrol-fieldselectfor-filtermodalmeeting {
  border-radius: 4px !important;
  margin-top: 19px !important;
  width: 100% !important;
}

body[dir="rtl"] .formcontrol-fieldselectfor-filtermodalmeeting {
  font-weight: 400 !important;
}

.icon-modalmeeting-ResetBtn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #5a5a5a !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

body[dir="rtl"] .icon-modalmeeting-ResetBtn {
  font-weight: 500 !important;
}

.icon-modalmeeting-ResetBtn:hover,
.icon-modalmeeting-ResetBtn:focus,
.icon-modalmeeting-ResetBtn:active {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #5a5a5a !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.icon-modalmeeting-confirm {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.icon-modalmeeting-confirm:hover,
.icon-modalmeeting-confirm:focus,
.icon-modalmeeting-confirm:active {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6 !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.icon-modalmeeting-SearchBtn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 0 !important;
  font-weight: 500 !important;
  font-size: 12px;
}

body[dir="rtl"] .icon-modalmeeting-SearchBtn {
  font-weight: 500 !important;
}

.icon-modalmeeting-SearchBtn:hover,
.icon-modalmeeting-SearchBtn:focus,
.icon-modalmeeting-SearchBtn:active,
.icon-modalmeeting-SearchBtn:focus-visible {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6 !important;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 15px;
  font-weight: 500 !important;
  font-size: 12px;
}

.delete-modal-title {
  font-size: 19px;
  font-weight: 600;
  text-align: center !important;
  margin: 0;
}

.meeting-title {
  width: 180px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  margin: 0;
  font-weight: 600 !important;
  color: #505050;
}

body[dir="rtl"] .meeting-title {
  font-weight: 600 !important;
}

.agenda-title {
  width: 110px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  margin: 0;
}

body[dir="rtl"] .agenda-title {
  font-weight: 400 !important;
}

p.Status-Name-label {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600 !important;
  color: #5a5a5a;
}

/* .selectbox-Meeting-organizationrole ul:-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.selectbox-Meeting-organizationrole ul::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
} */

.PaginationStyle-AllMeeting {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-AllMeeting:is(:focus-visible) {
  outline: none !important;
}
