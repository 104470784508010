.UpgradePackageCard {
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 10px;
  position: relative;
}

.lineBar {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  right: 0;
}

.UpgradeYourPackageTitle {
  color: #6172d6 !important;
  text-align: center;
  margin-bottom: 10px;
  font-size: calc(38px - 50%) !important;
  font-weight: 600 !important;
}

.packageDetails {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none !important;
  width: 70%;
  color: #fff;
  margin: 40px auto;
  border-radius: 4px;
  padding: 10px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
}

body[dir="rtl"] .packageDetails {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.packageheading {
  text-transform: uppercase;
  font-weight: 700 !important;
  margin: 20px 0 0 0;
  text-align: center;
  font-size: 40px;
  color: #5a5a5a !important;
}

.packageDetails p:nth-child(1) {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  font-weight: 700 !important;
}

.packageDetails p:nth-child(2) {
  text-transform: uppercase;
  font-weight: 500 !important;
  margin: 0;
}

.upgradePackageAmoutnandList {
  padding: 0 0 0 50px;
}

.upgradePackageAmoutnandList h2 {
  margin: 10px 0 0 0;
  font-weight: 700;
}

.upgradePackageAmoutnandList ul {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
}

.upgradePackageAmoutnandList > ul li::before {
  content: url(../../../../assets/images/newElements/check.png);
  margin-right: 10px;
  width: 2px;
}

body[dir="rtl"] .upgradePackageAmoutnandList > ul li::before {
  content: url(../../../../assets/images/newElements/check.png);
  margin-right: 0 !important;
  margin-left: 10px !important;
  width: 2px;
}

.upgradePackageAmoutnandList ul li {
  margin: 10px 0;
  font-size: 15px;
}

.packagecard_priceBox_container {
  /* width: 50%; */
  margin: 20px auto;
}

.packagecard_priceBox_container h4 {
  text-transform: lowercase;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 700;
}

.packagecard_priceBox_container p {
  font-size: 10px;
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
}

.packagecard_priceBox_container span {
  text-align: center;
  transition: 0.3s ease-in-out;
  user-select: none;
}

.span-foranually:hover {
  color: #5a5a5a;
  transition: 0.3s ease-in-out;
}

.packagecard_one {
  border: 1px solid #ccc;
  width: 50%;
  margin: 0 auto 10px auto;
}

.selectedPackage_priceDetails {
  padding: 12px 0;
  color: #fff;
  margin: 10px 0;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
}

body[dir="rtl"] .selectedPackage_priceDetails {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}

.selectedPackage_priceDetails h4 {
  color: #fff;
  font-weight: 700;
}

.packagecard_two {
  border: 1px solid #ccc;
  visibility: visible;
  margin: 0 auto 30px auto;
  max-width: 70%;
}

.packagecard_two h4 {
  color: #076db7;
  font-weight: 700;
}

.packagecard_two_visible {
  border: 1px solid #ccc;
  visibility: hidden;
  margin: 0 auto 30px auto;
}

.packagecard_disoucntprice_para {
  text-align: center;
  margin: 0 !important;
}

.selectedpackagecard_disoucntprice_para {
  text-align: center;
  margin: 0 !important;
  font-size: 0.7rem !important;
}

.UpgradeBtnCard {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0 auto;
  border: transparent;
  background: #6172d6;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.UpgradeBtnCard:hover,
.UpgradeBtnCard:active,
.UpgradeBtnCard:focus {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0 auto;
  border: transparent;
  background: #6172d6 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.crossicon {
  color: #5a5a5a;
  font-weight: 700 !important;
}

.crossicon1 {
  color: #ababab;
  font-weight: 700 !important;
}

.span-foranually {
  width: 100%;
  background-color: none;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 500 !important;
  height: 25px;
  margin-top: 3px;
  padding-top: 2px;
}

body[dir="rtl"] .span-foranually {
  width: 100%;
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-right: none;
  border-left: 1px solid #e1e1e1;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  font-weight: 500 !important;
  border-bottom-right-radius: 0;
}

.span-formontly {
  width: 100%;
  background-color: none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-weight: 500 !important;
  height: 25px;
  margin-top: 3px;
  padding-top: 3px;
}

body[dir="rtl"] .span-formontly {
  width: 100%;
  background-color: none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: none !important;
  border-right: 1px solid #e1e1e1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-weight: 500 !important;
}

.spanActive {
  background: #6172d6;
  width: 100%;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: 500 !important;
  height: 30px;
  padding-top: 6px;
}

.packagecard_two {
  border: 1px solid #707070;
  visibility: visible;
  border-radius: 4px;
  max-height: 200px;
  padding: 5px 0;
}

.packagecard_two h4 {
  color: #076db7;
  font-weight: 700;
}

.packagecard_two_visible {
  border: 1px solid #707070;
  visibility: hidden;
  border-radius: 4px;
  max-height: 200px;
  padding: 5px 0;
}

.packagecard_disoucntprice {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #6172d6;
  font-weight: 500 !important;
  padding: 10px 5px;
}

.packagecard_disoucntprice_amount {
  font-weight: 700 !important;
  color: #6172d6;
  font-size: 20px;
}

.packagecard_disoucntprice_text {
  font-size: 14px !important;
  color: #5a5a5a !important;
}

img.package-icon {
  position: absolute;
  top: -11px;
  left: 20px;
  width: 85px;
}

body[dir="rtl"] img.package-icon {
  position: absolute;
  top: -11px;
  left: auto !important;
  right: 20px !important;
  width: 85px;
}
