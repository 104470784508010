.dataroombreadCrumbs {
  color: #6172d6 !important;
  list-style: none !important;
  font-weight: 600;
}
.dataroombreadCrumbs ol li {
  display: flex;
  align-items: center;
}
.dataroombreadCrumbs ol {
  list-style: none !important;
  display: flex !important;
  padding-left: 0px !important;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.dataroombreadCrumbs ol li span.ant-breadcrumb-link {
  width: 100%;
  height: 25px;
  padding: 5px 10px;
  display: block;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataroombreadCrumbs ol li span.ant-breadcrumb-link:not(:first-child) {
  padding: 5px;
}
.dataroombreadCrumbs ol li span.ant-breadcrumb-link:hover {
  background-color: #ecefff !important;
  border-radius: 11px !important;
  width: 100%;
  height: 25px;
  padding: 5px 10px;
}
.ant-popover.overClass .ant-popover-content {
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
}
