.sidebar-container {
  margin: 0;
  padding: 0;
}

.dashboard-row {
  display: -webkit-box !important;
}

.talk-icons-container {
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.Dashboard-Content {
  margin-left: 60px !important;
}

.Dashboard-Main-Container.container-fluid {
  width: 99% !important;
}

body[dir="rtl"] .Dashboard-Main-Container.container-fluid {
  height: 90vh !important;
  padding-right: 0 !important;
  width: 97% !important;
}

.overlay-incoming-videocall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: auto;
  z-index: 8;
}

.dashbaord_data {
  width: 95%;
  height: calc(100vh - 89px);
}
.talk_features_home {
  width: 5%;
}
.mainDashboardLayout {
  min-height: 100vh;
}
.sidebar_layout {
  background: none !important;
}