.empty-search-state {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  height: "100vh";
}

.select-dropdowns-height-DataRoom {
  width: auto !important;
  display: flex;
  align-items: center;
}

.dropdown__Document_Value {
  width: 100%;
  height: 39px;
  padding: 0 7px !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  gap: 5px;
}

.overflow-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.Clear_All_btn {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
  cursor: pointer;
  position: relative;
}

.cancell_Search_button_Dataroom {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  padding: 12px 30px !important;
  border: none !important;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

.custom_date_range_heading {
  font-size: 18px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.datepicker_input {
  height: 39px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  outline: none !important;
  padding: 0 10px !important;
}

.datePickerTodoCreate2 .rmdp-container {
  width: 100% !important;
}

.NoMatchFoundHeading {
  font-weight: 600 !important;
  font-size: 30px !important;
  color: #707070;
  margin-top: 19px !important;
}

.NoMatchFoundSubHeading {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #707070;
  line-height: 13px !important;
}
