.Progress_bar_Loader .ant-progress-text {
  display: none !important;
}

.Progress_bar_Loader .ant-progress-inner .ant-progress-bg {
  height: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important ;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important ;
}
body[dir="rtl"] .Progress_bar_Loader .ant-progress-inner .ant-progress-bg {
  height: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important ;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important ;
}

.Progress_bar_Loader .ant-progress-inner {
  border-radius: 0px !important;
  background-color: none !important;
}
body[dir="rtl"] .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.Progress_bar_Loader {
  position: fixed;
  top: 10px;
  right: 45px !important;
  left: auto;
  width: 100%;
  z-index: 9999;
  font-size: 14px;
} 
.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.Progress_bar_Loader {
  position: fixed;
  top: 10px;
  left: 45px;
  width: 100%;
  z-index: 9999;
  font-size: 14px;
}

/* // for auth screens */
.Progress_bar_Loader_auth .ant-progress-text {
  display: none !important;
}

.Progress_bar_Loader_auth .ant-progress-inner .ant-progress-bg {
  height: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important ;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important ;
}
body[dir="rtl"] .Progress_bar_Loader_auth .ant-progress-inner .ant-progress-bg {
  height: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important ;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important ;
}

.Progress_bar_Loader_auth .ant-progress-inner {
  border-radius: 0px !important;
  background-color: none !important;
}
body[dir="rtl"] .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.Progress_bar_Loader_auth {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 9999;
  font-size: 14px;
} 
.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.Progress_bar_Loader_auth {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 9999;
  font-size: 14px;
}
