/* change color blue to this */
.badge-icon {
  background-color: #2097d6 !important;

  width: 18px;
  height: 18px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
}

/* .MeetingView .modal-content {
  height: 620px !important;
  width: 700px !important;
  border-radius: 4px !important;
} */

.MeetingViewTitleTextField.col-lg-12.col-md-12.col-12 input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 25px !important;
}

.minutes-view {
  overflow-y: auto;
  max-height: 200px;
}

.minutes-box {
  max-height: 280px;
  /* / overflow-y: auto; / */
}

button.dataRoom {
  width: 130px !important;
  /* margin-left: 25px !important; */
}

/* .modalMeetingViewFooter {
    border-top: none !important;
    height: 20% !important;
    max-height: 20% !important;
    min-height: 20% !important;
} */

.modalMeetingViewBody {
  min-height: 70vh !important;
  padding: 30px 20px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.meeting-view-file-icon {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  width: 90px !important;
}

.meeting-view-attendee-organizer-tab {
  color: #6172d6 !important;
  margin-top: 10px !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
  font-weight: 600;
}

.meeting-view-attendee-organizer-list {
  max-height: 100px !important;
  overflow-y: auto !important;
}

.meeting-view-attendee-card-icon {
  font-size: 40px !important;
  color: #00e900;
  margin-left: -15px;
  margin-top: 15px;
}

.meeting-view-attendee-participant-tab {
  color: #6172d6 !important;
  margin-top: 5px !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
  font-weight: 600;
}

.meeting-view-attendee-participant-list {
  height: 200px !important;
  overflow-y: auto !important;
}

.meeting-view-minutes-tab {
  margin-top: 75px !important;
}

.meeting-view-minutes-title {
  font-weight: 600 !important;
  color: #2097d8 !important;
}

.meeting-view-dataroom-attachment {
  margin-top: 10px;
  width: 120px;
  margin-bottom: 10px;
  cursor: pointer;
}

.modalMeetingViewHeader {
  margin-top: 15px !important;
}

.CreateMeetingInput {
  margin-top: -5px !important;
}

.agenda-Upper-btn {
  padding: 0px !important;
  margin-left: 4px !important;
}

.attendees-upper-btn {
  padding: 0px !important;
  margin-left: 18px !important;
}

.minutes-upper-btn {
  /* padding: 0px !important;
  margin-left: 30px !important;
  margin-right: -10px; */
  width: 115px;
}

.attachment-upper-btn {
  padding: 0px !important;
  margin-left: 24px !important;
}

.start-meeting-button {
  background-color: #6172d6 !important;
  width: 130px !important;
  height: 46px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 500 !important;
}

.end-meeting-btn_view {
  background-color: #f16b6b !important;
  outline: none;
  border: none;
  color: #fff !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  /* margin-left: 20px !important; */
}

.end-meeting-btn_view-org {
  background-color: #fff !important;
  outline: none;
  border: 1px solid #f16b6b !important;
  color: #f16b6b !important;
  padding: 12px 30px;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 10px;
  /* margin-left: 20px !important; */
}

.textAreaDivView textarea {
  border: none !important;
}

.isDetailBtn {
  width: 120px !important;
}

.AgendaShowBtn {
  width: 120px !important;
}

.DetaiShowBtn {
  width: 105px !important;
}

.AttendeeShowBtn {
  width: 120px !important;
}

.DataRoomShowBtn {
  width: 130px !important;
  margin-left: 5px !important;
}

.MinuteMeetingBtn {
  width: 110px !important;
  margin-left: -8px;
}

button.DetailUpperBtn {
  width: 105px !important;
}

button.AgendaUpper {
  width: 105px !important;
}

button.AttendeeUpper {
  width: 110px !important;
}

.isDetail-View-top-btn {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #fff !important;
}

body[dir="rtl"] .isDetail-View-top-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isDetail-View-top-btn-Outline {
  color: #666666 !important;
  border-color: #ccc !important;
  padding: 3px 20px !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isAgenda-View-top-btn {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #fff !important;
}

body[dir="rtl"] .isAgenda-View-top-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isAgenda-View-top-btn-Outline {
  color: #666666 !important;
  border-color: #ccc !important;
  padding: 3px 20px !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isAttendee-View-top-btn {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #fff !important;
}

body[dir="rtl"] .isAttendee-View-top-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isAttendee-View-top-btn-Outline {
  color: #666666 !important;
  border-color: #ccc !important;
  padding: 3px 20px !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isMinutes-View-top-btn {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #fff !important;
}

body[dir="rtl"] .isMinutes-View-top-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isMinutes-View-top-btn-Outline {
  color: #505050 !important;
  border-color: #e1e1e1 !important;
  padding: 3px 20px !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.isDataRoom-View-top-btn {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 3px 20px !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #fff !important;
}

body[dir="rtl"] .isDataRoom-View-top-btn {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.isDataRoom-View-top-btn-Outline {
  color: #505050 !important;
  border-color: #e1e1e1 !important;
  padding: 3px 20px !important;
  border-radius: 4px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .isDetail-View-top-btn {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 3px !important;
    padding: 3px 10px !important;
    font-size: 12px !important;
    height: 30px !important;
  }

  .isDetail-View-top-btn-Outline {
    color: #666666 !important;
    border-color: #ccc !important;
    padding: 3px 10px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
    height: 30px !important;
  }

  .isAgenda-View-top-btn {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 3px !important;
    padding: 3px 10px !important;
    font-size: 12px !important;
    height: 30px !important;
    margin-left: 15px !important;
  }

  .isAgenda-View-top-btn-Outline {
    color: #666666 !important;
    border-color: #ccc !important;
    padding: 3px 10px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
    height: 30px !important;
    margin-left: 15px !important;
  }

  .isAttendee-View-top-btn {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 3px !important;
    padding: 3px 10px !important;
    font-size: 12px !important;
    height: 30px !important;
    margin-left: 20px !important;
  }

  .isAttendee-View-top-btn-Outline {
    color: #666666 !important;
    border-color: #ccc !important;
    padding: 3px 10px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
    height: 30px !important;
    margin-left: 20px !important;
  }

  .isMinutes-View-top-btn {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 3px !important;
    padding: 3px 10px !important;
    font-size: 12px !important;
    margin-left: 30px !important;
    margin-right: -10px;
    /* margin-left: -5px; */
    height: 30px !important;
  }

  .isMinutes-View-top-btn-Outline {
    color: #666666 !important;
    border-color: #ccc !important;
    padding: 3px 10px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
    margin-left: 30px !important;
    margin-right: -10px;
    width: 80px !important;
    /* margin-left: -5px; */
    height: 30px !important;
  }

  .isDataRoom-View-top-btn {
    background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
    border: none !important;
    border-radius: 3px !important;
    padding: 3px 6px 4px 7px !important;
    font-size: 12px !important;
    width: 85px !important;
    margin-left: 30px;
    height: 30px !important;
  }

  .isDataRoom-View-top-btn-Outline {
    color: #666666 !important;
    border-color: #ccc !important;
    padding: 3px 6px 4px 7px !important;
    border-radius: 3px !important;
    background: #fff !important;
    font-size: 12px !important;
    margin-left: 30px;
    width: 85px !important;
    height: 30px !important;
  }

  .isDetailBtn {
    width: 60px !important;
  }

  .AgendaShowBtn {
    width: 80px !important;
  }

  .AttendeeShowBtn {
    width: 80px !important;
  }

  .MinuteMeetingBtn {
    width: 80px !important;
    /* margin-left: -8px; */
  }

  .DataRoomShowBtn {
    width: 130px !important;
    margin-left: 5px !important;
  }

  /* .minutes-upper-btn {} */
}

.MeetingViewLocationTextField {
  font-weight: 600;
  color: #505050;
  font-size: 17px !important;
  border: none !important;
}

.MeetingViewTitleTextField span {
  font-size: 20px !important;
  /* margin: 15px 0px 0px 0px; */
  border: none !important;
}

.textAreaDivView textarea {
  border: none !important;
  height: 150px !important;
  overflow: auto;
  font-size: 16px !important;
}

.textAreaDivView textarea::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.textAreaDivView textarea::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.viewModalTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5f78d6 !important;
  word-wrap: break-word !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  font-size: 25px !important;
  line-height: 32px !important;
  max-height: 100px !important;
  height: 93px !important;
  margin: 0 10px !important;
}

.MeetingViewDateTimeTextField {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.MeetingViewLocationText_Field {
  color: #505050;
  font-size: 16px !important;
  border: none !important;
  font-weight: 600 !important;
}

.CopyLinkButton {
  padding: 5px 20px !important;
  height: 39px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  margin-right: 10px;
}

.CopyLinkButton:is(:active, :hover, :focus, :focus-visible) {
  height: 39px !important;
  padding: 5px 20px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.JoinMeetingButton {
  padding: 5px 20px !important;
  height: 39px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.JoinMeetingButton:is(:active, :hover, :focus, :focus-visible) {
  padding: 5px 20px !important;
  height: 39px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: 1px solid#6172d6 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}
.meetingView_documents {
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
}
.closeBtn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}