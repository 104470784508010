.videoCallScreen {
  position: fixed;
  left: 65px;
  width: 90%;
  height: 57vh;
  background-color: #fff !important;
  bottom: 0;
  padding: 10px 15px 15px 15px;
  border: 1px solid #4adede;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  animation: slideUp 0.5s ease-in-out;
  z-index: 99 !important;
}

.videoCallScreen.more-zindex {
  position: fixed;
  left: 65px;
  width: 90%;
  height: 57vh;
  background-color: #fff !important;
  bottom: 0;
  padding: 10px 15px 15px 15px;
  border: 1px solid #4adede;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  animation: slideUp 0.5s ease-in-out;
  z-index: 9999 !important;
}

body[dir="rtl"] .videoCallScreen {
  position: fixed;
  left: auto;
  right: 65px;
  width: 90%;
  height: 55vh;
  background-color: #fff !important;
  bottom: 0;
  padding: 10px 15px 15px 15px;
  border: 1px solid #4adede;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  animation: slideUp 0.5s ease-in-out;
}

.max-video-panel {
  position: fixed;
  height: 89vh;
  top: 70px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 1 !important;
  padding: 0px 30px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.Presenter-Max-VideoPanel {
  position: fixed;
  height: 100vh !important;
  top: 0 !important;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 9999 !important;
  padding: 0px 30px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 100% !important;
  left: 0 !important;
}

.max-video-panel.more-zindex {
  position: fixed;
  height: 89vh;
  top: 70px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 9999 !important;
  padding: 0px 30px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.two-images-before-expand {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 60px;
}

.video-side-icons {
  display: flex;
  justify-content: flex-end !important;
  flex-direction: column !important;
  width: 7%;
  gap: 10px;
  margin-left: 10px;
}

.normal-avatar {
  height: 45vh;
  margin-top: -5px !important;
}

.normal-avatar img {
  width: 100%;
  height: 100%;
}
.ParticipantsWaiting_In {
  animation: slideInRight 0.5s ease-in-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.ParticipantsWaiting_Out {
  animation: slideOutRight 0.5s ease-in-out forwards;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.Participants-Lists {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  position: absolute;
  width: 230px;
  top: 51px;
  right: 50px;
  height: 190px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.Participant-name-title {
  font-weight: 700;
  font-size: 17px;
  color: #6172d6;
}
.participantModal_name {
  white-space: nowrap;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
