.NotificationMessegeUnmarked {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 12.19px !important;
  width: 100% !important;
}

.NotifcationDateStyles {
  line-height: 15.75px !important;
  font-size: 8px !important;
  font-weight: 600 !important;
  color: #6172d6 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: end !important;
}

.SeperateNotificationLine {
  display: inline-block !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
}

.CardSectionInner {
  cursor: pointer !important;
  padding: 0px 8px !important;
}
