.newDashboard {
  max-height: calc(100vh - 89px);
  margin: 15px 15px;
}
.StatsBox {
  min-height: 20vh !important;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
  padding: 15px;
  display: grid;
  align-items: center;
}

.CalendarBox {
  flex: 1 !important;
  background-color: #fff;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  justify-content: center;
  align-items: center;
}
.EventBox {
  padding: 0 10px;
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  margin-bottom: 5px;
}
.Tasks {
  flex: 1;
  width: 100%;
  height: 100%;
}
.RecentActivity {
  flex: 1;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 15px 10px;
}
.Notes {
  flex: 1;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 15px 10px;
}
.EventBox_heading {
  font-weight: 700;
  font-size: 20px;
  color: #5a5a5a;
}
.Events_content {
  overflow-y: auto;
  max-height: 38vh;
  overflow-x: hidden;
  margin-bottom: 10px;
}

/* previous  */

.box {
  height: 100%;
}
.DashboardTask-thead tr th {
  font-weight: 600;
  font-size: 12px;
  color: #6127d6;
}
.DashboardTask-body {
  padding-right: 10px;
}
.DashboardTask-row {
  height: 39px;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
  border-radius: 4px;
}
.DashboardTask-body table {
  border-spacing: 10px !important;
  table-layout: inherit !important;
  border-collapse: separate;
}
.DashboardTask-body table tr {
  border: 1px solid #e1e1e1;
}
.newDashboardTask .ant-table table {
  border-spacing: 0 10px !important;
}
.allow-limit-modal-p {
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 600;
  margin-top: 10px;
}
.allowModalIcon {
  color: #f16b6b;
  border: none !important;
  border-radius: 0px !important;
  margin-top: 25px !important;
}
