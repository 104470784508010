.title-heading {
  /* display: flex;
    align-items: baseline;
    justify-content: start; */
  font-size: 22px !important;
  color: #6172d6 !important;
  margin: auto;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .minimize-screen-on-bottom {
  display: flex;
  align-items: center;
  justify-content: end;
  vertical-align: top;
  gap: 20px;
  margin: 0px 60px;
} */

.minimizeGroup-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 5px;
}

.normal-screen-top-icons {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
}

.top-right-icons {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: -25px;
  gap: 20px;
}

.minimize_icon-class {
  height: 2px;
  margin-top: 13px !important;
  cursor: pointer;
}

.videoCallGroupScreen-minmizeVideoCall {
  position: fixed;
  left: 90px;
  width: 55%;
  height: 45px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  bottom: -1px;
  border-radius: 3px !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* Aun work on RTL in video screens */

body[dir="rtl"] .videoCallGroupScreen-minmizeVideoCall {
  position: fixed;
  right: 70px;
  width: 55%;
  height: 45px;
  background: linear-gradient(to left, #6172d6 0%, #49dbdb 100%) !important;
  bottom: 0;
  border-radius: 3px !important;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.videoCallGroupScreen-minmizeVideoCall .title-heading {
  font-size: 13px !important;
  font-weight: 600;
  color: #fff !important;
  margin-left: 15px !important;
}

/* Aun work on RTL in video screens */
body[dir="rtl"] .videoCallGroupScreen-minmizeVideoCall .title-heading {
  font-size: 13px !important;
  font-weight: 600;
  color: #fff !important;
  margin-right: 15px !important;
}

/* Aun work on RTL in video screens */
body[dir="rtl"] .minimize-video-icon,
body[dir="rtl"] .min-to-normal-icon,
body[dir="rtl"] .min-to-max-icon {
  transform: rotateY(180deg) !important;
}

img.normalize-Icon-Large {
  width: 20px;
  height: 20px;
  margin-top: 6px;
}

.video-menu-popups {
  position: absolute;
  top: 50px;
  right: 14px;
  background: #fff;
  width: 20%;
  padding: 15px 0px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.grayScaleImage {
  filter: grayscale(1);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  margin-right: 10px;
}

.leave-meeting-options {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  width: 215px;
  top: 50px;
  right: -15px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

body[dir="rtl"] .leave-meeting-options {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  width: 215px;
  top: 40px;
  left: 10px !important;
  right: auto !important;
}

.minimize-leave-meeting-options {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  width: 215px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  bottom: 45px;
  right: -88px;
}

body[dir="rtl"] .minimize-leave-meeting-options {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  width: 248px;
  /* Adjust the positioning based on the icon's position */
  bottom: 50px;
  right: 33%;
  /* Use left: 50% to horizontally center the popup relative to the icon */
  transform: translateX(-50%);
  /* Use transform to center the popup precisely */
  height: 130px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.leave-meeting-options__inner {
  text-align: center;
  padding: 0px 20px;
}

.leave-meeting-options__btn {
  border-radius: 3px;
  cursor: pointer;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  text-align: center !important;
}

.leave-meeting-red-button {
  background: #f16b6b;
  border: none;
  color: #fff;
  margin-bottom: 10px;
}

.leave-meeting-red-button:focus,
.leave-meeting-red-button:focus-visible,
.leave-meeting-red-button:hover,
.leave-meeting-red-button:active {
  background: #f16b6b !important;
  border: none !important;
  color: #fff !important;
}

.leave-meeting-gray-button {
  background: #fff;
  color: #5a5a5a;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px;
}

.leave-meeting-gray-button:focus,
.leave-meeting-gray-button:focus-visible,
.leave-meeting-gray-button:hover,
.leave-meeting-gray-button:active {
  background: #fff !important;
  color: #5a5a5a !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px;
}

.screenShare-Toggle {
  border-color: transparent;
  padding: 8px;
  outline: none;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

.screenShare-Toggle:active,
.screenShare-Toggle:focus,
.screenShare-Toggle:focus-within,
.screenShare-Toggle:target,
.screenShare-Toggle:visited,
.screenShare-Toggle:focus-visible {
  background: #6172d6;
  border-color: transparent;
  padding: 8px;
  outline: none;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
}

.screenShare-Toggle:active img {
  filter: brightness(0) invert(1);
}

.Notification-maximize {
  gap: 12px;
  border-radius: 4px;
  background: #6172d6;
  width: 275px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

p.Notification-text {
  margin: 0 !important;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}

.participants-list {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  position: absolute;
  width: 245px;
  top: 50px;
  height: 170px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  left: -105px;
  overflow: hidden auto;
}

.add-participants-list {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  position: absolute;
  width: 245px;
  top: 50px;
  height: 170px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  left: -105px;
  overflow: hidden auto;
  height: 200px;
}

.hostBorder.m-0.row {
  border-bottom: none !important;
  margin-bottom: 0 !important;
}

/* Aun work on minimize Participant List */
.minimize-participants-list {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  position: absolute;
  width: 200px;
  bottom: 40px;
  height: 100px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  left: -85px;
}

.add-participant-button {
  border-radius: 4px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;
  font-size: 11px;
  font-weight: 600;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: 0 auto !important;
}

span.text-positioning {
  position: relative;
  top: 2px;
}

p.participant-name {
  font-size: 12px;
  font-weight: 600;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.participant-state {
  font-size: 12px;
  font-weight: 600;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

body[dir="rtl"] .video_maximize_icon img {
  transform: rotate(-90deg);
}

.minimize-Participant-Toggle {
  border-color: transparent;
  padding: 8px;
  outline: none;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

.minimize-Participant-Toggle:focus,
.minimize-Participant-Toggle:visited,
.minimize-Participant-Toggle:focus-visible {
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-color: transparent;
  padding: 8px;
  outline: none;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  display: flex;
}

.active-state {
  background: #6172d6;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 10px;
}

.inactive-state {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.active-state-end {
  background: #f16b6b;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 10px;
}

.minimize.active-state-end {
  background: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

span.participants-counter {
  position: absolute;
  top: 0;
  right: 8px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  color: #fff;
  width: 16px;
  border-radius: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
}

span.participants-counter-For-Host {
  position: absolute;
  top: 0;
  right: 8px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  color: #fff;
  width: 16px;
  border-radius: 100%;
  text-align: center;
  align-items: center !important;
  font-size: 10px;
  font-weight: 600;
}

span.participants-counter-For-Host-waiting-counter {
  position: absolute;
  top: 0;
  right: -10px;
  background: #55ce5c !important;
  color: #fff;
  width: 16px;
  border-radius: 100%;
  text-align: center;
  align-items: center !important;
  font-size: 10px;
  font-weight: 600;
}

body[dir="rtl"] span.participants-counter-For-Host {
  position: absolute;
  top: 0;
  left: 0px;
  right: auto;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%);
  color: #fff;
  width: 16px;
  border-radius: 100%;
  text-align: center;
  align-items: center !important;
  font-size: 10px;
  font-weight: 600;
}

body[dir="rtl"] span.participants-counter-For-Host-waiting-counter {
  position: absolute;
  top: 0;
  left: -17px;
  right: auto;
  background: #55ce5c !important;
  color: #fff;
  width: 16px;
  border-radius: 100%;
  text-align: center;
  align-items: center !important;
  font-size: 10px;
  font-weight: 600;
}

.minimize {
  margin: 0 !important;
}

.active-state-minimize {
  background: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.for-host-active-state-minimize {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.gap-10 {
  gap: 10px !important;
}

.height100 {
  height: 100%;
}

button.participant-toggle,
button.participant-toggle:hover {
  border: none !important;
}

.addParticipantCheckbox {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
  margin-right: 10px;
}

img.image-style {
  border-radius: 100%;
}

.image-profile-wrapper {
  display: flex;
  align-items: center;
  vertical-align: middle;
  gap: 10px;
}

p.SelectAll {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #171d2d;
  margin: 0 !important;
  margin-left: 10px !important;
}

.participants-section {
  height: 130px;
  overflow: hidden auto;
}

.participants-section {
  height: 155px;
  overflow: hidden auto;
}

img.shade-style {
  position: absolute;
  top: -11px;
}
.MicOffClass {
  width: 35px;
  height: 35px;
  background-color: #6172d6;
  border-radius: 50%;
  object-fit: cover;
}

.handraised-participant {
  margin-left: 10px;
  margin-top: -10px;
}

.videoHide-participant {
  margin-left: 10px;
  margin-top: -10px;
  background-color: #6172d6;
  border-radius: 50%;
  border: none;
}
