.overlay {
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  backdrop-filter: blur(5px);
  left: 0;
  z-index: 99999;
}
.overlay-content {
  margin: 0 !important;
  padding: 20px !important;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  max-width: 650px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.overlay-content h3 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
}
.overlay-content img {
  max-width: 500px;
}
.loader-line {
  width: 250px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #2097d6;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.overlay-content p {
  margin-top: 10px;
  color: #2097d6;
  font-size: 1.2rem;
  font-weight: 800;
}
