.containerforButtons-onboard_forscreen1 {
  width: 100% !important;
  position: fixed;
  height: 85px;
  bottom: 10px;
  z-index: 9999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tour-skip_demo {
  text-decoration: underline !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer !important;
  color: #6172d6 !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 26px !important;
}

.skipButtons-onboard_forscreen1 {
  width: 95px !important;
  height: 40px !important;
  border: none;
  border-radius: 4px !important;
  text-align: center !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.skipButtons-onboard_forscreen2 {
  width: 95px !important;
  height: 40px !important;
  border: none;
  border-radius: 4px !important;
  text-align: center !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.for-icon-next-btn {
  margin-right: -10px;
  font-weight: 600 !important;
  justify-content: space-between !important;
}

.for-icon-prev-btn {
  margin-left: -10px;
  font-weight: 600 !important;
  justify-content: space-between !important;
}

body[dir="rtl"] .for-icon-prev-btn {
  margin: -3px;
  justify-content: space-between !important;
}

body[dir="rtl"] .skipButtons-onboard_forscreen2 {
  width: 95px !important;
  margin-left: 30px;
  height: 40px !important;
  border: none;
  border-radius: 4px !important;
  text-align: center !important;
  background-color: #6172d6 !important;
  font-size: 14px !important;
  letter-spacing: 1px;
  font-weight: 600;
}

body[dir="rtl"] .skipButtons-onboard_forscreen2 svg {
  transform: rotate(180deg);
}

body[dir="rtl"] button.skipButtons-onboard_forscreen1.btn.btn-primary svg {
  transform: rotate(180deg);
}

/* body[dir="rtl"]
  .MuiPaper-root.finalwelcomescreen.MuiPaper-elevation0.MuiPaper-rounded
  svg {
  transform: rotate(180deg);
} */
