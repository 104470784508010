.No_Tasks_View {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 15px;
}
.MainTitleClass {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 28px;
}
.SubtitleTodoMessege {
  color: #767d9b !important;
}

.CreateNewTaskButton {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 600;
}

.CreateNewTaskButton:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}
