.overlay {
  min-height     : 100vh;
  width          : 100%;
  position       : fixed;
  top            : 0;
  backdrop-filter: blur(8px);
  left           : 0;
  z-index        : 99999;
}

.overlay-content {
  margin         : 0 !important;
  padding        : 20px !important;
  font-weight    : bold;
  position       : relative;
  z-index        : 99999;
  max-width      : 100% !important;
  text-align     : center;
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  gap            : 20px;
  text-align     : center;
}


.overlay-content h3 {
  margin-top    : 0;
  color         : #fff;
  font-size     : 1.4rem;
  max-width     : 450px;
  letter-spacing: 0.1rem;
  font-weight   : 900;
}

.text-justify-finalwelcomebox {
  text-align: justify !important;
  margin-top: 7px !important; 
}

.finalwelcomescreen {
  padding: 20px 0;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  /* background-color: #2097d6 !important; */
  width: 550px !important;
}

body[dir="rtl"] .finalwelcomescreen {
  background-color: linear-gradient(to right, #49dbdb 0%, #6172d6 100%);
}