.nameparticipant {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.participant_designation {
  font-size: 10px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Space_limit {
  display: block;
}
.profile_image_shareFolder_modal {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
}

.cross_icon {
  position: absolute;
  right: 0;
  top: 0;
}
