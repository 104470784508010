.ModalHeader_View_poll {
  width: 100% !important;
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  height: auto;
  margin-top: -5px !important;
}

.ModalRequestHeader_polling_View_modal {
  margin: 0 !important;
  padding: 5px 12px !important;
  width: 100%;
}

.BackGroundStrip_viewPoll {
  background: rgba(194, 194, 194, 0.14);
  height: 40px !important;
  width: 100% !important;
}

.BackGroundStrip_viewPoll {
  background: rgba(194, 194, 194, 0.14);
  padding: 5px 5px !important;
  margin: 0 !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.Due_Date_viewPoll {
  font-size: 12px !important;
  font-weight: 600;
  color: #5a5a5a;
}

.View_poll_heading {
  font-size: 20px !important;
  font-weight: 600;
  color: #6172d6;
}

.View_Title {
  font-weight: 500;
  display: flex;
  align-items: center !important;
  font-size: 12px !important;
}

.Multiple_viewModal {
  font-weight: 600;
  font-size: 12px !important;
  color: #5a5a5a !important;
}

.Participants_Heading {
  font-weight: 600;
  font-size: 20px !important;
  color: #5a5a5a !important;
}

.Scroller_For_CreatePollModal2_View_poll {
  height: 23vh !important;
  overflow-x: hidden !important;
}

.Scroller_For_CreatePollModal2_View_poll::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.Scroller_For_CreatePollModal2_View_poll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Box_ViewPoll_after_publish {
  border: 1px solid #e1e1e1;
  height: auto;
  border-radius: 4px !important;
}

.Card_border2 {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  margin-left: 0px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

body[dir="rtl"] .Card_border2 {
  margin-right: 0px !important;
}

.Name_cards {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
  margin-left: 5px !important;
}

.Close_btn_ViewPoll {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.Close_btn_ViewPoll:hover,
.Close_btn_ViewPoll:active,
.Close_btn_ViewPoll:focus,
.Close_btn_ViewPoll:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
}

.View_cross_icon {
  cursor: pointer !important;
  z-index: 1 !important;
}

.OverallPadding {
  padding: 0px 45px !important;
  margin-top: -25px !important;
}

.BOx_for_yes {
  border: 1px solid #e1e1e1;
  height: 39px;
  border-radius: 4px !important;
}

.Update_btn_Published {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}

.Update_btn_Published:hover,
.Update_btn_Published:active,
.Update_btn_Published:focus,
.Update_btn_Published:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #fff !important;
  background: #6172d6 !important;
  border-radius: 4px !important;
  border: none !important;
}
