.video-max-host {
  width: 100%;
  object-fit: cover !important;
  max-height: 77vh;
  padding: 0px 0px;
}

.max-videoHost-panel {
  position: fixed;
  height: 89vh;
  top: 70px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 9999 !important;
  padding: 10px 40px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.max-videoHost-component {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  height: 96%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.max-videoHost-Join-Now-Btn {
  width: 118px;
  height: 39px;
  background-color: #55ce5c;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
}

.max-videoHost-Join-Now-Btn:hover {
  background-color: #55ce5c !important;
  color: #fff !important;
}

.max-videohost-ready-to-join {
  font-size: 30px;
  font-weight: 600;
  color: #5a5a5a;
}

.max-videohost-Icons-state {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.max-Hostvideo-tag-name {
  background: #5a5a5a;
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.max-Hostvideo-waiting-room-class {
  font-weight: 600;
  font-size: 30px;
  color: #6172d6;
  margin-bottom: 1px; /* Adjust the bottom margin to reduce spacing */
  display: flex;
  text-align: center !important;
  align-items: center;
  padding: 0px 40px;
}

.max-Hostvideo-organizer-allow-class {
  font-weight: 600;
  font-size: 16px;
  color: #5a5a5a;
  margin-top: 0; /* Remove any top margin */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  padding: 0px 60px;
}

.max-Hostvideo-left-meeting-text {
  color: #6172d6 !important;
  font-size: 30px;
  font-weight: 600;
  text-align: center !important;
  margin-bottom: 0px;
}

.max-Hostvideo-left-meeting-rejoin-text {
  color: #5a5a5a !important;
  font-size: 20px;
  font-weight: 600;
}

.max-host-gradient-sheet {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  width: 100%;
  height: 100%;
  position: relative;
}

.max-Host-title-name {
  color: #6172d6 !important;
  font-size: 20px;
  font-weight: 600;
}

.max-videoParticipants-new-panel {
  position: fixed;
  height: 55vh !important;
  bottom: 0px !important;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 99 !important;
  padding: 10px 40px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.video-max-Participant-new {
  width: 100%;
  object-fit: cover !important;
  max-height: 45vh;
  padding: 0px 0px;
}

.max-videoHostcomponent-panel {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  height: 98%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
