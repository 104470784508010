.selected_package_details {
  margin: 15px 0;
  border-bottom: 1px solid #ccc;
}
.selected_package_Container {
  background-color: #fff;
  margin: 0;
}
.payoutstandingBox {
  background-color: #fff !important;
  padding: 30px 20px;
  border-radius: 4px;
  border: 1px solid #e1e1e1 !important;
  height: auto !important;
}
