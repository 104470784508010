.ErrorLogBtn {
  max-width: 140px;
  background-color: #6172d6 !important;
  border: none;

  padding: 12px 30px;
}
.ErrorWrapperContainer {
  max-width: 600px;
  margin: auto;
  min-height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
}
.error-heading {
  color: #6172d6;
  font-size: 24px;
  font-weight: 600;
}
.error-text {
  color: #5a5a5a;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
