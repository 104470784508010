button.ant-switch.ant-switch-checked .ant-switch-handle::before {
  border-radius: 3px !important;
  width: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 14px !important;
  top: -3px !important;
  left: 0px !important;
  transition: all 0.5s ease-in-out !important;
  background: linear-gradient(to left, #4adede 0%, #6172d6 100%) !important;
  opacity: 1 !important;
}

body[dir="rtl"]
  button.ant-switch.ant-switch-checked
  .ant-switch-handle::before {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}
body[rtl] .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 11px - 0px) !important;
}
button.ant-switch .ant-switch-handle::before {
  border-radius: 3px !important;
  width: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 14px !important;
  top: -3px !important;
  left: 0px !important;
  transition: all 0.5s ease-in-out !important;
  background: #4adede !important;
  opacity: 90% !important;
}

button.ant-switch .ant-switch-handle::after[disabled] {
  opacity: 0.5 !important;
}

button.ant-switch .ant-switch-handle::before[disabled] {
  opacity: 0.5 !important;
}

body[dir="rtl"] button.ant-switch .ant-switch-handle::before {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%) !important;
}

.ant-switch-handle {
  left: 0px !important;
}
body[dir="rtl"] .ant-switch-handle {
  left: 7px !important;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 10.5px - 0px) !important;
}
body[dir="rtl"] .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 30.5px - 0px) !important;
}
.ant-switch {
  border-radius: 3px;
  min-width: 25px;
  width: 10px !important;
  height: 14px;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
}
.ant-switch {
  filter: grayscale(0.5) !important;
}
.ant-switch-checked {
  filter: grayscale(0) !important;
}

body[dir="rtl"] .ant-switch-checked {
  background: linear-gradient(to right, #4adede 0%, #6172d6 100%);
}
