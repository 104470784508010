.pendingApprovalContainer {
  width: 98% !important;
  height: 91vh !important;
  margin: 0 auto !important;
  overflow: hidden !important;
}

.pendingApprovalHeading {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #6172d6;
}

section.emptyScreen-height {
  height: 65vh;
}

.pendingApprovalPaper {
  border: 1px solid #e1e1e1;
  height: 80vh;
  overflow: hidden auto;
  border-radius: 8px !important;
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
  padding: 0 20px;
}

.pendingApprovalPaper::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 20px !important;
  background-color: #f2f2f2 !important;
}
.pendingApprovalPaper::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #a2a2a2 !important;
  margin-left: -15px !important;
}

.overallGap {
  display: flex;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.activeMinutes,
.activeMinutes:active,
.activeMinutes:hover,
.activeMinutes:focus,
.activeMinutes:target,
.activeMinutes:visited,
.activeMinutes:focus-visible,
.activeMinutes:focus-within {
  background: #6172d6 !important;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  border-color: #6172d6 !important;
  border-radius: 3px;
  line-height: 15px;
  position: relative;
}

.inActiveMinutes,
.inActiveMinutes:active,
.inActiveMinutes:hover,
.inActiveMinutes:focus,
.inActiveMinutes:target,
.inActiveMinutes:visited,
.inActiveMinutes:focus-visible,
.inActiveMinutes:focus-within {
  background: #fff !important;
  padding: 8px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #d4d4d4 !important;
  color: #5a5a5a !important;
  border-radius: 3px;
  position: relative;
}

.progressWrapper {
  margin-top: 15px;
  width: 100%;
  height: 89px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 25px;
  margin-bottom: 25px;
}

span.line {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  display: block;
  height: 70px;
  width: 3px;
  margin-top: -15px;
}

.progress-value-wrapper-purple {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-yellow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-red {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
}

.progress-value-wrapper-purple span {
  color: #6172d6;
}

.progress-value-wrapper-yellow span {
  color: #ffc300;
}

.progress-value-wrapper-red span {
  color: #f16b6b;
}

span.value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

span.numeric-value {
  /* font-family: "MontserratSemiBold"; */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
}

p.expiredStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #f16b6b;
}

p.reviewedStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #6172d6;
}

p.pendingStatus {
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffc300;
}
.No-Approvals {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #5a5a5a;
}

.No-Approvals-Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5a5a5a;
}

.FilterResetBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.FilterResetBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.ResetOkBtn:is(:hover, :active, :focus, :focus-visible) {
  padding: 8px 10px !important;
  border-radius: 4px !important;
  background: linear-gradient(to bottom, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
  font-size: 10px !important;
  border: none !important;
}

.pendingCountValue {
  position: absolute;
  right: -5px;
  top: -8px;
  background: #6172d6;
  border-radius: 50%;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pendingCountValue_hidden {
  display: none;
}
.pendingSignatureValue {
  position: absolute;
  right: -5px;
  top: -8px;
  background: #6172d6;
  border-radius: 50%;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pendingSignatureValue_hidden {
  display: none;
}
