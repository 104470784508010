.user-login-history-heading {
  color: #6172d6;
  font-weight: 600 !important;
  margin: 0;
  padding: 0;
}
.user-login-history-table-paper {
  min-height: 40vh !important;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  padding: 30px 30px 30px 30px;
  border-radius: 8px !important;
  border: 1px solid #e1e1e1 !important;
  --Paper-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: var(--Paper-shadow);
  display: inline-block !important;
  background: #fff !important;
}
.export-to-excel-btn {
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  font-size: 0.8em;
  border-radius: 4px !important;
  width: 160px;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 5px;
  cursor: pointer;
  margin-top: -4px;
}
.report_search_Box {
  position: relative;
}

.userLoginHistory_Box {
  width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #e1e1e1 !important;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  padding: 10px;
}
.searchbox_icon_userhistoryLogin {
  position: absolute !important;
  right: 10px;
  top: 10px;
  left: auto;
  cursor: pointer;
}
body[dir="rtl"] .searchbox_icon_userhistoryLogin {
  left: 10px;
  right: auto;
}
.ResetBtn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #e1e1e1 !important;
  color: #5a5a5a !important;
}
.SearchBtn {
  padding: 12px 30px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #6172d6 !important;
  border: none !important;
  color: #fff !important;
}
.datePicker_Container {
  width: 100% !important;
  display: block !important;
}
.UserLoginHistory_datePicker {
  width: 100% !important;
  border: 1px solid #e1e1e1 !important;
  height: 39px !important;
  padding: 0 10px;
  cursor: pointer !important;
}
.UserLoginHistory_datePicker:is(:focus, :focus-visible) {
  outline: none !important;
}

.SearchablesItems {
  padding: 5px 30px !important;
  border-radius: 4px !important;
  background: #6172d6;
  display: flex;
  gap: 25px !important;
}

.Searches {
  font-size: 12px !important;
  color: #fff !important;
}

.NameStylesTable {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #505050 !important;
}

body[dir="rtl"] .NameStylesTable {
  font-weight: 700 !important;
}

.DesignationStyles {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #505050 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

body[dir="rtl"] .DesignationStyles {
  font-weight: 400 !important;
}
