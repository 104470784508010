.max-Participant-Reject-Videopanel {
  position: fixed;
  height: 89vh;
  top: 70px;
  background-color: #fff !important;
  border: 1px solid #4adede !important;
  border-radius: 4px !important;
  z-index: 9999 !important;
  padding: 10px 40px;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
  width: 90%;
  left: 65px;
}

.max-Participant-Reject-Video {
  margin-top: 10%;
  height: auto !important;
  border-radius: 16px;
  background: transparent;
}

.max-Participant-Denied-Main-Heading {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  color: #6172d6 !important;
}

.Sub-max-Participant-Denied-Main-Heading {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #5a5a5a !important;
}
