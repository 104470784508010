.UnsaveheadingFileUpload {
  font-size: 15px !important;
  color: #5f78d6 !important;
  font-weight: 600;
}

.No_unsave_File_Upload {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;
}

.No_unsave_File_Upload:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
  background: #fff !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1 !important;
  font-weight: 600;
}

.Yes_unsave_File_Upload {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.Yes_unsave_File_Upload:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 600;
}
