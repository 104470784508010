p.add-deadline {
  margin: 0 !important;
  margin-top: 10px !important;
  font-size: 20px;
  font-weight: 600;
  color: #5a5a5a;
}

.modalBodyClass {
  margin-top: -20px;
}
