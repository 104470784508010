.Create_polls_Button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center !important;
  gap: 5px;
  border: none;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
}

body[dir="rtl"] .Create_polls_Button {
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Create_polls_Button:is(:hover, :focus, :focus-visible, :active) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.Vote_Button_Polls {
  width: 64px !important;
  height: 23px !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 8px !important;
  color: #6172d6 !important;
  background: #ecefff !important;
  font-weight: 600 !important;
}

.Vote_Button_Polls:is(:hover, :active, :focus, :focus-visible) {
  width: 64px !important;
  height: 23px !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 8px !important;
  color: #6172d6 !important;
  background: #ecefff !important;
  font-weight: 600 !important;
}

.Not_Vote_Button_Polls {
  width: 64px !important;
  height: auto !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 8px !important;
  color: #fff !important;
  background: #81db86 !important;
  font-weight: 600 !important;
}

.Not_Vote_Button_Polls:is(:hover, :active, :focus, :focus-visible) {
  width: 64px !important;
  height: auto !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 8px !important;
  color: #fff !important;
  background: #81db86 !important;
  font-weight: 600 !important;
}

.DateClass {
  color: #2197d7 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer;
}

.EmptyState_heading {
  color: #6172d6 !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}

.EmptyState_subHeading {
  color: #5a5a5a !important;
  font-weight: 500;
  font-size: 16px !important;
}

.Cancel_Button_Polls_meeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  border: 1px solid#E1E1E1;
  color: #5a5a5a;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Button_Polls_meeting:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Save_Button_Polls_meeting:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none;
  color: #fff;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.MaintainingHeight {
  height: 55vh !important;
}

.PaginationStyle-Resolution {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  width: auto !important;
  padding: 12px 40px !important;
  margin: 20px 0 40px 0;
  height: auto !important;
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 4px !important;
}

.PaginationStyle-Resolution:is(:focus-visible) {
  outline: none !important;
}

body[dir="rtl"] .PaginationStyle-Resolution {
  width: auto !important;
  padding: 12px 10px !important;
}

.poll_status {
  font-size: 14px;
  font-weight: 400 !important;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.Background-nonvoted-Button {
  max-width: 109px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  height: 42px !important;
  color: #6172d6 !important;
  background-color: #ecefff !important;
}

.votedBtn {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 8px !important;

  width: 64px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 20px !important;
  font-weight: 600 !important;
}
.votedBtn:is(:hover, :active, :focus, :focus-visible, :visited) {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 8px !important;
  width: 64px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 20px !important;
  font-weight: 600 !important;
}

.ViewVotesButtonStyles {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 95px !important;
  font-weight: 600 !important;
}

.ViewVotesButtonStyles:is(:hover, :focus, :focus-visible, :active) {
  background-color: #ecefff !important;
  border: none !important;
  color: #6172d6 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  width: 95px !important;
  font-weight: 600 !important;
}
