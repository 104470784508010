.filterdrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}

.btnEditReset {
  width: 120px !important;
  height: 34px !important;
  border: none;
  border-radius: 2px;
  background-color: #076db7;
}

.selectbox-Edit-organizationrole {
  margin-top: 15px !important;
  width: 100% !important;
}

.Edit-label-heading {
  font-weight: 600 !important;
  font-size: 35px !important;
}

.Edit-Name-label {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600 !important;
}

.Edit-Update-Btn {
  margin: 20px 0;
  width: 140px !important;
  height: 33px !important;
  border: none;
  border-radius: 0px;
  background-color: rgb(117, 117, 117);
}

.Edit-Update-Btn:hover {
  background-color: rgb(117, 117, 117);
}

.Edit-modal-container {
  padding: 10px !important;
}

.Edit-Main-Heading {
  font-size: calc(38px - 50%) !important;
  color: #6172d6 !important;
  font-weight: 700 !important;
}

body[dir="rtl"] .Edit-Main-Heading {
  font-weight: 600 !important;
}

.successfull-label {
  text-align: center !important;
  font-size: 14px;
  font-weight: 600 !important;
  display: grid;
}

.Ok-Successfull-btn {
  width: 90px !important;
  height: 35px !important;
  border: 1px solid #000000;
  border-radius: 0px;
  background-color: #fff !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.Ok-Successfull-btn:hover {
  background-color: #000000 !important;
  color: #fff !important;
  border: none !important;
}

.label.filter.form-label {
  display: none !important;
}

.searchbar-textfield {
  position: relative !important;
}

.formcontrol-names-fields {
  margin-top: 20px;
  width: 100% !important;
  height: 33px !important;
  border-radius: 2px !important;
}

.icon-modal-ResetBtn {
  width: 100px !important;
  height: 33px !important;
  border: none;
  border-radius: 0px;
  background-color: rgb(117, 117, 117);
}

.icon-filtermodal-ResetBtn {
  width: 110px !important;
  height: 33px !important;
  border: 1px solid #000000;
  border-radius: 0px;
  background-color: #fff !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.icon-filtermodal-ResetBtn:hover {
  background-color: rgb(117, 117, 117) !important;
  color: #fff !important;
  border: none !important;
}

.icon-filtermodal-SearrcchhBtn {
  width: 75% !important;
  height: 33px !important;
  border: 1px solid #000000;
  border-radius: 0px;
  background-color: #fff !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.icon-filtermodal-SearrcchhBtn:hover {
  background-color: rgb(123, 120, 120) !important;
  color: #fff !important;
  border: none !important;
}

.formcontrol-fieldfor-filtermodal {
  margin-top: 20px;
  width: 100% !important;
  height: 33px !important;
  border-radius: 2px !important;
}

.formcontrol-fieldselectfor-filtermodal {
  margin-top: 15px !important;
  width: 100% !important;
}

.formcontrol-Phone-field {
  margin-top: 20px;
}

.filterModal {
  position: absolute;
  top: 7px;
  right: 23px;
  cursor: pointer;
}

body[dir="rtl"] .filterModal {
  right: auto;
  left: 30px;
  cursor: pointer;
}

.deleteModal-message {
  font-size: 15px;
  font-weight: 600 !important;
  text-align: center !important;
}

.name-error-Message {
  margin: 0;
  height: 7px;
  color: #f16b6b;
  visibility: visible !important;
}

.name-errorMessage_hidden {
  visibility: hidden;
  margin: 0;
  height: 7px;
  color: #f16b6b !important;
}

.form-control-textfields {
  margin-top: 20px;
  width: 100% !important;
  height: 39px !important;
  border-radius: 4px !important;
}

.form-control-textfields:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.date-range {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.icon-PaymentHistory-ResetBtn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: black !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

body[dir="rtl"] .icon-PaymentHistory-ResetBtn {
  font-weight: 500 !important;
}

.icon-PaymentHistory-ResetBtn:hover {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: black !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.icon-PaymentHistory-SearchBtn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 30px;
  font-weight: 500 !important;
  font-size: 12px;
}

body[dir="rtl"] .icon-PaymentHistory-SearchBtn {
  font-weight: 500 !important;
}

.icon-PaymentHistory-SearchBtn:hover,
.icon-PaymentHistory-SearchBtn:focus,
.icon-PaymentHistory-SearchBtn:active,
.icon-PaymentHistory-SearchBtn:visited {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6 !important;
  border-radius: 4px !important;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 30px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

body[dir="rtl"] .icon-PaymentHistory-SearchBtn {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 0 !important;
  font-weight: 500 !important;
  font-size: 12px;
  margin-left: 30px !important;
}

body[dir="rtl"] .icon-PaymentHistory-SearchBtn:hover {
  width: 128px !important;
  height: 46px !important;
  border: 1px solid #6172d6;
  border-radius: 4px;
  background-color: #6172d6 !important;
  color: #fff !important;
  margin-right: 0 !important;
  font-weight: 500 !important;
  font-size: 12px;
  margin-left: 30px !important;
}

.container-payment {
  padding: 12px 30px !important;
}

.surcharge {
  font-size: 11px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .surcharge {
  font-weight: 400 !important;
}

.icon-PaymentHistory-DeleteBtn {
  width: 240px !important;
  height: 46px !important;
  border-radius: 4px !important;
  background-color: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.icon-PaymentHistory-DeleteBtn:hover {
  width: 240px !important;
  height: 46px !important;
  border-radius: 4px !important;
  background-color: #5f78d6 !important;
  color: #fff !important;
  border: none !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.Delete-Successfull-label {
  font-size: 14px;
  font-weight: 600;
  text-align: center !important;
  color: #6172d6;
}

.Account-Deleted-label {
  font-size: 25px;
  text-align: center !important;
  color: #5a5a5a;
  font-weight: 600 !important;
}

.allowModalIcon {
  color: #f16b6b;
  border: none !important;
  border-radius: 0px !important;
}

.InvoiceStart-Date-filtermodalmeeting {
  margin-top: 5px;
  width: 100% !important;
  height: 35px !important;
  border-radius: 4px !important;
}

.paymentHistoryTable table {
  padding: 20px;
}
.paymentHistoryTable .ant-table-thead tr {
  color: #6172d6 !important;
  font-size: 2rem !important;
  border-bottom: 1px solid #ccc !important;
}
.paymentHistoryTable table thead tr th {
  border-bottom: 1px solid #d6d6d6 !important;
}

.paymentHistoryTable::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.paymentHistoryTable::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2 !important;
}

.paymenthistory_searchbar {
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
}
.paymenthistory_searchbar::placeholder {
  font-weight: 500 !important;
  color: #000000;
  opacity: 32%;
}

body[dir="rtl"] .paymenthistory_searchbar::placeholder {
  font-weight: 500 !important;
}
.paymenthistory_searchbar:hover,
.paymenthistory_searchbar:focus {
  box-shadow: none !important;
}

.DownloadButton {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .DownloadButton {
  font-weight: 600 !important;
}

.DownloadButton:is(:hover, :focus, :focus-visible, :active) {
  background: #fff !important;
  color: #5a5a5a !important;
  padding: 12px 30px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.NameStylesTable {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #505050 !important;
}

body[dir="rtl"] .NameStylesTable {
  font-weight: 700 !important;
}

.DesignationStyles {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #505050 !important;
}

body[dir="rtl"] .DesignationStyles {
  font-weight: 400 !important;
}

.NoPaymentHistoryMainHeading {
  font-weight: 700 !important;
  font-size: 30px !important;
  color: #5a5a5a !important;
}

.SubHeadingTaglineNoPaymentHistory {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #5a5a5a !important;
}
