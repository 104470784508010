.AddMoreBtn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center !important;
  gap: 5px;
  border: none;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
}

.AddMoreBtn:hover,
.AddMoreBtn:active,
.AddMoreBtn:focus,
.AddMoreBtn:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  display: flex;
  gap: 5px;
  border-radius: 4px !important;
  font-weight: 500 !important;
  border: none !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.Cancel_Organization {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Cancel_Organization:hover,
.Cancel_Organization:active,
.Cancel_Organization:focus,
.Cancel_Organization:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600 !important;
}

.Next_Organization {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #5f78d6;
  color: #fff;
  font-weight: 600 !important;
}
.Next_Organization:hover,
.Next_Organization:active,
.Next_Organization:focus,
.Next_Organization:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Edit_Button_Organizers {
  padding: 12px 30px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
  background: #fff;
  color: #707070;
  display: flex;
  gap: 5px;
}

.Edit_Button_Organizers:hover,
.Edit_Button_Organizers:active,
.Edit_Button_Organizers:focus,
.Edit_Button_Organizers:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  display: flex;
  gap: 5px;
  border: 1px solid #e1e1e1 !important;
  background: #fff !important;
  font-weight: 500 !important;
  color: #707070 !important;
}

.definedHeight {
  height: 44px !important;
}
.Cancel_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}
.Next_Button_Organizers_view {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Next_Button_Organizers_view:is(:active, :hover, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #6172d6 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Cancel_Meeting_Details {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid#E1E1E1 !important;
  color: #5a5a5a !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.Empty_state_heading {
  color: #505050 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.Empty_state_Subheading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500;
}
.height2 {
  min-height: 60vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
