.AddMoreBtn {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center !important;
  gap: 5px;
  border: none;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff;
}

.AddMoreBtn:hover,
.AddMoreBtn:active,
.AddMoreBtn:focus,
.AddMoreBtn:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  display: flex !important;
  gap: 5px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  border: none !important;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  color: #fff !important;
}

.Cancel_Organization {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Cancel_Organization:is(:hover, :active, :focus, :focus-visible) {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}

.Next_Organization {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  background: #6172d6;
  color: #fff;
  font-weight: 600 !important;
}
.Next_Organization:hover,
.Next_Organization:active,
.Next_Organization:focus,
.Next_Organization:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #5f78d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.Options_classs_contributors {
  color: #505050 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.Empty_state_heading {
  color: #505050 !important;
  font-size: 30px !important;
  font-weight: 600 !important;
}

.Empty_state_Subheading {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 500;
}

.label_Styles {
  color: #5a5a5a !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.OverAll_padding {
  padding: 6px 30px !important;
}

.option {
  width: 100% !important;
  overflow-x: hidden !important;
}

.option:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.Footer_Class {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10px !important;
  position: relative !important;
  margin: 15px 0;
}
.Footer_Class2 {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10px !important;
  position: relative !important;
  margin: 15px 0;
  height: 44px !important;
}
.Save_button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  border: none !important;
  background: #6172d6 !important;
  color: #fff !important;
}
.Cancel_button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
}
.Edit_button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  display: flex !important;
  gap: 5px !important;
}

.select-class-agendaContri {
  display: flex;
  overflow: hidden !important;
  overflow-x: hidden !important;
}
.publish_button_AgendaContributor {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.publish_button_AgendaContributor:hover,
.publish_button_AgendaContributor:active,
.publish_button_AgendaContributor:focus,
.publish_button_AgendaContributor:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  background: #6172d6 !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.height2 {
  min-height: 60vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
