.Committee {
  background: #fff !important;
  width: 100% !important;
  height: 315px !important;
  border: 1px solid#81DB86 !important;
  border-radius: 4px !important;
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
}

.Committee_InActive {
  background: #fff !important;
  width: 100% !important;
  height: 315px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
}

.Committee_Archived {
  background: #fff !important;
  width: 100% !important;
  height: 315px !important;
  border: 1px solid #6172d6 !important;
  border-radius: 4px !important;
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
}

.Dropdown-container-Committee {
  background: #fff !important;
  height: auto !important;
  width: 213px !important;
  z-index: 99999 !important;
  position: absolute;
  top: 46px !important;
  right: 18px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
  font-weight: 600;
}

.HR-line-Committee-group {
  background-color: #5a5a5a !important;
  margin: 1px !important;
  border: 1px solid #5a5a5a;
}

.Dropdown-container-editIcon-Committee-Group {
  background: #fff !important;
  height: auto !important;
  width: 103px !important;
  z-index: 99999 !important;
  position: absolute;
  top: 45px !important;
  right: 44px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 5px #ccc;
  font-weight: 600;
}

.Active-status-Committee-Group-background {
  background-color: #ebf8ef !important;
  padding-top: 7px !important;
  height: 37px !important;
  color: #81db86 !important;
  width: 100% !important;
  font-size: 15px !important;
  border-radius: 2px !important;
}

.Archived-Status-Committee-Group {
  background: #ebeeff !important;
  color: #6172d6 !important;
  width: 100% !important;
  font-weight: 600;
  font-size: 16px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

body[dir="rtl"] .Archived-Status-Committee-Group {
  font-weight: 600;
}

.In-Active-status-Committee-Group-background {
  background-color: #e1e1e1 !important;
  color: #5a5a5a !important;
  display: flex;
  justify-content: center;
  height: 37px !important;
  align-items: center;
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 600;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.Archived-status-Committee-Group-background {
  background-color: #ebeeff !important;
  color: #ebeeff !important;
  height: 37px !important;
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 600;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Status-Committee-Group {
  font-weight: 600;
}

body[dir="rtl"] .Status-Committee-Group {
  font-weight: 600;
}

.group-icon-Committee-Group {
  height: 55px;
  width: 55px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 30px;
  font-size: 20px !important;
  padding: 10px !important;
}

.group-icon-Committee-Group_InActive {
  height: 55px;
  width: 55px;
  background: linear-gradient(to left, #49dbdb 0%, #6172d6 100%) !important;
  border-radius: 30px;
  font-size: 20px !important;
  padding: 10px !important;
  opacity: 50%;
}

body[dir="rtl"] .group-icon-Committee-Group {
  margin-left: 18px !important;
  background: linear-gradient(to right, #49dbdb 0%, #6172d6 100%) !important;
}

.Tagline-Committee-Group {
  line-height: 1.3rem !important;
}

.card-heading-Committee-Group {
  font-size: 20px !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

body[dir="rtl"] .card-heading-Committee-Group {
  font-weight: 600;
}

.card-heading-Committee-Group_InActive {
  font-size: 20px !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 50%;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

body[dir="rtl"] .card-heading-Committee-Group_InActive {
  font-weight: 600;
}
.card-heading-Committee-Group_InActive_Archeive {
  font-size: 20px !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 50%;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
body[dir="rtl"] .card-heading-Committee-Group_InActive_Archeive {
  font-weight: 600;
}

.namesCards-Committee-Group {
  width: 35px !important;
  font-size: 10px !important;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize !important;
  height: 14px !important;
}

body[dir="rtl"] .namesCards-Committee-Group {
  font-weight: 400;
}

.roundnumber {
  background-color: #6172d6;
  height: 30px !important;
  width: 30px !important;
  border-radius: 20px !important;
  color: #ffffff;
  font-size: 12px !important;
  padding: 6px !important;
}

.Two-Icons-style-Committee-Group {
  position: absolute;
  top: 35px;
  right: 0;
  cursor: pointer;
}

.Two-Icons-style-Committee-Group_InActive {
  position: absolute;
  top: 35px;
  right: 0;
}

body[dir="rtl"] .Two-Icons-style-Committee-Group {
  position: absolute;
  top: 35px;
  left: 15px;
}

.update-Committee-btn {
  height: 39px;
  width: 247px;
  font-weight: 600;
  font-size: 12px !important;
  background: #6172d6;
  color: #fff;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .update-Committee-btn {
  font-weight: 600;
}

.update-Committee-btn:hover {
  background-color: #6172d6 !important;
}

.update-Group-btn:hover {
  background-color: #6172d6 !important;
}

body[dir="rtl"] .Dropdown-container-Committee {
  left: auto !important;
  right: 9px !important;
  font-weight: 600;
}

body[dir="rtl"] .Dropdown-container-editIcon-Committee-Group {
  left: auto !important;
  right: 39px !important;
}

.Dropdown-container-Committee:hover {
  border-color: #6172d6 !important;
  border-radius: 4px !important;
}

.ContainerForcards {
  max-width: 345px !important;
  height: 315px !important;
  border: 1px solid#81DB86 !important;
  border-radius: 4px !important;
  width: 100% !important;
  position: relative;
}

.profile_cards {
  margin: 0 auto;
  width: 260px;
  display: inline-block;
  text-align: center !important;
}

.card_profile_box {
  text-align: center !important;
  justify-content: center;
  display: grid;
  margin: 0px 0 !important;
}

.card_profile_box_InActive {
  text-align: center !important;
  justify-content: center;
  display: grid;
  margin: 10px 0 !important;
  opacity: 50%;
}

.namecards_morethan-3 {
  display: block;
  width: 36.48px !important;
  height: 36.48px !important;
  border-radius: 50%;
  background-color: #6172d6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.namecards_morethan-3_InActive {
  display: block;
  width: 36.48px !important;
  height: 36.48px !important;
  border-radius: 50%;
  background-color: #6172d6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  opacity: 50%;
}

.Edit_icon_styles {
  cursor: pointer !important;
}

.dot_icon_styles {
  cursor: pointer !important;
}

body[dir="rtl"] .dot_icon_styles {
  cursor: pointer !important;
  position: absolute !important;
  right: 10px !important;
}

body[dir="rtl"] .Edit_icon_styles {
  cursor: pointer !important;
  position: absolute !important;
  right: 39px !important;
}

.associated_tagLine {
  font-size: 8px !important;
}

.associated_tagLine_groupTitle {
  font-size: 8px;
  color: #6172d6 !important;
  font-weight: 400;
}

.associated_tagLine_groupTitle_single {
  font-size: 8px;
  color: #6172d6 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  width: auto !important;
  max-width: 100px;
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.Edit_icon_styles_InActive {
  background: #f4f9ff !important;
  width: 20px !important;
}

.dot_icon_styles_InActive {
  pointer-events: none;
}

.associated_tagLine_single {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 9px;
  white-space: nowrap;
}
.dropdown-text {
  cursor: pointer;
}
.dropdown-text-disable {
  opacity: 50%;
  cursor: default;
}
