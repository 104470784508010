.Red_bar_Background {
  background-color: rgba(241, 107, 107, 0.2) !important;
  padding: 10px 10px !important;
  margin: 0 !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.Images {
  opacity: 100% !important;
}

.ModalHeader {
  width: 100% !important;
  margin: 0;
  padding: 0;
  border-radius: 8px !important;
  height: auto;
  margin-top: -15px !important;
  border-radius: 20px !important;
}

.ModalRequestHeader {
  margin: 0 !important;
  padding: 15px 10px !important;

  width: 100%;
}

.text_for {
  font-size: 12px !important;
  font-weight: 600;
  color: #f16b6b;
}

.Share_a_folder {
  font-size: 20px !important;
  color: #6172d6;
  font-weight: 600;
}

.exclaimation_mark {
  position: absolute !important;
  left: 170.5px !important;
}

.Accessrequest {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Bold_heading {
  font-size: 12px !important;
  color: #6172d6;
  font-weight: 700;
}

.Email {
  font-size: 8px !important;
  color: #6172d6;
  font-weight: 600;
}

.Line_height {
  line-height: 7px !important;
}

.Allow_Access {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Box_Folder {
  width: 134px !important;
  padding: 12px 0px;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  margin-left: 10px !important;
  margin-top: 3px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Folder_name {
  font-size: 12px !important;
  color: #5a5a5a;
  font-weight: 600;
}

.Open_sharing_button {
  padding: 12px 30px !important;
  color: #ffffff;
  background: #6172d6 !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-weight: 600;
  font-size: 12px !important;
}

.Open_sharing_button:is(:hover, :active, :visited, :focus, :focus-visible) {
  padding: 12px 30px !important;
  color: #ffffff;
  background: #6172d6 !important;
  border: 1px solid #e1e1e1;
  border-radius: 4px !important;
  font-weight: 600;
  font-size: 12px !important;
}

.ModalBodyClass {
  padding: 20px 35px 10px 35px;
}
