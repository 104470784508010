.gridViewFile__name {
  background-color: #fff;
  border-top: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  display: flex;
  padding: 12px 16px !important;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  user-select: none !important;
}

.gridViewFolder__name {
  background-color: #fff;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px;
  display: flex;
  gap: 10px !important;
  padding: 12px 16px !important;
  justify-content: space-between;
  margin-bottom: 10px !important;
  position: relative;
  cursor: pointer;
}

.folderName__text {
  font-weight: 600;
  color: #5a5a5a !important;
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folderFile__text {
  font-weight: 600;
  color: #5a5a5a !important;
  font-size: 12px !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.FolderHeading {
  color: #6172d6;
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 10px !important;
  margin-left: 10px;
  margin-top: 10px;
}

.fileview__Box {
  height: auto !important;
  border: 1px solid #e1e1e1 !important;
  margin-bottom: 10px !important;
  border-radius: 4px !important;
}

.folderContainer {
  min-height: 55vh !important;
  height: auto !important;
  overflow-x: hidden;
}

.folderContainer::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5em;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.folderContainer::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.Name_heading__gridView {
  color: #6172d6 !important;
  font-size: 16px !important;
  font-weight: 600;
  position: relative !important;
  margin-left: 10px;
  display: block;
  gap: 12px !important;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  max-width: 110px;
}

.border_bottom__gridView {
  height: 1px;
  width: 100%;
  display: block !important;
  background-color: #e1e1e1 !important;
}

.three_dot__gridView {
  cursor: pointer;
  position: relative;
}

.FilterDropDown_GridView {
  position: absolute;
  padding: 5px 10px;
  width: 170px !important;
  height: auto !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  z-index: 1;
  background-color: #fff;
  margin-left: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
}

.NavLink__filter {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter:is(:hover) {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter:last-child {
  border: none !important;
}

.NavLink__filter {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
  display: flex;
}

.NavLink__filter:is(:hover) {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter:last-child {
  border: none !important;
}

.FilterDropDown_TableView {
  padding: 5px 10px;
  width: 120px !important;
  height: auto !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  z-index: 99999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
  left: 90%;
}

.NavLink__filter_file {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
  display: flex;
}

.NavLink__filter_file:is(:hover) {
  border-bottom: 1px solid #5a5a5a !important;
  padding: 5px 0;
  color: #5a5a5a;
  font-weight: 600;
}

.NavLink__filter_file:last-child {
  border: none !important;
}

.FilterDropDown_TableView_file {
  padding: 5px 10px;
  width: 120px !important;
  height: auto !important;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  z-index: 99999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
  left: 90%;
}

.options_dropdown button {
  border: none !important;
  padding: 5px 0px !important;
}

.options_dropdown button:is(:hover, :active, :focus, :focus-visible, :visited) {
  border: none !important;
}

.sortIconGrid {
  cursor: pointer !important;
}
.dots_Icons {
  z-index: -1 !important;
}