.Resolution_cancell_discard {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff;
  color: #5a5a5a;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: 1px solid#E1E1E1 !important;
}

body[dir="rtl"] .Resolution_cancell_discard {
  font-weight: 600 !important;
}

.Resolution_cancell_discard:hover,
.Resolution_cancell_discard:active,
.Resolution_cancell_discard:focus,
.Resolution_cancell_discard:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: 1px solid#E1E1E1 !important;
}

.Resolution-Discard-button {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fb9494;
  color: #fff;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: none !important;
}

body[dir="rtl"] .Resolution-Discard-button {
  font-weight: 600 !important;
}

.Resolution-Discard-button:hover,
.Resolution-Discard-button:focus,
.Resolution-Discard-button:active,
.Resolution-Discard-button:focus-visible {
  padding: 12px 30px !important;
  font-size: 12px !important;
  background: #fb9494 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  border: none !important;
}

.Heading_For_Discard_resolution {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #5a5a5a !important;
}

body[dir="rtl"] .Heading_For_Discard_resolution {
  font-weight: 600 !important;
}
