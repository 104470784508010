.Viewnote-heading {
  color: #6172d6 !important;
  font-size: 20pt !important;
  font-weight: 600 !important;
  margin: 0 !important;
  padding: 0 !important;
}

body[dir="rtl"] .Viewnote-heading {
  font-weight: 600 !important;
}

.date-updatenote {
  font-size: 8pt !important;
  color: #505050 !important;
}

body[dir="rtl"] .date-updatenote {
  font-weight: 400 !important;
}

.close-note-modal-btn {
  background: white !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 4px !important;
  padding: 12px 30px !important;
  font-size: 14px !important;
  color: #5a5a5a !important;
  cursor: pointer !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .close-note-modal-btn {
  font-weight: 600 !important;
}

.modal-row {
  display: contents !important;
}

.modal-update-attachment-heading {
  font-size: 10pt !important;
  font-weight: 600 !important;
}

body[dir="rtl"] .modal-update-attachment-heading {
  font-weight: 600 !important;
}

.modal-View-title {
  font-size: 20px;
  color: #5a5a5a !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  word-wrap: break-word !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}

body[dir="rtl"] .modal-View-title {
  font-weight: 600 !important;
}

.modal-View-title::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.modal-View-title::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}

.modal-view-discription {
  font-size: 12px;
  color: #5a5a5a;
  font-weight: 400 !important;
  max-height: 125px !important;
  overflow-y: auto;
}

body[dir="rtl"] .modal-view-discription {
  font-weight: 400 !important;
}

.modal-view-discription p {
  word-wrap: break-word !important;
}
.modal-view-discription::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 20px;
  background-color: #f2f2f2;
}

.modal-view-discription::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #ccc;
  margin-left: -15px;
}
.NotesViewAttachment {
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
}
