.datePicker_createTodo {
  max-width: 100% !important;
  width: 100% !important;
  height: 39px !important;
  border: 1px solid #e1e1e1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #000 !important;
  margin-left: 15px !important;
}

.datePicker_Value {
  color: #000 !important;
}

.datePicker_createTodo svg {
  right: auto !important;
}

.datepicker_input {
  padding: 0px 10px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da;
  width: 100% !important;
  height: 39px !important;
  font-weight: 500;
  font-size: 12px !important;
  font-weight: 600 !important;
  cursor: pointer;
}
.datepicker_input:is(:hover, :focus, :focus-visible, :visited) {
  outline: none !important;
}

.datePickerTodoCreate2 {
  border: 1px solid #e1e1e1 !important;
  font-weight: 600 !important;
  box-shadow: 0 0 5px #8798ad !important;
}

.proposedMeetindatesDatePicker .rmdp-container {
  width: 500px !important;
}

.datepicker_input_minute {
  padding: 0px 10px !important;
  border-radius: 4px !important;
  border: 1px solid #e1e1e1;
  width: 227px !important;
  height: 39px !important;
  cursor: pointer;
}

.datepicker_input_minute:is(:hover, :focus, :focus-visible, :visited) {
  outline: none !important;
}

.datepicker_input_minute_sendreview {
  padding: 0px 10px !important;
  border-radius: 4px !important;
  border: 1px solid #f16b6b;
  height: 39px !important;
  cursor: pointer;
  width: 100%;
  color: #f16b6b !important;
}

.datepicker_input_minute_sendreview:is(
    :hover,
    :focus,
    :focus-visible,
    :visited
  ) {
  outline: none !important;
}

.datepicker_input_minute_sendreview::placeholder {
  color: #f16b6b !important;
}
