.comment-wrapper {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  height: 42px;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  margin: 0px 15px 10px 0px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
}

p.comment-text {
  margin: 0 !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #5a5a5a;
}
